// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qx8_7sAjHt58pjem89Kg {
  background: var(--card-color);
  border-radius: 12px;
  height: 40px;
  width: 100%;
}
.qx8_7sAjHt58pjem89Kg .ZBdJGEgOA6R3C8NlCgeS {
  color: var(--text-secondary-color);
  font-size: 14px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.xtqaFHWsgPN2mFrIbueU {
  height: 75px;
}

.NkBdLeRRwfcegZM2PTBu {
  height: calc(100vh - 290px);
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/pages/admin/user-roster/enroll-user/enroll-user.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AACF;AACE;EACE,kCAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,2BAAA;EACA,gBAAA;AAAF","sourcesContent":[".csvButton {\n  background: var(--card-color);\n  border-radius: 12px;\n  height: 40px;\n  width: 100%;\n\n  .csvButtonText {\n    color: var(--text-secondary-color);\n    font-size: 14px;\n    line-height: 24px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n}\n\n.headerRow {\n  height: 75px;\n}\n\n.tableLayout {\n  height: calc(100vh - 290px);\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"csvButton": `qx8_7sAjHt58pjem89Kg`,
	"csvButtonText": `ZBdJGEgOA6R3C8NlCgeS`,
	"headerRow": `xtqaFHWsgPN2mFrIbueU`,
	"tableLayout": `NkBdLeRRwfcegZM2PTBu`
};
export default ___CSS_LOADER_EXPORT___;
