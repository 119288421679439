import React from 'react';
import { Control, Controller, DeepMap, FieldError, FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import Select, { MultiValue, SingleValue } from 'react-select';
import styles from './app-select.module.scss';

export type FieldErrors<TFieldValues extends FieldValues = FieldValues> = DeepMap<TFieldValues, FieldError>;

export interface Option {
  value: string;
  label: string | JSX.Element;
  data?: any;
}

interface IProps extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  errorMessage?: string;
  label?: string;
  onIconClick?: () => void;
  onSelectOption?: (option: Option) => void;
  options: Option[];
  register?: UseFormRegisterReturn;
  errors?: FieldErrors;
  control?: Control<any, any>;
  isMultiSelect?: boolean;
  onChangeOption?: (option: Option) => void;
  selectedValue?: Option | Option[] | undefined;
  borderRadiusStyle?: 'Normal' | 'Right';
  menuHeight?: string;
  isRequired?: boolean;
}

const AppSelect = (props: IProps) => {
  const {
    className,
    label,
    options,
    errors,
    isMultiSelect,
    control,
    onChangeOption,
    selectedValue,
    borderRadiusStyle = 'Normal',
    menuHeight = '300px',
    isRequired,
    ...rest
  } = props;

  const style = {
    control: (base: any) => ({
      ...base,
      border: '0 !important',
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
      borderRadius: '12px',
      height: '40px',
      cursor: 'pointer',
      backgroundColor: styles.appSelectInput,
    }),
    singleValue: (base: any) => ({
      ...base,
      color: styles.appSelectInput,
    }),
    input: (base: any) => ({
      ...base,
      color: styles.appSelectInput,
    }),
    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? 'var(--text-secondary-color)' : 'var(--text-secondary-color)',
      backgroundColor: state.isSelected ? '#dddddd22' : null,
      '&:hover': {
        backgroundColor: '#dddddd22',
        color: 'var(--text-secondary-color)',
      },
    }),
    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      borderRadius: '8px',
      boxShadow: '0px 6px 16px 0px rgba(193, 193, 193, 0.25)',
      backgroundColor: 'var(--card-color)',
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: menuHeight,
      overflow: 'auto',
    }),
    multiValueLabel: (defaultStyles: any) => ({
      ...defaultStyles,
      color: 'var(--text-secondary-color)',
    }),
    multiValueRemove: (defaultStyles: any) => ({
      ...defaultStyles,
      color: 'var(--text-secondary-color)',
      backgroundColor: ' #F6F6F6',
      height: 30,
      ':hover': {
        color: '#000000',
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      padding: '2px',
      margin: '16px',
      width: '8px',
      height: '5px',
      cursor: 'pointer',
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 5' fill='none'%3E%3Cpath d='M3.3 4.3L0.7 1.7C0.38 1.38 0.31 1.02 0.49 0.61C0.66 0.2 0.98 0 1.42 0H6.58C7.02 0 7.34 0.2 7.51 0.61C7.69 1.02 7.62 1.38 7.3 1.7L4.7 4.3C4.6 4.4 4.49 4.48 4.38 4.52C4.26 4.57 4.13 4.6 4 4.6C3.87 4.6 3.74 4.57 3.63 4.52C3.51 4.48 3.4 4.4 3.3 4.3Z' fill='%23969FA1'/%3E%3C/svg%3E")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      transition: state.isFocused ? '0.2s' : '0.2s',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      '& svg': {
        fill: 'transparent',
      },
      '&:hover svg': {
        fill: 'transparent',
      },
    }),
    clearIndicator: base => ({
      ...base,
      color: 'var(--text-secondary-color)',
      ':hover': {
        color: 'var(--text-primary-color)',
      },
    }),
  };

  return (
    <div className={`${className}`}>
      {label && (
        <label className={`${styles.label}`}>
          {label}
          {isRequired && <span className={`${styles.required}`}> *</span>}
        </label>
      )}
      <div
        className={`${styles.appSelectInput}  ${
          control ? rest.name && errors && errors[rest.name]?.message && styles.hasDanger : errors && styles.hasDanger
        } `}
        style={borderRadiusStyle === 'Right' ? { borderRadius: '12px 0 0 12px' } : { borderRadius: '12px' }}
      >
        {control ? (
          <Controller
            name={rest.name}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={options}
                styles={style}
                isMulti={isMultiSelect}
                placeholder={rest.placeholder}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            )}
          />
        ) : (
          <Select
            options={options}
            styles={style}
            isMulti={isMultiSelect}
            placeholder={rest.placeholder}
            components={{
              IndicatorSeparator: () => null,
            }}
            onChange={(newValue: SingleValue<Option> | MultiValue<Option>) => {
              const option: Option = newValue as Option;
              onChangeOption && onChangeOption(option);
            }}
            value={selectedValue}
          />
        )}
      </div>
      {rest.id && <span className={`${styles.errorMessage}`}>{control ? errors && errors[rest.id]?.message : errors.message}</span>}
    </div>
  );
};

export default AppSelect;
