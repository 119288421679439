import { AUTHORITIES } from 'app/config/constants';
import { IRootState, useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { ETheme } from 'app/shared/model/enumerations/theme.model';
import React from 'react';
import { BsBell, BsBook, BsBoxArrowRight, BsCash, BsGear, BsHouseDoor } from 'react-icons/bs';
import PrimaryNavBarMenuItem from './app-primary-nav-bar-menu-item/app-primary-nav-bar-menu-item';
import styles from './app-primary-nav-bar.module.scss';
interface IProps {
  imagePath: string;
  userFirstName: string;
  userLastName: string;
}

const AppPrimaryNavBar = (props: IProps) => {
  const authState = useAppSelector(state => state.authentication);
  const isAuthorizedAdmin = hasAnyAuthority(authState.account.authorities, [AUTHORITIES.ADMIN]);
  const { theme } = useAppSelector((state: IRootState) => ({ theme: state.theme.theme }));

  const topItemsAdmin = [
    { title: 'Home', link: '', icon: BsHouseDoor },
    { title: 'Courses', link: '/admin/courses', icon: BsBook },
    { title: 'Notices', link: '/notices', icon: BsBell },
    { title: 'Payment', link: `/admin/payment`, icon: BsCash },
  ];

  const topItemsStudent = [
    { title: 'Home', link: '', icon: BsHouseDoor },
    { title: 'Courses', link: '/student/courses', icon: BsBook },
    { title: 'Notices', link: '/notices', icon: BsBell },
  ];

  const bottomItems = [
    {
      title: `${props.userFirstName}\n ${props.userLastName}`,
      link: isAuthorizedAdmin ? '/admin/account/profile' : '/student/account/profile',
      image: `${props.imagePath || '.././././././content/images/default-profile.png'}`,
    },
    {
      title: 'Settings',
      link: isAuthorizedAdmin ? '/admin/settings/coupons' : '/student/settings',
      icon: BsGear,
    },
    { title: 'Log Out', icon: BsBoxArrowRight, link: '/logout' },
  ];

  return (
    <div className={`vh-100 d-flex flex-column justify-content-between py-4 ${styles.primaryNavBar}`}>
      <div>
        <div>
          <img
            src={theme === ETheme.DARK ? 'content/images/SKF_ALAthwalaLogo_Dark@2x.webp' : 'content/images/SKF_ALAthwalaLogo_Light@2x.webp'}
            alt="Athwela- skill surf logo"
            className={`d-block mx-auto mb-3 mt-2 ${styles.logoImg}`}
          />
        </div>
        <div>
          {(isAuthorizedAdmin ? topItemsAdmin : topItemsStudent).map((item, index) => (
            <PrimaryNavBarMenuItem key={index} {...item} />
          ))}
        </div>
      </div>
      <div>
        {bottomItems.map((item, index) => (
          <PrimaryNavBarMenuItem key={index} {...item} userProfileImage={item.image} />
        ))}
      </div>
    </div>
  );
};

export default AppPrimaryNavBar;
