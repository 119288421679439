import React, { useEffect, useState } from 'react';
import styles from './app-toggle.module.scss';

interface IProps {
  initialState: boolean;
  disabled?: boolean;
  onToggle?: (newState: boolean) => void;
}

const AppToggle = (props: IProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(props.initialState);
  }, [props.initialState]);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (props.onToggle) {
      props.onToggle(!isChecked);
    }
  };
  return (
    <div className="form-switch">
      <input
        className={`${isChecked && styles['checkedFormSwitch']} form-check-input cursor-pointer ${styles.formSwitch}`}
        type="checkbox"
        id="flexSwitchCheckDefault"
        checked={isChecked}
        onChange={handleToggle}
        disabled={props.disabled}
      />
    </div>
  );
};

export default AppToggle;
