// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i7jbsz_BBFEw0q2cqpXo {
  background: var(--card-secondary-color);
  padding-top: 24px;
  padding-bottom: 27px;
}
.i7jbsz_BBFEw0q2cqpXo .FMKOWhiH8Gk70FKVx5su {
  color: var(--text-primary-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.i7jbsz_BBFEw0q2cqpXo .guzF6TSLbBkKCj0Poa6Y {
  color: var(--text-secondary-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/nav-bar/app-secondary-nav-bar/app-secondary-nav-bar.module.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,iBAAA;EACA,oBAAA;AACF;AACE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AACE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ","sourcesContent":[".courseHeader {\n  background: var(--card-secondary-color);\n  padding-top: 24px;\n  padding-bottom: 27px;\n\n  .courseTitle {\n    color: var(--text-primary-color);\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n  }\n  .courseSubTitle {\n    color: var(--text-secondary-color);\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseHeader": `i7jbsz_BBFEw0q2cqpXo`,
	"courseTitle": `FMKOWhiH8Gk70FKVx5su`,
	"courseSubTitle": `guzF6TSLbBkKCj0Poa6Y`
};
export default ___CSS_LOADER_EXPORT___;
