// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuRnlEX2gV0RKD9uN33K {
  background-color: var(--card-color);
}

.iFhSWdhxY1zvqq_GfKIY {
  color: var(--text-primary-color);
}
.iFhSWdhxY1zvqq_GfKIY p {
  font-size: 18px;
  margin-bottom: 0;
}

.yxwM212qnfmhOmHc3kMI {
  background-color: var(--background-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/program-catalog/catalog-header/catalog-header.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;AACF;;AAEA;EACE,gCAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;AACJ;;AAGA;EACE,oDAAA;AAAF","sourcesContent":[".catalogHeaderContainer {\n  background-color: var(--card-color);\n}\n\n.catalogHeader {\n  color: var(--text-primary-color);\n\n  p {\n    font-size: 18px;\n    margin-bottom: 0;\n  }\n}\n\n.catalogSearch {\n  background-color: var(--background-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"catalogHeaderContainer": `QuRnlEX2gV0RKD9uN33K`,
	"catalogHeader": `iFhSWdhxY1zvqq_GfKIY`,
	"catalogSearch": `yxwM212qnfmhOmHc3kMI`
};
export default ___CSS_LOADER_EXPORT___;
