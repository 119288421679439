import { PayloadAction, createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { EntityState, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { chapterObjConvertIdsToStringsForDND } from 'app/shared/util/dnd-utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import {
  IArrangeChapterOfCoursePayload,
  IChapterManagerChapter,
  ICourseChapterManager,
  IGetChapterOfCoursePayload,
  defaultValue,
} from './course-chapter-manager.model';

const initialState: EntityState<ICourseChapterManager> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/admin/chapter-sequence';
const apiChapterCRUD = 'api/admin/chapters';
const arrangeChaptersAPI = 'api/admin/courses/chapter-sequence';

// Actions
export const getChapterOfCourse = createAsyncThunk(
  'courseChapterManager/fetch_entity',
  async (data: IGetChapterOfCoursePayload) => {
    const requestUrl = data.unitId
      ? `${apiUrl}/${data.courseId}/${data.lessonId}/${data.unitId}`
      : `${apiUrl}/${data.courseId}/${data.lessonId}`;
    return axios.get<ICourseChapterManager>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const arrangeChapters = createAsyncThunk(
  'courseChapterManager/arrange-chapter',
  async (data: IArrangeChapterOfCoursePayload, thunkAPI) => {
    const result = await axios.post<ICourseChapterManager>(arrangeChaptersAPI, cleanEntity(data));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'courseChapterManager/delete_entity',
  async (data: IGetChapterOfCoursePayload, thunkAPI) => {
    const requestUrl = `${apiChapterCRUD}/${data.id}`;
    const result = await axios.delete<IChapterManagerChapter>(requestUrl);
    thunkAPI.dispatch(getChapterOfCourse(data));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice
export const CourseChapterManagerSlice = createEntitySlice({
  name: 'courseChapterManager',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getChapterOfCourse.fulfilled, (state, action) => {
        action.payload;
        state.loading = false;
        action.payload.data.chapters = chapterObjConvertIdsToStringsForDND('chapter', action.payload.data.chapters as any) as any;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(arrangeChapters, deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getChapterOfCourse), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(arrangeChapters, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
  reducers: {
    updateChaptersHandler(state, action: PayloadAction<IChapterManagerChapter[]>) {
      state.entity.chapters = action.payload;
    },
  },
});

export const { reset, updateChaptersHandler } = CourseChapterManagerSlice.actions;

// Reducer
export default CourseChapterManagerSlice.reducer;
