import React, { FC, useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { Col, Row } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { BsFillCaretDownFill } from 'react-icons/bs';
import NavProfileListItem, { IListItem } from './list-views/nav-profile-list-item';
import styles from './nav-profile.module.scss';

interface IProps {
  firstName: string;
  lastName: string;
  items: IListItem[];
  image?: string;
  onClick?: (key: string) => void;
  isMobileNav?: boolean;
}

const NavProfile: FC<IProps> = props => {
  const [collapse, setCollapse] = useState<boolean>(false);

  const ref = useRef(null);

  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setCollapse(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <Row ref={ref}>
      <Col xs="auto">
        <Row
          className="align-items-center"
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <Col>
            <Avatar name={props.firstName + props.lastName} round={'12px'} size="32" src={props.image ? props.image : null} />
          </Col>
          <Col className={`${props.isMobileNav ? styles.mobileNavProfileFont : styles.navProfileFont} px-0`}>
            <Row>
              <Col>{props.firstName}</Col>
            </Row>
            <Row>
              <Col>{props.lastName}</Col>
            </Row>
          </Col>

          <Col>
            <BsFillCaretDownFill className={`cursor-pointer`} size={16} color={props.isMobileNav ? '#ffffff' : 'var(--accent-color)'} />
          </Col>
        </Row>
        <Collapse in={collapse} dimension="height">
          <div
            onMouseLeave={e => {
              setCollapse(false);
              e.stopPropagation();
            }}
            className={`${styles.customDropdownList} position-absolute mt-2 z-index-100`}
          >
            {props.isMobileNav ? (
              <NavProfileListItem itemList={props.items} onClick={props.onClick} isMobileNav />
            ) : (
              <NavProfileListItem itemList={props.items} onClick={props.onClick} />
            )}
          </div>
        </Collapse>
      </Col>
    </Row>
  );
};

export default NavProfile;
