import { PayloadAction, createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { EntityState, createStudentContentEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICourseChapterPresenter, defaultValue } from './course-chapter-presenter.model';

export interface CourseChapterPresenterEntityState<T> extends EntityState<T> {
  currentChapterIndex: number;
}

const initialState: CourseChapterPresenterEntityState<ICourseChapterPresenter> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  currentChapterIndex: 0,
};

const apiUrl = 'api/chapter-sequence-student';
const apiPostUrl = '/api/record-progress';
const adminPreviewUrl = 'api/chapter-sequence-preview';

export const getAdminEntityPreview = createAsyncThunk(
  'courseChapterPresenter/fetch_preview_entity',
  async (params: { courseId: number; lessonId: number; unitId?: number }) => {
    const requestUrl = params.unitId
      ? `${adminPreviewUrl}/${params.courseId}/${params.lessonId}/${params.unitId}`
      : `${adminPreviewUrl}/${params.courseId}/${params.lessonId}`;
    return axios.get<ICourseChapterPresenter>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'courseChapterPresenter/fetch_entity',
  async (params: { enrollmentId: number; lessonId: number; unitId?: number }) => {
    const requestUrl = params.unitId
      ? `${apiUrl}/${params.enrollmentId}/${params.lessonId}/${params.unitId}`
      : `${apiUrl}/${params.enrollmentId}/${params.lessonId}`;
    return axios.get<ICourseChapterPresenter>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const recordUserProgress = createAsyncThunk(
  'courseChapterPresenter/recordUserProgress',
  async (entity: { enrollmentId: number; chapterId: number }, thunkAPI) => {
    const result = await axios.post<{ enrollmentId: number; chapterId: number }>(apiPostUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const courseChapterPresenterSlice = createStudentContentEntitySlice({
  name: 'courseChapterPresenter',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getAdminEntityPreview.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
        state.currentChapterIndex = initialState.currentChapterIndex;
      })
      .addMatcher(isFulfilled(recordUserProgress), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity.chapters[state.currentChapterIndex].completed = true;
        if (state.currentChapterIndex !== undefined && state.currentChapterIndex !== state.entity.chapters.length - 1) {
          state.currentChapterIndex += 1;
        }
      })
      .addMatcher(isPending(getEntity, getAdminEntityPreview), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(recordUserProgress), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.updating = true;
      });
  },
  reducers: {
    nextChapterHandler(state) {
      state.entity.chapters[state.currentChapterIndex].completed = true;
      if (state.currentChapterIndex !== undefined && state.currentChapterIndex !== state.entity.chapters.length - 1) {
        state.currentChapterIndex += 1;
      }
    },
    previousChapterHandler(state) {
      if (state.currentChapterIndex !== undefined && state.currentChapterIndex !== 0) {
        state.currentChapterIndex -= 1;
      }
    },
    navigateToSpecificChapter(state, action: PayloadAction<number>) {
      state.currentChapterIndex = action.payload;
    },
    recordUserProgressChapter(state, action: PayloadAction<number>) {
      state.currentChapterIndex = action.payload;
    },
  },
});

export const { reset, nextChapterHandler, previousChapterHandler, navigateToSpecificChapter } = courseChapterPresenterSlice.actions;

export default courseChapterPresenterSlice.reducer;
