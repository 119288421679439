import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import Pagination from 'app/shared/components/pagination/pagination';
import { ESortOrder } from 'app/shared/model/enumerations/sort-types.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppSearch from 'app/shared/ui-elements/app-search/app-search';
import AppSortIcon from 'app/shared/ui-elements/app-sort-icon/app-sort-icon';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './user-roster.module.scss';
import { getCourseEnrolledUsers, getCourseEnrolledUsersAsClusters } from './user-roster.reducer';

const userRoster = () => {
  const itemsPerPage = 10;

  const params = useParams() as { courseId: string };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { enrolledUsers, enrolledUsersCluster, totalItems } = useAppSelector((rootState: IRootState) => ({
    enrolledUsers: rootState.userRoster.enrolledUsers,
    enrolledUsersCluster: rootState.userRoster.enrolledUsersCluster,
    totalItems: rootState.userRoster.totalItems,
  }));
  const [sortedEmail, setSortedEmail] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedName, setSortedName] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedAccountId, setSortedAccountId] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedEnrolled, setSortedEnrolled] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedStatus, setSortedStatus] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');

  const arrowSort = (sortType: 'email' | 'name' | 'accountId' | 'enrolled' | 'status') => {
    setSortedEmail(prev =>
      sortType === 'email' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedName(prev =>
      sortType === 'name' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedAccountId(prev =>
      sortType === 'accountId' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedEnrolled(prev =>
      sortType === 'enrolled' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedStatus(prev =>
      sortType === 'status' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
  };

  const sortParam = `${
    sortedEmail !== ESortOrder.NOT_SORT ? `email,${sortedEmail === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}` : ''
  }${
    sortedName !== ESortOrder.NOT_SORT ? `firstName,${sortedName === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}` : ''
  }${
    sortedAccountId !== ESortOrder.NOT_SORT
      ? `index,${sortedAccountId === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}`
      : ''
  }${
    sortedEnrolled !== ESortOrder.NOT_SORT
      ? `enrolledDate,${sortedEnrolled === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}`
      : ''
  }
  ${
    sortedStatus !== ESortOrder.NOT_SORT
      ? `status,${sortedStatus === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}`
      : ''
  }
  `;

  useEffect(() => {
    dispatch(getCourseEnrolledUsers({ courseId: Number(params.courseId) }));
    dispatch(
      getCourseEnrolledUsersAsClusters({ courseId: Number(params.courseId), page: currentPage - 1, size: itemsPerPage, sort: sortParam })
    );
  }, []);

  const fetchData = () => {
    dispatch(
      getCourseEnrolledUsersAsClusters({
        courseId: Number(params.courseId),
        searchText,
        page: currentPage - 1,
        size: itemsPerPage,
        sort: sortParam,
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, sortParam]);

  const onSearch = () => {
    if (currentPage === 1) fetchData();
    else {
      setCurrentPage(1);
    }
  };

  return (
    <>
      <Row className="justify-content-between">
        <Col className="col-12 col-md-4">
          <AppSearch onChange={setSearchText} onSearch={onSearch} placeholder={'Search by email'} value={searchText} />
        </Col>
        <Col className="col-12 col-md-2 mt-3 mt-md-0">
          <AppButton text={'Enroll User'} onClick={() => navigate(`enroll-user`)} />
        </Col>
      </Row>

      <Row>
        <Col className={`mt-4`}>
          <Table responsive className="w-100">
            <thead>
              <tr>
                <th>
                  <div className={`d-flex align-items-center`}>
                    <AppSortIcon onChange={() => arrowSort('email')} sortedItem={sortedEmail} text={'Email'} />
                  </div>
                </th>
                <th>
                  <div className={`d-flex align-items-center`}>
                    <AppSortIcon onChange={() => arrowSort('name')} sortedItem={sortedName} text={'Name'} />
                  </div>
                </th>
                <th>
                  <div className={`d-flex align-items-center`}>
                    <AppSortIcon onChange={() => arrowSort('accountId')} sortedItem={sortedAccountId} text={'Account ID'} />
                  </div>
                </th>
                <th>
                  <div className={`d-flex align-items-center`}>
                    <AppSortIcon onChange={() => arrowSort('enrolled')} sortedItem={sortedEnrolled} text={'Enrolled'} />
                  </div>
                </th>
                <th>
                  <div className={`d-flex align-items-center`}>
                    <AppSortIcon onChange={() => arrowSort('status')} sortedItem={sortedStatus} text={'Status'} />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {enrolledUsersCluster.map((user, index) => {
                return (
                  <tr key={index}>
                    <td className={`${styles.txt2}`}>{user.email}</td>
                    <td className={`${styles.txt2}`}>
                      {user.firstName} {user.lastName}
                    </td>
                    <td className={`${styles.txt2}`}>{user.index}</td>
                    <td className={`${styles.txt2}`}>{moment(user.enrolledDate).format('L')}</td>
                    <td className={`${styles.txt2}`}>-</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

      {enrolledUsers.length > itemsPerPage && (
        <Row>
          <Col className="mb-3">
            <Pagination
              length={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              updateCurrentPage={(currentPageNumber: number) => {
                setCurrentPage(currentPageNumber);
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default userRoster;
