import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { handlePasswordResetFinish } from 'app/modules/account/password-reset/password-reset.reducer';
import AccountSideImageSection from 'app/shared/components/account-side-image-section/account-side-image-section';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import { passwordRegex } from 'app/shared/util/regex';
import { showSuccessToast } from 'app/shared/util/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './change-password.module.scss';

type ChangePasswordFormValues = {
  password: string;
};

const ChangePassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const { resetPasswordSuccess } = useAppSelector((rootState: IRootState) => ({
    resetPasswordSuccess: rootState.passwordReset.resetPasswordSuccess,
  }));

  const password = useRef({});
  password.current = watch('password', '');

  useEffect(() => {
    if (resetPasswordSuccess) {
      showSuccessToast('Password reset successfully.', 'resetPasswordSuccess');
      navigate('/login');
    }
  }, [resetPasswordSuccess]);

  const onSubmit: SubmitHandler<ChangePasswordFormValues> = (data: ChangePasswordFormValues) => {
    const key = searchParams.get('key');
    const payload = { key, newPassword: data.password };
    dispatch(handlePasswordResetFinish(payload));
  };

  return (
    <>
      <Container className="py-lg-5 py-md-3 h-100">
        <Row className="align-items-center justify-content-evenly h-100">
          <Col className={`col-12 col-lg-4`}>
            <AccountSideImageSection />
          </Col>

          <Col className={`col-12 col-lg-5 ${styles.formSection}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col className={`${styles.changePasswordTitle} text-center pb-1`}>Reset Password?</Col>
              </Row>
              <Row>
                <Col className={`${styles.changePasswordTextStyle} pb-5`}>Create a new strong password</Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <AppInput
                    id="password"
                    name="password"
                    label="Enter New Password"
                    placeholder={'Password'}
                    type={showPassword ? 'text' : 'password'}
                    icon={!showPassword ? BsEyeSlash : BsEye}
                    onIconClick={() => setShowPassword(!showPassword)}
                    register={register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 8,
                        message:
                          'Your password must be at least 8 characters long, contain at least one number, special character, and have a mix of uppercase and lowercase letters.',
                      },
                      pattern: {
                        value: passwordRegex,
                        message:
                          'Your password must be at least 8 characters long, contain at least one number, special character, and have a mix of uppercase and lowercase letters.',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-4">
                  <AppInput
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm New Password"
                    placeholder={'Confirm password'}
                    type={showConfirmPassword ? 'text' : 'password'}
                    icon={!showConfirmPassword ? BsEyeSlash : BsEye}
                    onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    register={register('confirmPassword', {
                      required: 'Confirm password is required',
                      validate: value => value === password.current || 'The passwords do not match',
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="mb-4 mt-5">
                  <AppButton type="submit" text={'Confirm'} />
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChangePassword;
