import { uploadFile } from 'app/services/file-upload-service';
import { EUploadFileType } from 'app/shared/model/enumerations/upload-file-type';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import { convertURL } from 'app/shared/util/course';
import React, { FC, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { BsChevronDown, BsChevronRight, BsTrashFill } from 'react-icons/bs';
import { v4 as uuidv4 } from 'uuid';
import ImageUploader, { ImageUploaderType } from '../image-uploader/image-uploader';
import styles from './multiple-rows-addition-field.module.scss';

export interface IRowInputsData {
  firstInputLabel: string;
  secondInputLabel: string;
  firstInputBracketLabel: string;
  secondInputBracketLabel: string;
  firstInputPlaceholder: string;
  secondInputPlaceholder: string;
}

export interface IRowData {
  id: number;
  firstInput: string;
  secondInput: string;
  isValidFirstInput: boolean;
  isValidSecondInput: boolean;
  imageUrl?: string;
  uploadingImage?: boolean;
}
interface IProps {
  label?: string;
  buttonText: string;
  iconTitle?: string;
  addImageEnabled: boolean;
  rowInputsData: IRowInputsData;
  imageUploaderType?: ImageUploaderType;
  dataList: IRowData[];
  setDataList: React.Dispatch<React.SetStateAction<IRowData[]>>;
  isRequired?: boolean;
}

const MultipleRowAdditionField: FC<IProps> = props => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [stashDataList, setStashDataList] = useState<IRowData[]>([]);

  const onClickExpandIcon = () => {
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      setStashDataList(props.dataList);
    } else {
      props.setDataList(stashDataList);
    }
  };

  const onUploadImage = (index: number, files: FileList) => {
    props.setDataList((prevRows: IRowData[]) => {
      const updatedRows: IRowData[] = [...prevRows];
      updatedRows[index].uploadingImage = true;
      return updatedRows;
    });

    const file = files[0];
    uploadFile(file, EUploadFileType.IMAGES, file.name + uuidv4() + '.jpg')
      .then((response: IFileUploadDTO) => {
        props.setDataList((prevRows: IRowData[]) => {
          const updatedRows: IRowData[] = [...prevRows];
          updatedRows[index].imageUrl = response.storagePath;
          updatedRows[index].uploadingImage = false;
          return updatedRows;
        });
      })
      .catch(() => {
        props.setDataList((prevRows: IRowData[]) => {
          const updatedRows: IRowData[] = [...prevRows];
          updatedRows[index].uploadingImage = false;
          return updatedRows;
        });
      });
  };

  const onClickAddRow = () => {
    const dataArrayLength: number = props.dataList.length;

    if (dataArrayLength > 0) {
      const lastRow: IRowData = props.dataList[dataArrayLength - 1];
      if (lastRow.firstInput === '' || lastRow.secondInput === '') {
        props.setDataList(prevRows => {
          const updatedRows: IRowData[] = [...prevRows];
          updatedRows[dataArrayLength - 1].isValidFirstInput = lastRow.firstInput !== '';
          updatedRows[dataArrayLength - 1].isValidSecondInput = lastRow.secondInput !== '';
          return updatedRows;
        });
        return;
      } else {
        props.setDataList(prevRows => {
          const updatedRows: IRowData[] = [...prevRows];
          updatedRows[dataArrayLength - 1].isValidFirstInput = true;
          updatedRows[dataArrayLength - 1].isValidSecondInput = true;
          return updatedRows;
        });
      }
    }

    const newRow: IRowData = {
      id: uuidv4(),
      firstInput: '',
      secondInput: '',
      isValidFirstInput: true,
      isValidSecondInput: true,
      imageUrl: '',
    };

    props.setDataList(prevRows => [...prevRows, newRow]);
  };

  const onClickDeleteRow = (selectedRowId: number) => {
    const availableRows: IRowData[] = props.dataList.filter(row => row.id !== selectedRowId);
    props.setDataList(availableRows);
  };

  const onRemoveImage = (id: number, files: any) => {
    const updatedDataList = props.dataList.map(row => {
      if (row.id === id) {
        return {
          ...row,
          imageUrl: null,
        };
      }
      return row;
    });

    props.setDataList(updatedDataList);
  };

  return (
    <Row>
      <Col className="col-12">
        <div className={`${styles.customCard} p-3`}>
          {props.label && (
            <Row className="align-items-center">
              <Col className={`${styles.labelStyle}`}>
                {props.label} {props.isRequired && <span className={`${styles.asteriskStyles}`}> * </span>}
              </Col>

              <Col className="d-flex justify-content-end">
                {isExpanded ? (
                  <BsChevronDown className="cursor-pointer" size={20} onClick={onClickExpandIcon} />
                ) : (
                  <BsChevronRight className="cursor-pointer" size={20} onClick={onClickExpandIcon} />
                )}
              </Col>
            </Row>
          )}

          <Collapse in={isExpanded}>
            <div>
              {props.dataList.map((row: IRowData, index) => (
                <Row key={row.id} className="align-items-center mt-2">
                  <Col className={`col-lg-4 col-md-4 order-1' ${props.addImageEnabled ? 'col-9' : 'col-12'}`}>
                    <label className={`${styles.label} d-flex pb-1`} htmlFor="flexSwitchCheckDefault">
                      {props.rowInputsData.firstInputLabel} ({props.rowInputsData.firstInputBracketLabel} {index + 1})
                    </label>
                    <div className={`appInput ${!row.isValidFirstInput && 'hasDanger'}`}>
                      <input
                        value={row.firstInput}
                        placeholder={props.rowInputsData.firstInputPlaceholder}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          props.setDataList((prevRows: IRowData[]) => {
                            const updatedRows: IRowData[] = [...prevRows];
                            updatedRows[index].firstInput = e.target.value;
                            return updatedRows;
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="order-sm-3 order-md-2 order-lg-2 order-3 ">
                    <label className={`${styles.label} d-flex pb-1`} htmlFor="flexSwitchCheckDefault">
                      {props.rowInputsData.secondInputLabel} ({props.rowInputsData.secondInputBracketLabel} {index + 1})
                    </label>
                    <div className={`appInput ${!row.isValidSecondInput && 'hasDanger'}`}>
                      <input
                        placeholder={props.rowInputsData.secondInputPlaceholder}
                        value={row.secondInput}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          props.setDataList((prevRows: IRowData[]) => {
                            const updatedRows: IRowData[] = [...prevRows];
                            updatedRows[index].secondInput = e.target.value;
                            return updatedRows;
                          });
                        }}
                      />
                    </div>
                  </Col>
                  {props.addImageEnabled && (
                    <Col className="col-2 col-sm-3 order-lg-3 order-md-3 order-sm-2 order-2 mt-4">
                      <ImageUploader
                        isMultipleImage={false}
                        iconTitle={props.iconTitle}
                        onImagesSelected={(files: FileList) => onUploadImage(index, files)}
                        imageUploaderType={props.imageUploaderType}
                        loading={row.uploadingImage}
                        existingFileUrls={convertURL(props.dataList[index].imageUrl)}
                        onChange={files => onRemoveImage(row.id, files)}
                      />
                    </Col>
                  )}
                  <Col className="col-auto order-4 mt-4">
                    <div className={`${styles.iconCard} p-1`}>
                      <BsTrashFill
                        className="cursor-pointer justify-content-end"
                        color="#FF4C6C"
                        onClick={() => onClickDeleteRow(row.id)}
                      />
                    </div>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col className="col-auto mt-4">
                  <AppButton
                    className={`${styles.btnStyle}`}
                    text={props.buttonText}
                    btnSize="sm"
                    btnType="bordered"
                    onClick={onClickAddRow}
                    type="button"
                  />
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
};

export default MultipleRowAdditionField;
