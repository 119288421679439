import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AppButton from '../app-button/app-button';
import styles from './mini-card.module.scss';

interface IProps {
  title?: string;
  description?: string;
  buttonText?: string;
}

const MiniCard = (props: IProps) => {
  return (
    <Row>
      <Col>
        <div className={`${styles.miniCardView} px-3 py-4`}>
          <Row>
            <Col className={props.buttonText && props.buttonText ? 'col-12 col-lg-10' : 'col-12'}>
              {props.title && (
                <Row>
                  <Col className={`${styles.headingText}`}>{props.title}</Col>
                </Row>
              )}
              {props.description && (
                <Row>
                  <Col className={`${styles.bodyText}`}>{props.description}</Col>
                </Row>
              )}
            </Col>
            {props.buttonText && (
              <Col className="col-6 col-sm-5 col-md-4 col-lg-2 mt-2 mt-lg-0">
                <AppButton text={props.buttonText} />
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default MiniCard;
