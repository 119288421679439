// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J0vXStGRI41bb9G6MNHD {
  background-color: var(--background-color);
}
.J0vXStGRI41bb9G6MNHD .YmBs3cQtTbmO5UQpXQZq {
  font-size: 16px;
  color: var(--text-primary-color);
}
.J0vXStGRI41bb9G6MNHD .YsOxx3OEWNvPy9JdzI2N {
  font-size: 15px;
  color: var(--text-secondary-color);
}
.J0vXStGRI41bb9G6MNHD .Y5ZZTx2ZhfwDV4Rgl8Gp {
  font-size: 20px;
  color: var(--text-primary-color);
}
.J0vXStGRI41bb9G6MNHD .FkORpXmoBOnzM5JTqsN4 {
  font-size: 16px;
  color: var(--text-secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/footer/footer.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;AACF;AACE;EACE,eAAA;EACA,gCAAA;AACJ;AAEE;EACE,eAAA;EACA,kCAAA;AAAJ;AAGE;EACE,eAAA;EACA,gCAAA;AADJ;AAIE;EACE,eAAA;EACA,kCAAA;AAFJ","sourcesContent":[".footer-container {\n  background-color: var(--background-color);\n\n  .footer-slogan {\n    font-size: 16px;\n    color: var(--text-primary-color);\n  }\n\n  .footer-copyrights {\n    font-size: 15px;\n    color: var(--text-secondary-color);\n  }\n\n  .footer-contactUs {\n    font-size: 20px;\n    color: var(--text-primary-color);\n  }\n\n  .footer-email {\n    font-size: 16px;\n    color: var(--text-secondary-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-container": `J0vXStGRI41bb9G6MNHD`,
	"footer-slogan": `YmBs3cQtTbmO5UQpXQZq`,
	"footer-copyrights": `YsOxx3OEWNvPy9JdzI2N`,
	"footer-contactUs": `Y5ZZTx2ZhfwDV4Rgl8Gp`,
	"footer-email": `FkORpXmoBOnzM5JTqsN4`
};
export default ___CSS_LOADER_EXPORT___;
