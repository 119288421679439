// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A255QiY_nqALOjq8YxyL {
  border-radius: 12px;
  background: var(--card-secondary-color);
}
.A255QiY_nqALOjq8YxyL .fGUmpOts1MqQhPxPkp3N {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.A255QiY_nqALOjq8YxyL ._uBGmR8YkJYNzXt3PIs4 {
  border-radius: 3px;
  background: var(--card-color);
}
.A255QiY_nqALOjq8YxyL .TZMlFGu2O_QHxTz3blEw {
  color: var(--error-color);
}
.A255QiY_nqALOjq8YxyL .NUkAqQ59O336N4Vb0BdF {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 400;
}
.A255QiY_nqALOjq8YxyL .nEdSiUwHgJnOH5ZJ41A6 {
  color: var(--accent-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/multiple-rows-addition-field/multiple-rows-addition-field.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,uCAAA;AACF;AACE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAEE;EACE,kBAAA;EACA,6BAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;AAFJ;AAKE;EACE,0BAAA;AAHJ","sourcesContent":[".customCard {\n  border-radius: 12px;\n  background: var(--card-secondary-color);\n\n  .labelStyle {\n    color: var(--text-secondary-color);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n\n  .iconCard {\n    border-radius: 3px;\n    background: var(--card-color);\n  }\n\n  .asteriskStyles {\n    color: var(--error-color);\n  }\n\n  .label {\n    color: var(--text-secondary-color);\n    font-size: 14px;\n    font-weight: 400;\n  }\n\n  .btnStyle {\n    color: var(--accent-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customCard": `A255QiY_nqALOjq8YxyL`,
	"labelStyle": `fGUmpOts1MqQhPxPkp3N`,
	"iconCard": `_uBGmR8YkJYNzXt3PIs4`,
	"asteriskStyles": `TZMlFGu2O_QHxTz3blEw`,
	"label": `NUkAqQ59O336N4Vb0BdF`,
	"btnStyle": `nEdSiUwHgJnOH5ZJ41A6`
};
export default ___CSS_LOADER_EXPORT___;
