import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AppBadge from '../app-badge/AppBadge';
import styles from './AppBadgeWithLabel.module.scss';

interface IProps {
  label: string;
  badgeLabel: string;
  variant?: 'primary' | 'secondary';
  fontSize?: number;
}

const AppBadgeWithLabel = (props: IProps) => {
  const { label, badgeLabel, variant = 'primary', fontSize = 12 } = props;
  return (
    <Row className="align-items-center">
      <Col className="col-auto mb-1">
        <span className={`${styles[variant]} fw-normal`} style={{ fontSize: `${fontSize}px` }}>
          {label} :
        </span>
      </Col>
      <Col className="col-auto mb-1 ps-0">
        <AppBadge label={badgeLabel} />
      </Col>
    </Row>
  );
};

export default AppBadgeWithLabel;
