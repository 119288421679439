import dayjs from 'dayjs';

import {
  APP_CUSTOM_DATETIME_FORMAT,
  APP_DEFUALT_DATE_FORMAT,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATE_FORMAT,
  APP_ORDINARY_DATETIME_FORMAT,
} from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATE_FORMAT) : null);

export const convertDateTimeToOrdinary = date => (date ? dayjs(date).format(APP_ORDINARY_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? dayjs(date).toDate() : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const displayDateTime = date => (date ? dayjs(date).format(APP_CUSTOM_DATETIME_FORMAT) : null);

export const displayDefaultDateFormat = date => (date ? dayjs(date).format(APP_DEFUALT_DATE_FORMAT) : null);

export const convertDateToIST = (date: Date) => {
  // Get the time zone offset in minutes (IST is GMT+0530)
  // -5 hours and 30 minutes
  const timeZoneOffsetMinutes = -330;

  const adjustedDate = new Date(date.getTime() - timeZoneOffsetMinutes * 60000);

  const adjustedISO8601String = adjustedDate.toISOString();

  return adjustedISO8601String;
};

export const removeDateTimeOffset = (dateString: string) => {
  const date = new Date(dateString);
  const offsetHours = 5;
  const offsetMinutes = 30;

  date.setUTCHours(date.getUTCHours() - offsetHours);
  date.setUTCMinutes(date.getUTCMinutes() - offsetMinutes);

  return date.toISOString();
};

export const checkValidDateRange = (activeDate: Date, expireDate: Date) => {
  const areDatesDifferent = activeDate.getTime() !== expireDate.getTime();
  const isExpireAfterActive = expireDate.getTime() > activeDate.getTime();
  return areDatesDifferent && isExpireAfterActive;
};

export const formatNumberToCurrency = (number: number) => {
  return number?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
