import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import AppPanelCard from 'app/shared/components/app-dnd-elements/app-panel-card/app-panel-card';
import Pagination from 'app/shared/components/pagination/pagination';
import { ICouponUser } from 'app/shared/model/coupon-user.model';
import { ECouponDiscountTypeStrings, ECouponState } from 'app/shared/model/enumerations/coupon-mode';
import { ESortOrder } from 'app/shared/model/enumerations/sort-types.model';
import AppSortIcon from 'app/shared/ui-elements/app-sort-icon/app-sort-icon';
import AppToggle from 'app/shared/ui-elements/app-toggle/app-toggle';
import { getStatus } from 'app/shared/util/coupon-utills';
import { convertDateTimeFromServer, convertDateTimeToOrdinary } from 'app/shared/util/date-utils';
import { showErrorToast, showSuccessToast } from 'app/shared/util/toast';
import React, { useEffect, useState } from 'react';
import { Col, Row, Stack, Table } from 'react-bootstrap';
import { BsThreeDotsVertical, BsTrashFill } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCoupon, getCouponEntity, getCouponUsage, resetCoupons, updateCouponState } from '../coupons.reducer';
import styles from './coupons-viewer.module.scss';

const CouponsViewer = () => {
  const navigate = useNavigate();
  const { couponId } = useParams<'couponId'>();
  const itemsPerPage = 10;
  const dispatch = useAppDispatch();

  const [usedBy, setUsedBy] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [course, setCourse] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [dateUsed, setDateUsed] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const arrowSort = (sortType: 'usedBy' | 'course' | 'dateUsed') => {
    setUsedBy(prev =>
      sortType === 'usedBy' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setCourse(prev =>
      sortType === 'course' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setDateUsed(prev =>
      sortType === 'dateUsed' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
  };

  const sortParam = `${
    usedBy !== ESortOrder.NOT_SORT ? `name,${usedBy === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}` : ''
  }${course !== ESortOrder.NOT_SORT ? `courseName,${course === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}` : ''}${
    dateUsed !== ESortOrder.NOT_SORT ? `createdDate,${dateUsed === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}` : ''
  }`;

  const { couponEntity, couponUsageList } = useAppSelector((rootState: IRootState) => ({
    couponEntity: rootState.coupons.couponEntity,
    couponUsageList: rootState.coupons.couponUsageList,
  }));

  useEffect(() => {
    dispatch(getCouponEntity({ coupon: couponId }));
    return () => {
      dispatch(resetCoupons());
    };
  }, [couponId]);

  useEffect(() => {
    dispatch(
      getCouponUsage({
        id: couponId,
        sort: sortParam,
        page: currentPage - 1,
        size: 10,
      })
    );
  }, [couponId, sortParam]);

  const handleCouponDelete = () => {
    dispatch(deleteCoupon({ id: couponId }))
      .unwrap()
      .then(res => {
        if (res.status === 204) navigate('/admin/settings/coupons');
      })
      .catch(e => console.error(e));
  };

  const handleCouponToggle = (state, id) => {
    dispatch(updateCouponState({ id, isActive: state }))
      .unwrap()
      .then(response => {
        if (response.status === 200) {
          showSuccessToast(`${state ? 'Coupon Activated' : 'Coupon Deactivated'}`, 'CouponToast');
        }
      })
      .catch(error => console.error(error));
  };

  return (
    couponEntity && (
      <Row>
        <Col>
          <AppPanelCard>
            <Row>
              <Col className="col-12 col-xl-3">
                <Stack>
                  <div className={styles.txt1}>{couponEntity.courseName === 'All' ? 'All Courses' : couponEntity.courseName}</div>
                  <div className={styles.coupon}>{couponEntity.couponCode}</div>
                </Stack>
              </Col>

              <Col className="col-12 col-xl-6">
                <Row>
                  <Col>
                    <Stack>
                      <div className={styles.txt1}>Type</div>
                      <div className={styles.values}>
                        {couponEntity.couponType.charAt(0) + couponEntity.couponType.slice(1).toLowerCase()}
                      </div>
                    </Stack>
                  </Col>
                  <Col>
                    <Stack>
                      <div className={styles.txt1}>Availability</div>
                      <div className={`${styles.values} ${couponEntity.usageLimit - couponEntity.usageCount === 0 ? styles.expired : ''}`}>
                        {(couponEntity.usageLimit - couponEntity.usageCount).toString()}/{couponEntity.usageLimit.toString()}
                      </div>
                    </Stack>
                  </Col>
                  <Col>
                    <Stack>
                      <div className={styles.txt1}>Expire Date</div>
                      <div
                        className={`${styles.values} ${
                          convertDateTimeFromServer(couponEntity.expireDate) < convertDateTimeFromServer(new Date())
                            ? styles.expired
                            : styles.notExpired
                        }`}
                      >
                        {convertDateTimeToOrdinary(couponEntity.expireDate)}
                      </div>
                    </Stack>
                  </Col>
                  <Col>
                    <Stack>
                      <div className={styles.txt1}>Discount</div>
                      <div className={styles.values}>
                        {couponEntity.discountType === ECouponDiscountTypeStrings.PERCENTAGE
                          ? couponEntity.discountAmount.toString() + '%'
                          : 'LKR ' + couponEntity.discountAmount.toString()}
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </Col>

              <Col className="col-auto col-xl-3">
                <Row>
                  <Col>
                    <Row direction="horizontal" className={styles.actionButtons}>
                      <Col className="p-2">
                        <FaEdit
                          className="cursor-pointer"
                          onClick={() => {
                            if (getStatus(couponEntity.activeDate, couponEntity.expireDate) === ECouponState.EXPIRED) {
                              showErrorToast('Coupon cannot be updated as it has expired.', 'couponExpired');
                            } else if (couponEntity.usageCount === couponEntity.usageLimit) {
                              showErrorToast('Coupon cannot be updated as it has been used.', 'couponUsageLimit');
                            } else {
                              navigate(`/admin/settings/coupons/${couponId}/edit`);
                            }
                          }}
                        />
                      </Col>
                      <Col className="p-2">
                        <BsTrashFill className="cursor-pointer" color="#FF4C6C" onClick={handleCouponDelete} />
                      </Col>
                      <Col className="d-flex align-items-center mt-1 px-2">
                        {new Date() >= new Date(couponEntity.activeDate) && new Date() <= new Date(couponEntity.expireDate) ? (
                          <div className="d-flex align-items-center">
                            <div className={`${styles.toggleStatus} me-2`}>{couponEntity.active ? 'Active' : 'Inactive'}</div>
                            <AppToggle
                              initialState={couponEntity.active}
                              onToggle={state => {
                                handleCouponToggle(state, couponId);
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col className="p-2">
                        <BsThreeDotsVertical className="cursor-pointer" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </AppPanelCard>

          <Row>
            <Col>
              <Table responsive className="w-100">
                <thead>
                  <tr>
                    <th>
                      <div className={`d-flex align-items-center`}>
                        <AppSortIcon onChange={() => arrowSort('usedBy')} sortedItem={usedBy} text={'Used by'} />
                      </div>
                    </th>
                    <th>
                      <div className={`d-flex align-items-center`}>
                        <AppSortIcon onChange={() => arrowSort('course')} sortedItem={course} text={'Course'} />
                      </div>
                    </th>
                    <th>
                      <div className={`d-flex align-items-center`}>
                        <AppSortIcon onChange={() => arrowSort('dateUsed')} sortedItem={dateUsed} text={'Date Used'} />
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {couponUsageList?.map((user: ICouponUser, index) => (
                    <tr key={index}>
                      <td className={`${styles.txt2}`}>
                        {user.firstname} {user.lastname}
                      </td>
                      <td className={`${styles.txt2}`}>{user.courseName}</td>
                      <td className={`${styles.txt2}`}>{convertDateTimeToOrdinary(user.usedDate)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>

          {couponEntity.couponUsers?.length > itemsPerPage && (
            <Row>
              <Col className="mb-3">
                <Pagination
                  length={9}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  updateCurrentPage={(currentPageNumber: number) => {
                    setCurrentPage(currentPageNumber);
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    )
  );
};

export default CouponsViewer;
