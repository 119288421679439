import { ECourseType } from 'app/shared/model/enumerations/course-type.model';

interface IUnitPresenter {
  unitId?: number;
  unitName?: string;
  completed?: boolean;
}

interface ILessonPresenter {
  lessonId?: number;
  lessonName?: string;
  lessonDescription?: string;
  containsUnits?: boolean;
  completed?: boolean;
  units?: IUnitPresenter[];
}

export interface ICourseLessonsUnitsPresenter {
  courseId?: number;
  enrollmentId?: number;
  courseName?: string;
  courseType?: ECourseType;
  courseTypeInText?: string;
  specialization?: string;
  coverImageUrl?: string;
  userCompletedPercentage?: number;
  lessons?: ILessonPresenter[];
  userCourseStatus?: string;
}

export const defaultValue: Readonly<ICourseLessonsUnitsPresenter> = {};
