// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kb5v9R3JL2wESE1U8V2W {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/pages/admin/user-roster/user-roster.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,6CAAA;AACF","sourcesContent":[".txt2 {\n  font-size: 14px;\n  font-weight: 400;\n  color: var(--text-secondary-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt2": `kb5v9R3JL2wESE1U8V2W`
};
export default ___CSS_LOADER_EXPORT___;
