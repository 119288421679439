// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sXX_Wd43HqKGmtJsZ_qO {
  color: var(--text-primary-color, #3f3e4a);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.uQisSddC2Sv1mrIbjQFq {
  color: var(--text-secondary-color, #767676);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.LdJgVlJ7Y6VHe5WGKs7t {
  color: var(--text-primary-color, #3f3e4a);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.ZfmoJx29WIiwk3HqkNV4 {
  color: var(--error-color);
}

.YkSXQ4esb4h8icWw1Lgq {
  color: #00b84a;
}

.tXWjDlTrQFDPD2iuDG2W {
  color: #f3b619;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/admin/admin-payments/order-information-modal/order-information-modal.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,2CAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,yCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".txt1 {\n  color: var(--text-primary-color, #3f3e4a);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n}\n\n.txt2 {\n  color: var(--text-secondary-color, #767676);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n}\n\n.txt3 {\n  color: var(--text-primary-color, #3f3e4a);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n}\n\n.canceled {\n  color: var(--error-color);\n}\n\n.success {\n  color: #00b84a;\n}\n\n.pending {\n  color: #f3b619;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt1": `sXX_Wd43HqKGmtJsZ_qO`,
	"txt2": `uQisSddC2Sv1mrIbjQFq`,
	"txt3": `LdJgVlJ7Y6VHe5WGKs7t`,
	"canceled": `ZfmoJx29WIiwk3HqkNV4`,
	"success": `YkSXQ4esb4h8icWw1Lgq`,
	"pending": `tXWjDlTrQFDPD2iuDG2W`
};
export default ___CSS_LOADER_EXPORT___;
