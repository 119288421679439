import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { createUnit, updateUnit } from 'app/modules/admin/course-lessons-units-manager/course-lessons-units-manager.reducer';
import ModelLayout from 'app/shared/layout/layouts/ModelLayout';
import { IUnit } from 'app/shared/model/unit.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import { noWhitespaceStartEndRegex } from 'app/shared/util/regex';
import React, { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import AppInput from '../../app-input/app-input';

interface IProps {
  isOpen: boolean;
  initData?: IUnit;
  closeHandler: () => void;
}

const UnitModel: FC<IProps> = props => {
  const dispatch = useAppDispatch();
  const { actionSuccess } = useAppSelector((rootState: IRootState) => ({
    actionSuccess: rootState.courseLessonsUnitsManager.updateSuccess,
  }));

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IUnit>({});

  useEffect(() => {
    if (props.initData?.id) {
      for (const [key, value] of Object.entries(props.initData)) {
        setValue(key as any, value, { shouldDirty: true, shouldValidate: true });
      }
    }
  }, [props.initData, setValue]);

  useEffect(() => {
    if (actionSuccess) {
      closeHandler();
    }
  }, [actionSuccess]);

  const onSubmit: SubmitHandler<IUnit> = (data: IUnit) => {
    if (data.id) {
      dispatch(updateUnit(data));
    } else {
      data.courseId = props.initData.courseId;
      data.lessonId = props.initData.lessonId;
      data.description = '';
      dispatch(createUnit(data));
    }

    reset();
  };

  const closeHandler = () => {
    props.closeHandler();
    reset();
  };

  return (
    <ModelLayout
      closeHandler={closeHandler}
      isOpen={props.isOpen}
      modelTitle={props.initData && props.initData?.id ? 'Update Unit' : 'Add Unit'}
    >
      <Row>
        <Col>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="mb-4">
                <AppInput
                  id="name"
                  name="name"
                  label="Unit Name"
                  placeholder={'Unit Name'}
                  register={register('name', {
                    required: 'Unit name is required',
                    minLength: {
                      value: 2,
                      message: 'This field must contain at least 2 characters',
                    },
                    maxLength: {
                      value: 50,
                      message: 'You have exceeded the maximum number of 50 characters in this field',
                    },
                    pattern: {
                      value: noWhitespaceStartEndRegex,
                      message: "Entered value can't start/end or contain only white spaces",
                    },
                  })}
                  errors={errors}
                />
              </Col>
            </Row>

            <Row>
              <Col className="col-12 col-sm-4 mb-2 mb-sm-0">
                <AppButton text={props.initData && props.initData?.id ? 'Update Unit' : 'Add Unit'} btnSize="sm" type="submit" />
              </Col>
              <Col className="col-12 col-sm-4">
                <AppButton text="Cancel" btnSize="sm" type="button" btnType="bordered" onClick={closeHandler} />
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </ModelLayout>
  );
};

export default UnitModel;
