// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BU9GevzhRaC7HVUNdYWY {
  color: var(--text-secondary-color);
}

.XXZSDPCkXE6IB7G3CN6N {
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-badge-with-label/AppBadgeWithLabel.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".primary {\n  color: var(--text-secondary-color);\n}\n\n.secondary {\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `BU9GevzhRaC7HVUNdYWY`,
	"secondary": `XXZSDPCkXE6IB7G3CN6N`
};
export default ___CSS_LOADER_EXPORT___;
