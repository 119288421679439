import { toast } from 'react-toastify';

export const showSuccessToast = (message: string, id: string, delay?: number) => {
  toast.success(message, {
    toastId: id,
    delay,
  });
};

export const showErrorToast = (message: string, id: string, delay?: number) => {
  toast.error(message, {
    toastId: id,
    delay,
  });
};

export const showWarningToast = (message: string, id: string, delay?: number) => {
  toast.warn(message, {
    toastId: id,
    delay,
  });
};
