import { ECourseType } from 'app/shared/model/enumerations/course-type.model';
import { ICourseOutlines } from './course-outline.model';
import { ECourseDifficultyLevel } from './enumerations/course-difficulty-level.model';
import { ECourseMode } from './enumerations/course-mode';
import { EFieldOfStudy } from './enumerations/field-of-study-type.model';
import { IExpert } from './expert.model';

export interface ICourse {
  id?: number;
  name?: string;
  description?: string;
  type?: keyof typeof ECourseType;
  difficultyLevel?: keyof typeof ECourseDifficultyLevel;
  outlines?: ICourseOutlines[] | null;
  expertsPanel?: IExpert[] | null;
  frequentlyAskedQuestions?: IFrequentlyAskedQuestions[] | null;
  collaborationLogos?: ICollaborationLogos[] | null;
  registrationStartDate?: string | null;
  registrationEndDate?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  coverImageUrl?: string | null;
  previewVideo?: string | null;
  bannerVectorUrl?: string | null;
  prerequisites?: string | null;
  classSchedule?: string | null;
  courseDuration?: string | null;
  fieldOfStudy?: keyof typeof EFieldOfStudy;
  price?: number;
  draft?: boolean;
  reviews?: string[];
  formLink?: string;
  needToPrepareSection?: string;
  sequence?: any;
  mode?: keyof typeof ECourseMode;
  isPaid?: boolean;
  isCouponAllowed?: boolean;
  estimatedCourseDuration?: string;
}

export interface IFrequentlyAskedQuestions {
  answer: string;
  question: string;
}

export interface ICollaborationLogos {
  url: string;
  name: string;
}

export const defaultValue: Readonly<ICourse> = {};
