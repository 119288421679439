import React from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import styles from './app-progress-bar.module.scss';

interface IProps {
  progress: number;
}

const AppProgressBar = (props: IProps) => {
  const { progress = 0 } = props;
  return (
    <Row className={'align-items-center'}>
      <Col xs={8} xl={9}>
        <ProgressBar className={`${styles.progressBar}`} now={progress} />
      </Col>
      <Col>
        <span className={`${styles.progressCount}`}>{progress}% Completed</span>
      </Col>
    </Row>
  );
};

export default AppProgressBar;
