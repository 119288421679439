import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './UserImageWithText.module.scss';

interface IProps {
  imagePath: string;
  userName: string;
  isEnrolled?: boolean;
}

const UserAvatarWithText = (props: IProps) => {
  return (
    <Row className="align-items-center">
      <Col className="col-1 pe-3">
        <img className={`rounded-5 ${styles.avatar}`} src={props.imagePath} height={'22px'} width={'22px'} />
      </Col>
      <Col>
        <span
          className={`fw-normal ${styles.imageWithTextFont} ${
            props.isEnrolled ? styles.imageWithTextFontEnrolled : styles.imageWithTextFont
          }`}
        >
          By {props.userName}
        </span>
      </Col>
    </Row>
  );
};

export default UserAvatarWithText;
