// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kW9HJh0kBfnkKGjPtUBV {
  font-size: 36px;
  font-weight: 500;
  color: var(--text-primary-color);
}
.fHZ_fuvH6LdCsCPHhaXc {
  color: var(--text-secondary-color);
  line-height: 22px;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/pages/contact-us/ContactUs.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gBAAA;EACA,gCAAA;AAAJ;AAGE;EACE,kCAAA;EACA,iBAAA;EACA,eAAA;AADJ","sourcesContent":[".contactSection {\n  &__text1 {\n    font-size: 36px;\n    font-weight: 500;\n    color: var(--text-primary-color);\n  }\n\n  &__text2 {\n    color: var(--text-secondary-color);\n    line-height: 22px;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactSection__text1": `kW9HJh0kBfnkKGjPtUBV`,
	"contactSection__text2": `fHZ_fuvH6LdCsCPHhaXc`
};
export default ___CSS_LOADER_EXPORT___;
