// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oRuDuW_4YQmUyMTPDOlA {
  width: 44px;
  height: 44px;
  border-radius: 12px;
  background-color: var(--card-color);
  margin-bottom: 3px;
}

.N4RIjLRGjpQxMQvf31AQ {
  background-color: var(--accent-color);
}

.lCeoSdZHKHZjTAemWVbR {
  background-color: var(--card-color);
}

.FvszSEE9dNtadkdTYJq1 {
  color: var(--text-secondary-color);
  font-size: 12px;
  font-weight: 400;
  white-space: pre-wrap;
  margin-top: 5px;
}
.FvszSEE9dNtadkdTYJq1 p {
  margin: 0;
  text-align: center;
  line-height: 1.3;
}

.GFeVkaExYsvuF8Hd3wOS {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  white-space: pre-wrap;
  margin-top: 5px;
}
.GFeVkaExYsvuF8Hd3wOS p {
  margin: 10px;
  text-align: center;
  line-height: 1.3;
}

.Gr0OOipEoih1UUcoy4C_ {
  width: 40px;
  height: 40px;
  border-radius: 12px;
}
.Gr0OOipEoih1UUcoy4C_:active {
  border: 1.5px solid var(--accent-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/nav-bar/app-primary-nav-bar/app-primary-nav-bar-menu-item/app-primary-nav-bar-menu-item.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,mCAAA;EACA,kBAAA;AACF;;AAEA;EACE,qCAAA;AACF;;AAEA;EACE,mCAAA;AACF;;AAEA;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,eAAA;AACF;AACE;EACE,SAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAGA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,eAAA;AAAF;AAEE;EACE,YAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;;AAIA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AADF;AAGE;EACE,uCAAA;AADJ","sourcesContent":[".icon {\n  width: 44px;\n  height: 44px;\n  border-radius: 12px;\n  background-color: var(--card-color);\n  margin-bottom: 3px;\n}\n\n.active {\n  background-color: var(--accent-color);\n}\n\n.mobileNavActive {\n  background-color: var(--card-color);\n}\n\n.title {\n  color: var(--text-secondary-color);\n  font-size: 12px;\n  font-weight: 400;\n  white-space: pre-wrap;\n  margin-top: 5px;\n\n  p {\n    margin: 0;\n    text-align: center;\n    line-height: 1.3;\n  }\n}\n\n.mobileNavTitle {\n  color: #ffffff;\n  font-size: 12px;\n  font-weight: 400;\n  white-space: pre-wrap;\n  margin-top: 5px;\n\n  p {\n    margin: 10px;\n    text-align: center;\n    line-height: 1.3;\n  }\n}\n\n.profileImage {\n  width: 40px;\n  height: 40px;\n  border-radius: 12px;\n\n  &:active {\n    border: 1.5px solid var(--accent-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `oRuDuW_4YQmUyMTPDOlA`,
	"active": `N4RIjLRGjpQxMQvf31AQ`,
	"mobileNavActive": `lCeoSdZHKHZjTAemWVbR`,
	"title": `FvszSEE9dNtadkdTYJq1`,
	"mobileNavTitle": `GFeVkaExYsvuF8Hd3wOS`,
	"profileImage": `Gr0OOipEoih1UUcoy4C_`
};
export default ___CSS_LOADER_EXPORT___;
