import UserAvatarWithText from 'app/shared/components/image-with-text/UserImageWithText';
import { ICourseDetails } from 'app/shared/model/dto/course-details.model';
import AppBadgeWithLabel from 'app/shared/ui-elements/app-badge-with-label/AppBadgeWithLabel';
import { formatPriceWithCommas } from 'app/shared/util/price-utils';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import styles from './course-card.module.scss';

interface IProps {
  course: ICourseDetails;
}

const CourseCard = (props: IProps) => {
  const field = props.course.fieldOfStudy
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  const millisecondsInAWeek = 7 * 24 * 60 * 60 * 1000;

  const handleClick = () => {
    const url = `/course-preview/${props.course.id}`;
    window.open(url, '_blank');
  };

  return (
    <Card
      className={`${styles.courseCard} cursor-pointer ${props.course.enrolled ? styles.enrolledCourseCard : styles.primaryCourseCard}`}
      onClick={() => handleClick()}
    >
      <Card.Img className={`${styles.cardImg}`} variant="top" src={props.course.coverImageUrl} />

      <Card.Body className="m-3">
        {/* <Row className="justify-content-between">
          <Col className="col-auto mb-3 mb-xl-2 ps-0 pe-2">
            <AppBadge label={props.course.courseType} variant="secondary" />
          </Col>
          <Col className="mb-3 mb-xl-2 pe-3">
            <IconWithText
              text={props.course.courseDifficultyLevel.charAt(0) + props.course.courseDifficultyLevel.slice(1).toLowerCase()}
              icon={BsFillMortarboardFill}
              variant={props.course.enrolled ? 'secondary' : 'primary'}
            />
          </Col>
          <Col className="mb-3 mb-xl-2">
            <IconWithText
              text={capitalizeFirstLetterWithNumber(props.course.courseDuration)}
              icon={BsClockFill}
              iconSize={12}
              variant={props.course.enrolled ? 'secondary' : 'primary'}
            />
          </Col>
        </Row> */}
        <Row>
          <Col className="ps-1">
            <AppBadgeWithLabel label={'Subject'} badgeLabel={field} variant={props.course.enrolled ? 'secondary' : 'primary'} />
          </Col>
        </Row>
        <Row>
          <Col className="ps-1">
            <Card.Title
              className={`mt-3 ${styles.cardTitle} ${props.course.enrolled ? styles.cardTitleFontEnrolled : styles.cardTitleFont}`}
            >
              {props.course.name}
            </Card.Title>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="ps-1">
            <span className={`mb-1 ${styles.cardPrice} ${props.course.enrolled ? styles.cardPriceFontEnrolled : styles.cardPriceFont}`}>
              {props.course.enrolled ? 'Enrolled' : props.course.price ? 'LKR ' + formatPriceWithCommas(props.course.price) : 'Free'}
            </span>
          </Col>
        </Row>
        <Row className="mt-4 mt-xl-3 justify-content-between align-items-end">
          <Col className="col-8 col-sm-9 col-md-8 ps-1">
            <UserAvatarWithText
              imagePath={props.course.instructorProfilePicture || '.././././././content/images/default-profile.png'}
              userName={props.course.instructorName}
              isEnrolled={!!props.course.enrolled}
            />
          </Col>
          {/* <Col className="col-4 col-sm-3 col-md-4 pe-2">
            <Row>
              <Col sm={12} className="pe-1 mt-3">
                <div className={`${styles.cardRatings} ${props.course.enrolled ? styles.cardRatingsFontEnrolled : styles.cardRatingsFont}`}>
                  Course Features:
                  <br />
                  <div className="mt-1">Sample Questions</div>
                </div>
              </Col>
            </Row>
            
          </Col> */}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CourseCard;
