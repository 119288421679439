import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import AppDNDMiniCard from 'app/shared/components/app-dnd-elements/app-dnd-mini-card/app-dnd-mini-card';
import CourseEnrollmentSummaryCard from 'app/shared/components/course-enrollment-summary-card/course-enrollment-summary-card';
import MainLoader from 'app/shared/components/main-loader/main-loader';
import { IDeleteModelData } from 'app/shared/model/DeleteModel.model';
import { ILesson } from 'app/shared/model/lesson.model';
import { IUnit } from 'app/shared/model/unit.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppMiniPopUp from 'app/shared/ui-elements/app-mini-popup/app-mini-pop-up';
import AppTooltip from 'app/shared/ui-elements/app-tooltip/AppTooltip';
import IconCard from 'app/shared/ui-elements/icon-card/icon-card';
import DeleteModel from 'app/shared/ui-elements/models/app-delete-model/DeleteModel';
import LessonModel from 'app/shared/ui-elements/models/lesson-model/LessonModel';
import UnitModel from 'app/shared/ui-elements/models/unit-model/UnitModel';
import { extractNumber } from 'app/shared/util/dnd-utils';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { BsArrowsMove, BsExclamationCircle, BsQuestionCircle, BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { ICourseLessonsUnitsManager } from './course-lessons-units-manager.model';
import styles from './course-lessons-units-manager.module.scss';
import { arrangeLessonAndUnitSequence, getLessonsAndUnitsOfCourse, updateLessonsHandler } from './course-lessons-units-manager.reducer';

const CourseLessonsUnitsManager = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openModel, setOpenModel] = useState<boolean>(false);
  const [unitModel, setUnitModel] = useState<boolean>(false);
  const [isChangedOrder, setIsChangedOrder] = useState<boolean>(false);
  const [deleteModelState, setDeleteModelState] = useState<IDeleteModelData | null>(null);
  const [initLessonData, setInitLessonData] = useState<ILesson>();
  const [initUnitData, setInitUnitData] = useState<IUnit>();

  const { state, loading } = useAppSelector((rootState: IRootState) => ({
    state: rootState.courseLessonsUnitsManager.entity as ICourseLessonsUnitsManager,
    loading: rootState.courseLessonsUnitsManager.loading,
  }));

  useEffect(() => {
    dispatch(getLessonsAndUnitsOfCourse(courseId));
  }, []);

  const saveHandler = () => {
    dispatch(arrangeLessonAndUnitSequence(state));
    setIsChangedOrder(false);
  };

  const onDragEnd = (result: { source: any; destination: any }) => {
    const { source, destination } = result;

    // if no destination (e.g., dragged outside of any drop area), then return
    if (!destination) return;

    // if the item didn't move to a new spot, then return
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Handle outer list reordering
    if (source.droppableId === 'outer-list' && destination.droppableId === 'outer-list') {
      const newList = Array.from(state.lessons);
      const [movedItem] = newList.splice(source.index, 1);
      newList.splice(destination.index, 0, movedItem);

      dispatch(updateLessonsHandler(newList));
      setIsChangedOrder(true);
      return;
    }

    // Handle inner list reordering
    if (source.droppableId.includes('outer-') && destination.droppableId.includes('outer-')) {
      const outerIndex = state.lessons.findIndex(item => item.lessonId === source.droppableId);
      if (outerIndex === -1) return; // just in case

      const newList = Array.from(state.lessons[outerIndex].units);
      const [movedItem] = newList.splice(source.index, 1);
      newList.splice(destination.index, 0, movedItem);

      const newOuterList = Array.from(state.lessons);

      const updatedLesson = { ...newOuterList[outerIndex] };

      updatedLesson.units = newList;

      newOuterList[outerIndex] = updatedLesson;

      dispatch(updateLessonsHandler(newOuterList));
      setIsChangedOrder(true);
    }
  };

  const openLessonModel = () => {
    setOpenModel(true);
  };

  const closeLessonModel = () => {
    setOpenModel(false);
    setInitLessonData(null);
  };

  const lessonUpdateHandler = (data: ILesson) => {
    setInitLessonData(data);
    setOpenModel(true);
  };

  const openDeleteModel = (data: IDeleteModelData) => {
    setDeleteModelState(data);
  };

  const closeDeleteModel = () => {
    setDeleteModelState(ps => {
      return {
        ...ps,
        show: false,
      };
    });
  };

  const closeUnitModel = () => {
    setUnitModel(false);
    setInitUnitData(null);
  };

  const unitUpdateHandler = (data: IUnit) => {
    setInitUnitData(data);
    setUnitModel(true);
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col className="mb-3">{state ? <CourseEnrollmentSummaryCard data={state} /> : <></>}</Col>
        </Row>
        <Row className="align-items-center justify-content-between">
          <Col className={`${styles.text1} mb-2 mb-sm-0`}>Course Materials</Col>
          <Col className="col-12 col-sm-5 col-md-2">
            <AppButton text={'Add Lesson'} onClick={openLessonModel} />
          </Col>
        </Row>
        <Row>
          <Col className="my-3">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="outer-list" type="OUTER">
                {(provided: {
                  innerRef: React.LegacyRef<HTMLDivElement>;
                  droppableProps: React.JSX.IntrinsicAttributes &
                    React.ClassAttributes<HTMLDivElement> &
                    React.HTMLAttributes<HTMLDivElement>;
                  placeholder:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | React.ReactPortal;
                }) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {state &&
                      state.lessons.length > 0 &&
                      state.lessons.map((outerItem, index) => (
                        <Draggable key={outerItem.lessonId} draggableId={outerItem.lessonId} index={index}>
                          {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                          {(provided: {
                            innerRef: React.LegacyRef<HTMLDivElement>;
                            draggableProps: React.JSX.IntrinsicAttributes &
                              React.ClassAttributes<HTMLDivElement> &
                              React.HTMLAttributes<HTMLDivElement>;
                            dragHandleProps: React.JSX.IntrinsicAttributes &
                              React.ClassAttributes<HTMLDivElement> &
                              React.HTMLAttributes<HTMLDivElement>;
                          }) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <Row>
                                <Col className="my-1">
                                  <Card className={`${styles.accordionContainer}`}>
                                    <Accordion className={styles.accordionArrowIcon}>
                                      <Accordion.Header>
                                        <Card.Body>
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <BsArrowsMove className="cursor-pointer" color="var(--icon-color)" size={16} />
                                            </Col>
                                            <Col className={styles.header}>{outerItem.lessonName}</Col>
                                            <Col className="col-auto">
                                              <div className="position-relative">
                                                <IconCard
                                                  icon={
                                                    <BsThreeDotsVertical className="cursor-pointer" color="var(--icon-color)" size={16} />
                                                  }
                                                />

                                                <AppMiniPopUp
                                                  key={outerItem.lessonId}
                                                  className="pop-up"
                                                  items={[
                                                    {
                                                      text: 'Update Lesson',
                                                      onClick() {
                                                        const lesson: ILesson = {
                                                          name: outerItem.lessonName,
                                                          containsUnits: outerItem.containsUnits,
                                                          description: outerItem.lessonDescription,
                                                          id: extractNumber(outerItem.lessonId as any),
                                                          courseId: extractNumber(courseId as any),
                                                        };
                                                        lessonUpdateHandler(lesson);
                                                      },
                                                    },
                                                    {
                                                      text: 'Delete Lesson',
                                                      onClick() {
                                                        const data: IDeleteModelData = {
                                                          id: extractNumber(outerItem.lessonId as any),
                                                          title: outerItem.lessonName,
                                                          warningMessage:
                                                            'This operation is irreversible. Are you sure you want to proceed?',
                                                          courseId: state.courseId,
                                                          show: true,
                                                          modelType: 'LESSON',
                                                        };

                                                        openDeleteModel(data);
                                                      },
                                                    },
                                                    {
                                                      text: `${outerItem.containsUnits ? 'Add Unit' : ''}`,
                                                      onClick() {
                                                        if (outerItem.containsUnits) {
                                                          const data: IUnit = {
                                                            id: null,
                                                            courseId: state.courseId,
                                                            lessonId: extractNumber(outerItem.lessonId as any),
                                                            name: '',
                                                            description: '',
                                                          };

                                                          setInitUnitData(data);
                                                          setUnitModel(true);
                                                        }
                                                      },
                                                    },
                                                  ]}
                                                />
                                              </div>
                                            </Col>
                                          </Row>
                                        </Card.Body>
                                      </Accordion.Header>

                                      <Accordion.Body>
                                        <Row>
                                          <Col>
                                            {outerItem.lessonDescription && (
                                              <Row>
                                                <Col className={`${styles.descriptionText} mb-3`}>{outerItem.lessonDescription}</Col>
                                              </Row>
                                            )}
                                            <Droppable droppableId={outerItem.lessonId} type="INNER">
                                              {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                                              {(provided: {
                                                innerRef: React.LegacyRef<HTMLDivElement>;
                                                droppableProps: React.JSX.IntrinsicAttributes &
                                                  React.ClassAttributes<HTMLDivElement> &
                                                  React.HTMLAttributes<HTMLDivElement>;
                                                placeholder:
                                                  | string
                                                  | number
                                                  | boolean
                                                  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                                  | Iterable<React.ReactNode>
                                                  | React.ReactPortal;
                                              }) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                  {outerItem && outerItem?.containsUnits ? (
                                                    <>
                                                      {outerItem && outerItem.units.length > 0 && outerItem.units[0].unitId !== null ? (
                                                        outerItem.units.map((innerItem, innerIndex) => (
                                                          <Draggable
                                                            key={innerItem.unitId}
                                                            draggableId={innerItem.unitId}
                                                            index={innerIndex}
                                                          >
                                                            {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                                                            {(provided: {
                                                              innerRef: React.LegacyRef<HTMLDivElement>;
                                                              draggableProps: React.JSX.IntrinsicAttributes &
                                                                React.ClassAttributes<HTMLDivElement> &
                                                                React.HTMLAttributes<HTMLDivElement>;
                                                              dragHandleProps: React.JSX.IntrinsicAttributes &
                                                                React.ClassAttributes<HTMLDivElement> &
                                                                React.HTMLAttributes<HTMLDivElement>;
                                                            }) => (
                                                              <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                              >
                                                                <Row key={innerItem.unitId}>
                                                                  <Col className="my-1">
                                                                    <AppDNDMiniCard
                                                                      key={innerItem.unitId}
                                                                      text={innerItem.unitName}
                                                                      buttonText="View Unit"
                                                                      onClickButton={() => {
                                                                        navigate(
                                                                          `lesson/${extractNumber(
                                                                            outerItem.lessonId as any
                                                                          )}/unit/${extractNumber(innerItem.unitId as any)}/chapters`
                                                                        );

                                                                        extractNumber;
                                                                      }}
                                                                      firstIcon={
                                                                        <BsArrowsMove
                                                                          className="cursor-pointer"
                                                                          color="var(--icon-color)"
                                                                          size={16}
                                                                        />
                                                                      }
                                                                      lastIcon={
                                                                        <div className="position-relative">
                                                                          <BsThreeDotsVertical
                                                                            className="cursor-pointer"
                                                                            color="var(--icon-color)"
                                                                            size={12}
                                                                          />

                                                                          <AppMiniPopUp
                                                                            key={innerItem.unitId}
                                                                            className="pop-up"
                                                                            items={[
                                                                              {
                                                                                text: 'Update Unit',
                                                                                onClick() {
                                                                                  const data: IUnit = {
                                                                                    id: extractNumber(innerItem.unitId as any),
                                                                                    courseId: state.courseId,
                                                                                    lessonId: extractNumber(outerItem.lessonId as any),
                                                                                    name: innerItem.unitName,
                                                                                    description: '',
                                                                                  };

                                                                                  unitUpdateHandler(data);
                                                                                },
                                                                              },
                                                                              {
                                                                                text: 'Delete Unit',
                                                                                onClick() {
                                                                                  const data: IDeleteModelData = {
                                                                                    id: extractNumber(innerItem.unitId as any),
                                                                                    title: innerItem.unitName,
                                                                                    warningMessage:
                                                                                      'This operation is irreversible. Are you sure you want to proceed?',
                                                                                    courseId: state.courseId,
                                                                                    show: true,
                                                                                    modelType: 'UNIT',
                                                                                  };
                                                                                  openDeleteModel(data);
                                                                                },
                                                                              },
                                                                            ]}
                                                                          />
                                                                        </div>
                                                                      }
                                                                    />
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            )}
                                                          </Draggable>
                                                        ))
                                                      ) : (
                                                        <Row className="align-items-center">
                                                          <Col className="col-auto pe-0">
                                                            <div className="d-flex align-items-center">
                                                              <BsExclamationCircle color="#FFC444" size={14} />
                                                              <div className={`${styles.descriptionText} ms-2`}>
                                                                Please add a unit to this lesson in order to continue
                                                              </div>
                                                            </div>
                                                          </Col>
                                                        </Row>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Row className="justify-content-start">
                                                        <Col className="col-6 col-md-3">
                                                          <AppButton
                                                            text="View Lesson"
                                                            btnType="gray"
                                                            onClick={() => {
                                                              navigate(`lesson/${extractNumber(outerItem.lessonId as any)}/chapters`);
                                                            }}
                                                          />
                                                        </Col>
                                                      </Row>
                                                    </>
                                                  )}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </Droppable>
                                          </Col>
                                        </Row>
                                      </Accordion.Body>
                                    </Accordion>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {isChangedOrder && (
              <Row>
                <Col className="col-5 col-sm-2 mt-5 mb-3">
                  <div className="d-flex align-items-center">
                    <AppButton text={'Save'} onClick={saveHandler} />
                    <div className="mx-2 cursor-pointer">
                      <AppTooltip
                        tooltipId="someId"
                        place="top"
                        content="If there are any changes, the button will be enabled. By clicking it, you can save the updated order."
                      >
                        <BsQuestionCircle size={16} color="var(--icon-color)" />
                      </AppTooltip>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <MainLoader show={loading} />
        <LessonModel isOpen={openModel} closeHandler={closeLessonModel} initData={initLessonData} />
        <DeleteModel data={deleteModelState} closeHandler={closeDeleteModel} />
        <UnitModel isOpen={unitModel} closeHandler={closeUnitModel} initData={initUnitData} />
      </Col>
    </Row>
  );
};

export default CourseLessonsUnitsManager;
