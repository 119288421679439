import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppProgressBar from 'app/shared/ui-elements/app-progress-bar/app-progress-bar';
import { ObjectChapterItemSequenceNumberAsString } from 'app/shared/util/dnd-utils';
import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UseFormRegisterReturn } from 'react-hook-form';
import AppDNDCard from '../app-dnd-card/app-dnd-card';
import { getPresignedUrl } from './video-upload-service';

interface IProps {
  chapterContentItem: ObjectChapterItemSequenceNumberAsString;
  register?: UseFormRegisterReturn;
  onVideoUrlSet: (fileName: string, url: string, chapterItemSequenceNumber: string) => void;
  onDeleteContent: (chapterItemSequenceNumber: string) => void;
  isSubmitDisabledHandler?: (value: boolean) => void;
}

const AppVideoUploader: FC<IProps> = props => {
  const [stashXHR, setStashXHR] = useState<XMLHttpRequest | null>(null);
  const [uploadElement, setUploadElement] = useState(null as any);
  const [fileName, setFilename] = useState(props.chapterContentItem.fileName);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onUpdateItem = (awsSavedVideoName: string, originalVideoName: string) => {
    props.onVideoUrlSet(originalVideoName, awsSavedVideoName, props.chapterContentItem.chapterItemSequenceNumber);
  };

  const resetLoadingStatesAndFiles = () => {
    props.isSubmitDisabledHandler && props.isSubmitDisabledHandler(false);
    setUploadProgress(0);
    setIsFileUploading(false);
    setSelectedFile(undefined);

    if (stashXHR) {
      stashXHR.abort();
      setStashXHR(null);
    }
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  const uploadFileToPresignedURLWithXMLHttpRequest = async () => {
    if (selectedFile) {
      setIsFileUploading(true);

      const generatedVideoName = +new Date() + selectedFile.name.split(' ').join('');

      getPresignedUrl(generatedVideoName)
        // eslint-disable-next-line @typescript-eslint/require-await
        .then((res: any) => {
          const presignedUrl = res.data;

          const xhr = new XMLHttpRequest();
          setStashXHR(xhr);

          xhr.open('PUT', presignedUrl, true);
          xhr.setRequestHeader('Content-Type', 'multipart/form-data');
          xhr.upload.onprogress = event => {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            setUploadProgress(percentCompleted);
          };
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 403) {
              resetLoadingStatesAndFiles();
            }
          };
          xhr.onerror = () => {
            resetLoadingStatesAndFiles();
          };
          xhr.onabort = () => {
            resetLoadingStatesAndFiles();
          };
          xhr.ontimeout = () => {
            resetLoadingStatesAndFiles();
          };
          xhr.onload = () => {
            onUpdateItem(generatedVideoName, selectedFile.name);
            resetLoadingStatesAndFiles();
          };
          xhr.send(selectedFile);
        })
        .catch(() => {
          resetLoadingStatesAndFiles();
        });
    }
  };

  useEffect(() => {
    if (selectedFile && uploadProgress <= 0) {
      uploadFileToPresignedURLWithXMLHttpRequest();
    }
  }, [selectedFile]);

  useEffect(() => {
    props.isSubmitDisabledHandler && props.isSubmitDisabledHandler(!!(uploadProgress || isFileUploading));
  }, [uploadProgress, isFileUploading]);

  return (
    <>
      <Row>
        <Col>
          <AppDNDCard
            label={'Upload Video'}
            onClickRemoveIcon={() => {
              resetLoadingStatesAndFiles();
              props.onDeleteContent(props.chapterContentItem.chapterItemSequenceNumber);
            }}
          >
            <Row>
              <Col className="col-12 col-md-8 mb-3 mb-md-0">
                <div className={`appInput`}>
                  <input placeholder={'Video Name'} value={fileName} disabled />
                </div>
              </Col>
              <Col>
                <input
                  className="d-none"
                  type="file"
                  ref={input => setUploadElement(input)}
                  accept=".webm, .rm, .qt, .mxf, .mpeg, .mp4, .mov, .flv, .f4v, .asf, .wmv, .mkv"
                  onChange={e => {
                    const file: any = e.target.files ? e.target.files[0] : null;
                    const fileType = file?.name.split('.').pop().toLowerCase();
                    const exList = 'webm,rm,qt,mxf,mpeg,mp4,mov,flv,f4v,asf,wmv,mkv'.split(',');
                    const b1 = file && exList.filter(ext => ext === fileType).length > 0;
                    const b2 = file && file.size <= 105255200 * 20;

                    if (b1 && b2 && file) {
                      setFilename(file?.name);
                      setSelectedFile(file);
                    }
                  }}
                />
                <AppButton
                  text={uploadProgress || isFileUploading ? 'In Progress' : 'Upload video'}
                  btnSize="md"
                  type="button"
                  btnType="filled"
                  onClick={() => {
                    if (uploadProgress <= 0) {
                      uploadElement.click();
                    }
                  }}
                  disabled={!!(uploadProgress || isFileUploading)}
                  style={uploadProgress || isFileUploading ? { cursor: 'wait' } : { cursor: 'pointer' }}
                />
              </Col>
            </Row>
            {isFileUploading ? (
              <Row>
                <Col>
                  <AppProgressBar progress={uploadProgress} />
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </AppDNDCard>
        </Col>
      </Row>
    </>
  );
};

export default AppVideoUploader;
