// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BgheKaD9hXfQBGY1q2YY {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: var(--text-secondary-color);
}

.gPLgCnxCgNnV1eb3DAWU {
  color: var(--error-color);
}

.odS3sPTjLsf4mWjR8aIO {
  color: var(--error-secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/tinymce-content-editor/TinyMceEditorContent.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kCAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,mCAAA;AACF","sourcesContent":[".tinyMceLabel {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 17px;\n  color: var(--text-secondary-color);\n}\n\n.asteriskStyles {\n  color: var(--error-color);\n}\n\n.errorMessage {\n  color: var(--error-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tinyMceLabel": `BgheKaD9hXfQBGY1q2YY`,
	"asteriskStyles": `gPLgCnxCgNnV1eb3DAWU`,
	"errorMessage": `odS3sPTjLsf4mWjR8aIO`
};
export default ___CSS_LOADER_EXPORT___;
