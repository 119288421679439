import Logout from 'app/modules/login/logout';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import React from 'react';
import { Route } from 'react-router-dom';
import { AUTHORITIES } from './config/constants';
import Activate from './modules/account/activate/activate';
import AdminCourses from './modules/admin/admin-courses/AdminCourses';
import AdminPayments from './modules/admin/admin-payments/admin-paymnets';
import ManageContent from './modules/admin/chapter-management/manage-content';
import CourseChapterManager from './modules/admin/course-chapter-manager/course-chapter-manager';
import CourseLessonsUnitsManager from './modules/admin/course-lessons-units-manager/course-lessons-units-manager';
import CouponsDashBoard from './modules/admin/settings/coupones/coupons-dashboard/coupons-dashboard';
import CouponsManager from './modules/admin/settings/coupones/coupons-manager/coupons-manager';
import CouponsViewer from './modules/admin/settings/coupones/coupons-viewer/coupons-viewer';
import ProgramCatalogPage from './modules/program-catalog/program-catalog-page';
import CourseChapterPresenter from './modules/student/course-chapter-presenter/course-chapter-presenter';
import CourseLessonsUnitsPresenter from './modules/student/course-lessons-units-presenter/course-lessons-units-presenter';
import StudentCourses from './modules/student/student-courses/StudentCourses';
import EmailConfirmation from './pages/account/email-confirmation/email-confirmation';
import EnrollUser from './pages/admin/user-roster/enroll-user/enroll-user';
import UserRoster from './pages/admin/user-roster/user-roster';
import ChangePassword from './pages/change-password/change-password';
import ContactUs from './pages/contact-us/ContactUs';
import ForgotPassword from './pages/forgot-password/forgot-password';
import JoinTheTeam from './pages/join-The-Team/JoinTheTeam';
import Payment from './pages/payment/payment';
import { PaymentStatus } from './pages/payment/payment-status';
import SignIn from './pages/sign-in/sign-in';
import SignUp from './pages/sign-up/sign-up';
import PrivateRoute from './shared/auth/private-route';
import MyProfile from './shared/components/my-profile/MyProfile';
import PageNotFound from './shared/error/page-not-found';
import CourseAddView from './shared/layout/components/course-add/CourseAdd';
import CoursePreview from './shared/layout/components/course-preview/CoursePreview';
import AuthLayout from './shared/layout/layouts/AuthLayout';
import CourseLayout from './shared/layout/layouts/CourseLayout';
import LandingLayout from './shared/layout/layouts/LandingLayout';
import MainLayout from './shared/layout/layouts/MainLayout';
import PaymentLayout from './shared/layout/layouts/PaymentLayout';

const AppRoutes = () => {
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route path="/" element={<LandingLayout />}>
          <Route index element={<ProgramCatalogPage />} />
          <Route path="catalog" element={<ProgramCatalogPage />} />
          <Route path="/" element={<ProgramCatalogPage />} />
          <Route path="course-preview/:courseId" element={<CoursePreview />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="join-the-team" element={<JoinTheTeam />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="/" element={<AuthLayout />}>
          <Route path="login" element={<SignIn />} />
          <Route path="register" element={<SignUp />} />
          <Route path="verify-email" element={<EmailConfirmation />} />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<ForgotPassword />} />
            <Route path="finish" element={<ChangePassword />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route path="logout" element={<Logout />} />

        <Route path="/payment" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]} />}>
          <Route path=":courseId" element={<PaymentLayout />}>
            <Route index element={<Payment />} />
            <Route path="status" element={<PaymentStatus />} />
          </Route>
        </Route>

        <Route path="/student" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]} />}>
          <Route path="courses" element={<MainLayout />}>
            <Route index element={<StudentCourses />} />
            <Route path=":courseId/*" element={<CourseLayout />}>
              <Route index element={<div>Course Dashboard</div>} />
              <Route path="course-dashboard" element={<div>Course Dashboard</div>} />
              <Route path="course-materials/*">
                <Route index element={<CourseLessonsUnitsPresenter />} />
                <Route path="enroll/:enrollmentId/lesson/:lessonId" element={<CourseChapterPresenter />} />
                <Route path="enroll/:enrollmentId/lesson/:lessonId/unit/:unitId" element={<CourseChapterPresenter />} />
              </Route>
              <Route path="grades" element={<div>Grades</div>} />
              <Route path="announcements" element={<div>Announcements</div>} />
              <Route path="course-info" element={<div>Course Info</div>} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Route>
          <Route path="notices" element={<MainLayout />}>
            <></>
          </Route>
          <Route path="account" element={<MainLayout />}>
            <Route element={<CourseLayout />}>
              <Route path="profile" element={<MyProfile />} />
              <Route path="orders" element={<div> Orders </div>} />
              <Route path="certificates" element={<div> Cerificates </div>} />
            </Route>
          </Route>
        </Route>

        <Route path="/admin" element={<PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]} />}>
          <Route path="courses" element={<MainLayout />}>
            <Route index element={<AdminCourses />} />
            <Route path="course-add" element={<CourseAddView />} />
            <Route path="course-add/:courseId" element={<CourseAddView />} />
            <Route path=":courseId/*" element={<CourseLayout />}>
              <Route index element={<div>Course Dashboard</div>} />
              <Route path="course-dashboard" element={<div>Course Dashboard</div>} />
              <Route path="course-materials/*">
                <Route index element={<CourseLessonsUnitsManager />} />
                <Route path="lesson/:lessonId/chapters/*">
                  <Route index element={<CourseChapterManager />} />
                  <Route path="add-chapter" element={<ManageContent />} />
                  <Route path=":chapterId/edit-chapter" element={<ManageContent />} />
                  <Route path="preview" element={<CourseChapterPresenter />} />
                </Route>
                <Route path="lesson/:lessonId/unit/:unitId/chapters/*">
                  <Route index element={<CourseChapterManager />} />
                  <Route path="add-chapter" element={<ManageContent />} />
                  <Route path=":chapterId/edit-chapter" element={<ManageContent />} />
                  <Route path="preview" element={<CourseChapterPresenter />} />
                </Route>
              </Route>
              <Route path="grades" element={<div>Grades</div>} />
              <Route path="announcements" element={<div>Announcements</div>} />
              <Route path="course-info" element={<div>Course Info</div>} />
              <Route path="user-roster">
                <Route index element={<UserRoster />} />
                <Route path="enroll-user" element={<EnrollUser />} />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Route>
          <Route path="notices" element={<MainLayout />}>
            <></>
          </Route>
          <Route path="payment" element={<MainLayout />}>
            <Route index element={<AdminPayments />} />
          </Route>
          <Route path="settings" element={<MainLayout />}>
            <Route element={<CourseLayout />}>
              <Route path="coupons/*">
                <Route index element={<CouponsDashBoard />} />
                <Route path="add" element={<CouponsManager />} />
                <Route path=":couponId/view" element={<CouponsViewer />} />
                <Route path=":couponId/edit" element={<CouponsManager />} />
              </Route>
            </Route>
          </Route>
          <Route path="account" element={<MainLayout />}>
            <Route element={<CourseLayout />}>
              <Route path="profile" element={<MyProfile />} />
              <Route path="orders" element={<div> Orders </div>} />
              <Route path="certificates" element={<div> Cerificates </div>} />
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
