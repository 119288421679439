import PriceRangeSlider from 'app/shared/components/range-slider/range-slider';
import { ECourseDifficultyLevel } from 'app/shared/model/enumerations/course-difficulty-level.model';
import { EFieldOfStudy } from 'app/shared/model/enumerations/field-of-study-type.model';
import { EPricingOption } from 'app/shared/model/enumerations/pricing-option.model';
import { IOption } from 'app/shared/model/option.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppSelect from 'app/shared/ui-elements/app-select/app-select';
import React, { useEffect, useState } from 'react';
import { Accordion, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsFunnel, BsX } from 'react-icons/bs';
import styles from './program-filter.module.scss';

interface IProps {
  onFilterChange: (field: string, skillLevel: string, sortBy: string, price: string, max: number, min: number) => void;
}

type TFilterFormValues = {
  fields: IOption;
  skillLevels: IOption;
  sortBy: IOption;
  price: IOption;
  max: number;
  min: number;
};

const initialFilters = {
  fields: { value: '', label: '' },
  skillLevels: { value: '', label: '' },
  sortBy: { value: '', label: '' },
  price: { value: '', label: '' },
  max: null,
  min: null,
};

const ProgramFilter = (props: IProps) => {
  const { onFilterChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [filterValues, setFilterValues] = useState(initialFilters);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const [priceRangeValue, setPriceRangeValue] = useState([0, 0]);

  const accordionOnClick = () => {
    setIsOpen(!isOpen);
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TFilterFormValues>({});

  const selectedFields = watch('fields');
  const selectedSkillLevel = watch('skillLevels');
  const selectedSortBy = watch('sortBy');
  const selectedPrice = watch('price');

  const clearFilters = () => {
    setFilterValues(initialFilters);
    setActiveFiltersCount(0);
    setValue('fields', filterValues.fields);
    setValue('skillLevels', filterValues.skillLevels);
    setValue('sortBy', filterValues.sortBy);
    setValue('price', filterValues.price);
    setPriceRangeValue([0, 0]);
  };

  const Field = Object.values(EFieldOfStudy).map(field => ({
    value: field,
    label: field
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
  }));
  const SkillLevel = [
    { value: ECourseDifficultyLevel.BEGINNER, label: 'Beginner' },
    { value: ECourseDifficultyLevel.INTERMEDIATE, label: 'Intermediate' },
    { value: ECourseDifficultyLevel.ADVANCED, label: 'Advanced' },
  ];

  const Price = [
    { value: EPricingOption.PAID, label: EPricingOption.PAID },
    { value: EPricingOption.FREE, label: EPricingOption.FREE },
  ];

  const SortBy = [
    { value: 'name,desc', label: 'Name' },
    { value: 'startDate,asc', label: 'Date' },
  ];

  const handleFilterChange = (field: any, skillLevel: any, sortBy: any, price: any, max: any, min: any) => {
    setFilterValues({
      fields: field || null,
      skillLevels: skillLevel || null,
      sortBy: sortBy || null,
      price: price || null,
      min: min || null,
      max: max || null,
    });

    let count = 0;
    if (field || skillLevel || sortBy || price) count = (field ? 1 : 0) + (skillLevel ? 1 : 0) + (sortBy ? 1 : 0) + (price ? 1 : 0);
    setActiveFiltersCount(count);
  };

  const renderFilterDropdown = () => {
    return (
      <Form onSubmit={handleSubmit(clearFilters)}>
        <Row className="align-items-center">
          <Col className="col-12 col-md-6 col-lg-3">
            <AppSelect
              options={Field}
              label="Subject"
              id="fields"
              name="fields"
              register={register('fields')}
              errors={errors}
              control={control}
            />
          </Col>
          {/* <Col className="col-12 col-md-6 col-lg-2">
            <AppSelect
              options={SkillLevel}
              label="Skill Level"
              id="skillLevels"
              name="skillLevels"
              {...register('skillLevels')}
              errors={errors}
              control={control}
            />
          </Col> */}
          <Col className="col-12 col-md-6 col-lg-2">
            <AppSelect
              options={SortBy}
              label="Sort By"
              id="sortBy"
              name="sortBy"
              {...register('sortBy')}
              errors={errors}
              control={control}
            />
          </Col>
          <Col className="col-12 col-md-6 col-lg-2">
            <AppSelect options={Price} label="Price" id="price" name="price" {...register('price')} errors={errors} control={control} />
          </Col>
          {selectedPrice?.label === EPricingOption.PAID && (
            <Col className="mt-4">
              <PriceRangeSlider
                priceRangeMinValue={priceRangeValue[0]}
                priceRangeMaxValue={priceRangeValue[1]}
                onInput={setPriceRangeValue}
                value={priceRangeValue}
              />
            </Col>
          )}
          {activeFiltersCount > 0 && (
            <Col className={`col-auto ps-0 d-none d-md-block mt-4 pt-2`}>
              <AppButton text={'Clear Filters'} btnType="onlyText" btnSize="sm" />
            </Col>
          )}
        </Row>
      </Form>
    );
  };

  useEffect(() => {
    handleFilterChange(
      selectedFields?.value,
      selectedSkillLevel?.value,
      selectedSortBy?.value,
      selectedPrice?.value,
      priceRangeValue[0],
      priceRangeValue[1]
    );
    onFilterChange(
      selectedFields?.value,
      selectedSkillLevel?.value,
      selectedSortBy?.value,
      selectedPrice?.value,
      priceRangeValue[0],
      priceRangeValue[1]
    );
  }, [selectedSkillLevel, selectedFields, selectedSortBy, selectedPrice, priceRangeValue]);

  return (
    <Container>
      <Row>
        <Col className="d-none d-md-block mt-5 col-12">{renderFilterDropdown()}</Col>
        <Col>
          <Row className="align-items-center">
            <Col xs={5} className={`${isOpen ? '' : 'pe-0'}`}>
              <div className={`${styles.accordionWrapper} ${isOpen ? styles.fullWidth : ''} mt-5 d-block d-md-none`}>
                <Accordion className={`${styles.accordion}`}>
                  <Accordion.Item eventKey="0" className={`${styles.accordionItem}`}>
                    <Accordion.Header onClick={accordionOnClick} className={`cursor-pointer`}>
                      Filters
                      <span className={`${styles.headerIcon}`}>
                        {isOpen ? (
                          <BsX size={24} color="var(--text-primary-color)" />
                        ) : (
                          <div className={` ${activeFiltersCount && 'mb-3'}`}>
                            {activeFiltersCount > 0 && <div className={`${styles.badge}`}>{activeFiltersCount}</div>}
                            <BsFunnel size={24} color="var(--icon-color)" />
                          </div>
                        )}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>{renderFilterDropdown()}</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            {activeFiltersCount > 0 && (
              <Col className={`${isOpen ? 'ms-5 ps-5' : 'col-auto px-0'} d-block d-md-none mt-5`}>
                <AppButton text={'Clear Filters'} onClick={clearFilters} btnType="onlyText" btnSize="sm" />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ProgramFilter;
