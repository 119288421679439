import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getAllEntities, getAllEntitiesWithEnrollment } from 'app/entities/course/course.reducer';
import { ICourseDetails } from 'app/shared/model/dto/course-details.model';
import { ECourseDifficultyLevel } from 'app/shared/model/enumerations/course-difficulty-level.model';
import { EFieldOfStudy } from 'app/shared/model/enumerations/field-of-study-type.model';
import { EPricingOption } from 'app/shared/model/enumerations/pricing-option.model';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Pagination from '../../../shared/components/pagination/pagination';
import CourseCard from './course-card/course-card';
import CourseCardLoader from './course-loader-card/course-loader-card';

const CourseGridView = ({ selectedField, selectedSkillLevel, selectedSortBy, searchInput, selectedPrice, minPrice, maxPrice }) => {
  const dispatch = useAppDispatch();
  const { courseList, totalItems, loading, isAuthenticated, courseListWithEnrollment } = useAppSelector((state: IRootState) => ({
    courseList: state.course.entities,
    courseListWithEnrollment: state.course.courseListWithEnrollment,
    totalItems: state.course.totalItems,
    loading: state.course.loading,
    isAuthenticated: state.authentication.isAuthenticated,
  }));

  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);

  const handleResize = () => {
    if (window.innerWidth >= 992) {
      setItemsPerPage(12);
    } else if (window.innerWidth >= 768) {
      setItemsPerPage(4);
    } else {
      setItemsPerPage(3);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const getOrdinalValue = (enumObject: any, enumValue: string): number => {
    const keys = Object.keys(enumObject);
    const index = keys.findIndex(key => enumObject[key] === enumValue);
    return index;
  };

  const paramsList = useMemo(() => {
    return {
      ...(selectedSkillLevel && {
        difficultyLevel: getOrdinalValue(ECourseDifficultyLevel, selectedSkillLevel),
      }),
      ...(searchInput && { searchText: searchInput }),
      ...(selectedField && {
        fieldOfStudy: getOrdinalValue(EFieldOfStudy, selectedField),
      }),
      ...(selectedPrice && {
        selectedPrice: selectedPrice === EPricingOption.PAID,
      }),
      ...(selectedSortBy && {
        sort: selectedSortBy,
      }),
      ...(minPrice && {
        minPrice,
      }),
      ...(maxPrice && {
        maxPrice,
      }),
      page: currentPage - 1,
      size: itemsPerPage,
    };
  }, [selectedSkillLevel, searchInput, selectedField, selectedSortBy, selectedPrice, minPrice, maxPrice, currentPage, itemsPerPage]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(
        getAllEntitiesWithEnrollment({
          page: paramsList.page,
          size: paramsList.size,
          sort: paramsList.sort,
          difficultyLevel: paramsList.difficultyLevel,
          searchText: paramsList.searchText,
          fieldOfStudy: paramsList.fieldOfStudy,
          isPaid: paramsList.selectedPrice,
          minPrice: paramsList.minPrice,
          maxPrice: paramsList.maxPrice,
        })
      );
    } else {
      dispatch(
        getAllEntities({
          page: paramsList.page,
          size: paramsList.size,
          sort: paramsList.sort,
          difficultyLevel: paramsList.difficultyLevel,
          searchText: paramsList.searchText,
          fieldOfStudy: paramsList.fieldOfStudy,
          isPaid: paramsList.selectedPrice,
          minPrice: paramsList.minPrice,
          maxPrice: paramsList.maxPrice,
        })
      );
    }
  }, [selectedSortBy, dispatch, paramsList, isAuthenticated]);

  useEffect(() => {
    const maxPage = Math.ceil(totalItems / itemsPerPage);
    const newPage = maxPage < currentPage ? 1 : currentPage;
    setCurrentPage(newPage);
  }, [totalItems, currentPage]);

  const handlePageChange = currentPageNumber => {
    setCurrentPage(currentPageNumber);
  };

  return (
    <Container className="pb-5">
      <Row className={`mt-5 mb-0 mb-md-2 mb-lg-5`}>
        {loading
          ? Array.from({ length: itemsPerPage }).map((_, index) => (
              <Col xl={3} lg={4} md={6} sm={12} key={index} className="mb-4 px-lg-2">
                <CourseCardLoader />
              </Col>
            ))
          : isAuthenticated
          ? courseListWithEnrollment &&
            courseListWithEnrollment.map((item: ICourseDetails) => (
              <Col xl={3} lg={4} md={6} sm={12} key={item.id} className="mb-4 px-lg-2">
                <CourseCard course={item} />
              </Col>
            ))
          : courseList &&
            courseList.map((item: ICourseDetails) => (
              <Col xxl={3} lg={4} md={6} sm={12} key={item.id} className="mb-4 px-lg-2">
                <CourseCard course={item} />
              </Col>
            ))}
      </Row>
      {!loading && (
        <Pagination length={totalItems} itemsPerPage={itemsPerPage} currentPage={currentPage} updateCurrentPage={handlePageChange} />
      )}
    </Container>
  );
};

export default CourseGridView;
