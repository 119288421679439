import { v4 as uuidv4 } from 'uuid';
import { IRowData } from '../components/multiple-rows-addition-field/multiple-rows-addition-field';
import { ICourseOutlines } from '../model/course-outline.model';
import { IFrequentlyAskedQuestions } from '../model/course.model';
import { IExpert } from '../model/expert.model';
import { getPartBeforeLastTwoPeriods } from './app-elements-utils';

export const convertExpertsToIRowData = (expertsPanel: { name: string; designation: string; imageUrl: string }[]) => {
  return expertsPanel
    ? expertsPanel?.map(data => {
        return {
          id: uuidv4(),
          firstInput: data.name,
          secondInput: data.designation,
          isValidFirstInput: true,
          isValidSecondInput: true,
          imageUrl: data.imageUrl,
        };
      })
    : [];
};

export const convertOutlinesToIRowData = (outlines: { title: string; description: string }[]) => {
  return outlines
    ? outlines?.map(outline => {
        return {
          id: uuidv4(),
          firstInput: outline.title,
          secondInput: outline.description,
          isValidFirstInput: true,
          isValidSecondInput: true,
          imageUrl: '',
        };
      })
    : [];
};

export const convertFaqsToIRowData = (frequentlyAskedQuestions: { question: string; answer: string }[]) => {
  return frequentlyAskedQuestions
    ? frequentlyAskedQuestions?.map(data => {
        return {
          id: uuidv4(),
          firstInput: data.question,
          secondInput: data.answer,
          isValidFirstInput: true,
          isValidSecondInput: true,
          imageUrl: '',
        };
      })
    : [];
};

export const convertToExperts = (rowDataArray: IRowData[]): IExpert[] => {
  return rowDataArray
    .filter(data => Boolean(data.firstInput) && Boolean(data.secondInput))
    .map(rowData => {
      const { firstInput, secondInput, imageUrl } = rowData;
      return {
        imageUrl,
        name: firstInput,
        designation: secondInput,
      };
    });
};

export const convertToFaqs = (rowDataArray: IRowData[]): IFrequentlyAskedQuestions[] => {
  return rowDataArray
    .filter(data => Boolean(data.firstInput) && Boolean(data.secondInput))
    .map(rowData => {
      const { firstInput, secondInput } = rowData;
      return {
        question: firstInput,
        answer: secondInput,
      };
    });
};

export const convertToOutlines = (rowDataArray: IRowData[]): ICourseOutlines[] => {
  return rowDataArray
    .filter(data => Boolean(data.firstInput) && Boolean(data.secondInput))
    .map(rowData => {
      const { firstInput, secondInput } = rowData;
      return {
        title: firstInput,
        description: secondInput,
      };
    });
};

export const convertURL = (url: string) => {
  if (url) {
    const fileName = getPartBeforeLastTwoPeriods(url);
    return [{ url, file: fileName }];
  }
};

export const getOrdinalValue = (enumObject: any, enumValue: string): number => {
  const keys = Object.keys(enumObject);
  const index = keys.findIndex(key => enumObject[key] === enumValue);
  return index;
};

export const capitalizeFirstLetterWithNumber = str => {
  if (typeof str !== 'string' || str.trim() === '') {
    return '';
  }
  const words = str.split(' ');
  const capitalizedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(' ');
};
