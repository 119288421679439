// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-slider {
  background: var(--card-color);
  border-radius: 12px;
  height: 12px;
}
.range-slider .range-slider__range {
  background: var(--accent-color);
}
.range-slider .range-slider__thumb {
  border: 1px solid var(--accent-color) !important;
  background: var(--card-secondary-color);
  width: 14px;
  height: 14px;
}

.priceRange {
  font-size: 12px;
  font-weight: 400;
  line-height: 0px;
  color: var(--text-secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/range-slider/range-slider.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,+BAAA;AACJ;AAEE;EACE,gDAAA;EACA,uCAAA;EACA,WAAA;EACA,YAAA;AAAJ;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kCAAA;AADF","sourcesContent":[".range-slider {\n  background: var(--card-color);\n  border-radius: 12px;\n  height: 12px;\n\n  .range-slider__range {\n    background: var(--accent-color);\n  }\n\n  .range-slider__thumb {\n    border: 1px solid var(--accent-color) !important;\n    background: var(--card-secondary-color);\n    width: 14px;\n    height: 14px;\n  }\n}\n\n.priceRange {\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 0px;\n  color: var(--text-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
