import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getAllEntitiesWithEnrollment } from 'app/entities/course/course.reducer';
import Pagination from 'app/shared/components/pagination/pagination';
import { EPaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { ESortOrder } from 'app/shared/model/enumerations/sort-types.model';
import { IOption } from 'app/shared/model/option.model';
import { IPayment } from 'app/shared/model/payment.model';
import AppSearch from 'app/shared/ui-elements/app-search/app-search';
import AppSelect from 'app/shared/ui-elements/app-select/app-select';
import AppSortIcon from 'app/shared/ui-elements/app-sort-icon/app-sort-icon';
import { displayDateTime } from 'app/shared/util/date-utils';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsEye } from 'react-icons/bs';
import styles from './admin-payment.module.scss';
import OrderInformationModal from './order-information-modal/order-information-modal';
import { getPayments, resetPayments } from './payment.reducer';

const AdminPayment = () => {
  const dispatch = useAppDispatch();

  const { paymentList, totalItems, courseList } = useAppSelector((rootState: IRootState) => ({
    paymentList: rootState.payments.paymentList,
    totalItems: rootState.payments.totalItems,
    courseList: rootState.course.courseListWithEnrollment,
  }));

  const itemsPerPage = 10;
  const [searchText, setSearchText] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [courses, setCourses] = useState<IOption[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<IPayment>();
  const [modalOpen, setModalOpen] = useState(false);
  const [sortedId, setSortedId] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedStatus, setSortedStatus] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedCourseName, setSortedCourseName] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedCreatedDate, setSortedCreatedDate] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedAmount, setSortedAmount] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedName, setSortedName] = useState<ESortOrder>(ESortOrder.NOT_SORT);

  const { register, control, watch } = useForm({});
  const selectedCourses = watch('courses');

  const arrowSort = (sortType: 'id' | 'status' | 'courseName' | 'createdDate' | 'amount' | 'name') => {
    setSortedId(prev =>
      sortType === 'id' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedStatus(prev =>
      sortType === 'status' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedCourseName(prev =>
      sortType === 'courseName' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedCreatedDate(prev =>
      sortType === 'createdDate' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedAmount(prev =>
      sortType === 'amount' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedName(prev =>
      sortType === 'name' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
  };

  const sortParam = `${
    sortedId !== ESortOrder.NOT_SORT ? `id,${sortedId === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}` : ''
  }${
    sortedStatus !== ESortOrder.NOT_SORT
      ? `status,${sortedStatus === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}`
      : ''
  }${
    sortedCourseName !== ESortOrder.NOT_SORT
      ? `courseName,${sortedCourseName === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}`
      : ''
  }${
    sortedCreatedDate !== ESortOrder.NOT_SORT
      ? `createdDate,${sortedCreatedDate === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}`
      : ''
  }${
    sortedAmount !== ESortOrder.NOT_SORT
      ? `amount,${sortedAmount === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}`
      : ''
  }${sortedName !== ESortOrder.NOT_SORT ? `name,${sortedName === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}` : ''}`;

  const extractCourses = () => {
    if (courseList) {
      setCourses(courseList.map(course => ({ value: course.id.toString(), label: course.name })));
    }
  };

  const fetchPayments = () => {
    dispatch(
      getPayments({
        searchText,
        page: currentPage - 1,
        size: itemsPerPage,
        courseId: selectedCourses?.value,
        sort: sortParam,
      })
    );
    dispatch(
      getAllEntitiesWithEnrollment({
        draft: false,
        isPaid: true,
        page: 0,
        size: 100,
      })
    );
  };

  const onSearch = () => {
    if (currentPage === 1) fetchPayments();
    else {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    extractCourses();
    setCourses(prev => [{ value: null, label: 'All' }, ...prev]);
  }, [courseList]);

  useEffect(() => {
    fetchPayments();
    return () => {
      resetPayments();
    };
  }, [currentPage, searchText, selectedCourses, sortParam]);

  useEffect(() => {
    const maxPage = Math.ceil(totalItems / itemsPerPage);
    const newPage = maxPage < currentPage ? 1 : currentPage;
    setCurrentPage(newPage);
  }, [totalItems, currentPage]);

  return (
    <div className="p-4">
      <Row>
        <Col className={styles.txt1}>All Payments</Col>
      </Row>
      <Row className="mt-1 align-items-end">
        <Col className="col-12 col-md-6 col-lg-4 pe-md-0">
          <AppSearch onChange={setSearchText} onSearch={onSearch} placeholder={'Search ID or Name'} value={searchText} />
        </Col>
        <Col className="col-12 col-md-6 col-lg-4 mt-3 mt-md-0">
          <AppSelect label="Courses" id="courses" name="courses" options={courses} register={register('courses')} control={control} />
        </Col>
      </Row>
      <Row>
        <Col className="mt-4">
          <Table responsive className="w-100">
            <thead>
              <tr>
                <th>
                  <div className="d-flex align-items-center">
                    <AppSortIcon onChange={() => arrowSort('id')} sortedItem={sortedId} text={'ID'} />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <AppSortIcon onChange={() => arrowSort('status')} sortedItem={sortedStatus} text={'Status'} />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <AppSortIcon onChange={() => arrowSort('name')} sortedItem={sortedName} text={'Name'} />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <AppSortIcon onChange={() => arrowSort('courseName')} sortedItem={sortedCourseName} text={'Course'} />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <AppSortIcon onChange={() => arrowSort('createdDate')} sortedItem={sortedCreatedDate} text={'Payments At'} />
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <AppSortIcon onChange={() => arrowSort('amount')} sortedItem={sortedAmount} text={'Amount (LKR)'} />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentList?.map((payment: IPayment) => (
                <tr
                  className={`${styles.txt3} cursor-pointer`}
                  key={payment.orderId}
                  onClick={() => {
                    setSelectedOrder(payment);
                  }}
                >
                  <td>{payment.orderId}</td>
                  <td>
                    {payment.paymentStatus === EPaymentStatus.PENDING ? (
                      <span>Pending Payhere</span>
                    ) : payment.paymentStatus === EPaymentStatus.SUCCESS ? (
                      <span>Received Payment</span>
                    ) : payment.paymentStatus === EPaymentStatus.FAILED ? (
                      <span>Payment Failed</span>
                    ) : payment.paymentStatus === EPaymentStatus.CANCELED ? (
                      <span>Payment Canceled</span>
                    ) : payment.paymentStatus === EPaymentStatus.CHARGEBACK ? (
                      <span>Change Back</span>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>{payment.userFirstname + ' ' + payment.userLastname}</td>
                  <td>{payment.courseName}</td>
                  <td>{displayDateTime(payment.paidOn)}</td>
                  <td>
                    <Row className="justify-content-between">
                      <Col>{payment.subTotal.toLocaleString()}</Col>
                      <Col className="col-auto">
                        <BsEye onClick={() => setModalOpen(true)} className="cursor-pointer" color={`var(--accent-color)`} size={20} />
                      </Col>
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      {totalItems > itemsPerPage && (
        <Row>
          <Col className="mb-3">
            <Pagination
              length={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              updateCurrentPage={(currentPageNumber: number) => {
                setCurrentPage(currentPageNumber);
              }}
            />
          </Col>
        </Row>
      )}
      <OrderInformationModal isOpen={modalOpen} closeHandler={() => setModalOpen(false)} OrderInformationData={selectedOrder} />
    </div>
  );
};

export default AdminPayment;
