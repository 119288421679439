import { ECouponState } from '../model/enumerations/coupon-mode';

export const getStatus = (activeDate: string, expireDate: string) => {
  const currentDate = new Date();
  const activeDateTime = new Date(activeDate);
  const expireDateTime = new Date(expireDate);

  if (currentDate < activeDateTime) {
    return ECouponState.PENDING;
  } else if (currentDate >= activeDateTime && currentDate <= expireDateTime) {
    return ECouponState.ACTIVE;
  } else {
    return ECouponState.EXPIRED;
  }
};
