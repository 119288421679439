// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J44p4wHpMQnF8QqwU2ji .dc63F7cYNAacQYWnpxw6 {
  border-radius: 12px;
  background: var(--card-color);
}
.J44p4wHpMQnF8QqwU2ji .iytLFzhDTfvVTTtsBuid {
  color: var(--text-light-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-banner/app-banner.module.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,6BAAA;AAAJ;AAEE;EACE,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".bannerCard {\n  .bannerContainer {\n    border-radius: 12px;\n    background: var(--card-color);\n  }\n  .bannerText {\n    color: var(--text-light-color);\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 17px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerCard": `J44p4wHpMQnF8QqwU2ji`,
	"bannerContainer": `dc63F7cYNAacQYWnpxw6`,
	"bannerText": `iytLFzhDTfvVTTtsBuid`
};
export default ___CSS_LOADER_EXPORT___;
