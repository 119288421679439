import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ICourseLessonsUnitsManager } from 'app/modules/admin/course-lessons-units-manager/course-lessons-units-manager.model';
import { createLesson, updateLesson } from 'app/modules/admin/course-lessons-units-manager/course-lessons-units-manager.reducer';
import ModelLayout from 'app/shared/layout/layouts/ModelLayout';
import { ILesson } from 'app/shared/model/lesson.model';
import { noWhitespaceStartEndRegex } from 'app/shared/util/regex';
import React, { FC, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BsQuestionSquare } from 'react-icons/bs';
import AppBanner from '../../app-banner/app-banner';
import AppButton from '../../app-button/app-button';
import AppInput from '../../app-input/app-input';
import AppToggle from '../../app-toggle/app-toggle';
import TextArea from '../../text-area-component/TextArea';

interface IProps {
  isOpen: boolean;
  initData?: ILesson;
  closeHandler: () => void;
}

const LessonModel: FC<IProps> = props => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<ILesson>({});

  const containsUnits = useRef<boolean>();
  containsUnits.current = watch('containsUnits', true);

  const dispatch = useAppDispatch();
  const { state, actionSuccess } = useAppSelector(rootState => ({
    state: rootState.courseLessonsUnitsManager.entity as ICourseLessonsUnitsManager,
    actionSuccess: rootState.courseLessonsUnitsManager.updateSuccess,
  }));

  useEffect(() => {
    if (props.initData) {
      for (const [key, value] of Object.entries(props.initData)) {
        setValue(key as any, value, { shouldDirty: true, shouldValidate: true });
      }
    }
  }, [props.initData, setValue]);

  useEffect(() => {
    if (actionSuccess) {
      closeHandler();
    }
  }, [actionSuccess]);

  const onToggle = (newStatus: boolean) => {
    setValue('containsUnits', newStatus);
  };

  const onSubmit: SubmitHandler<ILesson> = (data: ILesson) => {
    data.courseId = state.courseId;
    data.containsUnits = containsUnits.current;

    if (data.id) {
      dispatch(updateLesson(data));
    } else {
      dispatch(createLesson(data));
    }

    reset();
  };

  const closeHandler = () => {
    props.closeHandler();
    reset();
  };

  return (
    <ModelLayout closeHandler={closeHandler} isOpen={props.isOpen} modelTitle={props.initData ? 'Update Lesson' : 'Add Lesson'}>
      <Row>
        <Col>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="mb-2">
                <AppInput
                  id="name"
                  name="name"
                  label="Lesson Name"
                  placeholder="Lesson Name"
                  isRequired={true}
                  register={register('name', {
                    required: 'Lesson name is required',
                    minLength: {
                      value: 2,
                      message: 'This field must contain at least 2 characters',
                    },
                    maxLength: {
                      value: 50,
                      message: 'You have exceeded the maximum number of 50 characters in this field',
                    },
                    pattern: {
                      value: noWhitespaceStartEndRegex,
                      message: "Entered value can't start/end or contain only white spaces",
                    },
                  })}
                  errors={errors}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <TextArea
                  id="description"
                  name="description"
                  label="Description"
                  placeholder="Description"
                  register={register('description', {
                    minLength: {
                      value: 2,
                      message: 'This field must contain at least 2 characters',
                    },
                    maxLength: {
                      value: 50,
                      message: 'You have exceeded the maximum number of 50 characters in this field',
                    },
                    pattern: {
                      value: noWhitespaceStartEndRegex,
                      message: "Entered value can't start/end or contain only white spaces",
                    },
                  })}
                  errors={errors}
                />
              </Col>
            </Row>

            <Row className="align-items-center mb-2">
              <Col className="col-auto">Lesson includes units?</Col>
              <Col className="col-auto">
                <AppToggle
                  initialState={props.initData?.id ? props.initData.containsUnits : containsUnits.current}
                  disabled={props.initData ? true : false}
                  onToggle={onToggle}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-4">
                <AppBanner
                  icon={<BsQuestionSquare size={25} color="var(--accent-color)" />}
                  text="This is a permanent setting. You won't be able to change it later. Please make sure that if the lesson requires any
                units before proceeding."
                />
              </Col>
            </Row>

            <Row>
              <Col className="col-12 col-sm-4 mb-2 mb-sm-0">
                <AppButton text={props.initData ? 'Update Lesson' : 'Add Lesson'} btnSize="sm" type="submit" />
              </Col>
              <Col className="col-12 col-sm-4">
                <AppButton text="Cancel" btnSize="sm" type="button" btnType="bordered" onClick={closeHandler} />
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </ModelLayout>
  );
};

export default LessonModel;
