import { ESortOrder } from 'app/shared/model/enumerations/sort-types.model';
import React from 'react';
import { BsArrowDownShort } from 'react-icons/bs';
import styles from './app-sort-icon.module.scss';

interface IProps {
  onChange: () => void;
  sortedItem: string;
  text: string;
}

const AppSortIcon = (props: IProps) => {
  return (
    <>
      <span className={`${styles.txt1} mt-1`}>{props.text}</span>
      <div
        onClick={props.onChange}
        className={`${styles.upArrow} cursor-pointer ${
          props.sortedItem !== ESortOrder.NOT_SORT ? (props.sortedItem === ESortOrder.SORT_ASC ? styles.rotate0 : styles.rotate180) : ''
        }`}
      >
        <BsArrowDownShort color={`var(--text-primary-color)`} size={20} />
      </div>
    </>
  );
};

export default AppSortIcon;
