import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { handlePasswordResetInit } from 'app/modules/account/password-reset/password-reset.reducer';
import AccountSideImageSection from 'app/shared/components/account-side-image-section/account-side-image-section';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import { showErrorToast, showSuccessToast } from 'app/shared/util/toast';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import styles from './email-confirmation.module.scss';
import { resendEmail } from './email-confirmation.reducer';

const EmailConfirmation = () => {
  const location = useLocation();
  const email = location.state?.email;
  const isFromForgetPassword = location.state?.isFromForgetPassword;
  const dispatch = useAppDispatch();
  const { emailSendSuccess, emailSendFailure, loading, resetPasswordSuccess, resetPasswordFailure } = useAppSelector(
    (state: IRootState) => ({
      emailSendSuccess: state.emailConfirmation.emailSendSuccess,
      emailSendFailure: state.emailConfirmation.emailSendFailure,
      loading: state.emailConfirmation.loading,
      resetPasswordSuccess: state.passwordReset.resetPasswordSuccess,
      resetPasswordFailure: state.passwordReset.resetPasswordFailure,
    })
  );

  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    startCountdown();
  }, []);

  useEffect(() => {
    if (emailSendSuccess) {
      showSuccessToast('Email has been successfully resent. You will receive it shortly.', 'emailSendSuccess');
      startCountdown();
    }

    if (emailSendFailure) {
      showErrorToast('Email resend failed. Please try again.', 'emailSendFailure');
    }
  }, [emailSendSuccess, emailSendFailure]);

  useEffect(() => {
    if (resetPasswordSuccess && isFromForgetPassword) {
      showSuccessToast('Password reset request has been sent successfully.', 'resetPasswordSuccess');
      startCountdown();
    }

    if (resetPasswordFailure) {
      showErrorToast('Email resend failed. Please try again.', 'resetPasswordSuccess');
    }
  }, [resetPasswordSuccess, resetPasswordFailure]);

  useEffect(() => {
    let interval;

    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countdown]);

  const startCountdown = () => {
    setCountdown(299);
  };

  const resendEmailHandler = () => {
    if (isFromForgetPassword && isFromForgetPassword) {
      dispatch(handlePasswordResetInit(email));
    } else {
      dispatch(resendEmail({ email }));
    }
  };

  const formatCountdown = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes === 0) {
      return `${remainingSeconds} seconds`;
    } else {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
    }
  };

  return (
    <>
      <Container className="py-lg-5 py-md-3 h-100">
        <Row className="align-items-center justify-content-evenly h-100">
          <Col className="col-12 col-lg-4">
            <AccountSideImageSection />
          </Col>

          <Col className={`col-12 col-lg-5 ${styles.formSection}`}>
            <Row>
              <Col className={`${styles.mainText} text-center`}>
                An email has been sent to your email to
                <span className={`${styles['mainText_verifyText']}`}> verify email </span>
                address
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col className={` ${styles.customGap} text-center`}>
                <span className={`${styles.subText}`}>
                  Please check your email
                  <span className={`${styles['subText_emailText']} ms-1`}>{email && email}</span>. Email might take up to 5 minutes to
                  receive. If you didn&apos;t even receive after 5 minutes try again by clicking resend email button.
                </span>
              </Col>
            </Row>

            <Row>
              <Col>
                <AppButton text={'Resend Email'} onClick={resendEmailHandler} disabled={countdown > 0} loading={loading} />
              </Col>
            </Row>
            <Row>
              <Col className="text-center mt-3">{countdown > 0 ? `Wait ${formatCountdown(countdown)} before resend` : ''}</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmailConfirmation;
