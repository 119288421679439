import { createAsyncThunk, isPending } from '@reduxjs/toolkit';
import { EntityState, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import { ICourseLessonsUnitsPresenter, defaultValue } from './course-lessons-units-presenter.model';

const initialState: EntityState<ICourseLessonsUnitsPresenter> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/courses-sequence-student';

// Actions
export const getLessonsAndUnitsOfCourse = createAsyncThunk(
  'courseLessonsUnitsPresenter/fetch_entity',
  async (courseId: string | number) => {
    const requestUrl = `${apiUrl}/${courseId}`;
    return axios.get<ICourseLessonsUnitsPresenter>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// slice
export const CourseLessonsUnitsPresenterSlice = createEntitySlice({
  name: 'courseLessonsUnitsPresenter',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getLessonsAndUnitsOfCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getLessonsAndUnitsOfCourse), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = CourseLessonsUnitsPresenterSlice.actions;

// Reducer
export default CourseLessonsUnitsPresenterSlice.reducer;
