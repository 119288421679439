import { PayloadAction, createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ILesson } from 'app/shared/model/lesson.model';
import { IUnit } from 'app/shared/model/unit.model';
import { EntityState, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { convertLessonIdsAndUnitIdsToString, convertToCourseLessonsAndUnitsSequenceRequest } from 'app/shared/util/dnd-utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';
import { ICourseLessonsUnitsManager, defaultValue } from './course-lessons-units-manager.model';

const initialState: EntityState<ICourseLessonsUnitsManager> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/admin/courses-sequence';
const saveSequenceApiUrl = '/api/admin/courses/sequence';
const crudUrlForLesson = 'api/admin/lessons';
const crudUrlForUnit = 'api/admin/units';

// Actions
export const getLessonsAndUnitsOfCourse = createAsyncThunk(
  'courseLessonsUnitsManager/fetch_entity',
  async (courseId: string | number) => {
    const requestUrl = `${apiUrl}/${courseId}`;
    return axios.get<ICourseLessonsUnitsManager>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const arrangeLessonAndUnitSequence = createAsyncThunk(
  'courseLessonsUnitsManager/create_entity',
  async (data: ICourseLessonsUnitsManager, thunkAPI) => {
    const upatedData = convertToCourseLessonsAndUnitsSequenceRequest(data as any);
    const result = await axios.post<ICourseLessonsUnitsManager>(saveSequenceApiUrl, cleanEntity(upatedData));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createLesson = createAsyncThunk(
  'courseLessonsUnitsManager/create_lesson',
  async (entity: ILesson, thunkAPI) => {
    const result = await axios.post<ILesson>(crudUrlForLesson, cleanEntity(entity));
    thunkAPI.dispatch(getLessonsAndUnitsOfCourse(entity.courseId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateLesson = createAsyncThunk(
  'courseLessonsUnitsManager/update_lesson',
  async (entity: ILesson, thunkAPI) => {
    const result = await axios.put<ILesson>(`${crudUrlForLesson}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getLessonsAndUnitsOfCourse(entity.courseId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteLesson = createAsyncThunk(
  'courseLessonsUnitsManager/delete_lesson',
  async (data: { courseId: number; id: number }, thunkAPI) => {
    const requestUrl = `${crudUrlForLesson}/${data.id}`;
    const result = await axios.delete<ILesson>(requestUrl);
    thunkAPI.dispatch(getLessonsAndUnitsOfCourse(data.courseId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createUnit = createAsyncThunk(
  'courseLessonsUnitsManager/create_unit',
  async (entity: IUnit, thunkAPI) => {
    const result = await axios.post<IUnit>(crudUrlForUnit, cleanEntity(entity));
    thunkAPI.dispatch(getLessonsAndUnitsOfCourse(entity.courseId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateUnit = createAsyncThunk(
  'courseLessonsUnitsManager/update_unit',
  async (entity: IUnit, thunkAPI) => {
    const result = await axios.put<IUnit>(`${crudUrlForUnit}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getLessonsAndUnitsOfCourse(entity.courseId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteUnit = createAsyncThunk(
  'courseLessonsUnitsManager/delete_unit',
  async (data: { courseId: number; id: number }, thunkAPI) => {
    const requestUrl = `${crudUrlForUnit}/${data.id}`;
    const result = await axios.delete<IUnit>(requestUrl);
    thunkAPI.dispatch(getLessonsAndUnitsOfCourse(data.courseId));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice
export const CourseLessonsUnitsManagerSlice = createEntitySlice({
  name: 'courseLessonsUnitsManager',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getLessonsAndUnitsOfCourse.fulfilled, (state, action) => {
        action.payload;
        state.loading = false;
        action.payload.data.lessons = convertLessonIdsAndUnitIdsToString([...action.payload.data.lessons] as any) as any;
        state.entity = action.payload.data;
      })
      .addMatcher(
        isFulfilled(createLesson, updateLesson, deleteLesson, createUnit, updateUnit, deleteUnit, arrangeLessonAndUnitSequence),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
        }
      )
      .addMatcher(isPending(getLessonsAndUnitsOfCourse), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createLesson, updateLesson, deleteLesson, createUnit, updateUnit, deleteUnit), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(arrangeLessonAndUnitSequence), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
        state.loading = true;
      });
  },
  reducers: {
    updateLessonsHandler(state, action: PayloadAction<ILesson[]>) {
      state.entity.lessons = action.payload;
    },
  },
});

export const { reset, updateLessonsHandler } = CourseLessonsUnitsManagerSlice.actions;

// Reducer
export default CourseLessonsUnitsManagerSlice.reducer;
