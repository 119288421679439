// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._vFMj2YECxUFs3YBf09f {
  background-color: var(--background-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/program-catalog/program-catalog-page.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;AACF","sourcesContent":[".catalogPageLayout {\n  background-color: var(--background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"catalogPageLayout": `_vFMj2YECxUFs3YBf09f`
};
export default ___CSS_LOADER_EXPORT___;
