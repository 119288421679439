import React, { TextareaHTMLAttributes } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DeepMap, FieldError, FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import styles from './TextArea.module.scss';

export type FieldErrors<TFieldValues extends FieldValues = FieldValues> = DeepMap<TFieldValues, FieldError>;
interface IProps extends React.DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  errorMessage?: string;
  register?: UseFormRegisterReturn;
  errors?: FieldErrors;
  isError?: boolean;
  isSuccess?: boolean;
}

const TextArea = (props: IProps) => {
  const { className, label, register, placeholder, errors, isError, isSuccess, isRequired, ...rest } = props;

  return (
    <Row className="align-items-center">
      <Col>
        <div className={`${className}`}>
          <label className={`${styles['text-area-input-label']} fw-normal mb-2`}>
            {label} {isRequired && <span className={`${styles.required}`}>*</span>}
          </label>
          <div
            className={
              (rest.name && errors[rest.name]?.message) || isError
                ? `${styles.appInput} ${styles.hasDanger}`
                : isSuccess
                ? `${styles.appInput} ${styles.hasSuccess}`
                : `${styles.appInput}`
            }
          >
            <textarea {...register} className={`${styles['text-area-input']}`} placeholder={placeholder}></textarea>
          </div>
          {rest.id && <span className={`${styles.errorMessage}`}>{errors[rest.id]?.message}</span>}
        </div>
      </Col>
    </Row>
  );
};

export default TextArea;
