import AppButton from 'app/shared/ui-elements/app-button/app-button';
import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './app-dnd-mini-card.module.scss';

interface IProps {
  text?: string;
  firstIcon?: ReactNode;
  lastIcon?: ReactNode;
  buttonText?: string;
  onClickButton?: () => void;
}

const AppDNDMiniCard = (props: IProps) => {
  const getButton = () => {
    return (
      <AppButton
        text={props.buttonText}
        btnType="onlyText"
        onClick={() => {
          props.onClickButton && props.onClickButton();
        }}
      />
    );
  };
  return (
    <Row>
      <Col>
        <div className={`${styles.miniCardView} px-3 py-2`}>
          <Row className="align-items-center">
            {props.firstIcon && <Col className="col-auto">{props.firstIcon}</Col>}
            {props.text && <Col className={`${styles.headingText}`}>{props.text}</Col>}
            {props.buttonText && <Col className="d-none d-md-block col-2">{getButton()}</Col>}
            {props.lastIcon && <Col className="col-auto pe-3">{props.lastIcon}</Col>}
          </Row>
          {props.buttonText && (
            <Row>
              <Col className="d-md-none col-6">{getButton()}</Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default AppDNDMiniCard;
