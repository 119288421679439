export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+~`\-={}[\]\\|:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+~`\-={}[\]\\|:;"'<>,.?/]{8,}$/;

export const noSpecialCharsNoWhitespaceStartEndRegex = /^(?!\s)(?!.*\s{2})[a-zA-Z0-9\s]*\w$/;

export const noSpecialCharsNoNumbersNoSpacesRegex = /^(?!\s)[a-zA-Z'-]+(?<!\s)$/;

export const noOnlyNumbersAndNoSpecialCharsRegex =
  /^(?!.*[ ]{2,})(?!.*[<>])(?!.*[\t])(?!.*[\r\n])(?=.*[^\d])[^\s][\w.,\/\\\-()#&'@_\[\] \d]{0,149}$/;

export const bioRegex = /^(?=.*[a-zA-Z])[\s.,\"\"()'a-zA-Z0-9]+$/;

export const mobileRegex = /^(70|71|72|73|74|75|76|77|78)\d{7}$/;

export const contactNumberRegex = /^(?:\+94|0)(70|71|72|73|74|75|76|77|78)\d{7}$/;

export const noLeadingWhiteSpaceRegex = /^(?!\s)(\S+(\s\S+)*\s?)?$/;

export const noWhitespaceStartEndRegex = /^(?!\s)(.*\S)$/;

export const phoneNumberWithCountryCodeRegex = /^(\+\d{1,2})(\d+)$/;

export const onlyNumbersRegex = /^[0-9]+$/;

export const couponCodeRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{5,}$/;

export const courseDurationRegex = /^(?![\W\d]+$).+/;

export const numbersWithUpToTwoDecimalPlace = /^(\d+(\.\d{1,2})?)?$/;
