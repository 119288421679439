import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './app-tab.module.scss';

export interface ITab {
  tabName: string;
  id: string;
  path?: string;
}

interface IProps {
  tabs: ITab[];
  onChange: (id: string) => void;
  selectedTabId: string;
}

const AppTab: FC<IProps> = props => {
  const handleClick = (tabId: string) => {
    props.onChange(tabId);
  };

  return (
    <>
      <Row className="cursor-pointer">
        <Col>
          <div className={`${styles.tabContainer}`}>
            {props.tabs.map((tab: ITab) => {
              return (
                <div
                  key={tab.id}
                  onClick={() => handleClick(tab.id)}
                  className={`${styles.navLink} ${tab.id === props.selectedTabId ? styles.navLinkActive : ''}`}
                >
                  {tab.tabName}
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AppTab;
