import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import styles from '../course-card/course-card.module.scss';
import stylesLoader from './course-loader-card.module.scss';

const CourseCardLoader = () => {
  return (
    <Card className={`${styles.courseCard} ${stylesLoader.courseCardLoader}`}>
      <Card.Img variant="top" className={`${styles.cardImg} ${stylesLoader.cardLoaderImg}`} />
      <Card.Body>
        <Placeholder as={Card.Text} animation="glow" className={`mt-3 mb-3`}>
          <Placeholder xs={3} className={`${stylesLoader.cardBorderRadius}`} />
          <Placeholder xs={3} className={`${stylesLoader.cardBorderRadius}`} />
          <Placeholder xs={3} className={`${stylesLoader.cardBorderRadius}`} />
        </Placeholder>
        <Placeholder as={Card.Text} className={`mt-3 mb-3`} animation="glow">
          <Placeholder xs={6} className={`${stylesLoader.cardBorderRadius}`} />
        </Placeholder>
        <Card.Title className={`mt-3 mb-5`}>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={12} className={`${stylesLoader.cardBorderRadius}`} />
          </Placeholder>
        </Card.Title>
        <Placeholder as={Card.Text} animation="glow" className="d-flex justify-content-between">
          <Placeholder xs={4} className={`${stylesLoader.cardBorderRadius}`} />
          <Placeholder xs={4} className={`${stylesLoader.cardBorderRadius}`} />
        </Placeholder>
      </Card.Body>
    </Card>
  );
};

export default CourseCardLoader;
