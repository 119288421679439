// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TJrd0BxRccI3s0z17Z0O {
  border-radius: 12px;
  background: var(--card-secondary-color);
}
.TJrd0BxRccI3s0z17Z0O .asQMEIQ_Wj160dYXfeyi {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.TJrd0BxRccI3s0z17Z0O .JVLhHI7EwnqePd7Whei3 {
  color: var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/expandable-field/expandable-field.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,uCAAA;AACF;AACE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAEE;EACE,yBAAA;AAAJ","sourcesContent":[".customCard {\n  border-radius: 12px;\n  background: var(--card-secondary-color);\n\n  .labelStyle {\n    color: var(--text-secondary-color);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n\n  .asteriskStyles {\n    color: var(--error-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customCard": `TJrd0BxRccI3s0z17Z0O`,
	"labelStyle": `asQMEIQ_Wj160dYXfeyi`,
	"asteriskStyles": `JVLhHI7EwnqePd7Whei3`
};
export default ___CSS_LOADER_EXPORT___;
