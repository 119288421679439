// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ci1FoaSsLVjv431sUJAC .GJ8xeo6IaupnEbCt7rx8 {
  color: var(--text-primary-color);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ci1FoaSsLVjv431sUJAC .qL1ZwWIXlXaxJEp7GduA {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--text-secondary-color);
}
.ci1FoaSsLVjv431sUJAC .gfOqVYPf_OTYDYc2YmhE {
  color: var(--accent-color);
  text-decoration-line: underline;
}
.ci1FoaSsLVjv431sUJAC .g1b3JQ0eJjfToiIyHnWb {
  color: var(--accent-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ci1FoaSsLVjv431sUJAC .whCaVxMkDPPgmTaofYr3 {
  color: var(--text-primary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/pages/sign-up/sign-up.module.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kCAAA;AADJ;AAGI;EACE,0BAAA;EACA,+BAAA;AADN;AAKE;EACE,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAHJ;AAME;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAJJ","sourcesContent":[".formSection {\n  .signUpTitle {\n    color: var(--text-primary-color);\n    font-size: 30px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n  }\n\n  .termsTextStyles {\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    color: var(--text-secondary-color);\n\n    &--one {\n      color: var(--accent-color);\n      text-decoration-line: underline;\n    }\n  }\n\n  .signUpTextStyleOne {\n    color: var(--accent-color);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n\n  .signUpTextStyleTwo {\n    color: var(--text-primary-color);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSection": `ci1FoaSsLVjv431sUJAC`,
	"signUpTitle": `GJ8xeo6IaupnEbCt7rx8`,
	"termsTextStyles": `qL1ZwWIXlXaxJEp7GduA`,
	"termsTextStyles--one": `gfOqVYPf_OTYDYc2YmhE`,
	"signUpTextStyleOne": `g1b3JQ0eJjfToiIyHnWb`,
	"signUpTextStyleTwo": `whCaVxMkDPPgmTaofYr3`
};
export default ___CSS_LOADER_EXPORT___;
