// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CkzKzDyUjCOcD37Ej8jX {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 400;
}

.m4d5lYtfWeiFGVjbdRUx {
  color: var(--error-secondary-color);
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
}

.Xu53iHVoB0fFYSRpNzK7 {
  font-size: 12px;
  color: var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-input/app-input.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,mCAAA;AACF;;AAEA,gCAAA;AACA;;EAEE,wBAAA;EACA,SAAA;AACF;;AAEA,YAAA;AACA;EACE,0BAAA;EACA,6BAAA;UAAA,qBAAA;AACF;;AAEA;EACE,eAAA;EACA,yBAAA;AACF","sourcesContent":[".label {\n  color: var(--text-secondary-color);\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.required {\n  color: var(--error-secondary-color);\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput[type='number']::-webkit-outer-spin-button,\ninput[type='number']::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type='number'] {\n  -moz-appearance: textfield;\n  appearance: textfield;\n}\n\n.errorMessage {\n  font-size: 12px;\n  color: var(--error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `CkzKzDyUjCOcD37Ej8jX`,
	"required": `m4d5lYtfWeiFGVjbdRUx`,
	"errorMessage": `Xu53iHVoB0fFYSRpNzK7`
};
export default ___CSS_LOADER_EXPORT___;
