import React, { FC } from 'react';
import { BsX } from 'react-icons/bs';
interface IProps {
  closeHandler: () => void;
  isOpen: boolean;
  modelTitle: string;
  children?: React.ReactNode;
}

const ModelLayout: FC<IProps> = (props: IProps) => {
  return (
    <div className={`model-layout ${props.isOpen && 'model-layout--open'}`}>
      <div className={`model-layout__content p-4 ${props.isOpen && 'model-layout__content--open'}`}>
        <header className="model-layout__header d-flex ">
          <div className="model-layout__title">{props.modelTitle}</div>
          <div className="model-layout__close ms-auto">
            <BsX size={30} onClick={props.closeHandler} />
          </div>
        </header>
        <div className="model-layout__body pt-4">{props.children}</div>
      </div>
    </div>
  );
};

export default ModelLayout;
