import AppMaterialUploader, { IUploadMaterialProps } from 'app/shared/ui-elements/app-material-uploader/app-material-uploader';
import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import AppDNDCard from '../app-dnd-card/app-dnd-card';

interface Props extends IUploadMaterialProps {
  label: string;
  onDeleteContent: () => void;
}

const AppDNDMaterialUploader: FC<Props> = props => {
  return (
    <Row>
      <Col>
        <AppDNDCard label={props.label} onClickRemoveIcon={props.onDeleteContent}>
          <Row>
            <Col>
              <AppMaterialUploader {...props} />
            </Col>
          </Row>
        </AppDNDCard>
      </Col>
    </Row>
  );
};

export default AppDNDMaterialUploader;
