import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { activateAction, reset } from 'app/modules/account/activate/activate.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import AccountSideImageSection from 'app/shared/components/account-side-image-section/account-side-image-section';
import { login } from 'app/shared/reducers/authentication';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppCheckbox from 'app/shared/ui-elements/app-checkbox/app-checkbox';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import { emailRegex } from 'app/shared/util/regex';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './sign-in.module.scss';

type SignInFormValues = {
  email: string;
  password: string;
};

const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { isAuthenticated, account, loading } = useAppSelector(state => ({
    isAuthenticated: state.authentication.isAuthenticated,
    account: state.authentication.account,
    loading: state.authentication.loading,
  }));
  const isAuthorizedAdmin = hasAnyAuthority(account.authorities, [AUTHORITIES.ADMIN]);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const key = searchParams.get('key');
    if (key !== null) {
      dispatch(activateAction(key));
    }

    return () => {
      dispatch(reset());
    };
  }, []);

  const { from } = location.state || {
    from: { pathname: `${isAuthorizedAdmin ? '/admin/courses' : '/student/courses'}`, search: location.search },
  };

  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }

  const onSubmit: SubmitHandler<SignInFormValues> = (data: SignInFormValues) => {
    dispatch(login(data.email, data.password, rememberMe));
  };

  return (
    <>
      <Container className="py-lg-5 py-md-3 h-100">
        <Row className="align-items-center justify-content-evenly h-100">
          <Col className="col-12 col-lg-4">
            <AccountSideImageSection />
          </Col>

          <Col className={`col-12 col-lg-5 ${styles.formSection}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col className={`${styles.signInTitle} text-center pb-4`}>Sign In</Col>
              </Row>
              <Row>
                <Col className="mb-4">
                  <AppInput
                    id="email"
                    placeholder={'email@mail.com'}
                    name="email"
                    label="Email"
                    register={register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col className="mb-4">
                  <AppInput
                    id="password"
                    name="password"
                    label="Password"
                    placeholder={'Password'}
                    type={showPassword ? 'text' : 'password'}
                    icon={!showPassword ? BsEyeSlash : BsEye}
                    onIconClick={() => setShowPassword(!showPassword)}
                    register={register('password', {
                      required: 'Password is required',
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="mb-4">
                  <AppCheckbox
                    label={<span className={styles.signInTextStyleTwo}>Stay signed in</span>}
                    checked={rememberMe}
                    onChange={checked => setRememberMe(checked)}
                  />
                </Col>

                <Col className={`text-end cursor-pointer`}>
                  <span className={styles.signInTextStyleOne} onClick={() => navigate('/reset/request')}>
                    Forgot password?
                  </span>
                </Col>
              </Row>

              <Row>
                <Col className="mb-4">
                  <AppButton type="submit" text={'Log In'} loading={loading} />
                </Col>
              </Row>

              <Row className="justify-content-center text-center">
                <Col className="col-auto">
                  <span className={`${styles.signInTextStyleTwo}`}>Don&apos;t have an account?</span>
                  <span className={`${styles.signInTextStyleOne} cursor-pointer ms-1`} onClick={() => navigate('/register')}>
                    Sign Up
                  </span>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignIn;
