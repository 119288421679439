import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './notice-view.module.scss';

interface IProps {
  title: string;
  description: string;
}

const NoticeView = (props: IProps) => {
  return (
    <Row>
      <Col className={`${styles.noticeViewBox}`}>
        <div className={`${styles.noticeViewBoxBorder}`}>
          <Row className="justify-content-center">
            <Col className="col-12 col-lg-6 col-md-9 px-4 text-center">
              <Row>
                <Col className={`${styles.noticeViewBoxTitle}`}>{props.title}</Col>
              </Row>
              <Row>
                <Col className={`${styles.noticeViewBoxDescription} pt-2`}>{props.description}</Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default NoticeView;
