// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hx_i1ZcPPokITXCz9HYk {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--text-secondary-color);
}

.RlYnBmEANJW9L8fV4QLK {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #ffffff;
}

.fIZlNB94ss_p7sxKn1Gs {
  min-width: 120px;
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/profiel-views/nav-profile.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kCAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;AACF","sourcesContent":[".navProfileFont {\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 16px;\n  color: var(--text-secondary-color);\n}\n\n.mobileNavProfileFont {\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 16px;\n  color: #ffffff;\n}\n\n.customDropdownList {\n  min-width: 120px;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navProfileFont": `hx_i1ZcPPokITXCz9HYk`,
	"mobileNavProfileFont": `RlYnBmEANJW9L8fV4QLK`,
	"customDropdownList": `fIZlNB94ss_p7sxKn1Gs`
};
export default ___CSS_LOADER_EXPORT___;
