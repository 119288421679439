// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jOgI2V14dflu3Zf6BtIQ {
  color: var(--text-secondary-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid var(--line-color);
}
.jOgI2V14dflu3Zf6BtIQ:hover {
  color: #ffffff;
  background: var(--accent-color);
  border-bottom: 1px solid var(--accent-color) !important;
}

.nP_IX2PYiUUVm17L238X {
  background: var(--accent-color);
  color: #ffffff;
  border-bottom: 1px solid var(--accent-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/nav-bar/app-secondary-nav-bar/app-menu-content/app-menu-content.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,0CAAA;AACF;AACE;EACE,cAAA;EACA,+BAAA;EACA,uDAAA;AACJ;;AAGA;EACE,+BAAA;EACA,cAAA;EACA,uDAAA;AAAF","sourcesContent":[".menuItem {\n  color: var(--text-secondary-color);\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  border-bottom: 1px solid var(--line-color);\n\n  &:hover {\n    color: #ffffff;\n    background: var(--accent-color);\n    border-bottom: 1px solid var(--accent-color) !important;\n  }\n}\n\n.activeItem {\n  background: var(--accent-color);\n  color: #ffffff;\n  border-bottom: 1px solid var(--accent-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": `jOgI2V14dflu3Zf6BtIQ`,
	"activeItem": `nP_IX2PYiUUVm17L238X`
};
export default ___CSS_LOADER_EXPORT___;
