import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getAllCoursesForCouponCreation, reset as resetCourses } from 'app/entities/course/course.reducer';
import { ICoupon } from 'app/shared/model/coupon.model';
import {
  ECouponCourseType,
  ECouponCourseTypeStrings,
  ECouponDiscountType,
  ECouponDiscountTypeStrings,
  ECouponType,
  ECouponTypeStrings,
} from 'app/shared/model/enumerations/coupon-mode';
import { ECourseMode } from 'app/shared/model/enumerations/course-mode';
import { IOption } from 'app/shared/model/option.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppDatePicker from 'app/shared/ui-elements/app-date-picker/app-date-picker';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import AppSelect from 'app/shared/ui-elements/app-select/app-select';
import { checkValidDateRange, displayDefaultDateFormat } from 'app/shared/util/date-utils';
import { couponCodeRegex, numbersWithUpToTwoDecimalPlace } from 'app/shared/util/regex';
import { showErrorToast } from 'app/shared/util/toast';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { createCoupon, getCouponEntity, updateCoupon } from '../coupons.reducer';
import styles from './coupons-manager.module.scss';

interface ICourse {
  couponAllowed: boolean;
  endDate: string;
  id: number;
  mode: ECourseMode;
  name: string;
  paid: false;
  price: number;
  startDate: string;
}

const courseTypes: IOption[] = [
  {
    value: ECouponType.COMMON,
    label: 'Common',
  },
];

const discountType: IOption[] = [
  {
    value: ECouponDiscountType.PERCENTAGE,
    label: 'Percentage',
  },
  {
    value: ECouponDiscountType.AMOUNT,
    label: 'Rupee(LKR)',
  },
];

interface IFormInputs {
  couponType: IOption;
  couponCode: string;
  discountType: IOption;
  discountAmount: string;
  usageLimit: string;
}

const CouponsManager = () => {
  const { couponId } = useParams<'couponId'>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    courseList,
    couponEntity,
    actionStatus,
    loading,
  }: { courseList: Array<ICourse>; couponEntity: ICoupon; actionStatus: boolean; loading: boolean } = useAppSelector(
    (state: IRootState) => ({
      courseList: state.course.couponsEligibleCourses,
      couponEntity: state.coupons.couponEntity,
      actionStatus: state.coupons.actionStatus,
      loading: state.coupons.loading,
    })
  );

  const [courses, setCourses] = useState<IOption[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<ICourse>();
  const [boundedCourseId, setBoundedCourseId] = useState<any>('');
  const [isCourseSelected, setIsCourseSelected] = useState<boolean>(true);
  const [allSelected, setAllSelected] = useState(false);
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const [expireDate, setExpireDate] = useState<Date>(new Date());
  const [updatedCourse, setUpdatedCourse] = useState<IOption>();
  const [isReset, setIsReset] = useState<boolean>(false);
  const {
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
  } = useForm<IFormInputs>();
  const notSelectedError = { message: 'Please select a course' };

  useEffect(() => {
    if (couponId) dispatch(getCouponEntity({ coupon: couponId }));
    dispatch(getAllCoursesForCouponCreation({ sort: 'ASC' }));
    return () => {
      resetFormValues();
      dispatch(resetCourses());
    };
  }, []);

  useEffect(() => {
    if (couponEntity && couponId) {
      const CouponTypeVal = courseTypes.find(
        option => option.value === (couponEntity.couponType === ECouponTypeStrings.COMMON ? ECouponType.COMMON : ECouponType.UNIQUE)
      );
      const discountTypeVal = discountType.find(
        option =>
          option.value ===
          (couponEntity.discountType === ECouponDiscountTypeStrings.PERCENTAGE
            ? ECouponDiscountType.PERCENTAGE
            : ECouponDiscountType.AMOUNT)
      );
      setValue('couponType', CouponTypeVal);
      setValue('couponCode', couponEntity.couponCode);
      if (couponEntity.couponCourseType === ECouponCourseTypeStrings.ALL) {
        setUpdatedCourse({ value: null, label: 'All' });
        setAllSelected(true);
      } else setUpdatedCourse(courses.find(option => option.label === couponEntity.courseName));
      setActiveDate(new Date(couponEntity.activeDate));
      setExpireDate(new Date(couponEntity.expireDate));
      setValue('discountType', discountTypeVal);
      setValue('discountAmount', couponEntity.discountAmount.toString());
      setValue('usageLimit', couponEntity.usageLimit.toString());
    }
  }, [couponEntity, courses]);

  useEffect(() => {
    if (!isReset) {
      setBoundedCourseId(updatedCourse?.value);
      setSelectedCourse(courseList.find(obj => obj.id.toString() === updatedCourse?.value));
    }
  }, [updatedCourse]);

  const extractCourses = () => {
    if (courseList) {
      // const couponAllowedCourseList = courseList.filter((course: any) => course.couponAllowed);
      const couponAllowedCourseList = courseList;
      const couponAllowedCourseListDropDown = couponAllowedCourseList.map(course => ({ value: course.id.toString(), label: course.name }));
      setCourses(couponAllowedCourseListDropDown);
    }
  };

  useEffect(() => {
    extractCourses();
    setCourses(prev => [{ value: null, label: 'All' }, ...prev]);
  }, [courseList]);

  useEffect(() => {
    if (actionStatus) {
      if (couponId) navigate(`/admin/settings/coupons/${couponId}/view`);
      else navigate('/admin/settings/coupons');
    }
  }, [actionStatus]);

  const handleOnChange = (option: IOption) => {
    setIsCourseSelected(true);
    if (couponId) {
      setAllSelected(false);
      setUpdatedCourse(courses.find(obj => obj.value === option.value));
      if (option.value === null && option.label === 'All') setAllSelected(true);
    } else {
      if (option.value === null && option.label === 'All') {
        setBoundedCourseId(null);
        setAllSelected(true);
      } else {
        setAllSelected(false);
        setBoundedCourseId(option.value);
        setSelectedCourse(courseList.find(obj => obj.id.toString() === option.value));
      }
    }
  };

  const resetFormValues = () => {
    setIsReset(true);
    setBoundedCourseId('');
    setActiveDate(new Date());
    setExpireDate(new Date());
    setUpdatedCourse({
      value: '',
      label: '',
    });
    reset();
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    if (!couponId && boundedCourseId === '') {
      setIsCourseSelected(false);
      return;
    }
    if (!checkValidDateRange(activeDate, expireDate)) {
      showErrorToast('Please set valid coupon active date and expire date', 'dateValidationError');
      return;
    }
    const newCoupon: ICoupon = {
      couponType: data.couponType.value,
      couponCode: data.couponCode,
      couponName: '',
      couponCourseType: boundedCourseId === null ? ECouponCourseType.ALL : ECouponCourseType.SPECIFIC,
      boundedCourseId: boundedCourseId ?? null,
      discountType: data.discountType.value,
      discountAmount: parseFloat(data.discountAmount),
      usageLimit: parseInt(data.usageLimit),
      activeDate: activeDate.toISOString(),
      expireDate: expireDate.toISOString(),
    };

    if (couponId) {
      const updatedCoupon: ICoupon = {
        ...newCoupon,
        id: Number(couponId),
        active: couponEntity.active,
      };
      dispatch(updateCoupon({ id: couponId, coupon: updatedCoupon }));
    } else {
      dispatch(createCoupon(newCoupon));
    }
  };

  const watchDiscountType = watch('discountType');
  const maxDiscountValue =
    watchDiscountType?.value === ECouponDiscountType.PERCENTAGE ? 100 : !allSelected ? selectedCourse?.price : undefined;

  return (
    <>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="py-2">
              <Col>
                <AppSelect
                  label="Coupon Type"
                  id="couponType"
                  name="couponType"
                  options={courseTypes}
                  isRequired={true}
                  register={register('couponType', {
                    required: 'Please select the Coupon Type',
                  })}
                  errors={errors}
                  control={control}
                />
              </Col>
            </Row>

            <Row className="py-2">
              <Col>
                <AppInput
                  id="couponCode"
                  name="couponCode"
                  label="Coupon Code"
                  placeholder="Coupon Code"
                  isRequired={true}
                  register={register('couponCode', {
                    required: 'Coupon code is required',
                    minLength: {
                      value: 5,
                      message: 'This field must contain at least 5 characters',
                    },
                    maxLength: {
                      value: 10,
                      message: 'You have exceeded the maximum number of 10 characters in this field',
                    },
                    pattern: {
                      value: couponCodeRegex,
                      message: 'Coupon code must consist of at least one letter and one digit. Special characters are not allowed',
                    },
                  })}
                  errors={errors}
                />
              </Col>
            </Row>

            <Row className="py-2">
              <Col>
                <AppSelect
                  label="Course"
                  id="boundedCourseId"
                  name="boundedCourseId"
                  options={courses}
                  selectedValue={couponId && updatedCourse}
                  isRequired={true}
                  onChangeOption={handleOnChange}
                  errors={!isCourseSelected && notSelectedError}
                />
              </Col>
            </Row>

            <Row className="py-2">
              <Col className="col-12 col-sm-6">
                <AppDatePicker
                  label="Active Date"
                  selectedDate={activeDate}
                  onChange={date => {
                    setActiveDate(date);
                  }}
                  isInput={true}
                  timePicker={true}
                  timePeriod={true}
                />
                {!allSelected && selectedCourse?.mode !== ECourseMode.ON_DEMAND && selectedCourse?.startDate && (
                  <span>Course start: {displayDefaultDateFormat(selectedCourse?.startDate)}</span>
                )}
              </Col>
              <Col className="col-12 col-sm-6 pt-3 pt-sm-0">
                <AppDatePicker
                  label="Expire Date"
                  selectedDate={expireDate}
                  onChange={date => {
                    setExpireDate(date);
                  }}
                  isInput={true}
                  timePicker={true}
                  timePeriod={true}
                />
                {!allSelected && selectedCourse?.mode !== ECourseMode.ON_DEMAND && selectedCourse?.endDate && (
                  <span>Course end: {displayDefaultDateFormat(selectedCourse?.endDate)}</span>
                )}
              </Col>
            </Row>

            <Row className="py-2">
              <Col className="col-12 col-sm-6">
                <AppSelect
                  label="Discount Type"
                  id="discountType"
                  name="discountType"
                  options={allSelected ? discountType.slice(0, -1) : discountType}
                  isRequired={true}
                  register={register('discountType', {
                    required: 'Please select the discount type',
                  })}
                  errors={errors}
                  control={control}
                />
              </Col>
              <Col className="col-12 col-sm-6 pt-3 pt-sm-0">
                <AppInput
                  id="discountAmount"
                  name="discountAmount"
                  label="Discount Amount"
                  placeholder="Discount Amount"
                  type="number"
                  step="any"
                  isRequired={true}
                  register={register('discountAmount', {
                    required: 'Discount amount is required',
                    validate(value) {
                      if (parseFloat(value) > maxDiscountValue) {
                        return `Maximum value for discount is ${maxDiscountValue}`;
                      }
                      if (parseFloat(value) === 0) return 'Zero is not allowed here';
                      return true;
                    },
                    minLength: {
                      value: 1,
                      message: 'This field must contain at least 1 characters',
                    },
                    pattern: {
                      value: numbersWithUpToTwoDecimalPlace,
                      message: 'Please enter a valid number with up to two decimal places',
                    },
                  })}
                  errors={errors}
                />
                {!allSelected && selectedCourse?.price && (
                  <p className={styles.txt2}>
                    Course Price <span className={styles.txt1}> LKR {selectedCourse?.price.toLocaleString()}</span>
                  </p>
                )}
              </Col>
            </Row>

            <Row className="py-2">
              <Col>
                <AppInput
                  id="usageLimit"
                  name="usageLimit"
                  label="Number of Coupon Codes"
                  placeholder="Number of Coupon Codes"
                  isRequired={true}
                  type={'number'}
                  onKeyDown={event => {
                    if (!['Backspace', 'Delete'].includes(event.key) && !/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  register={register('usageLimit', {
                    required: 'Number of coupon codes is required',
                    minLength: {
                      value: 1,
                      message: 'This field must contain at least 1 characters',
                    },
                    maxLength: {
                      value: 4,
                      message: 'You have exceeded the maximum number of 4 characters in this field',
                    },
                  })}
                  errors={errors}
                />
              </Col>
            </Row>

            <Row className="mt-sm-2 mt-md-4 mb-5">
              <Col className="col-6 col-lg-3 mt-3 mt-sm-0">
                <AppButton
                  text="Save"
                  btnSize="sm"
                  type="submit"
                  loading={loading}
                  onClick={() => {
                    if (boundedCourseId === undefined) setIsCourseSelected(false);
                    handleSubmit(onSubmit);
                  }}
                />
              </Col>
              <Col className="col-auto mt-3 mt-sm-0">
                <AppButton
                  text="Cancel"
                  btnSize="sm"
                  btnType="onlyText"
                  type="button"
                  onClick={() => {
                    navigate('/admin/settings/coupons');
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default CouponsManager;
