import { IResourceLink } from 'app/shared/util/dnd-utils';
import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaLink } from 'react-icons/fa';
import styles from './app-external-resource-presenter.module.scss';

interface Props {
  resourceLinks: IResourceLink[];
}

const AppExternalResourcePresenter: FC<Props> = props => {
  return (
    <Row>
      <Col>
        <div className={`${styles.externalResourcePresenter} cursor-pointer`}>
          {props.resourceLinks.map((resource: IResourceLink, index) => {
            return (
              <Row key={index}>
                <Col className="d-flex align-items-center mt-2">
                  <FaLink size={15} color="var(--icon-color)" className="me-2" />
                  <a
                    href={resource.url.includes('http') ? resource.url : `http://${resource.url}`}
                    className={`${styles.externalResourcePresenter__link}`}
                    target="_blank"
                    rel="noopener"
                  >
                    {resource.referenceName}
                  </a>
                </Col>
              </Row>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default AppExternalResourcePresenter;
