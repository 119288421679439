import React, { ReactNode } from 'react';
import styles from './app-checkbox.module.scss';

interface IProps {
  label: string | ReactNode;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const AppCheckbox = (props: IProps) => {
  return (
    <label className={`${styles.selectionBoxContainer}`}>
      <span className={`ps-2 ${styles.label}`}>{props.label}</span>
      <input
        type="checkbox"
        defaultChecked={props.checked}
        onChange={e => props.onChange && props.onChange(e.target.checked)}
        disabled={props.disabled}
      />
      <span className={`${styles.checkmark}`}></span>
    </label>
  );
};

export default AppCheckbox;
