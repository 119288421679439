import React, { ReactNode } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styles from './app-tool-tip.module.scss';

interface IProps {
  children: ReactNode;
  tooltipId: string;
  place?: 'bottom' | 'top' | 'left' | 'right';
  content: string;
}

const AppTooltip = (props: IProps) => {
  const { children, tooltipId, place = 'bottom', content } = props;
  return (
    <>
      <div data-tooltip-id={tooltipId}>{children}</div>
      <ReactTooltip id={tooltipId} place={place} variant="success" content={content} className={`${styles.appToolTip}`} />
    </>
  );
};

export default AppTooltip;
