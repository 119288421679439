import { EUserCourseStatus } from 'app/shared/model/enumerations/user-course-status.model';

export interface ICourseEnrollment {
  id?: number;
  courseId?: number;
  userId?: number;
  userCourseStatus?: keyof typeof EUserCourseStatus;
  userCourseCheckpoint?: number | null;
  userRating?: number | null;
  userReview?: string | null;
  userCompletedDate?: string | null;
  courseStartDate?: string | null;
}

export const defaultValue: Readonly<ICourseEnrollment> = {};
