export interface IChapterManagerChapter {
  chapterId?: number;
  chapterName?: string;
  completed?: false;
  contents?: null;
}

export interface ICourseChapterManager {
  lessonOrUnitName?: string;
  lessonOrUnitNameDescription?: string;
  chapters?: IChapterManagerChapter[];
}

export interface IGetChapterOfCoursePayload {
  courseId: number;
  lessonId: number;
  unitId?: number;
  id?: number;
}

export interface IArrangeChapterOfCoursePayload {
  courseId: number;
  lessonId: number;
  unitId?: number;
  chapterIdsInOrder: number[];
}

export const defaultValue: Readonly<ICourseChapterManager> = {};
