import { ChapterVideoType } from 'app/shared/model/enumerations/chapter-video-type.model';

export interface IChapterVideo {
  id?: number;
  chapterId?: number;
  url?: string;
  type?: keyof typeof ChapterVideoType;
  chapterItemSequenceNumber?: number | null;
}

export const defaultValue: Readonly<IChapterVideo> = {};
