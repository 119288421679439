// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._5HdOjn2JKZtsPwZix9ew .R9eKqAJESB9yUAN2K9g7 {
  font-size: 15px;
  color: var(--text-primary-color);
  font-weight: 500;
}
._5HdOjn2JKZtsPwZix9ew ._xNjQinqHobs1tVdqsZN {
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-uploaded-material-presenter/app-uploaded-material-presenter.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,gCAAA;EACA,gBAAA;AAAJ;AAGE;EACE,eAAA;AADJ","sourcesContent":[".appUploadedMaterialPresenter {\n  .header {\n    font-size: 15px;\n    color: var(--text-primary-color);\n    font-weight: 500;\n  }\n\n  .fileName {\n    font-size: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appUploadedMaterialPresenter": `_5HdOjn2JKZtsPwZix9ew`,
	"header": `R9eKqAJESB9yUAN2K9g7`,
	"fileName": `_xNjQinqHobs1tVdqsZN`
};
export default ___CSS_LOADER_EXPORT___;
