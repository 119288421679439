// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WXDnK1iz7nOr5XnY1wMH {
  display: flex;
  flex-direction: row;
}
.WXDnK1iz7nOr5XnY1wMH .Ttmmoi0yBDIHlJZjPJ9Q {
  border-radius: 12px 12px 0 0 !important;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  background-color: var(--card-color);
  color: var(--text-secondary-color);
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.WXDnK1iz7nOr5XnY1wMH .QqwD7bO1IQYRSKoUdJHp {
  background-color: var(--accent-color);
  color: #ffffff;
  border-color: var(--accent-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-tabs/app-tab.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,uCAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,mCAAA;EACA,kCAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAEE;EACE,qCAAA;EACA,cAAA;EACA,iCAAA;AAAJ","sourcesContent":[".tabContainer {\n  display: flex;\n  flex-direction: row;\n\n  .navLink {\n    border-radius: 12px 12px 0 0 !important;\n    height: 40px;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 19.2px;\n    background-color: var(--card-color);\n    color: var(--text-secondary-color);\n    display: flex;\n    flex-grow: 1;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .navLinkActive {\n    background-color: var(--accent-color);\n    color: #ffffff;\n    border-color: var(--accent-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": `WXDnK1iz7nOr5XnY1wMH`,
	"navLink": `Ttmmoi0yBDIHlJZjPJ9Q`,
	"navLinkActive": `QqwD7bO1IQYRSKoUdJHp`
};
export default ___CSS_LOADER_EXPORT___;
