import React, { FC } from 'react';
import { IconType } from 'react-icons';
import styles from './app-pill.module.scss';

interface IProps {
  name: string;
  onClick: () => void;
  icon?: IconType;
  className?: string;
}

const AppPill: FC<IProps> = props => {
  return (
    <button type="button" className={`${styles.appPill} ${props.className} btn d-flex align-items-center`} onClick={props.onClick}>
      {' '}
      {props.icon ? <props.icon className="me-1" /> : <></>}
      {props.name}{' '}
    </button>
  );
};

export default AppPill;
