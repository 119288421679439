import { EUploadFileType } from 'app/shared/model/enumerations/upload-file-type';
import axios from 'axios';

export const uploadFile = (file: File, uploadPath: EUploadFileType, fileName: string) => {
  const data = new FormData();
  data.append('file', file);
  data.append('storagePath', uploadPath);
  data.append('fileName', fileName);

  return axios.post(`/api/storage`, data).then(response => {
    return response.data as IFileUploadDTO;
  });
};
