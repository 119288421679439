import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import NoticeView from 'app/shared/components/notice-views/notice-view';
import { IUser } from 'app/shared/model/user.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import AppTooltip from 'app/shared/ui-elements/app-tooltip/AppTooltip';
import IconWithText from 'app/shared/ui-elements/icon-with-text/IconWithText';
import { parseCSV } from 'app/shared/util/parse-csv';
import { emailRegex } from 'app/shared/util/regex';
import { showErrorToast, showSuccessToast, showWarningToast } from 'app/shared/util/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BsDownload, BsFiletypeCsv, BsQuestionCircle, BsTrash3, BsXLg } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import * as xlsx from 'xlsx';
import { courseEnrollment, getAllCourseEnrolledUsers, resetUserRoster, toEnrollUsersByEmails } from '../user-roster.reducer';
import styles from './enroll-user.module.scss';

type TEnrollUserInputValue = {
  email: string;
};

const EnrollUser = () => {
  const params = useParams() as { courseId: string };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toEnrollUsersArr, courseEnrollmentError, courseEnrollmentStatus, enrolledUsers } = useAppSelector((state: IRootState) => ({
    toEnrollUsersArr: state.userRoster.toEnrollUsers,
    courseEnrollmentError: state.userRoster.courseEnrollmentError,
    courseEnrollmentStatus: state.userRoster.courseEnrollmentStatus,
    enrolledUsers: state.userRoster.enrolledUsers,
  }));

  const [addedUsers, setAddedUsers] = useState([]);
  const [uploadedEmails, setUploadedEmails] = useState<string[]>([]);
  const [csvFileName, setCsvFileName] = useState<string | null>(null);
  const [fileInputKey, setFileInputKey] = useState<number>(0);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    reset,
  } = useForm<TEnrollUserInputValue>({});

  useEffect(() => {
    setAddedUsers(prevAddedUsers => [...prevAddedUsers, ...toEnrollUsersArr]);
  }, [toEnrollUsersArr]);

  useEffect(() => {
    if (courseEnrollmentError) {
      showErrorToast('Course enrollment failed. Please try again.', 'courseEnrollmentError');
    }

    if (courseEnrollmentStatus === 200) {
      showSuccessToast('Course enrollment successful.', 'courseEnrollmentError');
      clearEnrollmentHandler();
      navigate(`/admin/courses/${params.courseId}/user-roster`);
    }
  }, [courseEnrollmentError, courseEnrollmentStatus]);

  useEffect(() => {
    dispatch(getAllCourseEnrolledUsers({ courseId: Number(params.courseId) }));

    return () => {
      dispatch(resetUserRoster());
    };
  }, []);

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileRemoval = () => {
    const remainingAddedUsers = addedUsers.filter(user => !uploadedEmails.includes(user.email));
    setAddedUsers(remainingAddedUsers);
    setUploadedEmails([]);
    setCsvFileName(null);
    setFileInputKey(prevKey => prevKey + 1);
  };

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = event => {
        const data = event.target.result;
        const workbook = xlsx.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const excelData = xlsx.utils.sheet_to_csv(workbook.Sheets[sheetName]);
        const tempEmailArray = parseCSV(excelData);
        let emailArray = tempEmailArray.filter((email, index) => tempEmailArray.indexOf(email) === index);
        if (addedUsers.length > 0) {
          emailArray = emailArray.filter(email => !addedUsers.some((user: IUser) => user.email === email));
        }
        const nonEnrolledEmails = emailArray.filter(email => !enrolledUsers.some((user: IUser) => user.email === email));
        const enrolledEmailCount = emailArray.length - nonEnrolledEmails.length;

        if (enrolledEmailCount > 0) {
          showWarningToast(`${enrolledEmailCount} already enrolled email(s) removed from the CSV.`, 'enrollmentError');
        }

        setUploadedEmails(nonEnrolledEmails);
        dispatch(toEnrollUsersByEmails({ emails: nonEnrolledEmails, courseId: Number(params.courseId) }));
        setCsvFileName(file.name);
      };

      reader.readAsBinaryString(file);
    }
  };

  const onClickDeleteUser = (email: string) => {
    const remainingAddedUsers = addedUsers.filter(user => user.email !== email);
    const remainingUploadedUsers = remainingAddedUsers.filter(user => uploadedEmails.includes(user.email));

    if (remainingUploadedUsers.length === 0) {
      handleFileRemoval();
    }

    setAddedUsers(remainingAddedUsers);
  };

  const onSubmit: SubmitHandler<TEnrollUserInputValue> = (data: TEnrollUserInputValue) => {
    const addedMail = addedUsers.some((user: IUser) => user.email === data.email);
    const isEnrolledUser = enrolledUsers.some((user: IUser) => user.email === data.email);
    if (isEnrolledUser) {
      showErrorToast('The user already enrolled to this course.', 'courseEnrollmentError');
      reset();
    } else if (addedMail) {
      showWarningToast('This email already included in the enrollment list.', 'duplicateEmailError');
      reset();
    } else {
      dispatch(toEnrollUsersByEmails({ emails: [data.email], courseId: Number(params.courseId) }))
        .unwrap()
        .then(() => {
          reset();
        })
        .catch(() => {
          setError('email', {
            type: 'manual',
            message: 'Course enrollment failed. Please try again.',
          });
        });
    }
  };

  const onConfirmCourseEnrollmentHandler = () => {
    const addedUsersEmails = addedUsers.map(user => user.email);

    dispatch(courseEnrollment({ emails: addedUsersEmails, courseId: Number(params.courseId) }));
  };

  const clearEnrollmentHandler = () => {
    setAddedUsers([]);
    setUploadedEmails([]);
    setCsvFileName(null);
    setFileInputKey(prevKey => prevKey + 1);
  };

  const downloadTemplateHandler = () => {
    const filePath = '../../../../../content/files/excel/enrollExcelTemplate.xlsx';
    const link = document.createElement('a');
    link.href = filePath;
    link.download = 'student-enrollment-template.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Row>
        <Col>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="align-items-center">
              <Col className="col-12 col-md-4">
                <div className={styles.headerRow}>
                  <AppInput
                    id="email"
                    name="email"
                    label="Email"
                    placeholder={'mail@gmail.com'}
                    register={register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email',
                      },
                    })}
                    errors={errors}
                  />
                </div>
              </Col>
              <Col className="d-flex align-items-end col-12 col-md-2 mt-2 mt-sm-4">
                <AppButton id="add" type="submit" text={'Add to list'} />
              </Col>

              <Col className={`d-flex align-items-end col-12 col-md-2 mt-2 mt-sm-4 ${uploadedEmails.length > 0 && 'me-3'}`}>
                <input
                  type="file"
                  accept=".xlsx"
                  style={{ display: 'none' }}
                  id="xlsxInput"
                  onChange={handleFileSelection}
                  ref={fileInputRef}
                  key={fileInputKey}
                />
                {uploadedEmails.length > 0 ? (
                  <div className={`${styles.csvButton} d-flex align-items-center justify-content-center gap-2 py-2 px-1`}>
                    <div>
                      <BsFiletypeCsv size={24} color="var(--icon-color)" />
                    </div>
                    <span className={`${styles.csvButtonText}`}>{csvFileName}</span>
                    <div className="cursor-pointer" onClick={handleFileRemoval}>
                      <BsXLg color="#F05A5A" size={18} />
                    </div>
                  </div>
                ) : (
                  <AppButton text={'Upload Excel'} btnType="bordered" type="button" onClick={handleFileUpload} />
                )}

                <div className="d-flex align-items-center mb-2 mx-2 cursor-pointer">
                  <AppTooltip
                    tooltipId="someId"
                    content="You can enroll users in bulk by uploading the provided template with the relevant columns filled"
                  >
                    <BsQuestionCircle size={16} color="var(--icon-color)" />
                  </AppTooltip>
                </div>
              </Col>
              <Col className="d-flex justify-content-center col-12 col-md-auto mt-2 mt-md-4">
                <IconWithText icon={BsDownload} variant="colored" text="Excel Template" onClick={downloadTemplateHandler} />
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
      <Row>
        <Col>
          {addedUsers && addedUsers.length > 0 ? (
            <>
              <Row>
                <Col className={`${styles.tableLayout} my-4`}>
                  <Table responsive className="w-100">
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Account ID</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {addedUsers &&
                        addedUsers.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td>{user.email}</td>
                              <td>{user.name ? user.name : '-'}</td>
                              <td>{user.index ? user.index : '-'}</td>
                              <td>
                                <div className="d-flex justify-content-end cursor-pointer">
                                  <BsTrash3 size={18} color="#FF4C6C" onClick={() => onClickDeleteUser(user.email)} />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col className="col-12 col-sm-4 col-md-3 mt-2">
                  <AppButton text={'Confirm Enrollment'} onClick={onConfirmCourseEnrollmentHandler} />
                </Col>
                <Col className="col-12 col-sm-4 col-md-3 mt-2">
                  <AppButton text={'Cancel'} btnType="bordered" onClick={clearEnrollmentHandler} />
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col className="my-5">
                <NoticeView
                  title={'Course Enrollment'}
                  description={
                    'Enroll students swiftly by entering their email addresses or effortlessly uploading a excel file containing multiple email addresses.'
                  }
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default EnrollUser;
