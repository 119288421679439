// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LmAUQ6bpTOc0sycRSgLi {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.oio289E3eaLGd2_mI7E3 {
  min-height: 98px;
  flex-shrink: 0;
  border-radius: 12px;
  background: var(--card-secondary-color);
}

.H6xmRkmwptfCoWCjqD89 {
  color: var(--error-secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/image-uploader-field/image-uploader-field.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,uCAAA;AACF;;AAEA;EACE,mCAAA;AACF","sourcesContent":[".labelText {\n  color: var(--text-secondary-color);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.imageUploaderCustomCard {\n  min-height: 98px;\n  flex-shrink: 0;\n  border-radius: 12px;\n  background: var(--card-secondary-color);\n}\n\n.required {\n  color: var(--error-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelText": `LmAUQ6bpTOc0sycRSgLi`,
	"imageUploaderCustomCard": `oio289E3eaLGd2_mI7E3`,
	"required": `H6xmRkmwptfCoWCjqD89`
};
export default ___CSS_LOADER_EXPORT___;
