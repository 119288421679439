// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ch9HwS5TCYkLSfzSwNtO {
  font-size: 12px;
  color: var(--text-secondary-color);
}

.al5JPq6eH8y6NglWeVNm {
  font-size: 12px;
  color: #ffffff;
}

.t5ZRO4A9As5vkALpMzbz {
  -o-object-fit: cover;
     object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/image-with-text/UserImageWithText.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kCAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;AACF;;AAEA;EACE,oBAAA;KAAA,iBAAA;AACF","sourcesContent":[".imageWithTextFont {\n  font-size: 12px;\n  color: var(--text-secondary-color);\n}\n\n.imageWithTextFontEnrolled {\n  font-size: 12px;\n  color: #ffffff;\n}\n\n.avatar {\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageWithTextFont": `Ch9HwS5TCYkLSfzSwNtO`,
	"imageWithTextFontEnrolled": `al5JPq6eH8y6NglWeVNm`,
	"avatar": `t5ZRO4A9As5vkALpMzbz`
};
export default ___CSS_LOADER_EXPORT___;
