import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import Pagination from 'app/shared/components/pagination/pagination';
import { ICoupon } from 'app/shared/model/coupon.model';
import { ECouponDiscountTypeStrings, ECouponState } from 'app/shared/model/enumerations/coupon-mode';
import { ESortOrder } from 'app/shared/model/enumerations/sort-types.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppSearch from 'app/shared/ui-elements/app-search/app-search';
import AppSortIcon from 'app/shared/ui-elements/app-sort-icon/app-sort-icon';
import AppToggle from 'app/shared/ui-elements/app-toggle/app-toggle';
import { convertDateFromServer } from 'app/shared/util/date-utils';
import { showSuccessToast } from 'app/shared/util/toast';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resetCoupons, searchCoupons, updateCouponState } from '../coupons.reducer';
import styles from './coupons-dashboard.module.scss';

const CouponsDashBoard = () => {
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { couponsList, totalItems } = useAppSelector((rootState: IRootState) => ({
    couponsList: rootState.coupons.couponsList,
    totalItems: rootState.coupons.totalItems,
  }));

  const [searchText, setSearchText] = useState<string>('');
  const [sortedCourse, setSortedCourse] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [sortedExpire, setSortedExpire] = useState<ESortOrder>(ESortOrder.NOT_SORT);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const arrowSort = (sortType: 'course' | 'expire') => {
    setSortedCourse(prev =>
      sortType === 'course' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
    setSortedExpire(prev =>
      sortType === 'expire' ? (prev === ESortOrder.SORT_ASC ? ESortOrder.SORT_DESC : ESortOrder.SORT_ASC) : ESortOrder.NOT_SORT
    );
  };

  const sortParam = `${
    sortedCourse !== ESortOrder.NOT_SORT
      ? `courseName,${sortedCourse === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}`
      : ''
  }${
    sortedExpire !== ESortOrder.NOT_SORT
      ? `expireDate,${sortedExpire === ESortOrder.SORT_ASC ? ESortOrder.SORT_ASC : ESortOrder.SORT_DESC}`
      : ''
  }`;

  useEffect(() => {
    fetchCoupons();
    return () => {
      resetCoupons();
    };
  }, [currentPage, sortParam]);

  const fetchCoupons = () => {
    dispatch(
      searchCoupons({
        searchText,
        page: currentPage - 1,
        size: itemsPerPage,
        sort: sortParam,
      })
    );
  };

  const onSearch = () => {
    if (currentPage === 1) fetchCoupons();
    else {
      setCurrentPage(1);
    }
  };

  const handleNavigation = (event, couponId: string) => {
    const clickedCellIndex = event.target.cellIndex;
    if (clickedCellIndex >= 0 && clickedCellIndex < 6) {
      navigate(`/admin/settings/coupons/${couponId}/view`);
    }
  };

  const getStatus = (activeDate: string, expireDate: string) => {
    const currentDate = new Date();
    const activeDateTime = new Date(activeDate);
    const expireDateTime = new Date(expireDate);

    if (currentDate < activeDateTime) {
      return ECouponState.PENDING;
    } else if (currentDate >= activeDateTime && currentDate <= expireDateTime) {
      return ECouponState.ACTIVE;
    } else {
      return ECouponState.EXPIRED;
    }
  };

  const handleCouponToggle = (state, id) => {
    dispatch(updateCouponState({ id, isActive: state }))
      .unwrap()
      .then(response => {
        if (response.status === 200) {
          showSuccessToast(`${state ? 'Coupon Activated' : 'Coupon Deactivated'}`, 'CouponToast');
          fetchCoupons();
        }
      })
      .catch(error => console.error(error));
  };

  return (
    couponsList && (
      <>
        <Row>
          <Col>
            <Row className="justify-content-between">
              <Col className="col-12 col-md-4">
                <AppSearch onChange={setSearchText} onSearch={onSearch} placeholder="Search by code name" value={searchText} />
              </Col>
              <Col className="col-12 col-md-3 mt-3 mt-md-0">
                <AppButton text="Add Coupon Codes" onClick={() => navigate('/admin/settings/coupons/add')} />
              </Col>
            </Row>

            <Row>
              <Col className={`${styles.tableContainer} mt-4`}>
                <Table className="w-100">
                  <thead>
                    <tr>
                      <th>
                        <div className={`d-flex align-items-center ${styles.txt1}`}>Code Name</div>
                      </th>
                      <th>
                        <div className={`d-flex align-items-center ${styles.txt1}`}>
                          <AppSortIcon onChange={() => arrowSort('course')} sortedItem={sortedCourse} text={'Course'} />
                        </div>
                      </th>
                      <th>
                        <div className={`d-flex align-items-center ${styles.txt1}`}>Type</div>
                      </th>
                      <th>
                        <div className={`d-flex align-items-center ${styles.txt1}`}>Availability</div>
                      </th>
                      <th>
                        <div className={`d-flex align-items-center ${styles.txt1}`}>
                          <AppSortIcon onChange={() => arrowSort('expire')} sortedItem={sortedExpire} text={'Expire Date'} />
                        </div>
                      </th>
                      <th>
                        <div className={`d-flex align-items-center ${styles.txt1}`}>Discount</div>
                      </th>
                      <th>
                        <div className={`d-flex align-items-center ${styles.txt1}`}>
                          <div className="pe-4">Status</div>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {couponsList?.map((coupon: ICoupon) => (
                      <tr className="cursor-pointer" key={coupon.id} onClick={e => handleNavigation(e, coupon.id)}>
                        <td className={`${styles.txt2}`}>{coupon.couponCode}</td>
                        <td className={`${styles.txt1} ${styles.courseNameCell}`}>
                          {coupon.courseName === 'All' ? 'All Courses' : coupon.courseName}
                        </td>
                        <td className={`${styles.txt2}`}>{coupon.couponType.charAt(0) + coupon.couponType.slice(1).toLowerCase()}</td>
                        <td className={`${styles.txt2} ${styles.customWidthColumn}`}>
                          {(+coupon.usageLimit - +coupon.usageCount).toString()} / {coupon.usageLimit.toString()}
                        </td>
                        <td className={`${styles.txt2}`}>{convertDateFromServer(coupon.expireDate)}</td>
                        <td className={`${styles.txt2} ${styles.customWidthColumn}`}>
                          {coupon.discountAmount.toLocaleString()}
                          {coupon.discountType === ECouponDiscountTypeStrings.PERCENTAGE ? '%' : ' LKR'}
                        </td>
                        <td className={`${styles.txt2} ${styles.customWidthColumn}`}>
                          <div
                            className={`me-3  ${
                              getStatus(coupon.activeDate, coupon.expireDate) === ECouponState.EXPIRED ? styles.expiredState : ''
                            }`}
                          >
                            {getStatus(coupon.activeDate, coupon.expireDate) === ECouponState.ACTIVE ? (
                              <div className="d-flex justify-content-between">
                                <div className={`me-2 mt-1 ${coupon.active && 'me-3'}`}>{coupon.active ? 'Active' : 'Inactive'}</div>
                                <div className="me-3">
                                  <AppToggle initialState={coupon.active} onToggle={state => handleCouponToggle(state, coupon.id)} />
                                </div>
                              </div>
                            ) : (
                              getStatus(coupon.activeDate, coupon.expireDate)
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>

            {totalItems > itemsPerPage && (
              <Row>
                <Col className="mb-3">
                  <Pagination
                    length={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    updateCurrentPage={(currentPageNumber: number) => {
                      setCurrentPage(currentPageNumber);
                    }}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </>
    )
  );
};

export default CouponsDashBoard;
