import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ICourseEnrollment, defaultValue } from 'app/shared/model/course-enrollment.model';
import { EntityState, IQueryParams, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

const initialState: EntityState<ICourseEnrollment> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/course-enrollments';
const apiUrlStartCourse = 'api/start-course';
const apiUrlCheckAndStartCourse = 'api/check-and-start-course';

// Actions

export const getEntities = createAsyncThunk('courseEnrollment/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICourseEnrollment[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'courseEnrollment/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICourseEnrollment>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getAllEntitiesStudent = createAsyncThunk('course/fetch_all_entity_list_student', async (params: IQueryParams) => {
  const filterQueryString = Object.keys(params)
    .filter(key => params[key] !== undefined && params[key] !== null)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

  const requestUrl = `${apiUrl}?${filterQueryString}`;
  return axios.get<ICourseEnrollment[]>(requestUrl);
});

export const createEntity = createAsyncThunk(
  'courseEnrollment/create_entity',
  async (entity: ICourseEnrollment, thunkAPI) => {
    const result = await axios.post<ICourseEnrollment>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'courseEnrollment/update_entity',
  async (entity: ICourseEnrollment, thunkAPI) => {
    const result = await axios.put<ICourseEnrollment>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'courseEnrollment/partial_update_entity',
  async (entity: ICourseEnrollment, thunkAPI) => {
    const result = await axios.patch<ICourseEnrollment>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'courseEnrollment/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ICourseEnrollment>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityStartCourse = createAsyncThunk(
  'courseEnrollment/start_course',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlStartCourse}/${id}`;
    const result = await axios.post<ICourseEnrollment>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntityCheckAndStartCourse = createAsyncThunk(
  'courseEnrollment/check_and_start_course',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlCheckAndStartCourse}/${id}`;
    const result = await axios.post<ICourseEnrollment>(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const createCourseEnrollment = createAsyncThunk(
  'courseEnrollment/create_enroll',
  async (id: number) => {
    const requestUrl = `${apiUrl}/${id}/enroll`;
    const result = await axios.post(requestUrl);
    return result.data;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const CourseEnrollmentSlice = createEntitySlice({
  name: 'courseEnrollment',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getAllEntitiesStudent), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, createCourseEnrollment), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getAllEntitiesStudent), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity,
          updateEntityStartCourse,
          updateEntityCheckAndStartCourse,
          createCourseEnrollment
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = CourseEnrollmentSlice.actions;

// Reducer
export default CourseEnrollmentSlice.reducer;
