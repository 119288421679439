// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RML4IbBSoOKTVuTJNwoV {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: var(--text-secondary-color);
}

.NuNA5dGCEIujvUAspX0d {
  color: var(--error-color);
}

.aKJQ83YADpu5NVzM4eer {
  color: var(--error-color);
}

.PM5On1Q_Tnrn6Lwwfa1Q {
  border: 1px solid var(--error-color);
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/tinymce-editor/TinyMceEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kCAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,oCAAA;EACA,mBAAA;AACF","sourcesContent":[".tinyMceLabel {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 17px;\n  color: var(--text-secondary-color);\n}\n\n.asteriskStyles {\n  color: var(--error-color);\n}\n\n.errorMessage {\n  color: var(--error-color);\n}\n\n.errorContainer {\n  border: 1px solid var(--error-color);\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tinyMceLabel": `RML4IbBSoOKTVuTJNwoV`,
	"asteriskStyles": `NuNA5dGCEIujvUAspX0d`,
	"errorMessage": `aKJQ83YADpu5NVzM4eer`,
	"errorContainer": `PM5On1Q_Tnrn6Lwwfa1Q`
};
export default ___CSS_LOADER_EXPORT___;
