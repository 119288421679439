// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J2LgLuKI3TmQZi0zUTfA {
  width: 94px;
  background-color: var(--card-color);
}

.FoTIWuBCU197Gx4__ZUt {
  width: 58px;
  height: 55px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/nav-bar/app-primary-nav-bar/app-primary-nav-bar.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mCAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".primaryNavBar {\n  width: 94px;\n  background-color: var(--card-color);\n}\n\n.logoImg {\n  width: 58px;\n  height: 55px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryNavBar": `J2LgLuKI3TmQZi0zUTfA`,
	"logoImg": `FoTIWuBCU197Gx4__ZUt`
};
export default ___CSS_LOADER_EXPORT___;
