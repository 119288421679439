import { Editor } from '@tinymce/tinymce-react';
import { IRootState, useAppSelector } from 'app/config/store';
import { ETheme } from 'app/shared/model/enumerations/theme.model';
import React, { useEffect, useRef, useState } from 'react';
import styles from './TinyMceEditorContent.module.scss';

interface IProps {
  onEditorChange: (content: string) => void;
  content: string;
  label?: string;
  height?: number;
  required?: boolean;
  errorMessage?: string;
  index?: string;
}

const TinyMceEditorContent = (props: IProps) => {
  const { onEditorChange, content, height = 200, label, required = false, errorMessage, index } = props;
  const editorRef = useRef(null);
  const { theme } = useAppSelector((state: IRootState) => ({ theme: state.theme.theme }));
  const [textEditor, setTextEditor] = useState(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.destroy();
      setTextEditor(null);
    }
  }, [theme, index]);

  useEffect(() => {
    if (!textEditor) {
      setTextEditor(
        <Editor
          apiKey={TINY_MCE_API_KEY}
          onInit={(evt, editor) => (editorRef.current = editor)}
          onEditorChange={() => onEditorChange(editorRef.current.getContent())}
          initialValue={content}
          init={{
            height,
            menubar: false,
            skin: theme === ETheme.DARK ? 'oxide-dark' : 'oxide',
            content_css: theme === ETheme.DARK ? 'dark' : 'default',
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount',
            ],
            toolbar:
              'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Lato, sans-serif; font-size:14px }',
          }}
        />
      );
    }
  }, [textEditor]);

  return (
    <>
      <p className={`${styles.tinyMceLabel} mb-1`}>
        {label} {required && <span className={`${styles.asteriskStyles}`}> * </span>}
      </p>
      {textEditor}
      {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
    </>
  );
};

export default TinyMceEditorContent;
