import { createAsyncThunk, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import {
  contentDefaultValue,
  convertIChapterItemsResponseToIConvertedIdsChapterItemsResponse,
  IChapterItemsResponse,
  IConvertedIdsChapterItemsResponse,
  ObjectChapterItemSequenceNumberAsString,
  updateObjectByChapterItemSequenceNumber,
} from 'app/shared/util/dnd-utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

const initialState: EntityState<IConvertedIdsChapterItemsResponse> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: contentDefaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/admin/chapters';

export const getEntity = createAsyncThunk(
  'content/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IChapterItemsResponse>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'content/create_entity',
  async (entity: IChapterItemsResponse, thunkAPI) => {
    const result = await axios.post<IChapterItemsResponse>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const ContentSlice = createEntitySlice({
  name: 'content',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = convertIChapterItemsResponseToIConvertedIdsChapterItemsResponse(action.payload.data);
      })
      .addMatcher(isFulfilled(createEntity), (state, action) => {
        state.errorMessage = null;
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.updating = true;
      });
  },
  reducers: {
    setInitialState(state, action: PayloadAction<IConvertedIdsChapterItemsResponse>) {
      state.entity = action.payload;
      state.updateSuccess = false;
    },
    arrangeContentItemsHandler(state, action: PayloadAction<ObjectChapterItemSequenceNumberAsString[]>) {
      state.entity.contentItems = action.payload;
    },
    addChapterExternalResourceHandler(state, action: PayloadAction<ObjectChapterItemSequenceNumberAsString>) {
      state.entity.contentItems.push(action.payload);
    },
    addChapterMaterialHandler(state, action: PayloadAction<ObjectChapterItemSequenceNumberAsString>) {
      state.entity.contentItems.push(action.payload);
    },
    addChapterVideoHandler(state, action: PayloadAction<ObjectChapterItemSequenceNumberAsString>) {
      state.entity.contentItems.push(action.payload);
    },
    addChapterTextHandler(state, action: PayloadAction<ObjectChapterItemSequenceNumberAsString>) {
      state.entity.contentItems.push(action.payload);
    },
    chapterNameHandler(state, action: PayloadAction<string>) {
      state.entity.chapterName = action.payload;
    },
    textChangeHandler(
      state,
      action: PayloadAction<{
        item: ObjectChapterItemSequenceNumberAsString;
        value: any;
      }>
    ) {
      const clonedArray = [...state.entity.contentItems];
      const updatedArray = updateObjectByChapterItemSequenceNumber(clonedArray, action.payload.item.chapterItemSequenceNumber, {
        content: action.payload.value,
      });

      state.entity.contentItems = updatedArray;
    },
    materialChangeHandler(
      state,
      action: PayloadAction<{
        item: ObjectChapterItemSequenceNumberAsString;
        value: { fileName: string; url: string };
      }>
    ) {
      const clonedArray = [...state.entity.contentItems];
      const selectedSlice = clonedArray.find(v => v.chapterItemSequenceNumber === action.payload.item.chapterItemSequenceNumber);
      const selectedIndex = clonedArray.findIndex(v => v.chapterItemSequenceNumber === action.payload.item.chapterItemSequenceNumber);

      if (selectedIndex > -1) {
        selectedSlice.materials.push(action.payload.value);
        clonedArray[selectedIndex] = selectedSlice;
        state.entity.contentItems = clonedArray;
      }
    },
    externalResourceSubItemResourceNameHandler(
      state,
      action: PayloadAction<{ chapterItemSequenceNumber: string; index: number; referenceName: string }>
    ) {
      const clonedArray = [...state.entity.contentItems];
      const selectedSlice = clonedArray.find(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);
      const selectedIndex = clonedArray.findIndex(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);

      if (selectedIndex >= 0) {
        selectedSlice.resourceLinks[action.payload.index] = {
          ...selectedSlice.resourceLinks[action.payload.index],
          referenceName: action.payload.referenceName,
        };
        clonedArray[selectedIndex] = selectedSlice;
        state.entity.contentItems = clonedArray;
      }
    },
    externalResourceSubItemUrlHandler(state, action: PayloadAction<{ chapterItemSequenceNumber: string; index: number; url: string }>) {
      const clonedArray = [...state.entity.contentItems];
      const selectedSlice = clonedArray.find(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);
      const selectedIndex = clonedArray.findIndex(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);

      if (selectedIndex >= 0) {
        selectedSlice.resourceLinks[action.payload.index] = {
          ...selectedSlice.resourceLinks[action.payload.index],
          url: action.payload.url,
        };
        clonedArray[selectedIndex] = selectedSlice;
        state.entity.contentItems = clonedArray;
      }
    },
    externalResourceAddNewRow(state, action: PayloadAction<{ chapterItemSequenceNumber: string }>) {
      const clonedArray = [...state.entity.contentItems];
      const selectedSlice = clonedArray.find(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);
      const selectedIndex = clonedArray.findIndex(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);

      if (selectedIndex >= 0) {
        selectedSlice.resourceLinks = [
          ...selectedSlice.resourceLinks,
          { referenceName: '', url: '', isValidReferenceName: true, isValidUrl: true },
        ];
        clonedArray[selectedIndex] = selectedSlice;
        state.entity.contentItems = clonedArray;
      }
    },
    externalResourceRemoveRow(state, action: PayloadAction<{ chapterItemSequenceNumber: string; index: number }>) {
      const clonedArray = [...state.entity.contentItems];
      const selectedSlice = clonedArray.find(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);
      const selectedIndex = clonedArray.findIndex(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);

      if (selectedIndex >= 0) {
        selectedSlice.resourceLinks = [...selectedSlice.resourceLinks].filter((item, idx) => idx !== action.payload.index);
        clonedArray[selectedIndex] = selectedSlice;
        state.entity.contentItems = clonedArray;
      }
    },
    setExternalResourceValidations(state, action: PayloadAction<{ allResources: ObjectChapterItemSequenceNumberAsString[] }>) {
      const clonedArray = [...state.entity.contentItems];

      clonedArray.forEach(val => {
        const selected = action.payload.allResources.find(v => v.chapterItemSequenceNumber === val.chapterItemSequenceNumber);

        if (selected) {
          let selectedSlice = clonedArray.find(v => v.chapterItemSequenceNumber === selected.chapterItemSequenceNumber);
          const selectedIndex = clonedArray.findIndex(v => v.chapterItemSequenceNumber === selected.chapterItemSequenceNumber);
          if (selectedIndex >= 0) {
            selectedSlice = selected;
            clonedArray[selectedIndex] = selectedSlice;
          }
        }
      });
      state.entity.contentItems = clonedArray;
    },
    setVideoUrlHandler(state, action: PayloadAction<{ fileName: string; url: string; chapterItemSequenceNumber: string }>) {
      const clonedArray = [...state.entity.contentItems];
      const selectedSlice = clonedArray.find(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);
      const selectedIndex = clonedArray.findIndex(v => v.chapterItemSequenceNumber === action.payload.chapterItemSequenceNumber);

      selectedSlice.fileName = action.payload.fileName;
      selectedSlice.url = action.payload.url;
      clonedArray[selectedIndex] = selectedSlice;
      state.entity.contentItems = clonedArray;
    },
    deleteContentHandler(state, action: PayloadAction<string>) {
      const clonedArray = [...state.entity.contentItems];
      state.entity.contentItems = clonedArray.filter(v => v.chapterItemSequenceNumber !== action.payload);
    },
    materialRemoveHandler(state, action: PayloadAction<{ item: ObjectChapterItemSequenceNumberAsString; idx: number }>) {
      const clonedArray = [...state.entity.contentItems];
      const selectedSlice = clonedArray.find(v => v.chapterItemSequenceNumber === action.payload.item.chapterItemSequenceNumber);
      const selectedIndex = clonedArray.findIndex(v => v.chapterItemSequenceNumber === action.payload.item.chapterItemSequenceNumber);

      selectedSlice.materials.splice(action.payload.idx, 1);

      clonedArray[selectedIndex].materials = selectedSlice.materials;

      state.entity.contentItems = clonedArray;
    },
  },
});

export const {
  reset,
  setInitialState,
  arrangeContentItemsHandler,
  addChapterExternalResourceHandler,
  addChapterMaterialHandler,
  addChapterVideoHandler,
  addChapterTextHandler,
  chapterNameHandler,
  textChangeHandler,
  externalResourceSubItemResourceNameHandler,
  externalResourceSubItemUrlHandler,
  externalResourceAddNewRow,
  externalResourceRemoveRow,
  setExternalResourceValidations,
  setVideoUrlHandler,
  deleteContentHandler,
  materialChangeHandler,
  materialRemoveHandler,
} = ContentSlice.actions;

export default ContentSlice.reducer;
