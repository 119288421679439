import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from 'app/entities/course/course.reducer';
import { createPayment, fetchPaymentDataByCoupon, fetchPaymentDataByOrderId } from 'app/services/payment-service';
import PricingCard from 'app/shared/components/pricing-card/pricing-card';
import { ICourseDetails } from 'app/shared/model/dto/course-details.model';
import { IDiscount } from 'app/shared/model/dto/discount.model';
import IPaymentResponseById from 'app/shared/model/dto/payment-response-by-id.model';
import { IPaymentResponse } from 'app/shared/model/dto/payment-response.model';
import { EPaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { IPayHere } from 'app/shared/model/payhere-model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import {
  contactNumberRegex,
  emailRegex,
  noOnlyNumbersAndNoSpecialCharsRegex,
  noSpecialCharsNoNumbersNoSpacesRegex,
} from 'app/shared/util/regex';
import { showErrorToast, showSuccessToast } from 'app/shared/util/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BsXLg } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './payment.module.scss';

interface IFormInputs {
  courseId: number;
  couponId: string;
  currency: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  address: string;
  city: string;
}

interface Payhere {
  onCompleted: (orderId: number) => void;
  startPayment: (paymentDetails: IPayHere) => void;
  onDismissed: (orderId: number) => void;
}
interface CustomWindow extends Window {
  payhere: Payhere;
}

declare const window: CustomWindow;

const Payment = () => {
  const { courseId } = useParams<'courseId'>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [discountDetails, setDiscountDetails] = useState<IDiscount>();
  const [paymentDetails, setPaymentDetails] = useState<IPaymentResponseById>();
  const [isStartCheckout, setIsStartCheckout] = useState<boolean>(false);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);

  const { courseEntity, account } = useAppSelector((state: IRootState) => ({
    courseEntity: state.course.entity as ICourseDetails,
    account: state.authentication.account,
  }));

  useEffect(() => {
    dispatch(getEntity(courseId));
  }, []);

  const {
    register,
    setValue,
    formState: { errors },
    control,
    handleSubmit,
    watch,
  } = useForm<IFormInputs>({});

  const { handleSubmit: handleSubmitCoupon } = useForm({});

  const couponCode = useRef('');
  couponCode.current = watch('couponId', '');

  useEffect(() => {
    if (account) {
      setValue('firstName', account.firstName);
      setValue('lastName', account.lastName);
      setValue('email', account.email);
      setValue('contactNumber', account.mobileNumber);
    }
  }, [account]);

  useEffect(() => {
    if (paymentDetails && paymentDetails.paymentStatus === EPaymentStatus.SUCCESS) {
      navigate(`/payment/${courseId}/status?payment_status=successful`, {
        state: {
          courseId: courseEntity.id,
          ...paymentDetails,
        },
      });
    } else if (
      (paymentDetails && paymentDetails?.paymentStatus === EPaymentStatus.FAILED) ||
      paymentDetails?.paymentStatus === EPaymentStatus.CANCELED
    ) {
      navigate(`/payment/${courseId}/status?payment_status=unsuccessful`, {
        state: {
          courseId: courseEntity.id,
          ...paymentDetails,
        },
      });
    }
  }, [paymentDetails]);

  const onSubmitCoupon = () => {
    fetchPaymentDataByCoupon(couponCode.current, courseEntity.id)
      .then((response: IDiscount) => {
        setDiscountDetails(response);
        setCouponApplied(true);
      })
      .catch(error => {
        if (error.response.data.status === 412) {
          showErrorToast(error.response.data.message, 'error');
        } else {
          showErrorToast('Please enter valid coupon code', 'coupon');
        }
      });
  };

  const onSubmit: SubmitHandler<IFormInputs> = (data: IFormInputs) => {
    setIsStartCheckout(true);

    createPayment({
      courseId: courseEntity.id,
      couponId: discountDetails ? discountDetails.couponId : null,
      currency: 'LKR',
      firstname: data.firstName,
      lastname: data.lastName,
      email: data.email,
      contactNumber: data.contactNumber,
      address: data.address,
      city: data.city,
    })
      .then((res: IPaymentResponse) => {
        const payment = {
          order_id: res.orderId,
          merchant_id: res.merchantId,
          items: res.item,
          hash: res.hash,
          amount: res.amount,
          currency: res.currency,
          first_name: res.firstname,
          last_name: res.lastname,
          phone: res.contactNumber,
          email: res.email,
          address: res.address,
          city: res.city,
          country: res.country,
          return_url: '',
          cancel_url: '',
          notify_url: res.notifyUrl,
        };

        window.payhere.startPayment(payment);
        setIsStartCheckout(false);
      })
      .catch(() => {
        showErrorToast('Payment Failed', 'payment');
        setIsStartCheckout(false);
      });
  };

  window.payhere.onCompleted = (orderId: number) => {
    if (orderId) {
      fetchPaymentDataByOrderId(orderId).then((response: IPaymentResponseById) => {
        setPaymentDetails(response);
      });
    }
  };

  window.payhere.onDismissed = () => {
    setIsStartCheckout(false);
  };

  const validateAddress = (input: string) => {
    if (input.length > 0 && input[input.length - 1] === ' ') {
      return false;
    }
    const trimmedInput = input.replace(/\s+$/, '');
    return noOnlyNumbersAndNoSpecialCharsRegex.test(trimmedInput);
  };

  const onRemoveAppliedCoupon = () => {
    setDiscountDetails(null);
    setCouponApplied(false);
    setValue('couponId', '');
    showSuccessToast('Coupon code removed successfully', 'couponRemoved');
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col className={styles.txt1}>A/L අත්වැල by Skill Surf Payment</Col>
      </Row>
      <Row>
        <Col className="col-12 col-lg-7">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-3">
              <Col className="col-12 col-sm-6">
                <AppInput
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                  isRequired={true}
                  register={register('firstName', {
                    required: 'First Name is required',
                    minLength: {
                      value: 2,
                      message: 'This field must contain at least 5 characters',
                    },
                    maxLength: {
                      value: 15,
                      message: 'You have exceeded the maximum number of 15 characters in this field',
                    },
                    pattern: {
                      value: noSpecialCharsNoNumbersNoSpacesRegex,
                      message:
                        'Entered value should contain only letters (uppercase/ lowercase), hyphens, and apostrophes. Spaces, special characters and numbers are not allowed.',
                    },
                  })}
                  errors={errors}
                  disabled
                />
              </Col>
              <Col className="col-12 col-sm-6 mt-3 mt-sm-0">
                <AppInput
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  isRequired={true}
                  register={register('lastName', {
                    required: 'Last Name is required',
                    minLength: {
                      value: 2,
                      message: 'This field must contain at least 5 characters',
                    },
                    maxLength: {
                      value: 15,
                      message: 'You have exceeded the maximum number of 15 characters in this field',
                    },
                    pattern: {
                      value: noSpecialCharsNoNumbersNoSpacesRegex,
                      message:
                        'Entered value should contain only letters (uppercase/ lowercase), hyphens, and apostrophes. Spaces, special characters and numbers are not allowed.',
                    },
                  })}
                  errors={errors}
                  disabled
                />
              </Col>
            </Row>
            <AppInput
              className="mt-3"
              id="email"
              name="email"
              label="Email"
              placeholder="Email"
              isRequired={true}
              register={register('email', {
                required: 'Email is required',
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email',
                },
              })}
              errors={errors}
              disabled
            />
            <AppInput
              className="mt-3"
              id="contactNumber"
              name="contactNumber"
              label="Contact Number"
              isRequired={true}
              type="text"
              placeholder="Contact Number"
              register={register('contactNumber', {
                required: 'Contact number is required',
                pattern: {
                  value: contactNumberRegex,
                  message: 'Please enter a valid contact number',
                },
              })}
              errors={errors}
            />
            <AppInput
              className="mt-3"
              id="address"
              name="address"
              label="Address"
              isRequired={true}
              placeholder="Address"
              type="text"
              register={register('address', {
                required: 'Address is required',
                validate: value => validateAddress(value) || 'Customer address is invalid',
              })}
              errors={errors}
            />
            <AppInput
              className="mt-3"
              id="city"
              name="city"
              label="City"
              placeholder="City"
              isRequired={true}
              register={register('city', {
                required: 'City is required',
                pattern: {
                  value: noSpecialCharsNoNumbersNoSpacesRegex,
                  message:
                    'Entered value should contain only letters (uppercase/ lowercase),numbers, hyphens, and apostrophes. Special characters are not allowed.',
                },
              })}
              errors={errors}
            />
          </Form>
          <Form onSubmit={handleSubmitCoupon(onSubmitCoupon)}>
            <AppInput
              className="mt-3"
              id="couponId"
              name="couponId"
              label="Coupon Code (If any)"
              placeholder="Coupon Code"
              register={register('couponId')}
              errors={errors}
              button={
                <Row className="me-1">
                  <Col>
                    {!couponApplied ? (
                      <AppButton btnSize="xs" text="Apply" type="submit" />
                    ) : (
                      <BsXLg className="cursor-pointer" color="#FF4C6C" size={18} onClick={onRemoveAppliedCoupon} />
                    )}
                  </Col>
                </Row>
              }
              disabled={couponApplied}
            />
          </Form>
        </Col>
        <Col className="col-12 col-lg-5 mt-4 mt-lg-5 pt-lg-3">
          <PricingCard course={courseEntity} discountDetails={discountDetails} />
        </Col>
      </Row>
      <Row className="align-items-center mt-5">
        <Col className="col-6 col-lg-3 pe-0">
          <AppButton text="Checkout" type="submit" onClick={handleSubmit(onSubmit)} disabled={isStartCheckout} loading={isStartCheckout} />
        </Col>
        <Col className="col-auto ps-0">
          <AppButton
            text="Cancel"
            btnType="onlyText"
            type="button"
            onClick={() => navigate(`/course-preview/${courseId}`)}
            disabled={isStartCheckout}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Payment;
