import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState = {
  emailSendSuccess: false,
  emailSendFailure: false,
  loading: false,
};

export type emailConfirationState = Readonly<typeof initialState>;

export const resendEmail = createAsyncThunk(
  'emailConfirmation/resend_email',
  async (data: { email: string }) => axios.post(`api/public/resend-email`, data),
  {
    serializeError: serializeAxiosError,
  }
);

export const ActivateSlice = createSlice({
  name: 'emailConfirmation',
  initialState: initialState as emailConfirationState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resendEmail.pending, () => ({ ...initialState, loading: true }))
      .addCase(resendEmail.rejected, state => {
        state.emailSendFailure = true;
        state.loading = false;
      })
      .addCase(resendEmail.fulfilled, state => {
        state.emailSendSuccess = true;
        state.loading = false;
      });
  },
});

export const { reset } = ActivateSlice.actions;

// Reducer
export default ActivateSlice.reducer;
