import { useAppDispatch, useAppSelector } from 'app/config/store';
import { uploadFile } from 'app/services/file-upload-service';
import ExternalResourceHandlerSecond from 'app/shared/components/app-dnd-elements/app-dnd-external-resource-handler/app-dnd-external-resource-handler-second';
import AppDNDMaterialUploader from 'app/shared/components/app-dnd-elements/app-dnd-material-uploader/app-dnd-material-uploader';
import AppDNDTinyMCEEditor from 'app/shared/components/app-dnd-elements/app-dnd-tinymce-editor/app-dnd-tinymce-editor';
import AppVideoUploader from 'app/shared/components/app-dnd-elements/app-dnd-video-uploader/app-dnd-video-uploader';
import MainLoader from 'app/shared/components/main-loader/main-loader';
import { IRowData, IRowInputsData } from 'app/shared/components/multiple-rows-addition-field/multiple-rows-addition-field';
import { ECHAPTER } from 'app/shared/model/enumerations/chapters-types';
import { EUploadFileType } from 'app/shared/model/enumerations/upload-file-type';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import {
  IChapterItemsResponse,
  IConvertedIdChapterMaterial,
  IConvertedIdChapterTextContent,
  IConvertedIdsChapterItemsResponse,
  IResourceLinkUtilized,
  ObjectChapterItemSequenceNumberAsString,
  contentDefaultValue,
  createChapterSaveRequestPayload,
} from 'app/shared/util/dnd-utils';
import { noWhitespaceStartEndRegex } from 'app/shared/util/regex';
import { showErrorToast, showSuccessToast } from 'app/shared/util/toast';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
  arrangeContentItemsHandler,
  chapterNameHandler,
  createEntity,
  deleteContentHandler,
  externalResourceAddNewRow,
  externalResourceRemoveRow,
  externalResourceSubItemResourceNameHandler,
  externalResourceSubItemUrlHandler,
  getEntity,
  materialChangeHandler,
  materialRemoveHandler,
  reset,
  setInitialState,
  setVideoUrlHandler,
  textChangeHandler,
} from './manage-content.reducer';

const linkListRowInputsData: IRowInputsData = {
  firstInputLabel: 'Link Text',
  secondInputLabel: 'Link',
  firstInputPlaceholder: 'Link Text',
  secondInputPlaceholder: 'Link',
  firstInputBracketLabel: '',
  secondInputBracketLabel: '',
};

const setDefaultContents: any = [
  {
    id: null,
    chapterId: 0, // this value is set to zero because this values is not referred from here.
    content: '',
    chapterItemSequenceNumber: '0',
    contentType: ECHAPTER.TEXT_CONTENT,
  },
  {
    id: null,
    chapterId: 0, // this value is set to zero because this values is not referred from here.
    fileName: '',
    url: '',
    type: 0,
    chapterItemSequenceNumber: '1',
    contentType: ECHAPTER.VIDEO,
  },
];

const ManageContent = () => {
  const params = useParams() as { courseId: string; lessonId: string; unitId: string; chapterId: string };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IConvertedIdsChapterItemsResponse>({});

  const chapterName = useRef('');
  chapterName.current = watch('chapterName', '');

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [linkList, setLinkList] = useState<IRowData[]>([]);
  const [isUploadingMaterials, setIsUploadingMaterials] = useState<boolean>(false);

  const { loading, chapterEntity, updateSuccess, errorMessage } = useAppSelector(state => ({
    loading: state.content.loading,
    chapterEntity: state.content.entity as IConvertedIdsChapterItemsResponse,
    updateSuccess: state.content.updateSuccess,
    errorMessage: state.content.errorMessage,
  }));

  useEffect(() => {
    dispatch(chapterNameHandler(chapterName.current));
  }, [chapterName.current]);

  useEffect(() => {
    if (chapterEntity.chapterId) {
      for (const [key, value] of Object.entries(chapterEntity)) {
        setValue(key as any, value, { shouldDirty: true, shouldValidate: true });
      }
    }
  }, [chapterEntity, setValue]);

  useEffect(() => {
    if (params.chapterId) {
      dispatch(getEntity(params.chapterId));
    } else {
      dispatch(arrangeContentItemsHandler(setDefaultContents));
      dispatch(chapterNameHandler(''));
    }

    return () => {
      dispatch(setInitialState(contentDefaultValue));
    };
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      if (params.unitId) {
        navigate(`/admin/courses/${params.courseId}/course-materials/lesson/${params.lessonId}/unit/${params.unitId}/chapters`);
      } else {
        navigate(`/admin/courses/${params.courseId}/course-materials/lesson/${params.lessonId}/chapters`);
      }
      showSuccessToast(`${params.chapterId ? 'Chapter updated successfully' : 'Chapter created successfully'}`, 'AddChapterSuccess', 500);
    }
    if (errorMessage != null && !loading) {
      showErrorToast(`${params.chapterId ? 'Chapter update failed' : 'Chapter creation failed'}`, 'AddChapterFailure', 500);
    }
  }, [updateSuccess, errorMessage]);

  const onCancelButtonClick = () => {
    if (params.unitId) {
      navigate(`/admin/courses/${params.courseId}/course-materials/lesson/${params.lessonId}/unit/${params.unitId}/chapters`);
    } else {
      navigate(`/admin/courses/${params.courseId}/course-materials/lesson/${params.lessonId}/chapters`);
    }
  };

  function onDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(chapterEntity.contentItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(arrangeContentItemsHandler(items));
  }

  const referenceNameChangeHandler = (chapterItemSequenceNumber: string, index: number, referenceName: string) => {
    dispatch(externalResourceSubItemResourceNameHandler({ chapterItemSequenceNumber, index, referenceName }));
  };

  const urlChangeHandler = (chapterItemSequenceNumber: string, index: number, url: string) => {
    dispatch(externalResourceSubItemUrlHandler({ chapterItemSequenceNumber, index, url }));
  };

  const referenceLinksAddNewRowHandler = (chapterItemSequenceNumber: string) => {
    dispatch(externalResourceAddNewRow({ chapterItemSequenceNumber }));
  };

  const referenceLinksRemoveRowHandler = (chapterItemSequenceNumber: string, index: number) => {
    dispatch(externalResourceRemoveRow({ chapterItemSequenceNumber, index }));
  };

  const onVideoUrlSet = (fileName: string, url: string, chapterItemSequenceNumber: string) => {
    dispatch(setVideoUrlHandler({ fileName, url, chapterItemSequenceNumber }));
  };

  const onMaterialRemove = (item: ObjectChapterItemSequenceNumberAsString, index: number) => {
    dispatch(materialRemoveHandler({ item, idx: index }));
  };

  const onMaterialsSelected = (files: FileList, chapterContentItem: ObjectChapterItemSequenceNumberAsString) => {
    setIsUploadingMaterials(true);

    const uploadMaterialPromises = Array.from(files).map(async material => {
      const res = await uploadFile(material, EUploadFileType.DOCUMENTS, +new Date() + material.name);
      dispatch(materialChangeHandler({ item: chapterContentItem, value: { fileName: material.name, url: res.storagePath } }));
      return res;
    });

    Promise.all(uploadMaterialPromises)
      .then(() => {
        setIsUploadingMaterials(false);
      })
      .catch(() => {
        setIsUploadingMaterials(false);
      });
  };

  const onDeleteContent = (chapterItemSequenceNumber: string) => {
    dispatch(deleteContentHandler(chapterItemSequenceNumber));
  };

  const isSubmitDisabledHandler = (value: boolean) => {
    setIsSubmitDisabled(value);
  };

  const validateExternalResourceRows = (cE: IConvertedIdsChapterItemsResponse) => {
    let isFormValid = true;
    const clonedArray = [...cE.contentItems];

    const dumArray: ObjectChapterItemSequenceNumberAsString[] = [];

    clonedArray.forEach(val => {
      if (val.contentType === ECHAPTER.EXTERNAL_RESOURCE) {
        dumArray.push(val);
      }
    });

    for (let i = 0; i < dumArray.length; i++) {
      const resourceLinksArray = [...(dumArray[i] as any).resourceLinks];

      for (let j = 0; j < resourceLinksArray.length; j++) {
        const name = resourceLinksArray[j].referenceName;
        const url = resourceLinksArray[j].url;

        if (!name) {
          toast.error('Link texts cannot be empty');

          if (isFormValid) {
            isFormValid = false;
          }
        }

        if (!url) {
          // resourceLinksArray[j] = { ...resourceLinksArray[j], isValidUrl: false }
          toast.error('Links cannot be empty');
          if (isFormValid) {
            isFormValid = false;
          }
        }
      }
    }

    const hasMaterials = clonedArray.some(obj => obj.hasOwnProperty('materials'));

    if (hasMaterials) {
      let containMaterials = false;
      clonedArray.forEach(obj => {
        if (obj.hasOwnProperty('materials') && obj.materials.length > 0) containMaterials = true;
      });
      if (!containMaterials) {
        toast.error('Materials cannot be empty');
        isFormValid = false;
      }
    }

    // dispatch(setExternalResourceValidations({ allResources: dumArray }))

    return isFormValid;
  };

  const onSubmit = () => {
    const isValid = validateExternalResourceRows(chapterEntity);
    if (isValid) {
      const data: IChapterItemsResponse = createChapterSaveRequestPayload(chapterEntity);

      if (!data.chapterId)
        [(data.courseId = +params.courseId), (data.lessonId = +params.lessonId), (data.unitId = params.unitId ? +params.unitId : null)];

      dispatch(createEntity(data));
    }
  };

  useEffect(() => {
    if (!(chapterEntity.contentItems.length < 1)) {
      const externalResources = chapterEntity.contentItems.filter(val => val.contentType === ECHAPTER.EXTERNAL_RESOURCE);
      setLinkList(convertResourceLinksToIRowData(externalResources as any) as any);
    }
  }, [chapterEntity.contentItems]);

  const convertResourceLinksToIRowData = (resource: { resourceLinks: { referenceName: string; url: string }[] }[]) => {
    const data: IRowData[] = [];
    resource.forEach(v => {
      v.resourceLinks.map(link => {
        data.push({
          id: uuidv4(),
          firstInput: link.referenceName,
          secondInput: link.url,
          isValidFirstInput: true,
          isValidSecondInput: true,
          imageUrl: '',
        });
      });
    });
    return data;
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Row>
      <Col className="mb-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <AppInput
                id="chapterName"
                name="chapterName"
                label="Chapter Title"
                placeholder="Chapter Title"
                register={register('chapterName', {
                  required: 'Chapter title is required',
                  minLength: {
                    value: 2,
                    message: 'This field must contain at least 2 characters',
                  },
                  maxLength: {
                    value: 50,
                    message: 'You have exceeded the maximum number of 50 characters in this field',
                  },
                  pattern: {
                    value: noWhitespaceStartEndRegex,
                    message: "Entered value can't start/end or contain only white spaces",
                  },
                })}
                errors={errors}
              />
            </Col>
          </Row>

          <Row>
            <Col className="my-3">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="content-droppable">
                  {(provided: {
                    innerRef: React.LegacyRef<HTMLDivElement>;
                    droppableProps: React.JSX.IntrinsicAttributes &
                      React.ClassAttributes<HTMLDivElement> &
                      React.HTMLAttributes<HTMLDivElement>;
                    placeholder:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                      | Iterable<React.ReactNode>
                      | React.ReactPortal;
                  }) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {chapterEntity &&
                        chapterEntity.contentItems.map((chapterContentItem, index) => {
                          return (
                            <Row key={index}>
                              <Col>
                                <Draggable
                                  key={chapterContentItem.chapterItemSequenceNumber}
                                  draggableId={chapterContentItem.chapterItemSequenceNumber}
                                  index={index}
                                  isDragDisabled={isSubmitDisabled}
                                >
                                  {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                                  {provided => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="mb-3"
                                    >
                                      {chapterContentItem.contentType === ECHAPTER.VIDEO ? (
                                        <AppVideoUploader
                                          chapterContentItem={chapterContentItem}
                                          onVideoUrlSet={onVideoUrlSet}
                                          onDeleteContent={onDeleteContent}
                                          isSubmitDisabledHandler={isSubmitDisabledHandler}
                                        />
                                      ) : chapterContentItem.contentType === ECHAPTER.TEXT_CONTENT ? (
                                        <Row>
                                          <Col>
                                            <AppDNDTinyMCEEditor
                                              label={'Text Content'}
                                              content={(chapterContentItem as IConvertedIdChapterTextContent).content}
                                              index={index + ''}
                                              height={250}
                                              onEditorChange={content => {
                                                dispatch(textChangeHandler({ item: chapterContentItem, value: content }));
                                              }}
                                              onClickRemoveIcon={() => onDeleteContent(chapterContentItem.chapterItemSequenceNumber)}
                                            />
                                          </Col>
                                        </Row>
                                      ) : chapterContentItem.contentType === ECHAPTER.EXTERNAL_RESOURCE ? (
                                        <ExternalResourceHandlerSecond
                                          label="Links"
                                          addImageEnabled={false}
                                          buttonText="Add Link"
                                          rowInputsData={linkListRowInputsData}
                                          resourceLinks={chapterContentItem.resourceLinks as IResourceLinkUtilized[]}
                                          onClickRemoveIcon={() => onDeleteContent(chapterContentItem.chapterItemSequenceNumber)}
                                          chapterItemSequenceNumber={chapterContentItem.chapterItemSequenceNumber}
                                          referenceNameChangeHandler={referenceNameChangeHandler}
                                          urlChangeHandler={urlChangeHandler}
                                          referenceLinksAddNewRowHandler={referenceLinksAddNewRowHandler}
                                          referenceLinksRemoveRowHandler={referenceLinksRemoveRowHandler}
                                        />
                                      ) : (
                                        <AppDNDMaterialUploader
                                          label="Extra Materials"
                                          onDeleteContent={() => onDeleteContent(chapterContentItem.chapterItemSequenceNumber)}
                                          iconTitle="Add Materials"
                                          isMultipleMaterial={true}
                                          onMaterialsSelected={files => onMaterialsSelected(files, chapterContentItem)}
                                          materials={(chapterContentItem as IConvertedIdChapterMaterial).materials}
                                          onRemoveMaterial={matIndex => onMaterialRemove(chapterContentItem, matIndex)}
                                          loading={isUploadingMaterials}
                                        />
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              </Col>
                            </Row>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>
          </Row>

          <Row>
            <Col className="col-12 col-sm-4 mb-2 mb-sm-0">
              <AppButton
                text={`${params.chapterId ? 'Update and Publish' : 'Save and Publish'}`}
                type="submit"
                disabled={isSubmitDisabled || isUploadingMaterials}
              />
            </Col>
            <Col className="col-12 col-sm-auto">
              <AppButton
                text="Cancel"
                btnType="onlyText"
                type="button"
                disabled={isSubmitDisabled || isUploadingMaterials}
                onClick={onCancelButtonClick}
              />
            </Col>
          </Row>
        </form>
        <MainLoader show={loading} />
      </Col>
    </Row>
  );
};

export default ManageContent;
