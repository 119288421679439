// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ahA92q7x1LxmvurYz7QK {
  border-radius: 4px;
  font-size: 12px;
  width: 100%;
}

.AMirFY5VbyjmV0mw64ZW {
  color: #ffffff;
  background-color: var(--accent-color);
}

.WE0g6_oXfOFfTnvb1NAD {
  color: var(--text-secondary-color);
  background-color: var(--card-secondary-color);
  padding: 0 2px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-badge/AppBadge.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,cAAA;EACA,qCAAA;AACF;;AAEA;EACE,kCAAA;EACA,6CAAA;EACA,cAAA;AACF","sourcesContent":[".appBadge {\n  border-radius: 4px;\n  font-size: 12px;\n  width: 100%;\n}\n\n.primary {\n  color: #ffffff;\n  background-color: var(--accent-color);\n}\n\n.secondary {\n  color: var(--text-secondary-color);\n  background-color: var(--card-secondary-color);\n  padding: 0 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appBadge": `ahA92q7x1LxmvurYz7QK`,
	"primary": `AMirFY5VbyjmV0mw64ZW`,
	"secondary": `WE0g6_oXfOFfTnvb1NAD`
};
export default ___CSS_LOADER_EXPORT___;
