import { ECourseDifficultyLevel } from 'app/shared/model/enumerations/course-difficulty-level.model';
import { EFieldOfStudy } from 'app/shared/model/enumerations/field-of-study-type.model';
import { IOption } from 'app/shared/model/option.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppSearch from 'app/shared/ui-elements/app-search/app-search';
import AppSelect from 'app/shared/ui-elements/app-select/app-select';
import React, { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsFunnel, BsX } from 'react-icons/bs';
import { Container } from 'reactstrap';
import filterStyles from '../../../../modules/program-catalog/program-filter/program-filter.module.scss';
import styles from './course-filter.module.scss';

interface IProps {
  title?: string;
  onFilterChange: (field: string, skillLevel: string) => void;
  onSearch: (searchInput: string) => void;
  searchPhrase?: string;
  isSearchOnClick?: boolean;
}

type TFilterFormValues = {
  fields: IOption;
  skillLevels: IOption;
};

const initialFilters = {
  fields: { value: '', label: '' },
  skillLevels: { value: '', label: '' },
};

const CourseFilter = (props: IProps) => {
  const { title, onFilterChange, onSearch } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [filterValues, setFilterValues] = useState(initialFilters);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TFilterFormValues>({});

  const selectedFields = watch('fields');
  const selectedSkillLevel = watch('skillLevels');

  const clearFilters = () => {
    setFilterValues(initialFilters);
    setActiveFiltersCount(0);
    setValue('fields', filterValues.fields);
    setValue('skillLevels', filterValues.skillLevels);
  };

  const accordionOnClick = () => {
    setIsOpen(!isOpen);
  };

  const Field = Object.values(EFieldOfStudy).map(field => ({
    value: field,
    label: field
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
  }));
  const SkillLevel = [
    { value: ECourseDifficultyLevel.BEGINNER, label: 'Beginner' },
    { value: ECourseDifficultyLevel.INTERMEDIATE, label: 'Intermediate' },
    { value: ECourseDifficultyLevel.ADVANCED, label: 'Advanced' },
  ];

  const handleFilterChange = (field: any, skillLevel: any) => {
    setFilterValues({
      fields: field || null,
      skillLevels: skillLevel || null,
    });
    let count = 0;
    if (field || skillLevel) count = (field ? 1 : 0) + (skillLevel ? 1 : 0);
    setActiveFiltersCount(count);
  };

  useEffect(() => {
    handleFilterChange(selectedFields?.value, selectedSkillLevel?.value);
    onFilterChange(selectedFields?.value, selectedSkillLevel?.value);
  }, [selectedSkillLevel, selectedFields]);

  const renderFilterDropdown = () => {
    return (
      <Form onSubmit={handleSubmit(clearFilters)}>
        <Row className="align-items-center">
          <Col className="col-12 col-md-5 col-lg-4">
            <AppSelect
              options={Field}
              label="Subject"
              id="fields"
              name="fields"
              register={register('fields')}
              errors={errors}
              control={control}
            />
          </Col>
          {/* <Col className="col-12 ms-md-2 col-md-3">
            <AppSelect
              options={SkillLevel}
              label="Skill Level"
              id="skillLevels"
              name="skillLevels"
              {...register('skillLevels')}
              errors={errors}
              control={control}
            />
          </Col> */}
          {activeFiltersCount > 0 && (
            <Col className="col-auto px-0 d-none d-md-block mt-4 pt-2">
              <AppButton text={'Clear Filters'} btnType="onlyText" btnSize="sm" />
            </Col>
          )}
        </Row>
      </Form>
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <p className={`${styles.pageTitle} mt-5`}>{title}</p>
        </Col>
      </Row>
      <Row>
        <Col className={`col-12 col-md-6 col-lg-4 d-flex align-items-end mb-sm-2 mb-md-0`}>
          <AppSearch
            onSearch={() => {
              props.isSearchOnClick && onSearch(props.searchPhrase);
            }}
            onChange={input => {
              !props.isSearchOnClick && onSearch(input);
            }}
            value={props.searchPhrase}
            placeholder="Search"
          />
        </Col>
        <Col className="d-none d-md-block col-12 col-lg-8 ps-lg-4">{renderFilterDropdown()}</Col>
        <Col>
          <Row className="align-items-center">
            <Col xs={4} className={`${isOpen ? '' : 'pe-0'} mt-3`}>
              <div className={`${filterStyles.accordionWrapper} ${isOpen ? filterStyles.fullWidth : ''} d-block d-md-none`}>
                <Accordion className={`${filterStyles.accordion}`}>
                  <Accordion.Item eventKey="0" className={`${filterStyles.accordionItem}`}>
                    <Accordion.Header onClick={accordionOnClick} className={`cursor-pointer`}>
                      Filters
                      <span className={`${filterStyles.headerIcon}`}>
                        {isOpen ? (
                          <BsX size={24} color="var(--text-primary-color)" />
                        ) : (
                          <div className={` ${activeFiltersCount && 'mb-3'}`}>
                            {activeFiltersCount > 0 && <div className={`${filterStyles.badge}`}>{activeFiltersCount}</div>}
                            <BsFunnel size={24} color="var(--icon-color)" />
                          </div>
                        )}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>{renderFilterDropdown()}</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            {activeFiltersCount > 0 && (
              <Col className={`col-auto d-block d-md-none ${isOpen ? 'ms-5 ps-5' : 'px-0'} pt-3`}>
                <AppButton text={'Clear Filters'} onClick={clearFilters} btnType="onlyText" btnSize="sm" />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CourseFilter;
