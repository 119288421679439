import ScrollToTop from 'app/shared/components/scroll-to-top/ScrollToTop';
import React from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Footer from '../footer/footer';
import Header from '../header/header';

const LandingLayout = () => {
  return (
    <Container fluid className="p-0 overflow-hidden">
      <div className="landing-layout">
        <Header />
        <div className="landing-layout__body">
          <ScrollToTop />
          <Outlet />
        </div>
        <Footer />
      </div>
    </Container>
  );
};

export default LandingLayout;
