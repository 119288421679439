// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ko4Q7HLk0aordasZ1iHG {
  background: var(--card-secondary-color);
  padding-top: 24px;
  padding-bottom: 27px;
}
.ko4Q7HLk0aordasZ1iHG .kFV1dPZMP_CEApptJ8nS {
  color: var(--text-primary-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ko4Q7HLk0aordasZ1iHG .bWR6KeU5UqbYfrtraAFA {
  color: var(--text-secondary-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pheqMn0NWHbRFEcKhVU3 {
  height: calc(100vh - 100px);
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 0em;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/nav-bar/app-chapter-content-nav-bar/AppChapterContentSecondaryNavBar.module.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,iBAAA;EACA,oBAAA;AACF;AACE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAEE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AADF;;AAIA;EACE,UAAA;AADF","sourcesContent":[".courseHeader {\n  background: var(--card-secondary-color);\n  padding-top: 24px;\n  padding-bottom: 27px;\n\n  .courseTitle {\n    color: var(--text-primary-color);\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n  }\n\n  .courseSubTitle {\n    color: var(--text-secondary-color);\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n  }\n}\n\n.itemsContainer {\n  height: calc(100vh - 100px);\n  max-width: 100%;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n::-webkit-scrollbar {\n  width: 0em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseHeader": `ko4Q7HLk0aordasZ1iHG`,
	"courseTitle": `kFV1dPZMP_CEApptJ8nS`,
	"courseSubTitle": `bWR6KeU5UqbYfrtraAFA`,
	"itemsContainer": `pheqMn0NWHbRFEcKhVU3`
};
export default ___CSS_LOADER_EXPORT___;
