import chapterExternalResource from 'app/entities/chapter-external-resource/chapter-external-resource.reducer';
import chapterMaterial from 'app/entities/chapter-material/chapter-material.reducer';
import chapterTextContent from 'app/entities/chapter-text-content/chapter-text-content.reducer';
import chapterVideo from 'app/entities/chapter-video/chapter-video.reducer';
import chapter from 'app/entities/chapter/chapter.reducer';
import courseAnnouncement from 'app/entities/course-announcement/course-announcement.reducer';
import courseEnrollment from 'app/entities/course-enrollment/course-enrollment.reducer';
import course from 'app/entities/course/course.reducer';
import lesson from 'app/entities/lesson/lesson.reducer';
import unit from 'app/entities/unit/unit.reducer';
import userProfile from 'app/entities/user-profile/user-profile.reducer';
import content from 'app/modules/admin/chapter-management/manage-content.reducer';
import courseChapterManager from 'app/modules/admin/course-chapter-manager/course-chapter-manager.reducer';
import courseLessonsUnitsManager from 'app/modules/admin/course-lessons-units-manager/course-lessons-units-manager.reducer';
import courseChapterPresenter from 'app/modules/student/course-chapter-presenter/course-chapter-presenter.reducer';
import courseLessonsUnitsPresenter from 'app/modules/student/course-lessons-units-presenter/course-lessons-units-presenter.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  content,
  courseChapterManager,
  courseChapterPresenter,
  courseLessonsUnitsManager,
  courseLessonsUnitsPresenter,
  chapter,
  chapterExternalResource,
  chapterMaterial,
  chapterTextContent,
  chapterVideo,
  course,
  courseAnnouncement,
  courseEnrollment,
  lesson,
  unit,
  userProfile,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
