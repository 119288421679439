import { useAppDispatch, useAppSelector } from 'app/config/store';
import { handleRegister, reset } from 'app/modules/account/register/register.reducer';
import AccountSideImageSection from 'app/shared/components/account-side-image-section/account-side-image-section';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppCheckbox from 'app/shared/ui-elements/app-checkbox/app-checkbox';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import { emailRegex, noSpecialCharsNoNumbersNoSpacesRegex, passwordRegex } from 'app/shared/util/regex';
import { showSuccessToast } from 'app/shared/util/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import styles from './sign-up.module.scss';

type SignUpFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentLocale, loading, registrationSuccess, registeredEmail } = useAppSelector(state => ({
    currentLocale: state.locale.currentLocale,
    loading: state.register.loading,
    registrationSuccess: state.register.registrationSuccess,
    registeredEmail: state.register.registeredEmail,
  }));
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<SignUpFormValues>({});

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );

  useEffect(() => {
    if (registrationSuccess) {
      showSuccessToast('Registration successful.', 'registrationSuccess', 500);
      navigate('/verify-email', { state: { email: registeredEmail } });
    }
  }, [registrationSuccess]);

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit: SubmitHandler<SignUpFormValues> = (data: SignUpFormValues) => {
    dispatch(
      handleRegister({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        langKey: currentLocale,
      })
    );
  };

  return (
    <>
      <Container className="py-lg-5 py-md-3 h-100">
        <Row className="align-items-center justify-content-evenly h-100">
          <Col className="col-12 col-lg-4">
            <AccountSideImageSection />
          </Col>

          <Col className={`col-12 col-lg-5 ${styles.formSection}`}>
            <Row>
              <Col className={`${styles.signUpTitle} text-center pb-4`}>Sign Up</Col>
            </Row>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col className="mb-3">
                  <AppInput
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    placeholder={'First Name'}
                    register={register('firstName', {
                      required: 'First name is required',
                      minLength: {
                        value: 2,
                        message: 'This field must contain at least 2 characters',
                      },
                      maxLength: {
                        value: 15,
                        message: 'You have exceeded the maximum number of 15 characters in this field',
                      },
                      pattern: {
                        value: noSpecialCharsNoNumbersNoSpacesRegex,
                        message:
                          'Entered value should contain only letters (uppercase/ lowercase), hyphens, and apostrophes. Spaces, special characters and numbers are not allowed.',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <AppInput
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    placeholder={'Last Name'}
                    register={register('lastName', {
                      required: 'Last name is required',
                      minLength: {
                        value: 2,
                        message: 'This field must contain at least 2 characters',
                      },
                      maxLength: {
                        value: 15,
                        message: 'You have exceeded the maximum number of 15 characters in this field',
                      },
                      pattern: {
                        value: noSpecialCharsNoNumbersNoSpacesRegex,
                        message:
                          'Entered value should contain only letters (uppercase/ lowercase), hyphens, and apostrophes. Spaces, special characters and numbers are not allowed.',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <AppInput
                    id="email"
                    name="email"
                    label="Email"
                    placeholder={'oliveroueen@mail.com'}
                    register={register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <AppInput
                    id="password"
                    name="password"
                    label="Password"
                    placeholder={'Password'}
                    type={showPassword ? 'text' : 'password'}
                    icon={!showPassword ? BsEyeSlash : BsEye}
                    onIconClick={() => setShowPassword(!showPassword)}
                    register={register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 8,
                        message:
                          'Your password must be at least 8 characters long, contain at least one number, special character, and have a mix of uppercase and lowercase letters.',
                      },
                      pattern: {
                        value: passwordRegex,
                        message:
                          'Your password must be at least 8 characters long, contain at least one number, special character, and have a mix of uppercase and lowercase letters.',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-4">
                  <AppInput
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder={'Confirm password'}
                    type={showConfirmPassword ? 'text' : 'password'}
                    icon={!showConfirmPassword ? BsEyeSlash : BsEye}
                    onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    register={register('confirmPassword', {
                      required: 'Confirm password is required',
                      validate: value => value === password.current || 'The passwords do not match',
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mb-4">
                <Col className="col-auto">
                  <AppCheckbox
                    checked={acceptedTermsAndConditions}
                    label={
                      <div className={`${styles.termsTextStyles}`}>
                        <span>Accept</span>
                        <Link to={'#'}>
                          <span className={`${styles['termsTextStyles--one']} ms-1`}>Terms</span>
                        </Link>
                        <span className="ms-1">and</span>
                        <Link to={'#'}>
                          <span className={`${styles['termsTextStyles--one']} ms-1`}>Conditions</span>
                        </Link>
                      </div>
                    }
                    onChange={checked => setAcceptedTermsAndConditions(checked)}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="mb-4">
                  <AppButton type="submit" text={'Sign Up'} disabled={!acceptedTermsAndConditions} loading={loading} />
                </Col>
              </Row>

              <Row className="justify-content-center text-center mb-4">
                <Col className="col-auto">
                  <span className={`${styles.signUpTextStyleTwo}`}>Already have an account?</span>
                  <span className={`${styles.signUpTextStyleOne} cursor-pointer ms-1`} onClick={() => navigate('/login')}>
                    Sign In
                  </span>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignUp;
