// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VziPQtLiMzfTIN354vyz {
  border-radius: 8px;
  background-color: var(--card-color);
}
.VziPQtLiMzfTIN354vyz .wxEdsW_5BNkKJCzK7TSZ {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: var(--text-secondary-color);
  border-bottom: 1px solid var(--line-color);
  padding: 14px 35px;
}

.gZ6abiHrm4DszqB55Ci2 {
  background-color: rgba(0, 0, 0, 0.13);
  margin-left: -125px;
  margin-right: -81px;
}
.gZ6abiHrm4DszqB55Ci2 .wxEdsW_5BNkKJCzK7TSZ {
  font-size: 12px;
  margin-left: 120px;
  font-weight: 400;
  line-height: 12px;
  color: #ffffff;
  padding: 14px 35px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/profiel-views/list-views/nav-profile-list-item.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mCAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kCAAA;EACA,0CAAA;EACA,kBAAA;AACJ;;AAGA;EACE,qCAAA;EACA,mBAAA;EACA,mBAAA;AAAF;AAEE;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AAAJ","sourcesContent":[".listCard {\n  border-radius: 8px;\n  background-color: var(--card-color);\n\n  .subItem {\n    font-size: 10px;\n    font-weight: 400;\n    line-height: 12px;\n    color: var(--text-secondary-color);\n    border-bottom: 1px solid var(--line-color);\n    padding: 14px 35px;\n  }\n}\n\n.mobileListCard {\n  background-color: rgba(0, 0, 0, 0.13);\n  margin-left: -125px;\n  margin-right: -81px;\n\n  .subItem {\n    font-size: 12px;\n    margin-left: 120px;\n    font-weight: 400;\n    line-height: 12px;\n    color: #ffffff;\n    padding: 14px 35px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listCard": `VziPQtLiMzfTIN354vyz`,
	"subItem": `wxEdsW_5BNkKJCzK7TSZ`,
	"mobileListCard": `gZ6abiHrm4DszqB55Ci2`
};
export default ___CSS_LOADER_EXPORT___;
