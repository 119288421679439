import { useAppDispatch } from 'app/config/store';
import { updateEntityStartCourse } from 'app/entities/course-enrollment/course-enrollment.reducer';
import UserAvatarWithText from 'app/shared/components/image-with-text/UserImageWithText';
import useIsAdmin from 'app/shared/hooks/useIsAdmin';
import { ICourseEnrollment } from 'app/shared/model/course-enrollment.model';
import { ICourseDetails } from 'app/shared/model/dto/course-details.model';
import { ECourseType } from 'app/shared/model/enumerations/course-type.model';
import { EFieldOfStudy } from 'app/shared/model/enumerations/field-of-study-type.model';
import AppBadgeWithLabel from 'app/shared/ui-elements/app-badge-with-label/AppBadgeWithLabel';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppMiniPopUp from 'app/shared/ui-elements/app-mini-popup/app-mini-pop-up';
import AppProgressBar from 'app/shared/ui-elements/app-progress-bar/app-progress-bar';
import DeleteModel from 'app/shared/ui-elements/models/app-delete-model/DeleteModel';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styles from './course-card.module.scss';

interface IProps {
  course: ICourseDetails;
  status?: ICourseEnrollment;
  inCourse?: boolean;
  onClick?: () => void;
  filterData?: {
    page: number;
    fieldOfStudy: number;
    searchText: string;
    difficultyLevel: number;
  };
}

const CourseCard = (props: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const [deleteModelState, setDeleteModelState] = useState(null);
  const [collapse, setCollapse] = useState<boolean>(false);
  const [type, setType] = useState<string>('');

  const field = EFieldOfStudy[props.course.fieldOfStudy];

  useEffect(() => {
    const courseType = isAdmin ? ECourseType[props.course.type] : ECourseType[props.course.courseType];
    setType(
      courseType &&
        courseType
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
    );
  }, []);

  const handleItemClick = (path: string) => {
    const data = {
      id: props.course.id,
      title: props.course.name,
      warningMessage: 'This operation is irreversible. Are you sure you want to proceed?',
      courseId: props.course.id,
      show: true,
      modelType: 'COURSE',
      filterData: {
        page: props.filterData.page,
        fieldOfStudy: props.filterData.fieldOfStudy,
        searchText: props.filterData.searchText,
        difficultyLevel: props.filterData.difficultyLevel,
      },
    };

    path === 'delete' ? openDeleteModel(data) : navigate(path + `/${props.course.id}`);
  };

  const handleCourseStart = () => {
    dispatch(updateEntityStartCourse(props.course.id));
    props.onClick && props.onClick();
  };

  const openDeleteModel = data => {
    setDeleteModelState(data);
  };

  const closeDeleteModel = () => {
    setDeleteModelState(ps => {
      return {
        ...ps,
        show: false,
      };
    });
  };

  return (
    <Card className={`${styles.courseCard} cursor-pointer`}>
      <Row>
        <Col md={5} lg={3}>
          <Card.Img
            className={`${styles.cardImg}`}
            variant="top"
            src={isAdmin ? props.course.coverImageUrl : props.course.courseCoverImageUrl}
          />
        </Col>
        <Col className="d-flex justify-content-center align-items-center">
          <Card.Body className="m-3 ms-lg-0">
            <Row>
              <Col>
                <Row className="d-flex align-items-center">
                  {/* <Col className={`col-auto ${styles.cardBadge}`}>
                    <AppBadge label={type} variant="secondary" />
                  </Col> */}
                  <Col>
                    <AppBadgeWithLabel label="Subject" badgeLabel={field} />
                  </Col>
                </Row>
                <Card.Title className={`mt-2 ${styles.cardTitle}`}>{isAdmin ? props.course.name : props.course.courseName}</Card.Title>
                <Row className="mt-2">
                  {!props.inCourse ? (
                    <Col sm={6}>
                      <UserAvatarWithText
                        imagePath={props.course.instructorProfilePicture || '../././././content/images/default-profile.png'}
                        userName={props.course.instructorName}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className="align-items-center">
                  {!isAdmin ? (
                    props.status.userCourseStatus === 'IN_PROGRESS' ? (
                      <>
                        <AppProgressBar progress={props.course.userCompletedPercentage} />
                      </>
                    ) : props.status.userCourseStatus === 'COMPLETED' ? (
                      <div className="d-flex mt-2">
                        <p className={`${styles.completeText} pe-2 mb-0`}>Completed</p>
                        <span>{moment(props.status.userCompletedDate).format('Do MMMM YYYY')}</span>
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>
              {!props.inCourse && <Col xs={1} className={`d-none d-lg-block ${styles.separator}`}></Col>}
              <Col lg={3} className="mt-3 mt-lg-0 align-self-center">
                <Row>
                  {!isAdmin ? (
                    props.status.userCourseStatus === 'IN_PROGRESS' ? (
                      <Col xs={7} sm={4} lg={10} xl={8} className="ms-0 ms-lg-4 ms-xl-5">
                        <AppButton
                          text={'Continue'}
                          btnSize="md"
                          onClick={() => {
                            props.onClick && props.onClick();
                          }}
                        />
                      </Col>
                    ) : props.status.userCourseStatus === 'COMPLETED' ? (
                      <Col xs={8} sm={6} lg={12}>
                        <AppButton btnSize="md" text="View" onClick={props.onClick} />
                      </Col>
                    ) : props.status.userCourseStatus === 'NOT_STARTED' ? (
                      !props.inCourse && (
                        <>
                          <Col xs={6} sm={4} lg={8} className="ms-0 ms-lg-5">
                            <AppButton
                              text={'Start'}
                              btnSize="md"
                              onClick={handleCourseStart}
                              disabled={new Date(props.status.courseStartDate) > new Date() ? true : false}
                            />
                          </Col>
                        </>
                      )
                    ) : (
                      <></>
                    )
                  ) : (
                    !props.inCourse && (
                      <>
                        <Col xs={6} sm={4} lg={8} className="ms-0 ms-lg-4">
                          <AppButton text={'View'} btnSize="md" onClick={() => props.onClick && props.onClick()} />
                        </Col>
                        <Col lg={'auto'} className={`${styles.viewMoreIcon} cursor-pointer align-self-center`}>
                          <div className="position-relative">
                            <BsThreeDotsVertical
                              className="cursor-pointer d-flex justify-content-center"
                              color="var(--icon-color)"
                              size={16}
                              onClick={() => {
                                setCollapse(!collapse);
                              }}
                            />
                            <AppMiniPopUp
                              key={props.course.id}
                              className="pop-up"
                              items={[
                                {
                                  text: 'Edit Course',
                                  onClick() {
                                    handleItemClick('course-add');
                                  },
                                },
                                {
                                  text: 'Delete Course',
                                  onClick() {
                                    handleItemClick('delete');
                                  },
                                },
                              ]}
                            />
                          </div>
                        </Col>
                      </>
                    )
                  )}
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
      <DeleteModel data={deleteModelState} closeHandler={closeDeleteModel} />
    </Card>
  );
};

export default CourseCard;
