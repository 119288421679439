// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zF4srF7Zrk_4PHUi9oo0 {
  width: 100%;
  border: 1px solid var(--line-color);
  border-radius: 12px;
  height: 40px;
  background-color: var(--card-color);
}
.zF4srF7Zrk_4PHUi9oo0 .sdTQJTVXReokvI_U5aQp,
.zF4srF7Zrk_4PHUi9oo0 .sdTQJTVXReokvI_U5aQp:focus {
  width: 100%;
  border-style: none;
  outline: none;
  height: 38px;
  font-size: 14px;
  padding-top: 4px;
  margin-left: 10px;
  padding-left: 8px;
  background-color: var(--card-color);
  color: var(--text-primary-color);
}
.zF4srF7Zrk_4PHUi9oo0 .sdTQJTVXReokvI_U5aQp::-moz-placeholder, .zF4srF7Zrk_4PHUi9oo0 .sdTQJTVXReokvI_U5aQp:focus::-moz-placeholder {
  font-size: 16px;
  color: var(--text-secondary-color);
}
.zF4srF7Zrk_4PHUi9oo0 .sdTQJTVXReokvI_U5aQp::placeholder,
.zF4srF7Zrk_4PHUi9oo0 .sdTQJTVXReokvI_U5aQp:focus::placeholder {
  font-size: 16px;
  color: var(--text-secondary-color);
}
.zF4srF7Zrk_4PHUi9oo0 .gCwMYBFagrTZGAtR0vU0 {
  margin: -1px;
  width: 72px !important;
  border-radius: 12px !important;
  height: 40px !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-search/app-search.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mCAAA;EACA,mBAAA;EACA,YAAA;EACA,mCAAA;AACF;AACE;;EAEE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mCAAA;EACA,gCAAA;AACJ;AACI;EACE,eAAA;EACA,kCAAA;AAEN;AAJI;;EACE,eAAA;EACA,kCAAA;AAEN;AAEE;EACE,YAAA;EACA,sBAAA;EACA,8BAAA;EACA,uBAAA;AAAJ","sourcesContent":[".searchWrapper {\n  width: 100%;\n  border: 1px solid var(--line-color);\n  border-radius: 12px;\n  height: 40px;\n  background-color: var(--card-color);\n\n  .searchInput,\n  .searchInput:focus {\n    width: 100%;\n    border-style: none;\n    outline: none;\n    height: 38px;\n    font-size: 14px;\n    padding-top: 4px;\n    margin-left: 10px;\n    padding-left: 8px;\n    background-color: var(--card-color);\n    color: var(--text-primary-color);\n\n    &::placeholder {\n      font-size: 16px;\n      color: var(--text-secondary-color);\n    }\n  }\n\n  .searchBtn {\n    margin: -1px;\n    width: 72px !important;\n    border-radius: 12px !important;\n    height: 40px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": `zF4srF7Zrk_4PHUi9oo0`,
	"searchInput": `sdTQJTVXReokvI_U5aQp`,
	"searchBtn": `gCwMYBFagrTZGAtR0vU0`
};
export default ___CSS_LOADER_EXPORT___;
