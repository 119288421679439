import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ICourse, defaultValue } from 'app/shared/model/course.model';
import { EntityState, IQueryParams, createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

const initialState: EntityState<ICourse> = {
  loading: false,
  errorMessage: null,
  entities: [],
  specificCourseDetails: [],
  couponsEligibleCourses: [],
  entitiesDue: [],
  courseListWithEnrollment: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/public/courses';
const apiUrlInsert = 'api/admin/courses';
const apiUrlUpdate = 'api/admin/courses';
const apiUrlRegOpen = 'api/public/courses-open-for-registration';
const apiUrlDue = 'api/public/courses-due';
const apiUrlAdmin = 'api/admin/courses';
const apiUrlWithEnrollment = 'api/courses';

// Actions

export const getEntities = createAsyncThunk('course/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICourse[]>(requestUrl);
});

export const getEntitiesRegOpen = createAsyncThunk('course/fetch_entity_list_reg_open', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrlRegOpen}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICourse[]>(requestUrl);
});

export const getEntitiesDue = createAsyncThunk('course/fetch_entity_list_due', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrlDue}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ICourse[]>(requestUrl);
});

export const getAllEntities = createAsyncThunk(
  'course/fetch_all_entity_list',
  async ({ searchText, fieldOfStudy, difficultyLevel, sort, isPaid, minPrice, maxPrice, size, page }: IQueryParams) => {
    if (!sort) {
      sort = 'name,asc';
    }
    const filterParams = {
      searchText,
      fieldOfStudy,
      difficultyLevel,
      sort,
      isPaid,
      minPrice,
      maxPrice,
      page,
      size,
    };

    const filterQueryString = Object.keys(filterParams)
      .filter(key => filterParams[key] !== undefined && filterParams[key] !== null)
      .map(key => `${key}=${encodeURIComponent(filterParams[key])}`)
      .join('&');

    const requestUrl = `${apiUrl}?${filterQueryString}`;
    return axios.get<ICourse[]>(requestUrl);
  }
);

export const getAllEntitiesWithEnrollment = createAsyncThunk(
  'course/fetch_all_entity_with_enrollment_list',
  async ({ searchText, fieldOfStudy, difficultyLevel, isPaid, minPrice, maxPrice, sort, size, page }: IQueryParams) => {
    if (!sort) {
      sort = 'name,asc';
    }
    const filterParams = {
      searchText,
      fieldOfStudy,
      difficultyLevel,
      sort,
      isPaid,
      minPrice,
      maxPrice,
      page,
      size,
    };

    const filterQueryString = Object.keys(filterParams)
      .filter(key => filterParams[key] !== undefined && filterParams[key] !== null)
      .map(key => `${key}=${encodeURIComponent(filterParams[key])}`)
      .join('&');

    const requestUrl = `${apiUrlWithEnrollment}?${filterQueryString}`;
    return axios.get<ICourse[]>(requestUrl);
  }
);

export const getAllEntitiesAdmin = createAsyncThunk(
  'course/fetch_all_entity_list_admin',
  async ({ searchText, fieldOfStudy, difficultyLevel, draft, sort, size, page }: IQueryParams) => {
    const filterParams = {
      searchText,
      fieldOfStudy,
      difficultyLevel,
      draft,
      sort,
      page,
      size,
    };

    const filterQueryString = Object.keys(filterParams)
      .filter(key => filterParams[key] !== undefined && filterParams[key] !== null)
      .map(key => `${key}=${encodeURIComponent(filterParams[key])}`)
      .join('&');

    const requestUrl = `${apiUrlAdmin}?${filterQueryString}`;
    return axios.get<ICourse[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'course/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ICourse>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getEntityWithEnrollment = createAsyncThunk(
  'course/fetch_enrollment_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrlWithEnrollment}/${id}`;
    return axios.get<ICourse>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'course/create_entity',
  async (entity: ICourse, thunkAPI) => {
    const result = await axios.post<ICourse>(apiUrlInsert, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'course/update_entity',
  async (entity: ICourse, thunkAPI) => {
    const result = await axios.put<ICourse>(`${apiUrlUpdate}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'course/partial_update_entity',
  async (entity: ICourse, thunkAPI) => {
    const result = await axios.patch<ICourse>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteCourse = createAsyncThunk(
  'course/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlAdmin}/${id}`;
    const result = await axios.delete<ICourse>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getSpecificCourseDetails = createAsyncThunk(
  'course/fetch_specific_course_details',
  async (data: { draft?: boolean; isPaid?: boolean; sort?: string; page: number; size: number; active: boolean }) => {
    const requestUrl = `${apiUrlAdmin}/coupon-eligible?draft=${data.draft}&isPaid=${data.isPaid}&sort=${data.sort}&page=${data.page}&size=${data.size}&active=${data.active}`;
    return axios.get<ICourse[]>(requestUrl);
  }
);

export const getAllCoursesForCouponCreation = createAsyncThunk(
  'course/fetch_courses_eligible_for_coupons',
  async (data: { sort: 'ASC' | 'DESC' }) => {
    const requestUrl = `${apiUrlAdmin}/coupon-eligible?&sort=${data.sort}`;
    return axios.get<ICourse[]>(requestUrl);
  }
);

// slice

export const CourseSlice = createEntitySlice({
  name: 'course',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityWithEnrollment.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteCourse.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getSpecificCourseDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.specificCourseDetails = action.payload.data;
      })
      .addCase(getSpecificCourseDetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getAllCoursesForCouponCreation.fulfilled, (state, action) => {
        state.loading = false;
        state.couponsEligibleCourses = action.payload.data;
      })
      .addCase(getAllCoursesForCouponCreation.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(getEntities, getAllEntities, getAllEntitiesAdmin, getEntitiesRegOpen), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesDue), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entitiesDue: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getAllEntitiesWithEnrollment), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          courseListWithEnrollment: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(
        isPending(
          createEntity,
          getEntities,
          getEntity,
          getEntityWithEnrollment,
          getAllEntities,
          getAllEntitiesAdmin,
          getEntitiesRegOpen,
          getEntitiesDue,
          getSpecificCourseDetails,
          getAllCoursesForCouponCreation,
          getAllEntitiesWithEnrollment
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.loading = true;
        }
      )
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteCourse), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = CourseSlice.actions;

// Reducer
export default CourseSlice.reducer;
