import AppButton from 'app/shared/ui-elements/app-button/app-button';
import React from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styles from './JoinTheTeam.module.scss';

const JoinTheTeam: React.FC = () => {
  return (
    <div className="fade-in">
      <Row>
        <Col>
          <Container className={`d-flex justify-content-center align-items-center px-2 py-5`}>
            <Row className={`${styles.joinTheTeamSection1} py-5 d-flex align-items-center`}>
              <Col className="px-2">
                <Row className="d-flex align-items-center justify-content-center">
                  <Col className="col-12 col-lg-6 align-items-center">
                    <h2 className={`${styles.joinTheTeamSection1__text1} text-center text-lg-start mt-5 fw-bold`}>Join the Team</h2>
                    <h2 className={`${styles.joinTheTeamSection1__text2} text-center text-lg-start mb-3 fw-bold`}>
                      Shape your career with the Skill Surf team!
                    </h2>
                    <p className={`${styles.joinTheTeamSection1__para} text-center text-lg-start fw-normal`}>
                      We at Skill Surf are reinventing how educational content is delivered! Courses are outcome-oriented, combining strong
                      fundamentals and hands-on experience.
                    </p>
                    <p className={`${styles.joinTheTeamSection1__para} text-center text-lg-start fw-normal`}>
                      We started by organising free workshops conducted by a group of undergraduates, fresh graduates, and lecturers at
                      ENTC. We are currently conducting several courses related to the main focus areas of the Internet of Things, Computer
                      Architecture, and Cybersecurity.
                    </p>
                    <p className={`${styles.joinTheTeamSection1__para} text-center text-lg-start fw-normal`}>
                      Now that we look forward to expanding our scope, we need highly skilled lecturers/instructors on our team who have a
                      passion for teaching and help us empower Sri Lankans to be industry and life ready!
                    </p>
                  </Col>
                  <Col className="col-12 col-lg-6 d-flex justify-content-center">
                    <img className="img-fluid" src={'./../../../../content/images/career_1.webp'} alt="career" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <section className={` ${styles.joinTheTeamSection2} py-5 px-2`}>
            <Row>
              <Col>
                <Container>
                  <Row className="align-items-center d-flex py-4">
                    <Col className="col-12 col-md-8 col-lg-9">
                      <p className={`${styles.joinTheTeamSection2__text1}  mb-1 text-center text-md-start fw-normal`}>
                        If you share the same passion, please fill this Google Form and apply to be a part of our team!
                      </p>
                    </Col>
                    <Col className="col-12 col-md-4 col-lg-3 mt-3 mt-md-0 d-flex d-md-block justify-content-center">
                      <Row>
                        <Col className="col-auto">
                          <a href="https://forms.gle/SYj4TjG6H8Lb2gnz6" target={'_blank'} rel="noreferrer">
                            <AppButton
                              className={`${styles.joinTheTeamSection2__btn1} px-5 text-nowrap`}
                              text="Google Form"
                              btnSize="sm"
                              btnType="bordered"
                            />
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </section>

          <Container>
            <section className={`d-flex justify-content-center align-items-center px-2`}>
              <Row className={`${styles.joinTheTeamSection3} py-2 mt-4 `}>
                <Col>
                  <h2 className={`${styles.joinTheTeamSection3__text1}  text-center my-4 mb-5 mt-5`}>
                    Main Expectations from Skill Surf Lecturers
                  </h2>
                  <Row className="mt-4 mt-sm-4 mt-md-4 mt-lg-4 justify-content-between mx-1 py-3 pb-5">
                    <Col className="col-12 col-sm-6 col-md-6 col-lg-3">
                      <Row>
                        <Col className="d-flex justify-content-center justify-content-sm-start">
                          <img
                            src={'./../../../../content/images/strong_technical_background.webp'}
                            alt="Strong_Technical_Background"
                            width={80}
                          />
                        </Col>
                      </Row>

                      <h5 className={`${styles.joinTheTeamSection3__text2} text-center text-sm-start mt-2`}>Strong Technical Background</h5>
                      <p className={`${styles.joinTheTeamSection3__text3} text-center text-sm-start fw-normal`}>
                        A deep understanding of the subject matter, together with strong fundamentals and hands-on experience.
                      </p>
                    </Col>

                    <Col className="col-12 col-sm-6 col-md-6 col-lg-3 pt-4 pt-sm-0">
                      <Row>
                        <Col className="d-flex justify-content-center justify-content-sm-start">
                          <img
                            src={'./../../../../content/images/excellent_communication_skills.webp'}
                            alt="Excellent_Communication_Skills"
                            width={80}
                          />
                        </Col>
                      </Row>

                      <h5 className={`${styles.joinTheTeamSection3__text2} text-center text-sm-start mt-2`}>
                        Excellent Communication Skills
                      </h5>
                      <p className={`${styles.joinTheTeamSection3__text3} text-center text-sm-start fw-normal`}>
                        Ability to clearly and effectively communicate the subject matters to students and inspire students to learn.
                      </p>
                    </Col>

                    <Col className="col-12 col-sm-6 col-md-6 col-lg-3 pt-4 pt-lg-0">
                      <Row>
                        <Col className="d-flex justify-content-center justify-content-sm-start">
                          <img src={'./../../../../content/images/commitment_to_the_cause.webp'} alt="Commitment_to_the_Cause" width={80} />
                        </Col>
                      </Row>

                      <h5 className={`${styles.joinTheTeamSection3__text2} text-center text-sm-start mt-2`}>Commitment to the Cause</h5>
                      <p className={`${styles.joinTheTeamSection3__text3} text-center text-sm-start fw-normal`}>
                        Pay attention to detail to deliver the best outcome and ensure student expectations are exceeded.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>
          </Container>

          <Row className={`${styles.joinTheTeamSection4} py-5 mt-5 px-2`}>
            <Col>
              <Row className="align-items-center d-flex justify-content-center">
                <Col className="col-12 col-md-6 col-lg-5 d-flex justify-content-center">
                  <p className={`${styles.joinTheTeamSection4__text1}  fw-normal mb-1 text-center text-md-start`}>
                    View the promotional flyer for more details
                  </p>
                </Col>
                <Col className="col-12 col-md-3 col-lg-2 mt-3 mt-md-0 d-flex justify-content-center">
                  <Row>
                    <Col>
                      <AppButton className={`${styles.joinTheTeamSection4__btn1} px-5 text-nowrap`} text="VIEW FLYER" btnSize="sm" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Container>
            <Row className={`${styles.joinTheTeamSection5} mb-4 px-2`}>
              <Col>
                <p className={`${styles.joinTheTeamSection5__text1} text-center mt-5`}>
                  For any questions/clarifications, reach out to us through our email&nbsp;
                  <a className={`${styles.joinTheTeamSection5__link} href="mailto:skillsurflk@gmail.com`}>skillsurflk@gmail.com</a> or
                  social media.
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default JoinTheTeam;
