import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import styles from './pagination.module.scss';

interface IProps {
  length: number;
  itemsPerPage: number;
  currentPage: number;
  updateCurrentPage: (currentPageNumber: number) => void;
}

const Pagination = (props: IProps) => {
  const { itemsPerPage, length, currentPage, updateCurrentPage: onPageChange } = props;
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const [firstPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const pagesToShow = 7;

  useEffect(() => {
    const totalPages = length % itemsPerPage === 0 ? length / itemsPerPage : Math.floor(length / itemsPerPage) + 1;
    setTotalNumberOfPages(totalPages);
    setLastPage(totalPages);
  }, [length, itemsPerPage]);

  const handleClick = page => {
    if (page >= firstPage && page <= lastPage) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalNumberOfPages <= pagesToShow) {
      for (let i = firstPage; i <= lastPage; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= pagesToShow - 3) {
        for (let i = firstPage; i <= pagesToShow - 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('ellipsis');
        pageNumbers.push(lastPage - 1);
        pageNumbers.push(lastPage);
      } else if (currentPage >= lastPage - (pagesToShow - 4)) {
        pageNumbers.push(firstPage);
        pageNumbers.push(firstPage + 1);
        pageNumbers.push('ellipsis');
        for (let i = lastPage - (pagesToShow - 3); i <= lastPage; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(firstPage);
        pageNumbers.push('ellipsis');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('ellipsis');
        pageNumbers.push(lastPage);
      }
    }
    return pageNumbers.map((number, index) => (
      <Col
        key={index}
        className={`col-auto d-none ${number !== 'ellipsis' && 'cursor-pointer'} d-sm-flex justify-content-center align-items-center ${
          currentPage === number ? styles.activePage : styles.page
        }`}
        onClick={() => handleClick(number)}
      >
        <p className={`${currentPage === number ? `text-white` : styles.pageText} mb-0 ${styles.paginationButton}`}>
          {number === 'ellipsis' ? '...' : number}
        </p>
      </Col>
    ));
  };

  return length > 0 ? (
    <Row className={`align-items-center justify-content-center gap-2 mt-4`}>
      <Col className="col-auto px-0">
        <div
          className={`${currentPage !== 1 && 'cursor-pointer'} ${styles.page} d-flex justify-content-center align-items-center`}
          onClick={() => handleClick(currentPage - 1)}
        >
          <BsChevronLeft color="#0092d0" size={12} />
        </div>
      </Col>

      {renderPageNumbers()}

      <Col className="col-auto px-0">
        <div
          className={`${currentPage !== totalNumberOfPages && 'cursor-pointer'} ${
            styles.page
          } d-flex justify-content-center align-items-center`}
          onClick={() => {
            handleClick(currentPage + 1);
          }}
        >
          <BsChevronRight size={12} color="#0092d0" />
        </div>
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default Pagination;
