import { format } from 'date-fns';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { BsCalendar3, BsChevronRight } from 'react-icons/bs';
import styles from './app-date-picker.module.scss';
import './date-picker.scss';
interface IProps {
  selectedDate: Date;
  dateFormat?: string;
  onChange: (date: Date) => void;
  monthYearPicker?: boolean;
  yearPicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isInput?: boolean;
  label?: string;
  timePicker?: boolean;
  timePeriod?: boolean;
}

const AppDatePicker = (props: IProps) => {
  const {
    dateFormat = 'yyyy MMMM',
    selectedDate,
    monthYearPicker,
    yearPicker,
    minDate,
    maxDate,
    onChange,
    isInput,
    label,
    timePicker,
    timePeriod,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (date: Date) => {
    setIsOpen(!isOpen);
    onChange(date);
  };

  const previousMonth = () => {
    if (yearPicker) {
      onChange(new Date(selectedDate.setFullYear(selectedDate.getFullYear() - 1)));
      return;
    }
    onChange(new Date(selectedDate.setMonth(selectedDate.getMonth() - 1)));
  };

  const nextMonth = () => {
    if (yearPicker) {
      onChange(new Date(selectedDate.setFullYear(selectedDate.getFullYear() + 1)));
      return;
    }
    onChange(new Date(selectedDate.setMonth(selectedDate.getMonth() + 1)));
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={styles.label}>{label}</div>
      <div className={'month-selection'}>
        <Row className="justify-content-between align-items-center flex-nowrap w-100">
          {isInput ? (
            <>
              <Col className="col-auto px-1">
                <div>{format(selectedDate, `yyyy/MM/dd ${timePeriod ? 'hh.mm aaa' : 'HH.mm'}`)}</div>
              </Col>
              <Col className="col-auto px-1">
                <BsCalendar3 size={24} onClick={handleClick} color="var(--icon-color)" style={{ cursor: 'pointer' }} />
              </Col>
            </>
          ) : (
            <>
              {minDate && selectedDate.getMonth() === minDate.getMonth() ? (
                <></>
              ) : (
                <Col className="col-auto px-1">
                  <BsChevronRight style={{ cursor: 'pointer' }} onClick={previousMonth} />
                </Col>
              )}
              <Col className="text-center cursor-pointer font-size-10">
                <div onClick={handleClick}>{format(selectedDate, dateFormat)}</div>
              </Col>
              {maxDate && selectedDate.getMonth() === maxDate.getMonth() ? (
                <></>
              ) : (
                <Col className="col-auto px-1">
                  <BsChevronRight style={{ cursor: 'pointer' }} onClick={nextMonth} />
                </Col>
              )}
            </>
          )}
        </Row>
      </div>
      <div className="position-relative">
        <div className={`${styles.datePicker} ${isInput && styles.isInput}`}>
          {isOpen && (
            <DatePicker
              selected={selectedDate}
              onChange={handleChange}
              inline
              onClickOutside={handleClick}
              showMonthYearPicker={monthYearPicker}
              showYearPicker={yearPicker}
              calendarStartDay={1}
              minDate={minDate}
              maxDate={maxDate}
              showTimeSelect={timePicker}
              timeFormat="HH:mm"
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AppDatePicker;
