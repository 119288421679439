export interface IUserProfile {
  id?: number;
  userId?: number;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  address?: string | null;
  profilePicture?: string | null;
  bio?: string | null;
}

export const defaultValue: Readonly<IUserProfile> = {};
