import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import MainLoader from 'app/shared/components/main-loader/main-loader';
import NoticeView from 'app/shared/components/notice-views/notice-view';
import VideoPlayer from 'app/shared/components/video-player/video-player';
import useIsAdmin from 'app/shared/hooks/useIsAdmin';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppExternalResourcePresenter from 'app/shared/ui-elements/app-external-resource-presenter/app-external-resource-presenter';
import AppUploadedMaterialPresenter from 'app/shared/ui-elements/app-uploaded-material-presenter/app-uploaded-material-presenter';
import { CHAPTER_CONTENT_TYPES } from 'app/shared/util/dnd-utils';
import DOMPurify from 'dompurify';
import React, { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ICourseChapterPresenter } from './course-chapter-presenter.model';
import {
  CourseChapterPresenterEntityState,
  getAdminEntityPreview,
  getEntity,
  nextChapterHandler,
  previousChapterHandler,
  recordUserProgress,
} from './course-chapter-presenter.reducer';

const CourseChapterPresenter: FC = () => {
  const params = useParams<{ enrollmentId: string; courseId: string; lessonId: string; unitId: string }>();
  const isAdmin = useIsAdmin();
  const dispatch = useAppDispatch();

  const { loading, courseChapterPresenterEntity, courseChapterPresenter } = useAppSelector((state: IRootState) => ({
    loading: state.courseChapterPresenter.loading,
    courseChapterPresenterEntity: state.courseChapterPresenter.entity as ICourseChapterPresenter,
    courseChapterPresenter: state.courseChapterPresenter as CourseChapterPresenterEntityState<ICourseChapterPresenter>,
  }));

  const showCompleteButton = courseChapterPresenter.currentChapterIndex === courseChapterPresenterEntity.chapters?.length - 1;
  const isCompleteButtonDisabled =
    courseChapterPresenterEntity.chapters[courseChapterPresenterEntity.chapters?.length - 1]?.completed ||
    courseChapterPresenterEntity.chapters?.length - courseChapterPresenterEntity.chapters?.filter(c => c.completed).length !== 1;

  useEffect(() => {
    if (isAdmin) {
      dispatch(
        getAdminEntityPreview({ courseId: +params.courseId, lessonId: +params.lessonId, unitId: params.unitId ? +params.unitId : null })
      );
    } else {
      dispatch(
        getEntity({ enrollmentId: +params.enrollmentId, lessonId: +params.lessonId, unitId: params.unitId ? +params.unitId : null })
      );
    }
  }, []);

  const onClickPreviousChapter = () => {
    dispatch(previousChapterHandler());
  };

  const onClickNextChapter = (chapterId: number) => {
    if (isAdmin) {
      dispatch(nextChapterHandler());
    } else {
      dispatch(
        recordUserProgress({
          enrollmentId: +params.enrollmentId,
          chapterId,
        })
      );
    }
  };

  const getContent = (content: any) => {
    if (content.type === CHAPTER_CONTENT_TYPES.CHAPTER_EXTERNAL_RESOURCE) {
      return <AppExternalResourcePresenter resourceLinks={content.contentData.resourceLinks} />;
    } else if (content.type === CHAPTER_CONTENT_TYPES.CHAPTER_MATERIAL) {
      return <AppUploadedMaterialPresenter materials={content.contentData.materials} />;
    } else if (content.type === CHAPTER_CONTENT_TYPES.CHAPTER_TEXT_CONTENT) {
      const sanitizedContent = DOMPurify.sanitize(content.contentData.content);
      return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
    } else if (content.type === CHAPTER_CONTENT_TYPES.CHAPTER_VIDEO) {
      return <VideoPlayer fileName={content.contentData.url} />;
    }
  };

  return (
    <Row>
      <Col>
        {courseChapterPresenterEntity && courseChapterPresenterEntity.chapters.length > 0 ? (
          <React.Fragment>
            {courseChapterPresenterEntity.chapters &&
              courseChapterPresenterEntity.chapters[courseChapterPresenter.currentChapterIndex]?.contents.map(
                (content: any, index: React.Key) => {
                  return (
                    <Row key={index}>
                      <Col className="mb-3">{getContent(content)}</Col>
                    </Row>
                  );
                }
              )}

            <Row className="justify-content-between mt-3 mb-5">
              <Col className="col-12 col-sm-6 col-md-3">
                <AppButton
                  text="Previous"
                  btnSize="md"
                  type="button"
                  btnType="bordered"
                  disabled={courseChapterPresenter.currentChapterIndex === 0}
                  onClick={onClickPreviousChapter}
                />
              </Col>
              <Col className="col-12 col-sm-6 col-md-3 mt-2 mt-sm-0">
                <AppButton
                  text={showCompleteButton ? 'Complete' : 'Next'}
                  btnSize="md"
                  type="button"
                  btnType="filled"
                  disabled={showCompleteButton && isCompleteButtonDisabled}
                  onClick={() => {
                    onClickNextChapter(courseChapterPresenterEntity.chapters[courseChapterPresenter.currentChapterIndex].chapterId);
                  }}
                />
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <Row>
            <Col>
              <NoticeView title="No Content as of yet" description="The instructor has yet to create content" />
            </Col>
          </Row>
        )}

        <MainLoader show={loading} />
      </Col>
    </Row>
  );
};

export default CourseChapterPresenter;
