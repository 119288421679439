import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './app-banner.module.scss';

interface IProps {
  icon?: ReactNode;
  text?: string;
}

const AppBanner = (props: IProps) => {
  const { icon, text } = props;

  return (
    <Row>
      <Col className={`${styles.bannerCard}`}>
        <div className={`${styles.bannerContainer} px-2 py-3`}>
          <Row className="align-items-center">
            {icon && <Col className="col-auto ms-2">{icon}</Col>}
            {text && <Col className={`${styles.bannerText}`}>{text}</Col>}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default AppBanner;
