import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import AppDNDMiniCard from 'app/shared/components/app-dnd-elements/app-dnd-mini-card/app-dnd-mini-card';
import MainLoader from 'app/shared/components/main-loader/main-loader';
import NoticeView from 'app/shared/components/notice-views/notice-view';
import { IDeleteModelData } from 'app/shared/model/DeleteModel.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import MiniCard from 'app/shared/ui-elements/app-mini-card/mini-card';
import AppMiniPopUp from 'app/shared/ui-elements/app-mini-popup/app-mini-pop-up';
import IconCard from 'app/shared/ui-elements/icon-card/icon-card';
import DeleteModel from 'app/shared/ui-elements/models/app-delete-model/DeleteModel';
import { createCourseLessonsAndUnitsSequenceRequest, extractNumber } from 'app/shared/util/dnd-utils';
import React, { FC, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Col, Row } from 'react-bootstrap';
import { BsArrowsMove, BsEye, BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { ICourseChapterManager } from './course-chapter-manager.model';
import styles from './course-chapter-manager.module.scss';
import { arrangeChapters, getChapterOfCourse, updateChaptersHandler } from './course-chapter-manager.reducer';

const CourseChapterManager: FC = () => {
  const params = useParams() as { courseId: string; lessonId: string; unitId: string };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isChangedSequence, setIsChangedSequence] = useState<boolean>(false);
  const [initChapterData, setInitChapterData] = useState<IDeleteModelData | null>(null);

  const { state, loading } = useAppSelector((rootState: IRootState) => ({
    state: rootState.courseChapterManager.entity as ICourseChapterManager,
    loading: rootState.courseChapterManager.loading,
  }));

  useEffect(() => {
    dispatch(getChapterOfCourse({ courseId: +params.courseId, lessonId: +params.lessonId, unitId: params.unitId ? +params.unitId : null }));
  }, []);

  function onDragEnd(result: { destination: { index: number }; source: { index: number } }) {
    if (!result.destination) return;

    const items = Array.from(state.chapters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setIsChangedSequence(true);

    dispatch(updateChaptersHandler(items));
  }

  const submitHandler = () => {
    const unitId = params.unitId ? +params.unitId : null;
    const payload = createCourseLessonsAndUnitsSequenceRequest(+params.courseId, +params.lessonId, unitId, state as any);

    dispatch(arrangeChapters(payload));
    setIsChangedSequence(false);
  };

  const closeHandler = () => {
    setInitChapterData(ps => {
      return {
        ...ps,
        show: false,
      };
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-5">
              <MiniCard title={state.lessonOrUnitName} description={state.lessonOrUnitNameDescription} />
            </Col>
          </Row>

          <Row className="justify-content-between">
            <Col className={`${styles.pageText} mb-2 mb-sm-0`}>Course Chapters</Col>
            <Col className="col-12 col-sm-4 col-md-3">
              <Row className="align-items-center">
                <Col className={`col-auto`}>
                  <IconCard
                    icon={<BsEye size={18} />}
                    onClick={() => {
                      navigate(`preview`);
                    }}
                    iconCardVariant="bordered"
                  />
                </Col>
                <Col>
                  <AppButton
                    text="Add Chapter"
                    onClick={() => {
                      navigate(`add-chapter`);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="my-4">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="content-droppable">
                  {provided => (
                    <div className="" {...provided.droppableProps} ref={provided.innerRef}>
                      {state && state.chapters && state.chapters.length > 0 ? (
                        state.chapters.map((chapter, index) => {
                          return (
                            <Row key={index}>
                              <Col className="mt-2">
                                <Draggable key={chapter.chapterId} draggableId={chapter.chapterId} index={index}>
                                  {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                                  {(provided: {
                                    innerRef: React.LegacyRef<HTMLDivElement>;
                                    draggableProps: React.JSX.IntrinsicAttributes &
                                      React.ClassAttributes<HTMLDivElement> &
                                      React.HTMLAttributes<HTMLDivElement>;
                                    dragHandleProps: React.JSX.IntrinsicAttributes &
                                      React.ClassAttributes<HTMLDivElement> &
                                      React.HTMLAttributes<HTMLDivElement>;
                                  }) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                      <Row>
                                        <Col>
                                          <AppDNDMiniCard
                                            text={chapter.chapterName.toString()}
                                            firstIcon={<BsArrowsMove color="var(--icon-color)" size={16} />}
                                            lastIcon={
                                              <div className="position-relative">
                                                <BsThreeDotsVertical className="cursor-pointer" color="var(--icon-color)" size={12} />

                                                <AppMiniPopUp
                                                  key={chapter.chapterId}
                                                  className="pop-up"
                                                  items={[
                                                    {
                                                      text: 'Update Chapter',
                                                      onClick() {
                                                        navigate(`${extractNumber(chapter.chapterId as any)}/edit-chapter`);
                                                      },
                                                    },
                                                    {
                                                      text: 'Delete Chapter',
                                                      onClick() {
                                                        const data: IDeleteModelData = {
                                                          id: extractNumber(chapter.chapterId as any),
                                                          title: chapter.chapterName,
                                                          warningMessage:
                                                            'The operation is irreversible. Are you sure you want to proceed?',
                                                          courseId: +params.courseId,
                                                          lessonId: +params.lessonId,
                                                          unitId: +params.unitId,
                                                          show: true,
                                                          modelType: 'CHAPTER',
                                                        };

                                                        setInitChapterData(data);
                                                      },
                                                    },
                                                  ]}
                                                />
                                              </div>
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  )}
                                </Draggable>
                              </Col>
                            </Row>
                          );
                        })
                      ) : (
                        <NoticeView title="No chapters yet" description="No chapters have being added yet!" />
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>
          </Row>
          {state && state.chapters && state.chapters.length > 0 && isChangedSequence ? (
            <Row>
              <Col className="col-6 col-sm-4 col-md-3">
                <AppButton text="Save" onClick={submitHandler} />
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>

      <MainLoader show={loading} />
      <DeleteModel data={initChapterData} closeHandler={closeHandler} />
    </>
  );
};

export default CourseChapterManager;
