import React from 'react';
import { BsGrid } from 'react-icons/bs';
import styles from './header.module.scss';

interface IProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const MenuToggle = (props: IProps) => {
  return (
    <div onClick={props.onClick} className={`${styles['navbar-toggler']} d-flex align-items-center justify-content-center border-12`}>
      <BsGrid size={24} color={'#ffffff'} />
    </div>
  );
};

export default MenuToggle;
