export enum ECouponType {
  COMMON = '0',
  UNIQUE = '1',
}

export enum ECouponTypeStrings {
  COMMON = 'COMMON',
  UNIQUE = 'UNIQUE',
}

export enum ECouponCourseType {
  ALL = '0',
  SPECIFIC = '1',
}

export enum ECouponCourseTypeStrings {
  ALL = 'ALL',
  SPECIFIC = 'SPECIFIC',
}

export enum ECouponDiscountType {
  PERCENTAGE = '0',
  AMOUNT = '1',
}

export enum ECouponDiscountTypeStrings {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export enum ECouponState {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
}
