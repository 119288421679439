import { ChapterMaterialType } from 'app/shared/model/enumerations/chapter-material-type.model';

export interface IChapterMaterial {
  id?: number;
  chapterId?: number;
  url?: string;
  type?: keyof typeof ChapterMaterialType;
  chapterItemSequenceNumber?: number | null;
}

export const defaultValue: Readonly<IChapterMaterial> = {};
