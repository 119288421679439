import CatalogHeader from 'app/modules/program-catalog/catalog-header/catalog-header';
import CourseGridView from 'app/modules/program-catalog/course-grid-view/course-grid-view';
import React, { useState } from 'react';
import styles from './program-catalog-page.module.scss';
import ProgramFilter from './program-filter/program-filter';

const ProgramCatalogPage = () => {
  const [selectedField, setSelectedField] = useState('');
  const [selectedSkillLevel, setSelectedSkillLevel] = useState('');
  const [selectedSortBy, setSelectedSortBy] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const handleFilterChange = (field, skillLevel, sortBy, price, min, max) => {
    setSelectedField(field);
    setSelectedSkillLevel(skillLevel);
    setSelectedSortBy(sortBy);
    setSelectedPrice(price);
    setMinPrice(min);
    setMaxPrice(max);
  };

  const handleSearch = searchValue => {
    setSearchInput(searchValue);
  };

  return (
    <>
      <div className={`${styles.catalogPageLayout}`}>
        <CatalogHeader onSearch={handleSearch} />
        <ProgramFilter onFilterChange={handleFilterChange} />
        <CourseGridView
          selectedField={selectedField}
          selectedSkillLevel={selectedSkillLevel}
          selectedSortBy={selectedSortBy}
          searchInput={searchInput}
          selectedPrice={selectedPrice}
          minPrice={minPrice}
          maxPrice={maxPrice}
        />
      </div>
    </>
  );
};

export default ProgramCatalogPage;
