import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BsFillCaretLeftFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import AppMenuContent from './app-menu-content/app-menu-content';
import styles from './app-secondary-nav-bar.module.scss';

export interface IListItem {
  itemName: string;
  id: string;
  path?: string;
}

export interface IChaptersNavList {
  itemName: string;
  chapterId: number;
  isCompleted?: boolean;
}

interface IProps {
  title: string;
  subTitle?: string;
  itemList: IListItem[];
  basePath?: string;
}

const AppSecondaryNavBar = (props: IProps) => {
  const navigate = useNavigate();

  const onClickBackButton = () => {
    navigate(-1);
  };

  const getItemList = () => {
    return props.itemList.map((item: IListItem, index: number) => {
      return <AppMenuContent key={index} item={item} basePath={props.basePath} />;
    });
  };

  return (
    <>
      <Container>
        <Row>
          <Col className={`${styles.courseHeader}`}>
            <Row className="align-items-center">
              <Col className="col-2">
                <BsFillCaretLeftFill onClick={onClickBackButton} className="cursor-pointer" size={24} color={'var(--accent-color)'} />
              </Col>
              <Col className="col-10">
                <Row>
                  <Col className={`${styles.courseTitle} ps-0 pt-1`}>{props.title}</Col>
                </Row>
                {props.subTitle && (
                  <Row className="mt-1">
                    <Col className={`${styles.courseSubTitle} ps-0`}>{props.subTitle}</Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>{getItemList()}</Col>
        </Row>
      </Container>
    </>
  );
};

export default AppSecondaryNavBar;
