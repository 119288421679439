// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qfBKvJDT6Xe3SefQUDaY {
  border-radius: 3px;
  background: var(--card-color);
  width: 28px;
  height: 28px;
}

.coJL9FuaAssomlRrr6PY {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  border: 1px solid var(--accent-color);
  background: var(--card-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/icon-card/icon-card.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,6BAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,qCAAA;EACA,6BAAA;AACF","sourcesContent":[".iconCard {\n  border-radius: 3px;\n  background: var(--card-color);\n  width: 28px;\n  height: 28px;\n}\n\n.previewIcon {\n  width: 40px;\n  height: 40px;\n  border-radius: 12px;\n  border: 1px solid var(--accent-color);\n  background: var(--card-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconCard": `qfBKvJDT6Xe3SefQUDaY`,
	"previewIcon": `coJL9FuaAssomlRrr6PY`
};
export default ___CSS_LOADER_EXPORT___;
