import { emailRegex } from './regex';

export const parseCSV = (csvData: string): string[] => {
  const lines = csvData.split('\n');
  const emailArray: string[] = [];

  for (const line of lines) {
    const columns = line.split(',');
    if (columns.length > 0) {
      const email = columns[0].trim();
      if (emailRegex.test(email)) {
        emailArray.push(email);
      }
    }
  }

  return emailArray;
};
