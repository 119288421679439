import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import { updateEntityCheckAndStartCourse } from 'app/entities/course-enrollment/course-enrollment.reducer';
import { EPaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { generatePaymentReceiptPDF } from './payment-pdf-service/payment-pdf-service';
import styles from './payment.module.scss';

export const PaymentStatus = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const continueToCourseButtonHandler = () => {
    dispatch(updateEntityCheckAndStartCourse(state.courseEnrollmentId)).then(() => {
      navigate(`/student/courses/${state.courseEnrollmentId}/course-materials`);
    });
  };

  return (
    <Row className="text-center align-items-center" style={{ height: '70vh' }}>
      <Col>
        <Row>
          <Col className={styles.txt1}>
            {state?.paymentStatus === EPaymentStatus.SUCCESS ? 'Payment Successful' : `Payment Unsuccessful`}
          </Col>
        </Row>
        <Row>
          <Col className={styles.txt2}>
            {state?.paymentStatus === EPaymentStatus.SUCCESS
              ? 'Check the email you entered to check the receipt.'
              : `Payment was unsuccessful due to error`}
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <FontAwesomeIcon
              icon={state?.paymentStatus === EPaymentStatus.SUCCESS ? faCircleCheck : faCircleXmark}
              className={`${state?.paymentStatus === EPaymentStatus.SUCCESS ? styles['success-icon'] : styles['error-icon']} fa-4x`}
            />
          </Col>
        </Row>
        <Row>
          <Col className={styles.txt2}>
            {state?.paymentStatus === EPaymentStatus.SUCCESS ? `Transaction Id - ${state?.paymentId}` : `Transaction Failed`}
          </Col>
        </Row>
        <Row className="mt-5 align-items-center justify-content-center">
          {state?.paymentStatus === EPaymentStatus.SUCCESS ? (
            <>
              <Col className="col-9 col-sm-7 col-md-5 col-xl-3">
                <AppButton text="Continue to Course" type="button" onClick={continueToCourseButtonHandler} />
              </Col>
              <Col className="col-auto ps-0">
                <img
                  className="cursor-pointer"
                  src={'../../../../content/images/recept_download_icon.png'}
                  alt="recept download icon"
                  onClick={() => {
                    generatePaymentReceiptPDF(state);
                  }}
                />
              </Col>
            </>
          ) : (
            <Col className="col-9 col-sm-7 col-md-5 col-xl-3">
              <AppButton text="Try Again" type="button" onClick={() => navigate(`/payment/${state.courseId}`)} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
