import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { IPayment } from 'app/shared/model/payment.model';
import axios from 'axios';

const initialState = {
  loading: false,
  errorMessage: null,
  totalItems: 0,
  paymentList: [] as ReadonlyArray<IPayment>,
  paymentEntity: null as IPayment,
};

const apiUrlPayments = 'api/admin/payments';

export const getPayments = createAsyncThunk(
  'paymentManager/search_payment_by_name',
  async (data: { searchText?: string; page: number; size: number; sort?: string; courseId?: number }) => {
    const requestUrl = `${apiUrlPayments}?${data.searchText ? `searchText=${data.searchText}` : ''}${
      data.courseId ? `&courseId=${data.courseId}` : ''
    }&page=${data.page}&size=${data.size}${data.sort ? `&sort=${data.sort}` : ''}`;
    return axios.get<IPayment[]>(requestUrl);
  }
);

export type TPaymentState = Readonly<typeof initialState>;

export const paymentSlice = createSlice({
  name: 'payments',
  initialState: initialState as TPaymentState,
  reducers: {
    resetPayments() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder

      .addCase(getPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentList = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isPending(getPayments), state => {
        state.errorMessage = null;
        state.loading = true;
      })
      .addMatcher(isRejected(getPayments), (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { resetPayments } = paymentSlice.actions;
export default paymentSlice.reducer;
