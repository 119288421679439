// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vOSEPdSdEia2ZAHxf8uH {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 7000;
}

.E1rFabWI9BrcP_omzJMW {
  background: rgba(249, 249, 253, 0.85);
}

.HcvgS4Y_Ge2Z73zxHXrR {
  background: rgba(24, 25, 36, 0.85);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/main-loader/main-loader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;AACF;;AAEA;EACE,qCAAA;AACF;;AAEA;EACE,kCAAA;AACF","sourcesContent":[".spinnerContainer {\n  position: absolute;\n  top: 0;\n  left: 0;\n  height: 100%;\n  z-index: 7000;\n}\n\n.lightTheme {\n  background: rgba(249, 249, 253, 0.85);\n}\n\n.darkTheme {\n  background: rgba(24, 25, 36, 0.85);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": `vOSEPdSdEia2ZAHxf8uH`,
	"lightTheme": `E1rFabWI9BrcP_omzJMW`,
	"darkTheme": `HcvgS4Y_Ge2Z73zxHXrR`
};
export default ___CSS_LOADER_EXPORT___;
