import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { createCourseEnrollment } from 'app/entities/course-enrollment/course-enrollment.reducer';
import { getEntity, getEntityWithEnrollment } from 'app/entities/course/course.reducer';
import MainLoader from 'app/shared/components/main-loader/main-loader';
import useIsAdmin from 'app/shared/hooks/useIsAdmin';
import { ICourseDetails } from 'app/shared/model/dto/course-details.model';
import { ECourseMode } from 'app/shared/model/enumerations/course-mode.model';
import { IExpert } from 'app/shared/model/expert.model';
import AppBadgeWithLabel from 'app/shared/ui-elements/app-badge-with-label/AppBadgeWithLabel';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import { capitalizeFirstLetterWithNumber } from 'app/shared/util/course';
import { formatPriceWithCommas } from 'app/shared/util/price-utils';
import { showSuccessToast } from 'app/shared/util/toast';
import parse from 'html-react-parser';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, Card, Carousel, Col, Container, Row } from 'react-bootstrap';
import { Chrono } from 'react-chrono';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';
import '../../../../../content/sass/abstracts/_variables.scss';
import styles from './CoursePreview.module.scss';

const CoursePreview = () => {
  const { courseId } = useParams<'courseId'>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();

  const { courseEntity, loading, isAuthenticated } = useAppSelector((state: IRootState) => ({
    courseEntity: state.course.entity as ICourseDetails,
    loading: state.course.loading,
    isAuthenticated: state.authentication.isAuthenticated,
  }));

  const [field, setField] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [level, setLevel] = useState<string>('');
  const [swipe, setSwipe] = useState<any>();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getEntityWithEnrollment(courseId));
    } else {
      dispatch(getEntity(courseId));
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (courseEntity) {
      const calculatedField = courseEntity.fieldOfStudy
        ? courseEntity.fieldOfStudy
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : '';

      const calculatedType = courseEntity.type
        ? courseEntity.type
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : '';

      const calculatedLevel = courseEntity.difficultyLevel
        ? courseEntity.difficultyLevel
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        : '';

      setField(calculatedField);
      setType(calculatedType);
      setLevel(calculatedLevel);
    }
  }, [courseEntity]);

  const getExpertView = (expert: IExpert) => {
    return (
      <Row className={`card ${styles.sectionFour__expertCard} p-2 mx-1 mb-4`} key={uuidv4()}>
        <Col className="card-body p-2 p-sm-4 px-sm-2 px-md-3 align-items-center">
          <Row className="d-flex align-items-center">
            <Col className="col-3">
              <img
                className={`${styles.sectionFour__img2}`}
                src={
                  expert.imageUrl ||
                  'https://res.cloudinary.com/desnqqj6a/image/upload/v1683887268/User-Profile-PNG-High-Quality-Image_mwetdc.png'
                }
                alt=""
              />
            </Col>
            <Col className="col-9">
              <h6 className={`mb-1 ${styles.sectionFour__subHead}`}>{expert.name}</h6>
              <h6 className={`mb-0 pt-1 ${styles.sectionFour__details}`}>{expert.designation}</h6>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const getExpertsContent = () => {
    if (!courseEntity || !courseEntity.expertsPanel) {
      return [];
    }

    const expertCards = [];
    const expertsPanel = courseEntity.expertsPanel;

    for (let i = 0; i < expertsPanel.length; i += 6) {
      const expertOne = expertsPanel[i];
      const expertTwo = i + 3 < expertsPanel.length ? expertsPanel[i + 3] : null;

      expertCards.push(
        <SwiperSlide key={i}>
          {getExpertView(expertOne)}
          {expertTwo ? getExpertView(expertTwo) : ''}
        </SwiperSlide>
      );

      if (i + 1 < expertsPanel.length) {
        const expertThree = expertsPanel[i + 1];
        const expertFour = i + 4 < expertsPanel.length ? expertsPanel[i + 4] : null;

        expertCards.push(
          <SwiperSlide key={i + 1}>
            {getExpertView(expertThree)}
            {expertFour ? getExpertView(expertFour) : ''}
          </SwiperSlide>
        );
      }

      if (i + 2 < expertsPanel.length) {
        const expertFive = expertsPanel[i + 2];
        const expertSix = i + 5 < expertsPanel.length ? expertsPanel[i + 5] : null;

        expertCards.push(
          <SwiperSlide key={i + 2}>
            {getExpertView(expertFive)}
            {expertSix ? getExpertView(expertSix) : ''}
          </SwiperSlide>
        );
      }
    }

    return expertCards;
  };

  const getReviewContent = () => {
    if (!courseEntity || !courseEntity.totalReviews) {
      return [];
    }

    const reviewsArr = [];
    for (let i = 0; i < courseEntity.totalReviews.length; i = i + 3) {
      reviewsArr.push(
        <Carousel.Item className={`px-md-3 px-3`}>
          <Row>
            <Col className="mb-5">
              <Row className="gap-3 d-flex justify-content-center">
                <Col className={`${styles.sectionReview__card} col-12 p-4`}>
                  <p className={`mb-0 ${styles.sectionReview__comment} text-center text-md-start`}> {courseEntity.totalReviews[i]}</p>
                </Col>
                {courseEntity.totalReviews[i + 1] ? (
                  <Col className={`${styles.sectionReview__card} col-12 p-4`}>
                    <p className={`mb-0 ${styles.sectionReview__comment} text-center text-md-start`}>{courseEntity.totalReviews[i + 1]} </p>
                  </Col>
                ) : (
                  ''
                )}
                {courseEntity.totalReviews[i + 2] ? (
                  <Col className={`${styles.sectionReview__card} col-12 p-4`}>
                    <p className={`mb-0 ${styles.sectionReview__comment} text-center text-md-start`}> {courseEntity.totalReviews[i + 2]}</p>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </Col>
          </Row>
        </Carousel.Item>
      );
    }
    return reviewsArr;
  };

  const getCollaborationView = collaborationLogos => {
    if (!courseEntity || !courseEntity.collaborationLogos) {
      return undefined;
    }

    const collaborators = [];
    for (let i = 0; i < courseEntity.collaborationLogos.length; i++) {
      const logo = courseEntity.collaborationLogos[i];
      if (logo && logo.url && logo.name) {
        collaborators.push(
          <Col key={i}>
            <img src={logo.url} alt={logo.name} height={'20px'} />
          </Col>
        );
      }
    }
    return collaborators;
  };

  const HtmlRenderer = ({ htmlContent }) => {
    const parsedHtml = parse(htmlContent);
    return <div>{parsedHtml}</div>;
  };

  const handleCourseEnrollment = (id: number) => {
    if (isAuthenticated) {
      dispatch(createCourseEnrollment(id))
        .unwrap()
        .then(() => {
          dispatch(getEntityWithEnrollment(courseId));
          showSuccessToast('Enrollment successful', 'enrollmentSuccess');
        });
    } else {
      navigate(`/login`);
    }
  };

  const getRegStatusBtn = () => {
    if (courseEntity.mode === ECourseMode.LIVE_CLASS) {
      if (new Date() < new Date(courseEntity.registrationStartDate)) {
        return (
          <AppButton
            className={`${styles.sectionOne__btn3} px-3 ${isAdmin && 'cursor-not-allowed'}`}
            btnSize="sm"
            btnType="yellow"
            text="Waitlist Will Open Soon"
          />
        );
      } else if (new Date() > new Date(courseEntity.registrationStartDate) && new Date() < new Date(courseEntity.registrationEndDate)) {
        return (
          <AppButton
            className={`${styles.sectionOne__btn1} px-3 ${isAdmin && 'cursor-not-allowed'}`}
            btnSize="sm"
            text="Join Waitlist"
            onClick={() => {
              if (!isAdmin) window.open(courseEntity.formLink, '_blank');
            }}
          />
        );
      } else if (new Date() > new Date(courseEntity.registrationEndDate)) {
        if (courseEntity.enrolled) {
          return (
            <AppButton
              className={`${styles.sectionOne__btn1} px-3`}
              btnSize="sm"
              text="Continue to Course"
              onClick={() => navigate(`/student/courses/${courseEntity.enrollmentId}/course-materials`)}
            />
          );
        } else {
          if (courseEntity.paid) {
            return (
              <AppButton
                className={`${styles.sectionOne__btn1} px-3 ${isAdmin && 'cursor-not-allowed'}`}
                btnSize="sm"
                text="Purchase"
                onClick={() => {
                  if (!isAdmin) navigate(`/payment/${courseEntity.id}`);
                }}
              />
            );
          } else {
            return (
              <AppButton
                className={`${styles.sectionOne__btn1} px-3 ${isAdmin && 'cursor-not-allowed'}`}
                btnSize="sm"
                text="Enroll"
                onClick={() => {
                  if (!isAdmin) handleCourseEnrollment(courseEntity.id);
                }}
              />
            );
          }
        }
      }
    } else {
      if (courseEntity.paid) {
        if (courseEntity.enrolled) {
          return (
            <AppButton
              className={`${styles.sectionOne__btn1} px-3`}
              btnSize="sm"
              text="Continue to Course"
              onClick={() => navigate(`/student/courses/${courseEntity.enrollmentId}/course-materials`)}
            />
          );
        } else {
          return (
            <AppButton
              className={`${styles.sectionOne__btn1} px-3 ${isAdmin && 'cursor-not-allowed'}`}
              btnSize="sm"
              text="Purchase"
              onClick={() => {
                if (!isAdmin) navigate(`/payment/${courseEntity.id}`);
              }}
            />
          );
        }
      } else {
        if (courseEntity.enrolled) {
          return (
            <AppButton
              className={`${styles.sectionOne__btn1} px-3`}
              btnSize="sm"
              text="Continue to Course"
              onClick={() => navigate(`/student/courses/${courseEntity.enrollmentId}/course-materials`)}
            />
          );
        } else {
          return (
            <AppButton
              className={`${styles.sectionOne__btn1} px-3 ${isAdmin && 'cursor-not-allowed'}`}
              btnSize="sm"
              text="Enroll"
              onClick={() => {
                if (!isAdmin) handleCourseEnrollment(courseEntity.id);
              }}
            />
          );
        }
      }
    }
  };

  return (
    <Row className={`${styles.coursePreview}`}>
      <MainLoader show={loading} />
      <Col>
        <Container>
          <Row className={`${styles.sectionOne} mx-2 my-5  justify-content-center align-items-center text-center`}>
            <Col className="col-12 col-lg-6 py-3 px-4 ps-lg-0 pe-lg-4">
              <img src={courseEntity.coverImageUrl} className={`${styles.sectionOne__img1}`} alt="" />
            </Col>
            <Col className="col-12 col-lg-6 ps-lg-3 py-3">
              <Row className="mt-3 align-items-center justify-content-center justify-content-lg-start">
                {/* <Col className="col-5 col-sm-auto">
                  <AppBadge label={type} variant="secondary" />
                </Col>
                <Col className="col-5 col-sm-auto d-flex justify-content-center pe-4">
                  <IconWithText text={level} icon={BsFillMortarboardFill} />
                </Col> */}
                <Col className="col-12 col-sm-auto mt-3 mt-sm-0 d-flex justify-content-center justify-content-lg-start">
                  <AppBadgeWithLabel label="Subject" badgeLabel={field} />
                </Col>
              </Row>
              <Row className={`mt-5 mt-lg-3`}>
                <Col>
                  <p className={`${styles.sectionOne__txt1} text-center text-lg-start mb-0`}> {courseEntity.name} </p>
                </Col>
              </Row>
              <Row className={`mt-4 mt-lg-3`}>
                <Col>
                  <p className={`${styles.sectionOne__txt2} text-center text-lg-start`}>{courseEntity.description}</p>
                </Col>
              </Row>
              {(new Date() > new Date(courseEntity.registrationEndDate) || courseEntity.mode === ECourseMode.ON_DEMAND) &&
                courseEntity.enrolled === false && (
                  <Row className="justify-content-center justify-content-lg-start">
                    {courseEntity.price && (
                      <Col className={`col-12 text-center text-lg-start mb-1`}>
                        <span className={`${styles.sectionOne__txt2}`}>Base Price</span>
                      </Col>
                    )}
                    <Col className={`col-12 text-center text-lg-start`}>
                      <span className={`${styles.sectionOne__txt5}`}>
                        {courseEntity.price ? 'LKR ' + formatPriceWithCommas(courseEntity.price) : 'Free Course'}
                      </span>
                    </Col>
                  </Row>
                )}
              <Row className={`mt-4 mt-lg-3 align-items-center`}>
                <Col>
                  <Row className="align-items-center justify-content-center justify-content-lg-start">
                    <Col className={`col-12 col-sm-5 mb-2 mb-sm-0`}>{getRegStatusBtn()}</Col>
                    {courseEntity.mode === ECourseMode.LIVE_CLASS && (
                      <Col className="col-12 col-sm-auto">
                        <FontAwesomeIcon icon={faCircleDot} color="var(--error-secondary-color)" />
                        <span className={`${styles.sectionOne__txt4} ps-2`}>Live Class</span>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className={`${styles.sectionDivider} my-5 mx-2 px-lg-0 p-3`}>
            <Col>
              <Row className="justify-content-center justify-content-sm-start justify-content-lg-evenly align-items-center flex-wrap w-100 gap-4">
                {courseEntity.mode === ECourseMode.LIVE_CLASS && new Date() < new Date(courseEntity.registrationEndDate) && (
                  <>
                    <Col className={`col-auto d-flex flex-column justify-content-center pt-4 pt-lg-0`}>
                      <Row className="align-items-center">
                        <Col>
                          <p className={`${styles.sectionDivider__txt1} mb-1 text-center text-sm-start`}> Registration Start</p>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col>
                          <p className={`${styles.sectionDivider__txt2} mb-0 text-center text-sm-start`}>
                            {moment(courseEntity.registrationStartDate).format('Do MMMM YYYY')}{' '}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col className={`col-auto d-flex flex-column justify-content-center pt-4 pt-lg-0`}>
                      <Row className="align-items-center">
                        <Col>
                          <p className={`${styles.sectionDivider__txt1} mb-1 text-center text-sm-start`}> Registration End</p>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col>
                          <p className={`${styles.sectionDivider__txt2} mb-0 text-center text-sm-start`}>
                            {moment(courseEntity.registrationEndDate).format('Do MMMM YYYY')}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
                <Col className={`col-auto d-flex flex-column justify-content-center pt-lg-0`}>
                  <Row className="align-items-center">
                    <Col>
                      <p className={`${styles.sectionDivider__txt1} mb-1 text-center text-sm-start`}> Course Duration </p>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col>
                      <p className={`${styles.sectionDivider__txt2} mb-0 text-center text-sm-start`}>
                        {capitalizeFirstLetterWithNumber(courseEntity.courseDuration)}
                      </p>
                    </Col>
                  </Row>
                </Col>
                {courseEntity.collaborationLogos && courseEntity.collaborationLogos.length > 0 && (
                  <Col className={`col-auto pt-lg-0`}>
                    <Row>{courseEntity.collaborationLogos && getCollaborationView(courseEntity.collaborationLogos)}</Row>
                  </Col>
                )}
                <Col className={`col-auto`}>
                  <Row>
                    {/* <Col className="col-auto px-1">
                      <p className={`${styles.sectionDivider__txt3} pe-1 mb-0`}> ({courseEntity.rating}) </p>
                    </Col> */}
                    <Col className="col-auto">
                      <p className={`${styles.sectionDivider__txt1} mb-1 text-center text-sm-start`}>Course Features</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col className={`${styles.sectionDivider__txt2} text-center text-sm-start`}>
                      Audiovisual Materials / Sample Questions
                    </Col>
                  </Row>

                  {/* <AppRatings rating={+courseEntity.rating} /> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <section className={` ${styles.sectionCourseOutline} py-5`}>
          <Container className="py-3">
            <Row>
              <Col>
                <p className={`${styles.sectionCourseOutline__heading} text-center text-sm-start`}> Course Outline </p>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col className="col-12 col-lg-10 p-0">
                <Row>
                  <Col>
                    {!loading && courseEntity && courseEntity.outlines && (
                      <Chrono
                        items={courseEntity.outlines.map(outline => {
                          return {
                            cardTitle: outline.title,
                            cardSubtitle: outline.description,
                          };
                        })}
                        mode="VERTICAL"
                        cardHeight={120}
                        classNames={{
                          card: 'myCard',
                          cardSubTitle: 'myCardSubtitle',
                          cardTitle: 'myCardTitle',
                          controls: 'myControls',
                          title: 'myTitle',
                        }}
                        hideControls={true}
                        scrollable={false}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 col-lg-2">
                <Row className="mt-5 mt-lg-0">
                  <Col>
                    <Row>
                      <Col>
                        {courseEntity.prerequisites && <p className={`${styles.sectionCourseOutline__text1} mb-1`}> Prerequisites </p>}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className={`${styles.sectionCourseOutline__text2}`}>
                          {courseEntity.prerequisites && <HtmlRenderer htmlContent={courseEntity.prerequisites} />}
                        </p>
                      </Col>
                    </Row>
                    {courseEntity.needToPrepareSection && (
                      <>
                        <Row className="mt-3 mt-lg-5">
                          <Col>
                            <BsQuestionCircleFill color={'var(--text-primary-color)'} size={28} />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className={`${styles.sectionCourseOutline__text3} my-1`}> Need to prepare? </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <HtmlRenderer htmlContent={courseEntity.needToPrepareSection} />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <Container>
          <Row className={`${styles.sectionThree} my-5`}>
            <Col>
              <Row>
                <Col>
                  <p className={`${styles.sectionThree__txt1}`}> Class Schedule </p>
                </Col>
              </Row>

              <Row>
                <Col>
                  {courseEntity.classSchedule ? (
                    <HtmlRenderer htmlContent={courseEntity.classSchedule} />
                  ) : (
                    <NoticeView
                      title="Class Schedule is Currently Unavailable"
                      description="Instructors for this program will publish the class schedule as soon as possible. For any clarifications, please contact the administrators"
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container> */}

        {/* {courseEntity.expertsPanel && (
          <Row className={`${styles.sectionFour}`}>
            <Col>
              <section className="py-5">
                <Container className={`pb-5`}>
                  <Row className="d-flex align-items-center justify-content-between pb-4">
                    <Col>
                      <Row className="px-3 px-lg-0">
                        <Col className={`col-12 col-md-11`}>
                          <h4 className={`${styles.sectionFour__heading} text-center text-md-start`}>Learn From the Experts</h4>
                        </Col>
                        {!courseEntity.expertsPanel ||
                          (courseEntity.expertsPanel.length <= 3 ? (
                            ''
                          ) : (
                            <Col className="d-flex gap-2 col-12 col-md-1 align-items-center justify-content-center">
                              <Row onClick={() => swipe?.slidePrev()}>
                                <Col>
                                  <BsChevronLeft className={`${styles.sectionFour__chevron}`} size={20} color="var(--accent-color)" />
                                </Col>
                              </Row>
                              <Row onClick={() => swipe?.slideNext()}>
                                <Col>
                                  <BsChevronRight className={`${styles.sectionFour__chevron}`} size={20} color="var(--accent-color)" />
                                </Col>
                              </Row>
                            </Col>
                          ))}
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Swiper
                        spaceBetween={10}
                        slidesPerView={2}
                        onBeforeInit={swipper => setSwipe(swipper)}
                        pagination={false}
                        breakpoints={{
                          1276: { slidesPerView: 3, spaceBetween: 20 },
                          768: { slidesPerView: 2, spaceBetween: 20 },
                          300: { slidesPerView: 1, spaceBetween: 20 },
                        }}
                      >
                        {getExpertsContent()}
                      </Swiper>
                    </Col>
                  </Row>
                </Container>
              </section>
            </Col>
          </Row>
        )} */}

        {/* <Row className={` ${styles.sectionReview}`}>
          <Col>
            <Container>
              <Row>
                <Col>
                  <p className={`${styles.sectionReview__heading} mb-1 text-center text-md-start`}> Student Reviews </p>
                </Col>
              </Row>
              {courseEntity.totalReviews && courseEntity.totalReviews.length > 0 ? (
                <div className={` d-flex justify-content-center align-items-center`}>
                  <Row className="pt-3">
                    <Col>
                      <Carousel className={`pb-5`} controls={false} interval={3000}>
                        {courseEntity && getReviewContent()}
                      </Carousel>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Row>
                  <Col className="py-2">
                    <NoticeView
                      title="Student Reviews are Currently Unavailable"
                      description="Instructors for this program will publish the student reviews as soon as possible. For any clarifications, please contact the administrators"
                    />
                  </Col>
                </Row>
              )}
            </Container>
          </Col>
        </Row> */}

        {courseEntity.frequentlyAskedQuestions && courseEntity.frequentlyAskedQuestions.length > 0 && (
          <Row className={`${styles.sectionFive} py-5`}>
            <Col>
              <Container>
                <Row>
                  {courseEntity.frequentlyAskedQuestions && courseEntity.frequentlyAskedQuestions.length > 0 ? (
                    <Col className=" mt-3">
                      <h3 className={`${styles.sectionFive__heading}`}>FAQs</h3>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row className="d-flex flex-wrap justify-content-center">
                  <Col>
                    {courseEntity.frequentlyAskedQuestions &&
                      courseEntity.frequentlyAskedQuestions.map((faq: { question: string; answer: string }, index: number) => {
                        return (
                          <Fragment key={uuidv4()}>
                            <Card className={`${styles.sectionFive__card} mt-4 mt-sm-4 mt-md-4 mt-lg-4 `}>
                              <Accordion>
                                <Accordion.Header>
                                  <Card.Body>
                                    <Row className="align-items-center">
                                      <Col className="col-12">
                                        <Row className="justify-content-center">
                                          <Col>
                                            <p className={`${styles.sectionFive__questionHeading} mb-0`}> Question {index + 1}</p>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <Col className="col-col-12 col-col-sm-12 col-col-md-7 col-col-lg-7 mt-3">
                                              <p className={`${styles.sectionFive__question} mt-2`}>{faq.question}</p>
                                            </Col>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p className={`${styles.sectionFive__answer} my-3 ps-1`}>{parse(faq.answer)}</p>
                                </Accordion.Body>
                              </Accordion>
                            </Card>
                          </Fragment>
                        );
                      })}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        )}

        <section className={`${styles.sectionSix} pt-5 pb-5`}>
          <Row>
            <Col className="py-5">
              <Container>
                <Row>
                  <Col className="text-center">
                    <h3 className={`pt-4 ${styles.sectionSix__heading}`}>A/L අත්වැල තෝරාගතයුත්තේ ඇයි?</h3>
                  </Col>
                </Row>
                <Row className="mt-4 mt-sm-4 mt-md-4 mt-lg-5 justify-content-around mx-1 px-sm-5">
                  <Col className={`"col-12 col-sm-6 col-md-6 col-lg-3 pe-4`}>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <img src={'../../../../../content/images/icon_1.png'} alt="" width={80} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h5 className={`${styles.sectionSix__subHeading} mt-3 text-center`}>
                          මොරටුව විශ්‍වවිද්‍යාලයේ උපාධිදාරින් විසින් මෙහෙයවීම.
                        </h5>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col>
                        <p className={`${styles.sectionSix__description} text-center`}>
                          Immerse yourself in practical learning led by industry pros. Gain insights and skills directly from experts,
                          ensuring you&apos;re ready for real-world challenges.
                        </p>
                      </Col>
                    </Row> */}
                  </Col>
                  <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mt-3 mt-sm-2 mt-md-0 mt-lg-0 px-4">
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <img src={'../../../../../content/images/icon_2.png'} alt="" width={80} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h5 className={`${styles.sectionSix__subHeading} mt-3 text-center`}>
                          සිසුන් සමඟ වඩා හොඳ සම්බන්ධයක් සඳහා ඉගෙනුම් මෙවලම් මිශ්‍රණයක් භාවිතා කිරීම.
                        </h5>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col className="justify-content-center">
                        <p className={`${styles.sectionSix__description} text-center`}>
                          Shape your success with personalized career advice. From resumes to interviews, we guide you toward a compelling
                          career path.
                        </p>
                      </Col>
                    </Row> */}
                  </Col>
                  <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mt-3 mt-sm-3 mt-md-4 mt-lg-0 px-4">
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <img src={'../../../../../content/images/icon3.webp'} alt="" />
                      </Col>
                    </Row>
                    <Row className="font-family-class text-gray fs-5 course-include-heading mt-3 text-center">
                      <Col>
                        <h5 className={`${styles.sectionSix__subHeading}`}> සෑම පාඩමක්ම උදාහරණ සහ ගැටළු සමගින් නිර්මාණය කර තිබීම.</h5>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col>
                        <p className={`${styles.sectionSix__description} text-center`}>
                          Experience growth through one-on-one mentorship. Our supervision ensures supportive learning for personal and
                          professional development.
                        </p>
                      </Col>
                    </Row> */}
                  </Col>
                  <Col className="col-12 col-sm-6 col-md-6 col-lg-3 mt-3 mt-sm-3 mt-md-4 mt-lg-0 ps-4">
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <img src={'../../../../../content/images/icon4.webp'} alt="" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h5 className={`${styles.sectionSix__subHeading} mt-3 text-center`}> සියලුම පාඩම් සිංහලෙන් මෙහෙයවීම. </h5>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col>
                        <p className={`${styles.sectionSix__description} text-center`}>
                          Tailor education to your life. With flexible options, learn at your pace-whether you&apos;re a professional or
                          full-time student. Achieve goals on your terms.
                        </p>
                      </Col>
                    </Row> */}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </section>
      </Col>
    </Row>
  );
};

export default CoursePreview;
