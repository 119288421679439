import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsArrowsMove, BsTrashFill } from 'react-icons/bs';
import IconCard from '../../../ui-elements/icon-card/icon-card';
import styles from './app-dnd-card.module.scss';

interface IProps {
  label: string;
  children: ReactNode;
  onClickMoveIcon?: () => void;
  onClickRemoveIcon?: () => void;
}

const AppDNDCard = (props: IProps) => {
  const getRemoveIcon = () => {
    return <IconCard icon={<BsTrashFill className="cursor-pointer" color="#FF4C6C" />} onClick={props.onClickRemoveIcon} />;
  };

  return (
    <>
      <Row>
        <Col>
          <div className={`${styles.dndCardContainer} px-3 py-4`}>
            <Row className="align-items-center">
              <Col className="col-auto">
                <BsArrowsMove className="cursor-move" color="var(--icon-color)" size={16} onClick={props.onClickMoveIcon} />
              </Col>
              <Col>
                <Row>
                  <Col className={`mb-3 ${styles.labelStyle}`}>{props.label}</Col>
                  <Col className="col-auto d-md-none">{getRemoveIcon()}</Col>
                </Row>
                <Row>
                  <Col>{props.children}</Col>
                </Row>
              </Col>
              <Col className="col-auto d-none d-md-block">{getRemoveIcon()}</Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AppDNDCard;
