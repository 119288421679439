// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q7rSTY2Ux8lH4w2QtgLZ {
  position: absolute;
  border-radius: 12px;
  background: var(--card-color);
  box-shadow: 0px 0px 8px 0px rgba(24, 25, 36, 0.1);
  width: 122px;
  z-index: 9999;
  transition: 0.3s;
  top: 25px;
  right: -25px;
}
.Q7rSTY2Ux8lH4w2QtgLZ .hd7ZHPCbi6ppanI2ZCbp {
  color: #979797;
  font-family: "Roboto";
  font-size: 12px;
  padding: 15px 20px;
  text-wrap: nowrap;
}
.Q7rSTY2Ux8lH4w2QtgLZ .hd7ZHPCbi6ppanI2ZCbp:hover {
  color: var(--text-primary-color);
}
.Q7rSTY2Ux8lH4w2QtgLZ .S1iTl8RuBiy5lbuJ1NFA {
  border-bottom: 0.5px solid var(--icon-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-mini-popup/app-mini-pop-up.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,6BAAA;EACA,iDAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,SAAA;EACA,YAAA;AACF;AACE;EACE,cAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AACI;EACE,gCAAA;AACN;AAGE;EACE,4CAAA;AADJ","sourcesContent":[".miniPopUp {\n  position: absolute;\n  border-radius: 12px;\n  background: var(--card-color);\n  box-shadow: 0px 0px 8px 0px rgba(24, 25, 36, 0.1);\n  width: 122px;\n  z-index: 9999;\n  transition: 0.3s;\n  top: 25px;\n  right: -25px;\n\n  .miniPopUpText {\n    color: #979797;\n    font-family: 'Roboto';\n    font-size: 12px;\n    padding: 15px 20px;\n    text-wrap: nowrap;\n\n    &:hover {\n      color: var(--text-primary-color);\n    }\n  }\n\n  .miniPopUpBorderBottom {\n    border-bottom: 0.5px solid var(--icon-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"miniPopUp": `Q7rSTY2Ux8lH4w2QtgLZ`,
	"miniPopUpText": `hd7ZHPCbi6ppanI2ZCbp`,
	"miniPopUpBorderBottom": `S1iTl8RuBiy5lbuJ1NFA`
};
export default ___CSS_LOADER_EXPORT___;
