// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qj4My36o7fAKjreCTYFG {
  width: 100%;
  transition: width 0.3s ease;
  border-radius: 12px;
  background-color: var(--card-secondary-color) !important;
}
.qj4My36o7fAKjreCTYFG .eOjV__sddC_xho1yppII {
  position: absolute;
  right: 15px;
}

.RPl8RvobrTkjj6PflqwA {
  width: 175%;
}

.wZGbFLZB_PGKH5nxb1QJ {
  border: none;
}

.m3Sd1l90xpD9MxQM9VHM {
  position: relative;
  top: 12px;
  left: -2px;
  font-size: 9px;
  background-color: #ff4c6c;
  color: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TOR7hEyEYQBi_Qi5XgtU {
  --bs-accordion-active-bg: var(--card-secondary-color);
  --bs-accordion-active-color: var(--text-primary-color);
  --bs-accordion-bg: var(--card-secondary-color);
  --bs-accordion-color: var(--text-primary-color);
  --bs-accordion-btn-color: var(--text-primary-color);
  --bs-accordion-border-color: var(--card-secondary-color);
  --bs-accordion-btn-focus-box-shadow: var(--card-secondary-color);
  --bs-accordion-btn-padding-y: 11px;
  --bs-border-radius: 0.75rem;
}
.TOR7hEyEYQBi_Qi5XgtU :after {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/program-catalog/program-filter/program-filter.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,2BAAA;EACA,mBAAA;EACA,wDAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;AACJ;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,cAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,qDAAA;EACA,sDAAA;EACA,8CAAA;EACA,+CAAA;EACA,mDAAA;EACA,wDAAA;EACA,gEAAA;EACA,kCAAA;EACA,2BAAA;AAAF;AAEE;EACE,aAAA;AAAJ","sourcesContent":[".accordionWrapper {\n  width: 100%;\n  transition: width 0.3s ease;\n  border-radius: 12px;\n  background-color: var(--card-secondary-color) !important;\n\n  .headerIcon {\n    position: absolute;\n    right: 15px;\n  }\n}\n\n.fullWidth {\n  width: 175%;\n}\n\n.accordionItem {\n  border: none;\n}\n\n.badge {\n  position: relative;\n  top: 12px;\n  left: -2px;\n  font-size: 9px;\n  background-color: #ff4c6c;\n  color: white;\n  border-radius: 50%;\n  width: 12px;\n  height: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.accordion {\n  --bs-accordion-active-bg: var(--card-secondary-color);\n  --bs-accordion-active-color: var(--text-primary-color);\n  --bs-accordion-bg: var(--card-secondary-color);\n  --bs-accordion-color: var(--text-primary-color);\n  --bs-accordion-btn-color: var(--text-primary-color);\n  --bs-accordion-border-color: var(--card-secondary-color);\n  --bs-accordion-btn-focus-box-shadow: var(--card-secondary-color);\n  --bs-accordion-btn-padding-y: 11px;\n  --bs-border-radius: 0.75rem;\n\n  :after {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionWrapper": `qj4My36o7fAKjreCTYFG`,
	"headerIcon": `eOjV__sddC_xho1yppII`,
	"fullWidth": `RPl8RvobrTkjj6PflqwA`,
	"accordionItem": `wZGbFLZB_PGKH5nxb1QJ`,
	"badge": `m3Sd1l90xpD9MxQM9VHM`,
	"accordion": `TOR7hEyEYQBi_Qi5XgtU`
};
export default ___CSS_LOADER_EXPORT___;
