import { useAppDispatch, useAppSelector } from 'app/config/store';
import { handlePasswordResetInit } from 'app/modules/account/password-reset/password-reset.reducer';
import AccountSideImageSection from 'app/shared/components/account-side-image-section/account-side-image-section';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import { emailRegex } from 'app/shared/util/regex';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styles from './forgot-password.module.scss';

type ForgotPasswordFormValues = {
  email: string;
  isResendDisabled?: boolean;
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(state => ({
    loading: state.passwordReset.loading,
  }));

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<ForgotPasswordFormValues> = (data: ForgotPasswordFormValues) => {
    dispatch(handlePasswordResetInit(data.email));
    const userEmail = data.email;
    const isFromForgetPassword = true;
    navigate('/verify-email', { state: { email: userEmail, isFromForgetPassword: isFromForgetPassword } });
  };

  return (
    <>
      <Container className="py-lg-5 py-md-3 h-100">
        <Row className="align-items-center justify-content-evenly h-100">
          <Col className={`col-12 col-lg-4`}>
            <AccountSideImageSection />
          </Col>

          <Col className={`col-12 col-lg-5 ${styles.formSection}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col className={`${styles.forgotPasswordTitle} text-center pb-1`}>Forgot Password?</Col>
              </Row>
              <Row>
                <Col className={`${styles.forgotPasswordTextStyleThree} pb-5`}>Enter your email address</Col>
              </Row>
              <Row>
                <Col className="mb-5">
                  <AppInput
                    id="email"
                    placeholder={'email@mail.com'}
                    name="email"
                    label="Email"
                    register={register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="mb-4 mt-2">
                  <AppButton type="submit" text={'Continue'} loading={loading} />
                </Col>
              </Row>

              <Row className="justify-content-center text-center">
                <Col className="col-auto">
                  <span className={`${styles.forgotPasswordTextStyleTwo}`}>Back to</span>
                  <span className={`${styles.forgotPasswordTextStyleOne} cursor-pointer ms-1`} onClick={() => navigate('/login')}>
                    Sign In
                  </span>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
