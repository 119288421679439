import { IOption } from '../model/option.model';

export const getPartBeforeLastTwoPeriods = inputUrl => {
  const parts = inputUrl.split('.');
  if (parts.length >= 3) {
    const partBeforeLastTwoPeriods = parts.slice(0, -2).join(' ');
    return partBeforeLastTwoPeriods;
  }
  return inputUrl;
};

export const getDropdownSelectedValue = (value: string | any, options: IOption[]) => {
  const selectedValue = {
    value: value,
    label: options.find((option: IOption) => option.value === value)?.label,
  };

  return selectedValue;
};
