import Hls from 'hls.js';
import Plyr from 'plyr';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

interface Props {
  fileName: string;
}

function changeExtension(filename, newExtension) {
  const baseName = filename.substring(0, filename.lastIndexOf('.'));
  return `${baseName}.${newExtension}`;
}

const VideoPlayer: FC<Props> = props => {
  const [error, setError] = useState('');

  const sourcePrefix = 'https://learnova-hls-output.s3.amazonaws.com/';

  const updatedUrl = sourcePrefix + changeExtension(props.fileName, 'm3u8');

  const videoRef = useRef(null);

  useEffect(() => {
    if (!videoRef.current) return;

    const hls = new Hls();

    if (Hls.isSupported()) {
      hls.loadSource(updatedUrl);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        const qualityLevels = data.levels.map((level, index) => ({
          id: index,
          label: `${level.height}p`,
          value: index,
        }));

        new Plyr(videoRef.current, {
          controls: [
            'play-large',
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'captions',
            'settings',
            'pip',
            'airplay',
            'fullscreen',
          ],
          settings: ['captions', 'quality', 'speed'],
          speed: {
            selected: 1,
            options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
          },
          quality: {
            default: qualityLevels.length - 1, // set highest quality as default
            options: qualityLevels.map(q => q.value),
            forced: true,
            onChange: quality => (hls.currentLevel = quality),
          },
          i18n: {
            qualityLabel: qualityLevels.map(q => q.label),
          },
        }).quality = qualityLevels as any;
      });

      hls.on(Hls.Events.ERROR, function (event, data) {
        setError('Either the video is not available or the video is still being processed. Please try in few minutes');
      });
      hls.on(Hls.Events.BUFFER_CREATED, function (event, data) {
        setError('');
      });
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [props.fileName]);

  return (
    <Row>
      <Col>
        <video ref={videoRef} controls playsInline className="w-100"></video>
      </Col>
    </Row>
  );
};

export default VideoPlayer;
