import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './radio-button.module.scss';

interface IProps {
  buttonOptions: string[];
  onChange: (event) => void;
  selectedOption: string;
}

const RadioButton = (props: IProps) => {
  return (
    <Row className="align-items-center">
      {props.buttonOptions.map((option, index) => (
        <Col xs={'auto'} key={index}>
          <input type="radio" value={option} checked={props.selectedOption === option} onChange={props.onChange} />
          <label className={`ps-2 ${styles.label}`}>{option}</label>
        </Col>
      ))}
    </Row>
  );
};

export default RadioButton;
