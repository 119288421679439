import { IResourceLinkUtilized } from 'app/shared/util/dnd-utils';
import React, { FC, Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsTrashFill } from 'react-icons/bs';
import AppButton from '../../ui-elements/app-button/app-button';
import { ImageUploaderType } from '../image-uploader/image-uploader';
import styles from './multiple-rows-addition-field.module.scss';

export interface IRowInputsData {
  firstInputLabel: string;
  secondInputLabel: string;
  firstInputBracketLabel: string;
  secondInputBracketLabel: string;
  firstInputPlaceholder: string;
  secondInputPlaceholder: string;
}

export interface IRowData {
  id: number;
  firstInput: string;
  secondInput: string;
  isValidFirstInput: boolean;
  isValidSecondInput: boolean;
  imageUrl?: string;
  uploadingImage?: boolean;
}
interface IProps {
  label?: string;
  buttonText: string;
  iconTitle?: string;
  addImageEnabled: boolean;
  rowInputsData: IRowInputsData;
  resourceLinks: IResourceLinkUtilized[];
  chapterItemSequenceNumber: string;
  imageUploaderType?: ImageUploaderType;
  isRequired?: boolean;
  referenceNameChangeHandler: (chapterItemSequenceNumber: string, index: number, referenceName: string) => void;
  urlChangeHandler: (chapterItemSequenceNumber: string, index: number, url: string) => void;
  referenceLinksAddNewRowHandler: (chapterItemSequenceNumber: string) => void;
  referenceLinksRemoveRowHandler: (chapterItemSequenceNumber: string, index: number) => void;
}

const MultipleRowAdditionFieldSecond: FC<IProps> = props => {
  return (
    <Row>
      <Col className="col-12">
        <div className={`${styles.customCard} p-3`}>
          <Fragment>
            {props.resourceLinks.map((row, index) => (
              <Row key={index} className="align-items-center mt-2">
                <Col className={`col-lg-4 col-md-4 order-1' ${props.addImageEnabled ? 'col-9' : 'col-12'}`}>
                  <label className={`${styles.label} d-flex pb-1`} htmlFor="flexSwitchCheckDefault">
                    {props.rowInputsData.firstInputLabel} ({props.rowInputsData.firstInputBracketLabel} {index + 1})
                  </label>
                  <div className={`appInput ${!row.isValidReferenceName && 'hasDanger'}`}>
                    <input
                      value={row.referenceName}
                      placeholder={props.rowInputsData.firstInputPlaceholder}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        props.referenceNameChangeHandler(props.chapterItemSequenceNumber, index, e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col className="order-sm-3 order-md-2 order-lg-2 order-3 ">
                  <label className={`${styles.label} d-flex pb-1`} htmlFor="flexSwitchCheckDefault">
                    {props.rowInputsData.secondInputLabel} ({props.rowInputsData.secondInputBracketLabel} {index + 1})
                  </label>
                  <div className={`appInput ${!row.isValidUrl && 'hasDanger'}`}>
                    <input
                      placeholder={props.rowInputsData.secondInputPlaceholder}
                      value={row.url}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        props.urlChangeHandler(props.chapterItemSequenceNumber, index, e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col className="col-auto order-4 mt-4">
                  <div className={`${styles.iconCard} p-1`}>
                    <BsTrashFill
                      className="cursor-pointer justify-content-end"
                      color="#FF4C6C"
                      onClick={() => {
                        props.referenceLinksRemoveRowHandler(props.chapterItemSequenceNumber, index);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            ))}
            <Row>
              <Col className="col-auto mt-4">
                <AppButton
                  className={`${styles.btnStyle}`}
                  text={props.buttonText}
                  btnSize="sm"
                  btnType="bordered"
                  onClick={() => {
                    props.referenceLinksAddNewRowHandler(props.chapterItemSequenceNumber);
                  }}
                  type="button"
                />
              </Col>
            </Row>
          </Fragment>
        </div>
      </Col>
    </Row>
  );
};

export default MultipleRowAdditionFieldSecond;
