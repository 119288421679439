// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ixb1hvC0CM87ma2wU13c {
  border-radius: 12px;
  background: var(--card-secondary-color);
}
.yWTi13ksTRPfrkuBqIlw {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 93px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.2);
}
.Kprait3dZ9zJ_QW9aaQs {
  color: var(--text-primary-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.lKdtfpQwobYQsxCU22Ye {
  color: var(--text-secondary-color);
  font-size: 16px;
  font-style: normal;
}
.elsZIEWyoNVgZJq_qR3j {
  color: var(--text-primary-color);
  font-size: 16px;
  font-style: normal;
}
.qzICs0txVhuYqHRGMUqn {
  color: var(--success-color);
  font-size: 32px;
  font-style: normal;
  line-height: 0px;
}
.XIWXdoU5lPSegBlmXV3w {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/pricing-card/pricing-card.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,uCAAA;AACF;AACE;EACE,oBAAA;KAAA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAEE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;AADJ;AAIE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;AAFJ;AAKE;EACE,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAHJ;AAME;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;AAJJ","sourcesContent":[".pricingCard {\n  border-radius: 12px;\n  background: var(--card-secondary-color);\n\n  &__img {\n    object-fit: cover;\n    width: 100%;\n    height: 93px;\n    border-radius: 12px;\n    background: rgba(0, 0, 0, 0.2);\n  }\n\n  &__txt1 {\n    color: var(--text-primary-color);\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n  }\n\n  &__txt2 {\n    color: var(--text-secondary-color);\n    font-size: 16px;\n    font-style: normal;\n  }\n\n  &__txt3 {\n    color: var(--text-primary-color);\n    font-size: 16px;\n    font-style: normal;\n  }\n\n  &__txt4 {\n    color: var(--success-color);\n    font-size: 32px;\n    font-style: normal;\n    line-height: 0px;\n  }\n\n  &__txt5 {\n    color: var(--text-secondary-color);\n    font-size: 14px;\n    font-style: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pricingCard": `Ixb1hvC0CM87ma2wU13c`,
	"pricingCard__img": `yWTi13ksTRPfrkuBqIlw`,
	"pricingCard__txt1": `Kprait3dZ9zJ_QW9aaQs`,
	"pricingCard__txt2": `lKdtfpQwobYQsxCU22Ye`,
	"pricingCard__txt3": `elsZIEWyoNVgZJq_qR3j`,
	"pricingCard__txt4": `qzICs0txVhuYqHRGMUqn`,
	"pricingCard__txt5": `XIWXdoU5lPSegBlmXV3w`
};
export default ___CSS_LOADER_EXPORT___;
