// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qu3XRNUt_dNt_MmyqHEl {
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: 400;
}

.ITo57sMnhNWdP7zM2BQ_ {
  --bs-accordion-btn-icon-width: 16px;
}

.MAbHB3MJfQa3PcoEmvlR {
  border: none;
  border-radius: 14px;
}
.MAbHB3MJfQa3PcoEmvlR .SydoeuhRwxbwXBrclWln {
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: 400;
}
.MAbHB3MJfQa3PcoEmvlR .znPHrJnO0l8hJXxAtucc {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/admin/course-lessons-units-manager/course-lessons-units-manager.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,mCAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;AACE;EACE,gCAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".text1 {\n  color: var(--text-primary-color);\n  font-size: 16px;\n  font-weight: 400;\n}\n\n.accordionArrowIcon {\n  --bs-accordion-btn-icon-width: 16px;\n}\n\n.accordionContainer {\n  border: none;\n  border-radius: 14px;\n\n  .header {\n    color: var(--text-primary-color);\n    font-size: 16px;\n    font-weight: 400;\n  }\n\n  .descriptionText {\n    color: var(--text-secondary-color);\n    font-size: 14px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text1": `Qu3XRNUt_dNt_MmyqHEl`,
	"accordionArrowIcon": `ITo57sMnhNWdP7zM2BQ_`,
	"accordionContainer": `MAbHB3MJfQa3PcoEmvlR`,
	"header": `SydoeuhRwxbwXBrclWln`,
	"descriptionText": `znPHrJnO0l8hJXxAtucc`
};
export default ___CSS_LOADER_EXPORT___;
