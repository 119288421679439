// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SAz337Q1Zy7L65HzJL76 .MaLDhiY9S_SLjdaxtWau {
  color: var(--text-primary-color);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.SAz337Q1Zy7L65HzJL76 .LpTXLCmQnZt_3fqnrX_Y {
  color: var(--accent-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.SAz337Q1Zy7L65HzJL76 .lWS3cy9jk6KvqUJU6YKN {
  color: var(--text-secondary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/pages/sign-in/sign-in.module.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAIE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".formSection {\n  .signInTitle {\n    color: var(--text-primary-color);\n    font-size: 30px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n  }\n\n  .signInTextStyleOne {\n    color: var(--accent-color);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n\n  .signInTextStyleTwo {\n    color: var(--text-secondary-color);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSection": `SAz337Q1Zy7L65HzJL76`,
	"signInTitle": `MaLDhiY9S_SLjdaxtWau`,
	"signInTextStyleOne": `LpTXLCmQnZt_3fqnrX_Y`,
	"signInTextStyleTwo": `lWS3cy9jk6KvqUJU6YKN`
};
export default ___CSS_LOADER_EXPORT___;
