// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lp0vcKuLPQaUQBn4FxXe:focus-within {
  outline: none;
}

.lp0vcKuLPQaUQBn4FxXe {
  align-items: center;
  display: flex;
  height: 88px;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: var(--card-color);
  border: 1px solid var(--line-color);
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: var(--text-primary-color);
  padding: 10px;
  resize: none;
}

.oI7CpZWn5w7mjR8KlV07 {
  font-size: 16px;
  color: var(--text-secondary-color);
}

@media screen and (max-width: 576px) {
  .lp0vcKuLPQaUQBn4FxXe {
    height: 140px;
  }
}
.yg1xRXTX6lCd6jS4nLg_ {
  border-radius: 12px;
}
.yg1xRXTX6lCd6jS4nLg_.IcZuOMOCvbILva_8l16n {
  border: 1px solid var(--error-color);
}
.yg1xRXTX6lCd6jS4nLg_.hsKdFT7ogHHoaa_oChKI {
  border: 1px solid #99f59d;
}

.u1nt5Xhx0QbMz3bSnigX {
  color: var(--error-secondary-color);
}

.X35A9tsETe_KC3Pp1I3f {
  font-size: 12px;
  color: var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/text-area-component/TextArea.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,6BAAA;EACA,mCAAA;EACA,+BAAA;EACA,eAAA;EACA,gCAAA;EACA,aAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,kCAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF;AAEA;EACE,mBAAA;AAAF;AACE;EACE,oCAAA;AACJ;AAEE;EACE,yBAAA;AAAJ;;AAIA;EACE,mCAAA;AADF;;AAIA;EACE,eAAA;EACA,yBAAA;AADF","sourcesContent":[".text-area-input:focus-within {\n  outline: none;\n}\n\n.text-area-input {\n  align-items: center;\n  display: flex;\n  height: 88px;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  border-radius: 12px;\n  background: var(--card-color);\n  border: 1px solid var(--line-color);\n  font-family: 'Lato', sans-serif;\n  font-size: 16px;\n  color: var(--text-primary-color);\n  padding: 10px;\n  resize: none;\n}\n\n.text-area-input-label {\n  font-size: 16px;\n  color: var(--text-secondary-color);\n}\n\n@media screen and (max-width: 576px) {\n  .text-area-input {\n    height: 140px;\n  }\n}\n\n.appInput {\n  border-radius: 12px;\n  &.hasDanger {\n    border: 1px solid var(--error-color);\n  }\n\n  &.hasSuccess {\n    border: 1px solid #99f59d;\n  }\n}\n\n.required {\n  color: var(--error-secondary-color);\n}\n\n.errorMessage {\n  font-size: 12px;\n  color: var(--error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-area-input": `lp0vcKuLPQaUQBn4FxXe`,
	"text-area-input-label": `oI7CpZWn5w7mjR8KlV07`,
	"appInput": `yg1xRXTX6lCd6jS4nLg_`,
	"hasDanger": `IcZuOMOCvbILva_8l16n`,
	"hasSuccess": `hsKdFT7ogHHoaa_oChKI`,
	"required": `u1nt5Xhx0QbMz3bSnigX`,
	"errorMessage": `X35A9tsETe_KC3Pp1I3f`
};
export default ___CSS_LOADER_EXPORT___;
