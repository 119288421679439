// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kuIP9b79evDRJcHbMQcc:hover {
  text-decoration: underline;
  cursor: pointer;
}

.hITW8IIjlyuv1ZuiGvw9 {
  max-width: 8rem;
  white-space: nowrap;
}

.AUgHChP6f3CvNUqBSobn {
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Eq85R6Xxz1D7D0utrWwW {
  height: 65vh;
  overflow-y: auto;
}

.s3j6qafHhEkHiVwuJDdC {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary-color) !important;
}

.pC6QbqaBE0FU8cHuP5zD {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary-color) !important;
}

.md1JczKzdh6cC0TuJOhB {
  color: var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/admin/settings/coupones/coupons-dashboard/coupons-dashboard.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,2CAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,6CAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".selectedRow:hover {\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.customWidthColumn {\n  max-width: 8rem;\n  white-space: nowrap;\n}\n\n.courseNameCell {\n  max-width: 20rem;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.tableContainer {\n  height: 65vh;\n  overflow-y: auto;\n}\n\n.txt1 {\n  font-size: 14px;\n  font-weight: 400;\n  color: var(--text-primary-color) !important;\n}\n\n.txt2 {\n  font-size: 14px;\n  font-weight: 400;\n  color: var(--text-secondary-color) !important;\n}\n\n.expiredState {\n  color: var(--error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedRow": `kuIP9b79evDRJcHbMQcc`,
	"customWidthColumn": `hITW8IIjlyuv1ZuiGvw9`,
	"courseNameCell": `AUgHChP6f3CvNUqBSobn`,
	"tableContainer": `Eq85R6Xxz1D7D0utrWwW`,
	"txt1": `s3j6qafHhEkHiVwuJDdC`,
	"txt2": `pC6QbqaBE0FU8cHuP5zD`,
	"expiredState": `md1JczKzdh6cC0TuJOhB`
};
export default ___CSS_LOADER_EXPORT___;
