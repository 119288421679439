import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { IconType } from 'react-icons';
import styles from './app-button.module.scss';

export type ButtonType = 'filled' | 'bordered' | 'gray' | 'onlyText' | 'white' | 'delete' | 'yellow';
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text: string;
  btnType?: ButtonType;
  btnSize?: ButtonSize;
  icon?: IconType;
  iconOnly?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const AppButton = (props: IProps) => {
  const { text, btnType = 'filled', btnSize, icon: IconComponent, iconOnly, disabled, loading = false, ...rest } = props;

  const getSizeClass = () => {
    switch (btnSize) {
      case 'xs':
        return styles.appButtonExtraSmall;
      case 'sm':
        return styles.appButtonSmall;
      case 'md':
        return styles.appButtonMedium;
      case 'lg':
        return styles.appButtonLarge;
      default:
        return styles.appButtonMedium;
    }
  };

  const getStyleClass = () => {
    switch (btnType) {
      case 'filled':
        return styles.filledBtn;
      case 'bordered':
        return styles.outlinedBtn;
      case 'onlyText':
        return styles.textOnlyBtn;
      case 'white':
        return styles.whiteBtn;
      case 'delete':
        return styles.deleteBtn;
      case 'yellow':
        return styles.yellowBtn;
      default:
        return styles.grayBtn;
    }
  };

  const getButtonContent = () => {
    if (loading) {
      return <Spinner animation="border" className={styles.spinner} />;
    } else if (iconOnly) {
      return <>{IconComponent && <IconComponent size={18} />}</>;
    } else {
      return (
        <>
          <Row className="justify-content-center align-items-center">
            {IconComponent && (
              <Col className="col-auto">
                <IconComponent size={17} />
              </Col>
            )}
            <Col className={`col-auto ${styles.buttonText}`}>{text}</Col>
          </Row>
        </>
      );
    }
  };

  return (
    <button
      {...{
        ...rest,
        className: `${rest.className}  ${props.disabled && styles.appButtonDisabled}  ${styles.appButton}
        ${btnType === 'filled' ? styles.filledBtn : styles.outlinedBtn} ${getSizeClass()} ${getStyleClass()} ${
          iconOnly && styles['appIconButton']
        } px-3
        `,
      }}
      disabled={disabled}
    >
      {getButtonContent()}
    </button>
  );
};

export default AppButton;
