import AppTooltip from 'app/shared/ui-elements/app-tooltip/AppTooltip';
import { showErrorToast } from 'app/shared/util/toast';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsPlusLg, BsXLg } from 'react-icons/bs';
import styles from './image-uploader.module.scss';

export type ImageUploaderType = 'primary' | 'secondary';
export interface IImageUploaderProps {
  isMultipleImage: boolean;
  onImagesSelected: (files: FileList) => void;
  onChange?: (params: any) => void;
  iconTitle?: string;
  imageUploaderType?: ImageUploaderType;
  loading?: boolean;
  existingFileUrls?: { url: string; file: File }[];
  maxImagesCount?: number;
}

const ImageUploader: FC<IImageUploaderProps> = props => {
  const { imageUploaderType = 'primary', loading = false, existingFileUrls, maxImagesCount = 3 } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imagePreviews, setImagePreviews] = useState<{ url: string; file: File }[]>([]);
  const [selectedImageCount, setSelectedImageCount] = useState(0);
  useEffect(() => {
    if (existingFileUrls) {
      setImagePreviews(existingFileUrls);
      setSelectedImageCount(existingFileUrls ? existingFileUrls.length : imagePreviews.length);
    }
  }, []);

  const getTextStyleClass = () => {
    switch (imageUploaderType) {
      case 'primary':
        return styles['customButtonText-primary-color'];
      case 'secondary':
        return styles['customButtonText-secondary-color'];
      default:
        return styles['customButtonText-primary-color'];
    }
  };

  const getIconStyleClass = () => {
    switch (imageUploaderType) {
      case 'primary':
        return styles['addImageBackground-primary-color'];
      case 'secondary':
        return styles['addImageBackground-secondary-color'];
      default:
        return styles['addImageBackground-primary-color'];
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const fileTooLarge = Array.from(files).some(file => file.size > 2 * 1024 * 1024);
      const fileList = Array.from(files);

      const validFileExtensions = /\.(jpg|jpeg|png|jfif|gif)$/;

      const validImageType = fileList.every(image => {
        const fileNameParts = image.name.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
        return validFileExtensions.test(`.${fileExtension}`);
      });

      if (!validImageType) {
        showErrorToast('Please select valid image', 'fileList');
        return;
      }

      if (fileTooLarge) {
        showErrorToast('Image size limit exceeded. Please select images less than 2MB.', 'fileTooLarge');
        const fileInput = fileInputRef.current;
        if (fileInput) {
          fileInput.value = '';
        }
        return;
      }

      if (files.length > maxImagesCount - selectedImageCount) {
        showErrorToast(`You can only add a maximum of ${maxImagesCount} images.`, 'maximumImage');
        return;
      }

      if (!props.isMultipleImage) {
        setImagePreviews([]);
      }

      const newImagePreviews: { url: string; file: File }[] = [];

      const isDuplicateExisting =
        existingFileUrls &&
        existingFileUrls.length > 0 &&
        existingFileUrls.some(
          existingFile =>
            (existingFile.file as unknown as string) === files[files.length - 1].name.slice(0, files[files.length - 1].name.indexOf('.'))
        );
      const isDuplicatePreview = imagePreviews.some(preview => preview.file.name === files[files.length - 1].name);

      if (isDuplicateExisting || isDuplicatePreview) {
        showErrorToast(`Duplicate image ${files[files.length - 1].name}`, 'duplicateImage');
        return;
      } else {
        props.onImagesSelected(files);
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const reader = new FileReader();
        reader.onloadend = () => {
          const imagePreview = reader.result as string;
          newImagePreviews.push({ url: imagePreview, file });
          setSelectedImageCount(selectedImageCount + files.length);

          if (newImagePreviews.length === files.length) {
            setImagePreviews(prevPreviews => [...prevPreviews, ...newImagePreviews]);
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const onRemoveImage = (index: number) => {
    const newPreviews = [...imagePreviews];
    newPreviews.splice(index, 1);

    setImagePreviews(newPreviews);
    if (newPreviews.length !== 0) {
      setSelectedImageCount(selectedImageCount - 1);
    } else {
      setSelectedImageCount(0);
    }

    const selectedFiles = imagePreviews
      .filter((_, i) => i === index)
      .map(obj => (obj.file instanceof File ? obj.file.name.split('.')[0] : obj.file));
    props.onChange?.(selectedFiles);

    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const imageAddButton = () => {
    return (
      <>
        <Row className="align-items-center">
          <Col className="align-items-center col-auto pe-0">
            <div className={`${styles.addImageBackground} ${getIconStyleClass()} cursor-pointer`}>
              <BsPlusLg size={18} color="var(--card-color)" onClick={onClick} />
            </div>
          </Col>
          <Col>
            {props.iconTitle && (
              <span className={`${styles.customButtonText} ${getTextStyleClass()}`} onClick={onClick}>
                {props.iconTitle}
              </span>
            )}
          </Col>
        </Row>
      </>
    );
  };

  if (loading) {
    return (
      <Row>
        <Col>
          <Spinner animation="border" className={styles.spinner} />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col>
        <div>
          <input
            type="file"
            accept="image/*"
            className="d-none"
            ref={fileInputRef}
            multiple={props.isMultipleImage}
            onChange={handleFileChange}
          />
        </div>
        {imagePreviews.length > 0 ? (
          <Row className="align-items-center">
            {imagePreviews.map((preview, index) => (
              <Col key={index}>
                <div className={`${styles.imagePreviewCard}`}>
                  <div className={styles.imageContainer}>
                    <img src={preview.url} alt={preview.file.name} className={`${styles.image} img-fluid`} />
                    <div className={`${styles.imageRemoveIcon} cursor-pointer`} onClick={() => onRemoveImage(index)}>
                      <BsXLg color="#F05A5A" size={10} />
                    </div>
                  </div>

                  <AppTooltip tooltipId={preview.file.name + index} content={preview.file.name}>
                    <div className={`pt-1 ${styles.imageText} text-truncate`}>{preview.file.name}</div>
                  </AppTooltip>
                </div>
              </Col>
            ))}
            <Col>{props.isMultipleImage && selectedImageCount < maxImagesCount && imageAddButton()}</Col>
          </Row>
        ) : (
          imageAddButton()
        )}
      </Col>
    </Row>
  );
};

export default ImageUploader;
