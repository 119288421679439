import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaFilePdf } from 'react-icons/fa';
import IconCard from '../icon-card/icon-card';
import styles from './app-uploaded-material-presenter.module.scss';

interface IProps {
  materials: any;
}

const AppUploadedMaterialPresenter: FC<IProps> = props => {
  return (
    <Row>
      <Col className={`${styles.appUploadedMaterialPresenter}  cursor-pointer`}>
        <Row>
          <Col>
            <header className={`${styles.header} `}>Attachments: </header>
          </Col>
        </Row>

        <Row className="mt-2">
          {props.materials.map((value, index) => {
            return (
              <Col className="col-auto" key={index}>
                <div className={`d-flex align-items-center`} onClick={() => window.open(value.url, '_blank')}>
                  <IconCard icon={<FaFilePdf color="var(--icon-color)" />} />
                  <div className={`ps-2 ${styles.fileName}`}>{value.fileName}</div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default AppUploadedMaterialPresenter;
