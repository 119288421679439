import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getAllEntitiesAdmin, reset } from 'app/entities/course/course.reducer';
import CardLoader from 'app/shared/components/courses/card-loader/card-loader';
import CourseCard from 'app/shared/components/courses/course-card/couse-card';
import CourseFilter from 'app/shared/components/courses/course-filter/course-filter';
import Pagination from 'app/shared/components/pagination/pagination';
import { ECourseDifficultyLevel } from 'app/shared/model/enumerations/course-difficulty-level.model';
import { EFieldOfStudy } from 'app/shared/model/enumerations/field-of-study-type.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppTab from 'app/shared/ui-elements/app-tabs/app-tab';
import { getOrdinalValue } from 'app/shared/util/course';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AdminCourses = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { courseList, loading, totalItems } = useAppSelector((rootState: IRootState) => ({
    courseList: rootState.course.entities,
    loading: rootState.course.loading,
    totalItems: rootState.course.totalItems,
  }));

  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTabId, setSelectedTabId] = useState<string>('published');
  const [selectedField, setSelectedField] = useState('');
  const [selectedSkillLevel, setSelectedSkillLevel] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const paramsList = useMemo(() => {
    return {
      ...(selectedSkillLevel && {
        difficultyLevel: getOrdinalValue(ECourseDifficultyLevel, selectedSkillLevel),
      }),
      ...(searchInput && { searchText: searchInput }),
      ...(selectedField && {
        fieldOfStudy: getOrdinalValue(EFieldOfStudy, selectedField),
      }),
      page: currentPage - 1,
    };
  }, [selectedSkillLevel, searchInput, selectedField, selectedTabId, currentPage]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setItemsPerPage(4);
      } else if (window.innerWidth >= 768) {
        setItemsPerPage(4);
      } else {
        setItemsPerPage(2);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSearchInputChange = () => {
    paramsList.page = 0;
  };

  useEffect(() => {
    handleSearchInputChange();
  }, [searchInput]);

  useEffect(() => {
    dispatch(
      getAllEntitiesAdmin({
        difficultyLevel: paramsList.difficultyLevel,
        searchText: paramsList.searchText,
        fieldOfStudy: paramsList.fieldOfStudy,
        draft: selectedTabId === 'published' ? false : true,
        size: itemsPerPage,
        page: paramsList.page,
      })
    );
  }, [dispatch, paramsList, selectedTabId]);

  useEffect(() => {
    const maxPage = Math.ceil(totalItems / itemsPerPage);
    const newPage = maxPage < currentPage ? 1 : currentPage;
    setCurrentPage(newPage);
  }, [totalItems, currentPage]);

  const handlePageChange = currentPageNumber => {
    setCurrentPage(currentPageNumber);
  };

  const handleClick = () => {
    navigate('/admin/courses/course-add');
    dispatch(reset());
  };

  const courseCardClickHandler = (courseId: string) => {
    navigate(`${courseId}/course-materials`);
  };

  const handleFilterChange = (field, skillLevel) => {
    setSelectedField(field);
    setSelectedSkillLevel(skillLevel);
  };

  const handleSearch = searchValue => {
    setSearchInput(searchValue);
  };

  return (
    <>
      <CourseFilter title="Courses" onFilterChange={handleFilterChange} onSearch={handleSearch} />
      <Container className="mt-4 mt-md-5 mb-5">
        <Row className="justify-content-between">
          <Col className="col-12 col-md-6 col-lg-3">
            <AppTab
              tabs={[
                {
                  tabName: 'Published',
                  id: 'published',
                  path: 'published',
                },
                {
                  tabName: 'Unpublished',
                  id: 'unpublished',
                  path: 'unpublished',
                },
              ]}
              onChange={id => {
                setSelectedTabId(id);
              }}
              selectedTabId={selectedTabId}
            />
          </Col>
          <Col sm={12} md={3} lg={2} className="text-md-right">
            <AppButton text={'Add Course'} btnSize="sm" className="mt-4 mt-md-0" style={{ height: '40px' }} onClick={handleClick} />
          </Col>
        </Row>

        <Row className="mt-3">
          {loading &&
            Array.from({ length: itemsPerPage }).map((_, index) => (
              <Col sm={12} key={index} className="mb-4">
                <CardLoader />
              </Col>
            ))}
        </Row>

        {!loading && (
          <>
            <Row className={`mt-5 mb-0 mb-md-2 mb-lg-5`}>
              {courseList.map(item => (
                <Col sm={12} key={item.id} className="mb-4">
                  <CourseCard
                    course={item}
                    status={item}
                    onClick={() => courseCardClickHandler(item.id.toString())}
                    filterData={paramsList}
                  />
                </Col>
              ))}
            </Row>
          </>
        )}
        <Pagination length={totalItems} itemsPerPage={itemsPerPage} currentPage={currentPage} updateCurrentPage={handlePageChange} />
      </Container>
    </>
  );
};

export default AdminCourses;
