import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AUTHORITIES } from 'app/config/constants';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from 'app/entities/course/course.reducer';
import {
  addChapterExternalResourceHandler,
  addChapterMaterialHandler,
  addChapterTextHandler,
  addChapterVideoHandler,
} from 'app/modules/admin/chapter-management/manage-content.reducer';
import { getCouponEntity } from 'app/modules/admin/settings/coupones/coupons.reducer';
import { ICourseChapterPresenter } from 'app/modules/student/course-chapter-presenter/course-chapter-presenter.model';
import { ICourseLessonsUnitsPresenter } from 'app/modules/student/course-lessons-units-presenter/course-lessons-units-presenter.model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import AppChapterContentSecondaryNavBar from 'app/shared/components/nav-bar/app-chapter-content-nav-bar/AppChapterContentSecondaryNavBar';
import AppSecondaryNavBar from 'app/shared/components/nav-bar/app-secondary-nav-bar/app-secondary-nav-bar';
import ScrollToTop from 'app/shared/components/scroll-to-top/ScrollToTop';
import { ICourse } from 'app/shared/model/course.model';
import { ECHAPTER } from 'app/shared/model/enumerations/chapters-types';
import AppPill from 'app/shared/ui-elements/app-pill/app-pill';
import {
  IConvertedIdChapterExternalResource,
  IConvertedIdChapterMaterial,
  IConvertedIdChapterTextContent,
  IConvertedIdChapterVideo,
  IConvertedIdsChapterItemsResponse,
  getNextMaxId,
} from 'app/shared/util/dnd-utils';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaLink, FaRegFileLines, FaT, FaVideo, FaXmark } from 'react-icons/fa6';
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import OffCanvas from '../components/offCanvas/OffCanvas';

export type SecondaryNavCollapse = 'open' | 'closed';

const CourseLayout = () => {
  const params = useParams() as { courseId: string; lessonId: string; unitId: string; couponId: string };
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { courseChapterPresenterEntity, coursePresenterEntity, authState, courseManagerEntity, chapterEntity, couponEntity } =
    useAppSelector((state: IRootState) => ({
      courseChapterPresenterEntity: state.courseChapterPresenter.entity as ICourseChapterPresenter,
      coursePresenterEntity: state.courseLessonsUnitsPresenter.entity as ICourseLessonsUnitsPresenter,
      authState: state.authentication,
      courseManagerEntity: state.course.entity as ICourse,
      chapterEntity: state.content.entity as IConvertedIdsChapterItemsResponse,
      couponEntity: state.coupons.couponEntity,
    }));

  const isAuthorizedAdmin = hasAnyAuthority(authState.account.authorities, [AUTHORITIES.ADMIN]);

  const [collapse, setCollapse] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [selectedItem, setSelectedItem] = useState('');

  const closeHandler = () => setOpen(false);
  const openHandler = () => setOpen(true);

  useEffect(() => {
    if (params.courseId && isAuthorizedAdmin) {
      dispatch(getEntity(params.courseId));
    }
    if (params.couponId && isAuthorizedAdmin) {
      dispatch(getCouponEntity({ coupon: params.couponId }));
    }

    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const collapseHandler = () => {
    let secondaryNavCollapse: SecondaryNavCollapse;
    setCollapse(!collapse);
    if (!collapse) {
      secondaryNavCollapse = 'open';
    } else {
      secondaryNavCollapse = 'closed';
    }

    localStorage.setItem('secondary-nav-collapse', secondaryNavCollapse);
  };

  const studentCourseItemList = [
    {
      itemName: 'Course Dashboard',
      id: '1',
      path: '',
    },
    {
      itemName: 'Course Content',
      id: '2',
      path: 'course-materials',
    },
    {
      itemName: 'Grades',
      id: '3',
      path: '',
    },
  ];

  const adminCourseItemList = [
    {
      itemName: 'Course Dashboard',
      id: '1',
      path: '',
    },
    {
      itemName: 'Course Material',
      id: '2',
      path: 'course-materials',
    },
    {
      itemName: 'Grades',
      id: '3',
      path: '',
    },
    {
      itemName: 'User Roster',
      id: '4',
      path: 'user-roster',
    },
  ];

  const accountItemList = [
    {
      itemName: 'My Profile',
      id: '1',
      path: 'profile',
    },
    {
      itemName: 'My Orders',
      id: '2',
      path: '',
    },
    {
      itemName: 'My Certificates',
      id: '3',
      path: '',
    },
  ];

  const settingsList = [
    {
      itemName: 'Coupon Codes',
      id: '1',
      path: 'coupons',
    },
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const item = accountItemList.find(selectedAccountItem => currentPath.includes(selectedAccountItem.path));
    if (
      item &&
      item.itemName !== selectedItem &&
      (location.pathname.startsWith('/admin/account') || location.pathname.startsWith('/student/account'))
    ) {
      setSelectedItem(item.itemName);
    }
  }, [location.pathname, accountItemList]);

  const getList = () => {
    if (location.pathname.startsWith('/admin/courses') || location.pathname.startsWith('/student/courses')) {
      if (isAuthorizedAdmin) {
        return adminCourseItemList;
      } else {
        return studentCourseItemList;
      }
    } else if (location.pathname.startsWith('/admin/settings')) {
      return settingsList;
    } else if (location.pathname.startsWith('/admin/account')) {
      return accountItemList.slice(0, accountItemList.length - 2);
    } else if (location.pathname.startsWith('/student/account')) {
      return accountItemList;
    }
  };

  const getTitle = () => {
    if (location.pathname.startsWith('/admin/settings')) {
      if (location.pathname.startsWith('/admin/settings/coupons/add')) {
        return 'Create Coupon Codes';
      } else if (location.pathname.startsWith('/admin/settings/coupons')) {
        return 'Coupon Codes';
      } else return 'Settings';
    } else if (location.pathname.startsWith('/admin/courses') || location.pathname.startsWith('/student/courses')) {
      return isAuthorizedAdmin ? courseManagerEntity.name : coursePresenterEntity.courseName;
    } else if (location.pathname.startsWith('/admin/account') || location.pathname.startsWith('/student/account')) {
      return 'Account';
    }
  };

  const getSubTitle = () => {
    if (location.pathname.startsWith('/admin/courses') || location.pathname.startsWith('/student/courses')) {
      return null;
    } else if (location.pathname.startsWith('/admin/account') || location.pathname.startsWith('/student/account')) {
      return authState.account.firstName + ' ' + authState.account.lastName;
    }
  };

  const getLayoutBodyTitle = () => {
    if (location.pathname.startsWith(`/admin/courses/${params.courseId}/user-roster/enroll-user`)) {
      return 'Enroll User';
    } else if (location.pathname.startsWith('/admin/settings')) {
      if (location.pathname.startsWith(`/admin/settings/coupons/${params.couponId}/view`)) {
        return `Coupon Codes > ${couponEntity === null ? '' : couponEntity.couponCode}`;
      } else if (location.pathname.startsWith(`/admin/settings/coupons/${params.couponId}/edit`)) {
        return 'Update Coupon Codes';
      } else if (location.pathname.startsWith('/admin/settings/coupons/add')) {
        return 'Create Coupon Codes';
      } else if (location.pathname.startsWith('/admin/settings/coupons')) {
        return 'Coupon Codes';
      } else return 'Settings';
    } else if (location.pathname.startsWith('/admin/courses') || location.pathname.startsWith('/student/courses')) {
      return isAuthorizedAdmin ? courseManagerEntity.name : coursePresenterEntity.courseName;
    } else if (location.pathname.startsWith('/admin/account/profile') || location.pathname.startsWith('/student/account/profile')) {
      return 'My Profile';
    }
  };

  const onAddChapterExternalResourceClick = () => {
    const chapterItemSequenceNumber = getNextMaxId(chapterEntity.contentItems);

    const item: IConvertedIdChapterExternalResource = {
      id: null,
      chapterId: 0, // this value is set to zero because this values is not referred from here.
      resourceLinks: [{ referenceName: '', url: '', isValidReferenceName: true, isValidUrl: true }],
      chapterItemSequenceNumber,
      contentType: ECHAPTER.EXTERNAL_RESOURCE,
    };

    dispatch(addChapterExternalResourceHandler(item));
  };

  const onAddChapterMaterialClick = () => {
    const chapterItemSequenceNumber = getNextMaxId(chapterEntity.contentItems);

    const item: IConvertedIdChapterMaterial = {
      id: null,
      chapterId: 0, // this value is set to zero because this values is not referred from here.
      materials: [],
      type: 0,
      chapterItemSequenceNumber,
      contentType: ECHAPTER.MATERIAL,
    };

    dispatch(addChapterMaterialHandler(item));
  };

  const onAddChapterVideoClick = () => {
    const chapterItemSequenceNumber = getNextMaxId(chapterEntity.contentItems);

    const item: IConvertedIdChapterVideo = {
      id: null,
      chapterId: 0, // this value is set to zero because this values is not referred from here.
      fileName: '',
      url: '',
      type: 0,
      chapterItemSequenceNumber,
      contentType: ECHAPTER.VIDEO,
    };

    dispatch(addChapterVideoHandler(item));
  };

  const onAddChapterTextClick = () => {
    const chapterItemSequenceNumber = getNextMaxId(chapterEntity.contentItems);

    const item: IConvertedIdChapterTextContent = {
      id: null,
      chapterId: 0, // this value is set to zero because this values is not referred from here.
      content: '',
      chapterItemSequenceNumber,
      contentType: ECHAPTER.TEXT_CONTENT,
    };

    dispatch(addChapterTextHandler(item));
  };

  const getCourseBasePath = () => {
    const adminCourseBasePath = `/admin/courses/${params.courseId}`;
    const studentCourseBasePath = `/student/courses/${params.courseId}`;

    if (isAuthorizedAdmin) {
      return adminCourseBasePath;
    } else {
      return studentCourseBasePath;
    }
  };

  const onClickCloseChaptersPreviewing = () => {
    if (params.unitId) {
      navigate(`/admin/courses/${params.courseId}/course-materials/lesson/${params.lessonId}/unit/${params.unitId}/chapters`);
    } else {
      navigate(`/admin/courses/${params.courseId}/course-materials/lesson/${params.lessonId}/chapters`);
    }
  };

  const renderContent = () => {
    return (
      <section className="course-layout">
        <div className="d-flex flex-row">
          <nav className={`course-layout__nav ${collapse && 'course-layout__nav--collapsed'} d-none d-md-block vh-100`}>
            <div className={`course-layout__nav-content ${collapse && 'course-layout__nav-content--opacity-zero'}`}>
              <Routes>
                <Route
                  path="/course-materials"
                  element={
                    <AppSecondaryNavBar title={getTitle()} subTitle={getSubTitle()} itemList={getList()} basePath={getCourseBasePath()} />
                  }
                />
                <Route path="/*" element={<AppSecondaryNavBar title={getTitle()} subTitle={getSubTitle()} itemList={getList()} />} />
                <Route
                  path="/course-materials/enroll/:enrollmentId/lesson/:lessonId"
                  element={
                    <AppChapterContentSecondaryNavBar
                      title={courseChapterPresenterEntity.lessonOrUnitName}
                      itemList={courseChapterPresenterEntity.chapters.map(c => {
                        return {
                          itemName: c.chapterName,
                          chapterId: c.chapterId,
                          isCompleted: c.completed,
                          contents: c.contents,
                        };
                      })}
                    />
                  }
                />
                <Route
                  path="/course-materials/enroll/:enrollmentId/lesson/:lessonId/unit/:unitId"
                  element={
                    <AppChapterContentSecondaryNavBar
                      title={courseChapterPresenterEntity.lessonOrUnitName}
                      itemList={courseChapterPresenterEntity.chapters.map(c => {
                        return {
                          itemName: c.chapterName,
                          chapterId: c.chapterId,
                          isCompleted: c.completed,
                          contents: c.contents,
                        };
                      })}
                    />
                  }
                />

                <Route
                  path="/course-materials/lesson/:lessonId/chapters/preview"
                  element={
                    <AppChapterContentSecondaryNavBar
                      title={courseChapterPresenterEntity.lessonOrUnitName}
                      itemList={courseChapterPresenterEntity.chapters.map(c => {
                        return {
                          itemName: c.chapterName,
                          chapterId: c.chapterId,
                          isCompleted: c.completed,
                          contents: c.contents,
                        };
                      })}
                    />
                  }
                />

                <Route
                  path="/course-materials/lesson/:lessonId/unit/:unitId/chapters/preview"
                  element={
                    <AppChapterContentSecondaryNavBar
                      title={courseChapterPresenterEntity.lessonOrUnitName}
                      itemList={courseChapterPresenterEntity.chapters.map(c => {
                        return {
                          itemName: c.chapterName,
                          chapterId: c.chapterId,
                          isCompleted: c.completed,
                          contents: c.contents,
                        };
                      })}
                    />
                  }
                />
              </Routes>
            </div>
          </nav>
          <div className="flex-grow-1 d-flex flex-column">
            <div>
              <header
                className={`course-layout__header ${collapse && 'course-layout__header--collapsed'} d-md-flex bg-secondary text-dark`}
              >
                <div className="d-flex align-items-center">
                  <button
                    className="course-layout__nav-toggler"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvas"
                    aria-controls="offcanvas"
                  >
                    <FontAwesomeIcon
                      icon={faBars}
                      color="#0092D0"
                      cursor="pointer"
                      onClick={collapseHandler}
                      className="course-layout__icon d-none d-md-block pe-2"
                    />
                    <FontAwesomeIcon
                      icon={faBars}
                      color="#0092D0"
                      cursor="pointer"
                      onClick={openHandler}
                      className={`course-layout__icon  d-md-none pe-2 ${isMobileView ? 'pt-2' : ''}`}
                    />
                  </button>
                  <p className="course-layout__title mt-3 ms-2">{getLayoutBodyTitle()}</p>{' '}
                </div>
                <div className="ms-auto d-flex">
                  <Routes>
                    <Route path="/course-materials/lesson/:lessonId/chapters/*">
                      <Route
                        path="add-chapter"
                        element={
                          <React.Fragment>
                            <AppPill name="Text" icon={FaT} onClick={onAddChapterTextClick} />
                            <AppPill name="Video/Audio" icon={FaVideo} className="ms-2" onClick={onAddChapterVideoClick} />
                            <AppPill name="Links" icon={FaLink} className="ms-2" onClick={onAddChapterExternalResourceClick} />
                            <AppPill name="Material" icon={FaRegFileLines} className="ms-2" onClick={onAddChapterMaterialClick} />
                          </React.Fragment>
                        }
                      />

                      <Route
                        path=":chapterId/edit-chapter"
                        element={
                          <React.Fragment>
                            <AppPill name="Text" icon={FaT} onClick={onAddChapterTextClick} />
                            <AppPill name="Video/Audio" icon={FaVideo} className="ms-2" onClick={onAddChapterVideoClick} />
                            <AppPill name="Links" icon={FaLink} className="ms-2" onClick={onAddChapterExternalResourceClick} />
                            <AppPill name="Material" icon={FaRegFileLines} className="ms-2" onClick={onAddChapterMaterialClick} />
                          </React.Fragment>
                        }
                      />

                      <Route
                        path="preview"
                        element={
                          <React.Fragment>
                            <AppPill name="Previewing" icon={FaXmark} onClick={onClickCloseChaptersPreviewing} />
                          </React.Fragment>
                        }
                      />
                    </Route>

                    <Route path="/course-materials/lesson/:lessonId/unit/:unitId/chapters/*">
                      <Route
                        path="add-chapter"
                        element={
                          <React.Fragment>
                            <AppPill name="Text" icon={FaT} onClick={onAddChapterTextClick} />
                            <AppPill name="Video/Audio" icon={FaVideo} className="ms-2" onClick={onAddChapterVideoClick} />
                            <AppPill name="Links" icon={FaLink} className="ms-2" onClick={onAddChapterExternalResourceClick} />
                            <AppPill name="Material" icon={FaRegFileLines} className="ms-2" onClick={onAddChapterMaterialClick} />
                          </React.Fragment>
                        }
                      />

                      <Route
                        path=":chapterId/edit-chapter"
                        element={
                          <React.Fragment>
                            <AppPill name="Text" icon={FaT} onClick={onAddChapterTextClick} />
                            <AppPill name="Video/Audio" icon={FaVideo} className="ms-2" onClick={onAddChapterVideoClick} />
                            <AppPill name="Links" icon={FaLink} className="ms-2" onClick={onAddChapterExternalResourceClick} />
                            <AppPill name="Material" icon={FaRegFileLines} className="ms-2" onClick={onAddChapterMaterialClick} />
                          </React.Fragment>
                        }
                      />

                      <Route
                        path="preview"
                        element={
                          <React.Fragment>
                            <AppPill name="Previewing" icon={FaXmark} onClick={onClickCloseChaptersPreviewing} />
                          </React.Fragment>
                        }
                      />
                    </Route>
                  </Routes>
                </div>

                <OffCanvas open={open} closeHandler={closeHandler}>
                  <div className="pt-5">
                    <Routes>
                      <Route
                        path="/course-materials"
                        element={<AppSecondaryNavBar title={getTitle()} subTitle={getSubTitle()} itemList={getList()} />}
                      />
                      <Route path="/*" element={<AppSecondaryNavBar title={getTitle()} subTitle={getSubTitle()} itemList={getList()} />} />
                      <Route
                        path="/course-materials/enroll/:enrollmentId/lesson/:lessonId"
                        element={
                          <AppChapterContentSecondaryNavBar
                            title={courseChapterPresenterEntity.lessonOrUnitName}
                            itemList={courseChapterPresenterEntity.chapters.map(c => {
                              return {
                                itemName: c.chapterName,
                                chapterId: c.chapterId,
                                isCompleted: c.completed,
                                contents: c.contents,
                              };
                            })}
                          />
                        }
                      />
                      <Route
                        path="/course-materials/enroll/:enrollmentId/lesson/:lessonId/unit/:unitId"
                        element={
                          <AppChapterContentSecondaryNavBar
                            title={courseChapterPresenterEntity.lessonOrUnitName}
                            itemList={courseChapterPresenterEntity.chapters.map(c => {
                              return {
                                itemName: c.chapterName,
                                chapterId: c.chapterId,
                                isCompleted: c.completed,
                                contents: c.contents,
                              };
                            })}
                          />
                        }
                      />

                      <Route
                        path="/course-materials/lesson/:lessonId/chapters/preview"
                        element={
                          <AppChapterContentSecondaryNavBar
                            title={courseChapterPresenterEntity.lessonOrUnitName}
                            itemList={courseChapterPresenterEntity.chapters.map(c => {
                              return {
                                itemName: c.chapterName,
                                chapterId: c.chapterId,
                                isCompleted: c.completed,
                                contents: c.contents,
                              };
                            })}
                          />
                        }
                      />

                      <Route
                        path="/course-materials/lesson/:lessonId/unit/:unitId/chapters/preview"
                        element={
                          <AppChapterContentSecondaryNavBar
                            title={courseChapterPresenterEntity.lessonOrUnitName}
                            itemList={courseChapterPresenterEntity.chapters.map(c => {
                              return {
                                itemName: c.chapterName,
                                chapterId: c.chapterId,
                                isCompleted: c.completed,
                                contents: c.contents,
                              };
                            })}
                          />
                        }
                      />
                    </Routes>
                  </div>
                </OffCanvas>
              </header>
            </div>
            <div>
              <section className={`course-layout__body ${collapse && 'course-layout__body--collapsed'}`}>
                <ScrollToTop />
                <Container fluid className="p-0 h-100">
                  <Outlet />
                </Container>
              </section>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return <>{renderContent()}</>;
};

export default CourseLayout;
