export interface ICourseChapterPresenter {
  lessonOrUnitName: string;
  lessonOrUnitNameDescription: string;
  chapters: {
    id: number;
    chapterId: number;
    chapterItemSequenceNumber: number;
    [key: string]: any;
  }[];
}

export const defaultValue: Readonly<ICourseChapterPresenter> = {
  lessonOrUnitName: '',
  lessonOrUnitNameDescription: '',
  chapters: [],
};
