// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LkscFPMqtVSmP1WDuGIQ {
  color: var(--text-secondary-color);
}

.RYz7pyvl7jJkmWEE1D4S {
  background-color: var(--text-secondary-color);
}

.BYp77Lii5oyK8kZOUfP5 {
  border-radius: 12px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/program-catalog/course-grid-view/course-loader-card/course-loader-card.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;AACF;;AAEA;EACE,6CAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".courseCardLoader {\n  color: var(--text-secondary-color);\n}\n\n.cardLoaderImg {\n  background-color: var(--text-secondary-color);\n}\n\n.cardBorderRadius {\n  border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseCardLoader": `LkscFPMqtVSmP1WDuGIQ`,
	"cardLoaderImg": `RYz7pyvl7jJkmWEE1D4S`,
	"cardBorderRadius": `BYp77Lii5oyK8kZOUfP5`
};
export default ___CSS_LOADER_EXPORT___;
