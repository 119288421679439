// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KmDucuENQ0xq4utZcAda .k2wiJZuQUqy8twOuxxZw {
  color: var(--text-primary-color);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.KmDucuENQ0xq4utZcAda .AGi7eS_0_2cEfmBnpgWw {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: var(--text-primary-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/pages/change-password/change-password.module.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gCAAA;AADJ","sourcesContent":[".formSection {\n  .changePasswordTitle {\n    color: var(--text-primary-color);\n    font-size: 30px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n  }\n\n  .changePasswordTextStyle {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 19px;\n    text-align: center;\n    color: var(--text-primary-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSection": `KmDucuENQ0xq4utZcAda`,
	"changePasswordTitle": `k2wiJZuQUqy8twOuxxZw`,
	"changePasswordTextStyle": `AGi7eS_0_2cEfmBnpgWw`
};
export default ___CSS_LOADER_EXPORT___;
