import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { IRootState, useAppSelector } from 'app/config/store';
import { ETheme } from 'app/shared/model/enumerations/theme.model';
import { BsEnvelopeFill } from 'react-icons/bs';
import styles from './footer.module.scss';

const Footer = () => {
  const { theme } = useAppSelector((state: IRootState) => ({ theme: state.theme.theme }));

  return (
    <div className={`${styles['footer-container']}`}>
      <Container className="py-5">
        <Row>
          <Col className="col-12 col-lg-6">
            <Row>
              <Col className="col-12 col-lg-auto text-center mb-3 mb-lg-2">
                {/* <img src={'../../../../content/images/footer-logo.png'} alt="skill_surf logo" className="img-fluid" /> */}
                <img
                  src={
                    theme === ETheme.DARK
                      ? '../../../../content/images/ALAthwalaLogo(Horizontal)_Dark.webp'
                      : '../../../../content/images/ALAthwalaLogo(Horizontal)_Light.webp'
                  }
                  alt="Athwela- skill surf logo"
                  height={50}
                  width={150}
                  className="img-fluid"
                />
              </Col>
              <Col className="col-12 col-lg">
                <Row>
                  <Col>
                    <p className={`${styles['footer-slogan']} mb-2 text-center text-lg-start fw-normal`}>සිහින දිනන්න අපෙන් අත්වැලක්</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p className={`${styles['footer-copyrights']} mb-0 text-center text-lg-start fw-normal`}>
                      &#169; {new Date().getFullYear()} A/L අත්වැල by Skill Surf. All Rights Reserved
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-md-6 col-lg-3 mt-4 mt-lg-0">
            <Row>
              <Col>
                <p className={`${styles['footer-contactUs']} text-center text-lg-start mb-0 fw-bold`}>Contact Us</p>
              </Col>
            </Row>
            <Row className="justify-content-center justify-content-lg-start">
              <Col className="col-auto pe-0 d-flex align-items-center">
                <BsEnvelopeFill color="var(--accent-color)" />
              </Col>
              <Col className="col-auto">
                <a href="mailto:alevel.skf@gmail.com" className={`${styles['footer-email']} text-decoration-none fw-normal`}>
                  alevel.skf@gmail.com
                </a>
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-md-6 col-lg-3 mt-4 mt-lg-0">
            <Row>
              <Col>
                <p className={`${styles['footer-contactUs']} mb-0 text-center text-lg-start fw-bold`}>Follow Us</p>
              </Col>
            </Row>
            <Row className="justify-content-center justify-content-lg-start">
              <Col className="col-auto">
                <a href="https://facebook.com/AlevelbySKF/">
                  <img src={'../../../../content/images/fb.svg'} alt="Facebook Icon" />
                </a>
              </Col>

              <Col className="col-auto">
                <a href="https://www.linkedin.com/company/alevelbyskf/">
                  <img src={'../../../../content/images/in.svg'} alt="LinkedIn Icon" />
                </a>
              </Col>
              <Col className="col-auto">
                <a href="https://twitter.com/AlevelbySKF">
                  <img src={'../../../../content/images/icons8-twitterx.svg'} alt="Twitter Icon" />
                </a>
              </Col>
              <Col className="col-auto">
                <a href="https://www.tiktok.com/@alevelbyskf">
                  <img src={'../../../../content/images/icons8-tik-tok.svg'} alt="Tik-tok Icon" />
                </a>
              </Col>
              <Col className="col-auto">
                <a href="https://www.youtube.com/@AlevelbySKF">
                  <img src={'../../../../content/images/youtube.svg'} alt="Youtube Icon" />
                </a>
              </Col>
              <Col className="col-auto">
                <a href="https://wa.me/+94767666555">
                  <img src={'../../../../content/images/icons8-whatsapp.svg'} alt="Whatsapp Icon" />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
