// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NzmrYNZ8C6bw0ofc9F55 {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary-color);
}

.y4UTdz2Rb_I2RskrisEP {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary-color);
}

.jDhy7xmM_ZIEkNzKe0F8 {
  font-size: 22px;
}

.UmfPz3GEGIkNy5O3AMQg {
  font-size: 14px;
}

.qY3HTK5GLLLmcJqmUgXb {
  color: var(--error-color);
}

.VeYENvYvvfp5weQphjNQ {
  color: var(--success-color);
}

.YI4yvf_R3gb5aQLdOncB {
  transition: transform 200ms ease-in;
}

.eCULx4Za9EBQ7BSBOSWp {
  transform: rotate(0deg);
}

.lDdv5ZMDIHUXLnYhJxYz {
  transform: rotate(180deg);
}

.yR9xQ85FMILY4ma4o7Tb {
  font-size: 16px;
  font-weight: 400;
  color: var(--text-secondary-color);
}

.k8jemitP0wSpBlA3XA4e {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-secondary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/admin/settings/coupones/coupons-viewer/coupons-viewer.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,gCAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,gCAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,mCAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,kCAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,6CAAA;AACF","sourcesContent":[".coupon {\n  font-size: 18px;\n  font-weight: 600;\n  color: var(--text-primary-color);\n}\n\n.values {\n  font-size: 14px;\n  font-weight: 400;\n  color: var(--text-primary-color);\n}\n\n.actionButtons {\n  font-size: 22px;\n}\n\n.toggleStatus {\n  font-size: 14px;\n}\n\n.expired {\n  color: var(--error-color);\n}\n\n.notExpired {\n  color: var(--success-color);\n}\n\n.upArrow {\n  transition: transform 200ms ease-in;\n}\n\n.rotate0 {\n  transform: rotate(0deg);\n}\n\n.rotate180 {\n  transform: rotate(180deg);\n}\n\n.txt1 {\n  font-size: 16px;\n  font-weight: 400;\n  color: var(--text-secondary-color);\n}\n\n.txt2 {\n  font-size: 14px;\n  font-weight: 400;\n  color: var(--text-secondary-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coupon": `NzmrYNZ8C6bw0ofc9F55`,
	"values": `y4UTdz2Rb_I2RskrisEP`,
	"actionButtons": `jDhy7xmM_ZIEkNzKe0F8`,
	"toggleStatus": `UmfPz3GEGIkNy5O3AMQg`,
	"expired": `qY3HTK5GLLLmcJqmUgXb`,
	"notExpired": `VeYENvYvvfp5weQphjNQ`,
	"upArrow": `YI4yvf_R3gb5aQLdOncB`,
	"rotate0": `eCULx4Za9EBQ7BSBOSWp`,
	"rotate180": `lDdv5ZMDIHUXLnYhJxYz`,
	"txt1": `yR9xQ85FMILY4ma4o7Tb`,
	"txt2": `k8jemitP0wSpBlA3XA4e`
};
export default ___CSS_LOADER_EXPORT___;
