// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EXqx0wb_RRIHRDMkYTkF {
  color: var(--icon-color);
}

.vS4hfZi3Gq5wnhzDTdCu {
  color: var(--accent-color);
}

.vYkiYcRRQ_EWTiRRIEdX {
  font-size: 12px;
  font-weight: 400;
  text-wrap: nowrap;
}

.pLIu82dQorEFF4UATmIO {
  color: var(--text-secondary-color);
}

.lAupwsH_rZVZ5GCUym79 {
  color: #ffffff;
}

.rlmANpqyVjYJ0dJYqOop {
  color: var(--accent-color);
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/icon-with-text/IconWithText.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,0BAAA;EACA,eAAA;AACF","sourcesContent":[".icon {\n  color: var(--icon-color);\n}\n\n.coloredIcon {\n  color: var(--accent-color);\n}\n\n.iconWithTextFont {\n  font-size: 12px;\n  font-weight: 400;\n  text-wrap: nowrap;\n}\n\n.primary {\n  color: var(--text-secondary-color);\n}\n\n.secondary {\n  color: #ffffff;\n}\n\n.colored {\n  color: var(--accent-color);\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `EXqx0wb_RRIHRDMkYTkF`,
	"coloredIcon": `vS4hfZi3Gq5wnhzDTdCu`,
	"iconWithTextFont": `vYkiYcRRQ_EWTiRRIEdX`,
	"primary": `pLIu82dQorEFF4UATmIO`,
	"secondary": `lAupwsH_rZVZ5GCUym79`,
	"colored": `rlmANpqyVjYJ0dJYqOop`
};
export default ___CSS_LOADER_EXPORT___;
