import { ICourseDetails } from 'app/shared/model/dto/course-details.model';
import { IDiscount } from 'app/shared/model/dto/discount.model';
import { EDiscountType } from 'app/shared/model/enumerations/discount-type.model';
import { formatNumberToCurrency } from 'app/shared/util/date-utils';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './pricing-card.module.scss';

interface IProps {
  course: ICourseDetails;
  discountDetails: IDiscount;
}

const PricingCard = (props: IProps) => {
  const { course, discountDetails } = props;

  return (
    <div className={`${styles.pricingCard} p-4`}>
      <Row className="align-items-center">
        <Col className="col-12 col-sm-3 col-lg-4 col-xl-3">
          <img src={course.coverImageUrl} className={`${styles.pricingCard__img} img-fluid`} alt="cover-image" />
        </Col>
        <Col className={`${styles.pricingCard__txt1} col-12 col-sm mt-3 mt-sm-0`}>{course.name}</Col>
      </Row>
      <Row className="mt-4">
        <Col className={styles.pricingCard__txt2}>
          {/* <p className="mb-0">Price for Sri Lankan students and fresh graduates</p> */}
          <p className={discountDetails?.couponCode ? `mb-3` : 'mb-0'}>
            Total Amount{' '}
            <span className={styles.pricingCard__txt3}>
              LKR{' '}
              {discountDetails?.couponCode
                ? formatNumberToCurrency(discountDetails?.selectedCourseSubTotal)
                : formatNumberToCurrency(course?.price)}
            </span>
          </p>
        </Col>
      </Row>
      {discountDetails?.couponCode && (
        <Row>
          <Col>
            <p className={`${styles.pricingCard__txt2} text-decoration-line-through`}>{formatNumberToCurrency(course?.price)}</p>
            <p className={styles.pricingCard__txt4}>
              {discountDetails.discountType === EDiscountType.PERCENTAGE
                ? `${discountDetails?.discountAmount}%`
                : `LKR ${discountDetails.discountAmount}`}{' '}
              OFF
            </p>

            <p className={`${styles.pricingCard__txt5} mb-0`}>{discountDetails?.couponCode}</p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PricingCard;
