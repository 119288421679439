// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tGg3qWbvbU2fiGdplgcF {
  color: var(--text-primary-color);
  font-size: 12px;
}

.KICO5GDN3BPnFSjJvh6f {
  color: var(--text-secondary-color);
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/admin/settings/coupones/coupons-manager/coupons-manager.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,eAAA;AACF;;AAEA;EACE,kCAAA;EACA,eAAA;AACF","sourcesContent":[".txt1 {\n  color: var(--text-primary-color);\n  font-size: 12px;\n}\n\n.txt2 {\n  color: var(--text-secondary-color);\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt1": `tGg3qWbvbU2fiGdplgcF`,
	"txt2": `KICO5GDN3BPnFSjJvh6f`
};
export default ___CSS_LOADER_EXPORT___;
