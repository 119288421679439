// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cqVz2oOd7BZTzFAghUTW {
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/admin/course-chapter-manager/course-chapter-manager.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,eAAA;EACA,gBAAA;AACF","sourcesContent":[".pageText {\n  color: var(--text-primary-color);\n  font-size: 16px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageText": `cqVz2oOd7BZTzFAghUTW`
};
export default ___CSS_LOADER_EXPORT___;
