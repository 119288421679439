import { IDiscount } from 'app/shared/model/dto/discount.model';
import { IPaymentRequest } from 'app/shared/model/dto/payment-request.model';
import IPaymentResponseById from 'app/shared/model/dto/payment-response-by-id.model';
import { IPaymentResponse } from 'app/shared/model/dto/payment-response.model';
import axios from 'axios';

export const createPayment = async (entity: IPaymentRequest) => {
  return await axios.post('api/purchase', entity).then(response => {
    return response.data as IPaymentResponse;
  });
};

export const fetchPaymentDataByCoupon = async (couponCode: string, courseId: number) => {
  return await axios.get('api/coupons/apply', { params: { couponCode, courseId } }).then(response => {
    return response.data as IDiscount;
  });
};

export const fetchPaymentDataByOrderId = async (orderId: number) => {
  return await axios.get(`api/payment/${orderId}/view`).then(response => {
    return response.data as IPaymentResponseById;
  });
};

export const cancelPayment = async (orderId: number) => {
  return await axios.get(`api/payment/${orderId}/cancel`).then(response => {
    response.data;
  });
};
