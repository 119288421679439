// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aelV8cAU8dvASsRmhynl {
  color: var(--text-primary-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}

.M15fSdNLE90cpf28MztX {
  color: var(--text-secondary-color);
  font-size: 16px;
  font-style: normal;
}

.PnAlohbuWGrr0aAgqSsQ {
  color: var(--success-icon-color);
}

.l2soiszWZCGL5MnAz_6Z {
  color: var(--error-icon-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/pages/payment/payment.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE,8BAAA;AACF","sourcesContent":[".txt1 {\n  color: var(--text-primary-color);\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 500;\n}\n\n.txt2 {\n  color: var(--text-secondary-color);\n  font-size: 16px;\n  font-style: normal;\n}\n\n.success-icon {\n  color: var(--success-icon-color);\n}\n\n.error-icon {\n  color: var(--error-icon-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt1": `aelV8cAU8dvASsRmhynl`,
	"txt2": `M15fSdNLE90cpf28MztX`,
	"success-icon": `PnAlohbuWGrr0aAgqSsQ`,
	"error-icon": `l2soiszWZCGL5MnAz_6Z`
};
export default ___CSS_LOADER_EXPORT___;
