import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import TextArea from 'app/shared/ui-elements/text-area-component/TextArea';
import { emailRegex } from 'app/shared/util/regex';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import styles from './ContactUs.module.scss';

type ContactUsFormValues = {
  name: string;
  email: string;
  message: string;
};

const ContactUs = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContactUsFormValues>({});

  const onSubmit: SubmitHandler<ContactUsFormValues> = (data: ContactUsFormValues) => {};

  return (
    <Container>
      <section className={`${styles.contactSection} d-flex align-items-center px-2 py-5`}>
        <Row className="align-items-center">
          <Col className="col-12 col-lg-6 text-center">
            <img src={'./../../../../content/images/image-contact.webp'} className="img-fluid" alt="An illustration of chat" />
          </Col>
          <Col className="col-12 col-lg-6 pt-4 pt-lg-0">
            <h3 className={`${styles.contactSection__text1} text-center text-sm-start`}>Contact Us</h3>
            <p className={` ${styles.contactSection__text2} fw-normal text-center text-sm-start`}>
              Reach us with all your requests, questions and feedback! If you have specific requirements about teaching content in related
              areas, reach out to us and let&rsquo;s explore how the content can be tailor-made for your institute.
            </p>
            <form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col className="mb-3">
                  <AppInput
                    id="name"
                    name="name"
                    label="Your Name"
                    placeholder={'Your Name'}
                    register={register('name', {
                      required: 'Your name is required',
                      minLength: {
                        value: 3,
                        message: 'Your name should contain at least 3 characters',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <AppInput
                    id="email"
                    name="email"
                    label="Email"
                    placeholder={'Your Email'}
                    register={register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3">
                  <TextArea
                    id="message"
                    label="Your Message"
                    name="message"
                    placeholder="Your Message"
                    register={register('message', {
                      required: 'Your message is required',
                      minLength: {
                        value: 20,
                        message: 'Your message should at least contain 20 characters',
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row className={`d-flex justify-content-center justify-content-sm-start`}>
                <Col className="col-auto">
                  <AppButton className="fw-bold" text="SEND" btnSize="sm" />
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default ContactUs;
