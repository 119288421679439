import { IRowData } from '../components/multiple-rows-addition-field/multiple-rows-addition-field';
import { ECHAPTER } from '../model/enumerations/chapters-types';

export type ChapterContentType = ECHAPTER.EXTERNAL_RESOURCE | ECHAPTER.MATERIAL | ECHAPTER.TEXT_CONTENT | ECHAPTER.VIDEO;

type BaseObjectWithNumberId = {
  id: number;
  [key: string]: any;
};

type BaseObjectWithStringId = {
  id: string;
  [key: string]: any;
};

type ObjectChapterItemSequenceNumberAsNumber = {
  chapterItemSequenceNumber: number;
  id: number;
  contentType: ChapterContentType;
  [key: string]: any;
};

export type ObjectChapterItemSequenceNumberAsString = {
  chapterItemSequenceNumber: string;
  id: number;
  contentType: ChapterContentType;
  [key: string]: any;
};

type LessonObjectWithNumberId = {
  lessonId: number;
  [key: string]: any;
};

type LessonObjectWithStringId = {
  lessonId: string;
  [key: string]: any;
};

type UnitObjectWithNumberId = {
  unitId: number;
  [key: string]: any;
};

type UnitObjectWithStringId = {
  unitId: string;
  [key: string]: any;
};

type ChapterObjectWithNumberId = {
  chapterId: number;
  [key: string]: any;
};

type ChapterObjectWithStringId = {
  chapterId: string;
  [key: string]: any;
};

export interface ICourseLessonAndUnitSequenceResponse {
  courseId: number;
  courseName: string;
  coverImageUrl: string | null;
  lessons: {
    lessonId: string;
    lessonName: string;
    lessonDescription: string;
    completed: boolean;
    units: {
      unitId: string;
      unitName: string;
      completed: boolean;
    }[];
  }[];
  specialization: string | null;
}

export interface ICourseLessonsAndUnitsSequenceRequest {
  courseId: number;
  sequence: {
    lessonId: number;
    units: number[];
  }[];
}

export interface IChapterContentResponse {
  lessonOrUnitName: string;
  lessonOrUnitNameDescription: string;
  chapters: {
    chapterId: string;
    chapterName: number;
    completed: boolean;
    contents: any;
  }[];
}

export interface IResourceLink {
  referenceName: string;
  url: string;
}

export interface IResourceLinkUtilized extends IResourceLink {
  isValidReferenceName: boolean;
  isValidUrl: boolean;
}

export interface IChapterExternalResource {
  id: number;
  chapterId: number;
  resourceLinks: IResourceLink[];
  chapterItemSequenceNumber: number;
  contentType: ECHAPTER.EXTERNAL_RESOURCE;
}

export interface IConvertedIdChapterExternalResource {
  id: number;
  chapterId: number;
  resourceLinks: IResourceLinkUtilized[];
  chapterItemSequenceNumber: string;
  contentType: ECHAPTER.EXTERNAL_RESOURCE;
}

enum ChapterMaterialType {
  CSV,
  PDF,
}

export interface IMaterial {
  fileName: string;
  url: string;
}

interface IChapterMaterial {
  id: number;
  chapterId: number;
  materials: IMaterial[];
  type: ChapterMaterialType;
  chapterItemSequenceNumber: number;
  contentType: ECHAPTER.MATERIAL;
}

export interface IConvertedIdChapterMaterial {
  id: number;
  chapterId: number;
  materials: IMaterial[];
  type: ChapterMaterialType;
  chapterItemSequenceNumber: string;
  contentType: ECHAPTER.MATERIAL;
}

enum IChapterVideoType {
  MKV,
  MP4,
}

interface IChapterVideo {
  id: number;
  chapterId: number;
  fileName: string;
  url: string;
  type: IChapterVideoType;
  chapterItemSequenceNumber: number;
  contentType: ECHAPTER.VIDEO;
}

export interface IConvertedIdChapterVideo {
  id: number;
  chapterId: number;
  fileName: string;
  url: string;
  type: IChapterVideoType;
  chapterItemSequenceNumber: string;
  contentType: ECHAPTER.VIDEO;
}

interface IChapterTextContent {
  id: number;
  chapterId: number;
  content: string;
  chapterItemSequenceNumber: number;
  contentType: ECHAPTER.TEXT_CONTENT;
}

export interface IConvertedIdChapterTextContent {
  id: number;
  chapterId: number;
  content: string;
  chapterItemSequenceNumber: string;
  contentType: ECHAPTER.TEXT_CONTENT;
}

export const CHAPTER_CONTENT_TYPES = {
  CHAPTER_EXTERNAL_RESOURCE: ECHAPTER.EXTERNAL_RESOURCE,
  CHAPTER_MATERIAL: ECHAPTER.MATERIAL,
  CHAPTER_TEXT_CONTENT: ECHAPTER.TEXT_CONTENT,
  CHAPTER_VIDEO: ECHAPTER.VIDEO,
};

interface SequenceId {
  type: ChapterContentType;
  chapterItemSequenceNumber: number;
}

export interface IChapterItemsResponse {
  courseId: number;
  lessonId: number;
  unitId: number;
  chapterId: number;
  chapterName: string;
  chapterDescription: string;
  chapterExternalResourceDTOList: IChapterExternalResource[];
  chapterMaterialDTOList: IChapterMaterial[];
  chapterTextContentDTOList: IChapterTextContent[];
  chapterVideoDTOList: IChapterVideo[];
  sequenceIdDTOList: SequenceId[];
}

export interface IConvertedIdsChapterItemsResponse {
  courseId: number | null;
  lessonId: number | null;
  unitId: number | null;
  chapterId: number | null;
  chapterName: string;
  chapterDescription: string;
  contentItems: ObjectChapterItemSequenceNumberAsString[];
}

export const contentDefaultValue: Readonly<IConvertedIdsChapterItemsResponse> = {
  courseId: null,
  lessonId: null,
  unitId: null,
  chapterId: null,
  chapterName: '',
  chapterDescription: '',
  contentItems: [],
};

export const baseObjConvertIdsToStringsForDND = (prefix: string, arr: BaseObjectWithNumberId[]): BaseObjectWithStringId[] => {
  return arr.map(obj => ({
    ...obj,
    id: prefix + '-' + obj.id,
  }));
};

export const baseObjConvertIdsToNumberForDND = (arr: BaseObjectWithStringId[]): BaseObjectWithNumberId[] => {
  return arr.map(obj => ({
    ...obj,
    id: extractNumber(obj.id),
  }));
};

export const lessonObjConvertIdsToStringsForDND = (prefix: string, arr: LessonObjectWithNumberId[]): LessonObjectWithStringId[] => {
  return arr.map(obj => ({
    ...obj,
    lessonId: prefix + '-' + obj.lessonId,
  }));
};

export const lessonObjConvertIdsToNumberForDND = (arr: LessonObjectWithStringId[]): LessonObjectWithNumberId[] => {
  return arr.map(obj => ({
    ...obj,
    lessonId: extractNumber(obj.lessonId),
  }));
};

export const unitObjConvertIdsToStringsForDND = (prefix: string, arr: UnitObjectWithNumberId[]): UnitObjectWithStringId[] => {
  return arr.map(obj => ({
    ...obj,
    unitId: prefix + '-' + obj.unitId,
  }));
};

export const unitObjConvertIdsToNumberForDND = (arr: UnitObjectWithStringId[]): UnitObjectWithNumberId[] => {
  return arr.map(obj => ({
    ...obj,
    unitId: extractNumber(obj.unitId),
  }));
};

export const chapterObjConvertIdsToStringsForDND = (prefix: string, arr: ChapterObjectWithNumberId[]): ChapterObjectWithStringId[] => {
  return arr.map(obj => ({
    ...obj,
    chapterId: prefix + '-' + obj.chapterId,
  }));
};

export const chapterObjConvertIdsToNumberForDND = (arr: ChapterObjectWithStringId[]): ChapterObjectWithNumberId[] => {
  return arr.map(obj => ({
    ...obj,
    chapterId: extractNumber(obj.chapterId),
  }));
};

export const chapterItemObjConvertIdsToStringsForDND = (
  prefix: string,
  arr: ObjectChapterItemSequenceNumberAsNumber[]
): ObjectChapterItemSequenceNumberAsString[] => {
  return arr.map(obj => ({
    ...obj,
    chapterItemSequenceNumber: prefix + '-' + obj.chapterItemSequenceNumber,
  }));
};

export const chapterItemObjConvertIdsToNumberForDND = (
  arr: ObjectChapterItemSequenceNumberAsString[]
): ObjectChapterItemSequenceNumberAsNumber[] => {
  return arr.map(obj => ({
    ...obj,
    chapterItemSequenceNumber: extractNumber(obj.chapterItemSequenceNumber),
  }));
};

export const extractNumber = (stringId: string) => {
  const match = stringId.match(/\d+/); // This regex matches one or more digits
  return match ? parseInt(match[0], 10) : null;
};

export const convertLessonIdsAndUnitIdsToString = (
  lessons: {
    lessonId: number;
    lessonName: string;
    lessonDescription: string;
    completed: boolean;
    units: {
      unitId: number;
      unitName: string;
      completed: boolean;
    }[];
  }[]
) => {
  return lessonObjConvertIdsToStringsForDND('outer', lessons).map(l => {
    l.units = l.units.length === 1 && l.units[0] === null ? [] : unitObjConvertIdsToStringsForDND('inner', l.units);
    return l;
  });
};

export const convertLessonIdsAndUnitIdsToNumber = (
  lessons: {
    lessonId: string;
    lessonName: string;
    lessonDescription: string;
    completed: boolean;
    units: {
      unitId: string;
      unitName: string;
      completed: boolean;
    }[];
  }[]
) => {
  return lessonObjConvertIdsToNumberForDND(lessons).map(l => {
    l.units = unitObjConvertIdsToNumberForDND(l.units);
    return l;
  });
};

export const convertToCourseLessonsAndUnitsSequenceRequest = (courseData: ICourseLessonAndUnitSequenceResponse) => {
  const updatedLessons = convertLessonIdsAndUnitIdsToNumber(courseData.lessons);

  const request: ICourseLessonsAndUnitsSequenceRequest = {
    courseId: courseData.courseId,
    sequence: updatedLessons.map(l => {
      return {
        lessonId: l.lessonId,
        units: l.units.map(u1 => u1.unitId),
      };
    }),
  };

  return request;
};

export const createCourseLessonsAndUnitsSequenceRequest = (
  courseId: number,
  lessonId: number,
  unitId: number | null,
  courseData: IChapterContentResponse
) => {
  return {
    courseId,
    lessonId,
    unitId,
    chapterIdsInOrder: chapterObjConvertIdsToNumberForDND(courseData.chapters).map(c => c.chapterId),
  };
};

export const convertIChapterItemsResponseToIConvertedIdsChapterItemsResponse = (data: IChapterItemsResponse) => {
  const selectedChapterContents: ObjectChapterItemSequenceNumberAsString[] = [];

  data.sequenceIdDTOList.map(sI => {
    if (sI.type === CHAPTER_CONTENT_TYPES.CHAPTER_EXTERNAL_RESOURCE) {
      const selectedValue = data.chapterExternalResourceDTOList.find(
        i => i.chapterItemSequenceNumber === sI.chapterItemSequenceNumber
      ) as unknown as IConvertedIdChapterExternalResource;

      if (selectedValue) {
        selectedValue.chapterItemSequenceNumber = selectedValue.chapterItemSequenceNumber + '';
        selectedValue.contentType = ECHAPTER.EXTERNAL_RESOURCE;
      }

      selectedChapterContents.push(selectedValue);
    } else if (sI.type === CHAPTER_CONTENT_TYPES.CHAPTER_MATERIAL) {
      const selectedValue = data.chapterMaterialDTOList.find(
        i => i.chapterItemSequenceNumber === sI.chapterItemSequenceNumber
      ) as unknown as IConvertedIdChapterMaterial;

      if (selectedValue) {
        selectedValue.chapterItemSequenceNumber = selectedValue.chapterItemSequenceNumber + '';
        selectedValue.contentType = ECHAPTER.MATERIAL;
      }

      selectedChapterContents.push(selectedValue);
    } else if (sI.type === CHAPTER_CONTENT_TYPES.CHAPTER_VIDEO) {
      const selectedValue = data.chapterVideoDTOList.find(
        i => i.chapterItemSequenceNumber === sI.chapterItemSequenceNumber
      ) as unknown as IConvertedIdChapterVideo;

      if (selectedValue) {
        selectedValue.chapterItemSequenceNumber = selectedValue.chapterItemSequenceNumber + '';
        selectedValue.contentType = ECHAPTER.VIDEO;
      }

      selectedChapterContents.push(selectedValue);
    } else if (sI.type === CHAPTER_CONTENT_TYPES.CHAPTER_TEXT_CONTENT) {
      const selectedValue = data.chapterTextContentDTOList.find(
        i => i.chapterItemSequenceNumber === sI.chapterItemSequenceNumber
      ) as unknown as IConvertedIdChapterTextContent;

      if (selectedValue) {
        selectedValue.chapterItemSequenceNumber = selectedValue.chapterItemSequenceNumber + '';
        selectedValue.contentType = ECHAPTER.TEXT_CONTENT;
      }

      selectedChapterContents.push(selectedValue);
    }
  });

  const result: IConvertedIdsChapterItemsResponse = {
    courseId: data.courseId,
    lessonId: data.lessonId,
    unitId: data.unitId,
    chapterId: data.chapterId,
    chapterName: data.chapterName,
    chapterDescription: data.chapterDescription,
    contentItems: selectedChapterContents,
  };

  return result;
};

export const createChapterSaveRequestPayload = (data: IConvertedIdsChapterItemsResponse) => {
  const sequenceIdDTOList: SequenceId[] = data.contentItems.map(cI => {
    return { type: cI.contentType, chapterItemSequenceNumber: parseInt(cI.chapterItemSequenceNumber, 10) };
  });

  const updatedContentItems = chapterItemObjConvertIdsToNumberForDND(data.contentItems);

  const result: IChapterItemsResponse = {
    courseId: data.courseId,
    lessonId: data.lessonId,
    unitId: data.unitId,
    chapterId: data.chapterId,
    chapterName: data.chapterName,
    chapterDescription: data.chapterDescription,
    chapterVideoDTOList: updatedContentItems.filter(c => c.contentType === ECHAPTER.VIDEO) as IChapterVideo[],
    chapterMaterialDTOList: updatedContentItems.filter(c => c.contentType === ECHAPTER.MATERIAL) as IChapterMaterial[],
    chapterTextContentDTOList: updatedContentItems.filter(c => c.contentType === ECHAPTER.TEXT_CONTENT) as IChapterTextContent[],
    chapterExternalResourceDTOList: updatedContentItems.filter(
      c => c.contentType === ECHAPTER.EXTERNAL_RESOURCE
    ) as IChapterExternalResource[],
    sequenceIdDTOList,
  };

  return result;
};

export const getNextMaxId = (arr: ObjectChapterItemSequenceNumberAsString[]) => {
  let maxId = -Infinity;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (arr.length) {
    for (const obj of arr) {
      const currentId = parseInt(obj.chapterItemSequenceNumber, 10);
      if (currentId > maxId) {
        maxId = currentId;
      }
    }
  } else {
    maxId = -1;
  }

  // Increment the maxId by 1 and return as a string
  return (maxId + 1).toString();
};

export const getMaxId = (arr: ObjectChapterItemSequenceNumberAsString[]) => {
  let maxId = -Infinity;

  if (arr.length) {
    for (const obj of arr) {
      const currentId = parseInt(obj.chapterItemSequenceNumber, 10);
      if (currentId > maxId) {
        maxId = currentId;
      }
    }
  } else {
    maxId = 0;
  }

  return maxId.toString();
};

export const updateObjectByChapterItemSequenceNumber = (
  arr: ObjectChapterItemSequenceNumberAsString[],
  chapterItemSequenceNumber: string,
  newValues
) => {
  return arr.map(item => {
    if (item.chapterItemSequenceNumber === chapterItemSequenceNumber) {
      return { ...item, ...newValues };
    }
    return item;
  });
};

export const convertParamsToInts = params => {
  const newParams = {};
  for (const [key, value] of Object.entries(params)) {
    const intValue = parseInt(value as any, 10);
    newParams[key] = isNaN(intValue) ? value : intValue;
  }
  return newParams;
};

export const convertToResourceLinkList = (rowDataArray: IRowData[]): IResourceLink[] => {
  return rowDataArray
    .filter(data => Boolean(data.firstInput) && Boolean(data.secondInput))
    .map(rowData => {
      const { firstInput, secondInput } = rowData;
      return {
        referenceName: firstInput,
        url: secondInput,
      };
    });
};

export const convertResourceLinkListToRowDataArray = (resourceLinks: IResourceLink[]): IRowData[] => {
  return resourceLinks.map((data: IResourceLink, index) => {
    const { referenceName, url } = data;
    return {
      id: index,
      firstInput: referenceName,
      secondInput: url,
      isValidFirstInput: true,
      isValidSecondInput: true,
    };
  });
};
