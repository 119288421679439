import { phoneNumberWithCountryCodeRegex } from './regex';

export const splitPhoneNumber = (fullPhoneNumber: string | null | undefined) => {
  if (!fullPhoneNumber) {
    return null;
  }
  const match = fullPhoneNumber.match(phoneNumberWithCountryCodeRegex);

  if (match) {
    const [, code, phoneNumber] = match;
    return {
      code,
      phoneNumber,
    };
  } else {
    return null;
  }
};
