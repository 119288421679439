// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QFZuxmIpCl4d2NObBouL {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-primary-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/courses/course-filter/course-filter.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,gCAAA;AACF","sourcesContent":[".pageTitle {\n  font-size: 20px;\n  font-weight: 600;\n  color: var(--text-primary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageTitle": `QFZuxmIpCl4d2NObBouL`
};
export default ___CSS_LOADER_EXPORT___;
