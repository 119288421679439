import React from 'react';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import styles from './payment-footer.module.scss';

const PaymentFooter = () => {
  return (
    <Container>
      <Row className="mt-5 align-items-center">
        <Col className={`${styles.txt3} col-12 col-md-6 text-center text-md-start`}>
          © {new Date().getFullYear()} Skill Surf | Privacy | Terms
        </Col>
        <Col className="col-12 col-md-6">
          <Row className="align-items-center justify-content-center justify-content-md-end">
            <Col className={`${styles.txt3} col-auto pe-0`}>powered by</Col>
            <Col className="col-auto">
              <img src="../../../../content/images/PayHere.webp" alt="PayHere-Icon" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentFooter;
