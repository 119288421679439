import { IRootState, useAppSelector } from 'app/config/store';
import { ETheme } from 'app/shared/model/enumerations/theme.model';
import React from 'react';
import { PropagateLoader } from 'react-spinners';
import styles from './main-loader.module.scss';

interface IProps {
  show: boolean;
}

const MainLoader = (props: IProps) => {
  const { theme } = useAppSelector((state: IRootState) => ({ theme: state.theme.theme }));

  return (
    <>
      {props.show && (
        <div
          className={[
            styles.spinnerContainer,
            'w-100 h-100 d-flex align-items-center justify-content-center',
            theme === ETheme.DARK ? styles.darkTheme : styles.lightTheme,
          ].join(' ')}
        >
          <PropagateLoader color="var(--accent-color)" />
        </div>
      )}
    </>
  );
};

export default MainLoader;
