// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iYOOuT4kKb7jfcKyzGZi {
  color: var(--text-secondary-color);
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/models/app-delete-model/DeleteModel.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;AACF","sourcesContent":[".deleteModelText {\n  color: var(--text-secondary-color);\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteModelText": `iYOOuT4kKb7jfcKyzGZi`
};
export default ___CSS_LOADER_EXPORT___;
