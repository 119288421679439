import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntity, updateEntity } from 'app/entities/course/course.reducer';
import { uploadFile } from 'app/services/file-upload-service';
import ExpandableField from 'app/shared/components/expandable-field/expandable-field';
import ImageUploadField from 'app/shared/components/image-uploader-field/image-uploader-field';
import MainLoader from 'app/shared/components/main-loader/main-loader';
import MultipleRowAdditionField, {
  IRowData,
  IRowInputsData,
} from 'app/shared/components/multiple-rows-addition-field/multiple-rows-addition-field';
import TinyMceEditor from 'app/shared/components/tinymce-editor/TinyMceEditor';
import { ICollaborationLogos, ICourse } from 'app/shared/model/course.model';
import { ECourseMode } from 'app/shared/model/enumerations/course-mode';
import { EPricingOption } from 'app/shared/model/enumerations/pricing-option.model';
import { EUploadFileType } from 'app/shared/model/enumerations/upload-file-type';
import { IOption } from 'app/shared/model/option.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppDatePicker from 'app/shared/ui-elements/app-date-picker/app-date-picker';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import AppSelect from 'app/shared/ui-elements/app-select/app-select';
import AppToggle from 'app/shared/ui-elements/app-toggle/app-toggle';
import RadioButton from 'app/shared/ui-elements/radio-button/radio-button';
import TextArea from 'app/shared/ui-elements/text-area-component/TextArea';
import { getDropdownSelectedValue, getPartBeforeLastTwoPeriods } from 'app/shared/util/app-elements-utils';
import {
  convertExpertsToIRowData,
  convertFaqsToIRowData,
  convertOutlinesToIRowData,
  convertToExperts,
  convertToFaqs,
  convertToOutlines,
  convertURL,
} from 'app/shared/util/course';
import { courseDurationRegex } from 'app/shared/util/regex';
import { showErrorToast } from 'app/shared/util/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import styles from './CourseAdd.module.scss';

const courseOutlineRowInputsData: IRowInputsData = {
  firstInputLabel: 'Title',
  secondInputLabel: 'Description',
  firstInputBracketLabel: 'Point',
  secondInputBracketLabel: 'Point',
  firstInputPlaceholder: 'Enter title',
  secondInputPlaceholder: 'Enter description',
};

const expertsPanelRowInputsData: IRowInputsData = {
  firstInputLabel: 'Name',
  secondInputLabel: 'Designation',
  firstInputBracketLabel: 'Expert',
  secondInputBracketLabel: 'Expert',
  firstInputPlaceholder: 'Enter name',
  secondInputPlaceholder: 'Enter designation',
};

const faqsRowInputsData: IRowInputsData = {
  firstInputLabel: 'Name',
  secondInputLabel: 'Answer',
  firstInputBracketLabel: 'Question',
  secondInputBracketLabel: 'Question',
  firstInputPlaceholder: 'Enter question',
  secondInputPlaceholder: 'Enter answer',
};

const courseMode: IOption[] = [
  {
    value: 'LIVE_CLASS',
    label: 'Live Classes',
  },
  {
    value: 'ON_DEMAND',
    label: 'On Demand',
  },
];

const courseTypes: IOption[] = [
  {
    value: 'SHORT_COURSE',
    label: 'Short Course',
  },
  {
    value: 'WORKSHOP',
    label: 'Workshop',
  },
  {
    value: 'COURSE',
    label: 'Course',
  },
];

const difficultyLevels: IOption[] = [
  {
    value: 'BEGINNER',
    label: 'Beginner',
  },
  {
    value: 'INTERMEDIATE',
    label: 'Intermediate',
  },
  {
    value: 'ADVANCED',
    label: 'Advanced',
  },
];

const specializations: IOption[] = [
  {
    value: 'COMBINED_MATHS',
    label: 'Combined Maths',
  },
  {
    value: 'PHYSICS',
    label: 'Physics',
  },
  {
    value: 'CHEMISTRY',
    label: 'Chemistry',
  },
];

interface IFormInputs {
  name: string;
  description: string;
  type: string;
  difficultyLevel: string;
  previewVideo: string;
  fieldOfStudy: string;
  formLink: string;
  classSchedule: string;
  needToPrepareSection: string;
  prerequisites: string;
  mode: string;
  estimatedCourseDuration: string;
  price: number;
}

const CourseAddView = () => {
  const { courseId } = useParams<'courseId'>();
  const isNew = !courseId;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading, updateSuccess, course } = useAppSelector((state: IRootState) => ({
    loading: state.course.loading,
    course: state.course.entity,
    updateSuccess: state.course.updateSuccess,
  }));

  const [regStartDate, setRegStartDate] = useState(new Date());
  const [regEndDate, setRegEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [courseOutlineList, setCourseOutlineList] = useState<IRowData[]>([]);
  const [expertsPanelList, setExpertsPanelList] = useState<IRowData[]>([]);
  const [faqsList, setFaqsList] = useState<IRowData[]>([]);
  const [needToPrepareToggleState, setNeedToPrepareToggleState] = useState(false);
  const [uploadImages, setUploadImages] = useState<
    {
      image: File | null;
      imageType: 'coverImageUrl' | 'bannerVectorUrl' | 'collaborationLogos';
      name: string;
      isUpdate: boolean;
      url?: string;
    }[]
  >([]);
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [isSaveAsDraftClicked, setIsSaveAsDraftClicked] = useState(false);
  const [pricingOption, setPricingOption] = useState(EPricingOption.PAID);
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  function removePrefix(url, prefix) {
    if (url.startsWith(prefix)) {
      return url.substring(prefix.length);
    } else {
      return url;
    }
  }

  const prefix = 'https://devsalearnovaeastus2.blob.core.windows.net/dev-storage-container-learnova-eastus2/images%2F';

  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
  } = useForm<IFormInputs>();

  useEffect(() => {
    if (courseId) {
      dispatch(getEntity(courseId));
    }
  }, [courseId]);

  const isEmpty = obj => Object.keys(obj).length === 0;

  useEffect(() => {
    if (course && !isEmpty(course)) {
      for (const [key, value] of Object.entries(course)) {
        if (key === 'type') {
          const difficultyValue = getDropdownSelectedValue(value, courseTypes);
          setValue(key as any, difficultyValue, { shouldDirty: true, shouldValidate: true });
        } else if (key === 'difficultyLevel') {
          const difficultyValue = getDropdownSelectedValue(value, difficultyLevels);
          setValue(key as any, difficultyValue, { shouldDirty: true, shouldValidate: true });
        } else if (key === 'fieldOfStudy') {
          const difficultyValue = getDropdownSelectedValue(value, specializations);
          setValue(key as any, difficultyValue, { shouldDirty: true, shouldValidate: true });
        } else if (key === 'mode') {
          const difficultyValue = getDropdownSelectedValue(value, courseMode);
          setValue(key as any, difficultyValue, { shouldDirty: true, shouldValidate: true });
        } else {
          setValue(key as any, value, { shouldDirty: true, shouldValidate: true });
        }
      }

      setCourseOutlineList(convertOutlinesToIRowData(course.outlines));
      setExpertsPanelList(convertExpertsToIRowData(course.expertsPanel));
      setFaqsList(convertFaqsToIRowData(course.frequentlyAskedQuestions));
      setRegStartDate(course.registrationStartDate ? new Date(course.registrationStartDate) : new Date());
      setRegEndDate(course.registrationEndDate ? new Date(course.registrationEndDate) : new Date());
      setStartDate(course.startDate ? new Date(course.startDate) : new Date());
      setEndDate(course.endDate ? new Date(course.endDate) : new Date());
      setNeedToPrepareToggleState(!!course.needToPrepareSection);
      setUploadImages([
        {
          image: null,
          imageType: 'coverImageUrl',
          name: getPartBeforeLastTwoPeriods(removePrefix(decodeURI(course.coverImageUrl), prefix)),
          isUpdate: true,
          url: course.coverImageUrl,
        },
        {
          image: null,
          imageType: 'bannerVectorUrl',
          name: getPartBeforeLastTwoPeriods(removePrefix(decodeURI(course.bannerVectorUrl), prefix)),
          isUpdate: true,
          url: course.bannerVectorUrl,
        },
        ...course.collaborationLogos.map(logo => {
          return {
            image: null,
            imageType: 'collaborationLogos',
            name: logo.name,
            isUpdate: true,
            url: logo.url,
          };
        }),
      ]);
      setIsCouponApplied(course.couponAllowed);
      setPricingOption(course.paid ? EPricingOption.PAID : EPricingOption.FREE);
      setValue('estimatedCourseDuration', course.courseDuration);
    }
  }, [course]);

  const onSubmit: SubmitHandler<IFormInputs> = () => {
    if (!(loading || isUploadingImages)) {
      if (isSaveAsDraftClicked) {
        setLoadingDraft(true);
        saveCourse(true);
      } else {
        setLoadingDraft(false);
        saveCourse(false);
      }
    }
  };

  const selectedCourseMode = useRef({});
  selectedCourseMode.current = watch('mode', '');

  const saveCourse = (isDraft: boolean) => {
    const data = getValues();

    if (data.description.length > 300) {
      showErrorToast('You have exceeded the maximum number of 300 characters in the course description', 'description');
      return;
    }

    const coverImageElement = uploadImages.find(val => val.imageType === 'coverImageUrl');

    if (!coverImageElement) {
      showErrorToast('Please add course cover image', 'image');
      return;
    }

    const outlines = convertToOutlines(courseOutlineList);

    if (outlines.length === 0) {
      showErrorToast('Please add at least one course outline', 'outline');
      return;
    }

    const expertsPanel = convertToExperts(expertsPanelList);

    if (expertsPanel.length === 0) {
      showErrorToast('Please add at least one expert panel', 'expert');
      return;
    }

    setIsUploadingImages(true);
    const first = uploadImages.find(val => val.imageType === 'coverImageUrl');
    const firstPromise =
      first.image === null
        ? new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({
                storagePath: first.url,
                fileName: first.name,
              });
            }, 100);
          })
        : uploadFile(uploadImages.find(val => val.imageType === 'coverImageUrl').image, EUploadFileType.IMAGES, first.name);

    const second = uploadImages.find(val => val.imageType === 'bannerVectorUrl');
    const secondPromise =
      typeof second !== 'undefined'
        ? second.image === null
          ? new Promise(resolve => {
              setTimeout(() => {
                resolve({
                  storagePath: second.url,
                  fileName: second.name,
                });
              }, 100);
            })
          : uploadFile(uploadImages.find(val => val.imageType === 'bannerVectorUrl').image, EUploadFileType.IMAGES, second.name)
        : Promise.resolve(null);

    const thirdPromises = uploadImages
      .filter(val => val.imageType === 'collaborationLogos')
      .map(val => {
        if (val.image === null) {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({
                storagePath: val.url,
                fileName: val.name,
              });
            }, 100);
          });
        } else {
          return uploadFile(val.image, EUploadFileType.IMAGES, val.name);
        }
      });

    firstPromise
      .then(img1 => {
        secondPromise.then(img2 => {
          Promise.all(thirdPromises).then(img3 => {
            setIsUploadingImages(false);
            const newCourse: ICourse = {
              id: (data as any)?.id,
              name: data.name,
              description: data.description,
              type: (data.type as any).value,
              difficultyLevel: (data.difficultyLevel as any).value,
              outlines,
              expertsPanel: expertsPanel.map(val => ({ name: val.name, designation: val.designation, imageUrl: val.imageUrl })),
              frequentlyAskedQuestions: convertToFaqs(faqsList),
              collaborationLogos: img3.map(img => ({ name: (img as any).fileName, url: (img as any).storagePath })),
              registrationStartDate: selectedCourseMode.current['value'] === ECourseMode.LIVE_CLASS ? regStartDate.toISOString() : null,
              registrationEndDate: selectedCourseMode.current['value'] === ECourseMode.LIVE_CLASS ? regEndDate.toISOString() : null,
              startDate: selectedCourseMode.current['value'] === ECourseMode.LIVE_CLASS ? startDate.toISOString() : null,
              endDate: selectedCourseMode.current['value'] === ECourseMode.LIVE_CLASS ? endDate.toISOString() : null,
              coverImageUrl: (img1 as any).storagePath,
              bannerVectorUrl: img2?.storagePath,
              prerequisites: data.prerequisites || '',
              classSchedule: data.classSchedule,
              fieldOfStudy: (data.fieldOfStudy as any).value,
              previewVideo: data.previewVideo || '',
              needToPrepareSection: data.needToPrepareSection || '',
              draft: isDraft,
              formLink: data.formLink || null,
              sequence: [],
              mode: (data.mode as any).value,
              price: pricingOption === EPricingOption.FREE ? null : data.price,
              isPaid: pricingOption === EPricingOption.PAID,
              isCouponAllowed: pricingOption === EPricingOption.FREE ? false : isCouponApplied,
              estimatedCourseDuration: data.estimatedCourseDuration,
            };
            if (isNew) {
              dispatch(createEntity(newCourse));
            } else {
              dispatch(updateEntity(newCourse));
            }
          });
        });
      })
      .catch(() => {
        setIsUploadingImages(false);
      });
  };

  useEffect(() => {
    if (updateSuccess) {
      navigate('/admin/courses');
    }
  }, [updateSuccess]);

  const handleNeedToPrepareToggle = newState => {
    setNeedToPrepareToggleState(newState);
    if (!newState) setValue('needToPrepareSection', '');
  };

  return loading ? (
    <MainLoader show={loading} />
  ) : (
    <Row className="align-items-center mx-0">
      <Col className="mx-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="py-3">
            <Col className="col-12 col-md-3">
              <p className={`${styles.heading}`}> {isNew ? 'Add New Course' : 'Update Course'}</p>
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <AppSelect
                id="mode"
                label="Course Mode"
                name="mode"
                options={courseMode}
                isRequired={true}
                register={register('mode', {
                  required: 'Please select the Course Mode',
                })}
                errors={errors}
                control={control}
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <AppInput
                id="name"
                name="name"
                label="Course Name"
                placeholder="Course Name"
                isRequired={true}
                register={register('name', {
                  required: 'Course Name is required',
                  minLength: {
                    value: 2,
                    message: 'This field must contain at least 2 characters',
                  },
                  maxLength: {
                    value: 75,
                    message: 'You have exceeded the maximum number of 75 characters in this field',
                  },
                })}
                errors={errors}
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col className="col-12 col-sm-6">
              <AppSelect
                label="Course Type"
                id="type"
                name="type"
                options={courseTypes}
                isRequired={true}
                register={register('type', {
                  required: 'Please select the Course Type',
                })}
                errors={errors}
                control={control}
              />
            </Col>
            <Col className="col-12 col-sm-6 pt-3 pt-sm-0">
              <AppSelect
                id="difficultyLevel"
                label="Difficulty Level"
                name="difficultyLevel"
                options={difficultyLevels}
                isRequired={true}
                register={register('difficultyLevel', {
                  required: 'Please select the difficulty level',
                })}
                errors={errors}
                control={control}
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <TextArea
                id="description"
                label="Course Description"
                name="description"
                register={register('description', {
                  required: 'Description is required',
                  maxLength: {
                    value: 300,
                    message: 'You have exceeded the maximum number of 300 characters in this field',
                  },
                })}
                errors={errors}
                isRequired={true}
              />
            </Col>
          </Row>

          {selectedCourseMode.current['value'] === ECourseMode.LIVE_CLASS && (
            <>
              <Row className="py-2">
                <Col className="col-12 col-sm-6">
                  <AppDatePicker
                    label="Registration Start Date"
                    selectedDate={regStartDate}
                    onChange={date => {
                      setRegStartDate(date);
                    }}
                    isInput={true}
                    timePicker={true}
                  />
                </Col>
                <Col className="col-12 col-sm-6 pt-3 pt-sm-0">
                  <AppDatePicker
                    label="Registration End Date"
                    selectedDate={regEndDate}
                    onChange={date => {
                      setRegEndDate(date);
                    }}
                    isInput={true}
                    timePicker={true}
                  />
                </Col>
              </Row>

              <Row className="py-2">
                <Col className="col-12 col-sm-6">
                  <AppDatePicker
                    label="Course Start Date"
                    selectedDate={startDate}
                    onChange={date => {
                      setStartDate(date);
                    }}
                    isInput={true}
                    timePicker={true}
                  />
                </Col>
                <Col className="col-12 col-sm-6 pt-3 pt-sm-0">
                  <AppDatePicker
                    label="Course End Date"
                    selectedDate={endDate}
                    onChange={date => {
                      setEndDate(date);
                    }}
                    isInput={true}
                    timePicker={true}
                  />
                </Col>
              </Row>
            </>
          )}

          <Row className="py-2">
            <Col className="col-12 col-sm-6">
              <AppSelect
                id="fieldOfStudy"
                label="Subject"
                name="fieldOfStudy"
                options={specializations}
                isRequired={true}
                register={register('fieldOfStudy', {
                  required: 'Please select the specialization',
                })}
                errors={errors}
                control={control}
              />
            </Col>
            <Col className="col-12 col-sm-6 pt-3 pt-sm-0">
              {selectedCourseMode.current['value'] === ECourseMode.ON_DEMAND ? (
                <AppInput
                  id="estimatedCourseDuration"
                  name="estimatedCourseDuration"
                  label="Estimated Course Duration"
                  placeholder="Estimated Course Duration"
                  register={register('estimatedCourseDuration', {
                    required: 'Estimated course duration is required',
                    pattern: {
                      value: courseDurationRegex,
                      message: 'This field must contain at least one letter or non-special character',
                    },
                  })}
                  errors={errors}
                  isRequired
                />
              ) : (
                <AppInput
                  label="Google Form Link"
                  placeholder="Google Form Link"
                  id="formLink"
                  name="formLink"
                  register={register('formLink')}
                  errors={errors}
                />
              )}
            </Col>
          </Row>

          <Row className="py-2">
            <Col className="col-12 col-sm-6">
              <ImageUploadField
                label="Course Cover Image"
                isRequired={true}
                isMultipleImage={false}
                onImagesSelected={images => {
                  const removedCoverImageUrlInList = uploadImages.filter(val => val.imageType !== 'coverImageUrl');

                  setUploadImages([
                    ...removedCoverImageUrlInList,
                    { image: images[0], imageType: 'coverImageUrl', name: images[0].name + uuidv4() + '.jpg', isUpdate: false },
                  ]);
                }}
                iconTitle="Add Image"
                imageUploaderType="secondary"
                existingFileUrls={convertURL(course.coverImageUrl)}
                onChange={fileNames => {
                  const removedCoverImageUrlInList = uploadImages.filter(val => val.imageType !== 'coverImageUrl');
                  const newBannerVector = uploadImages
                    .filter(val => val.imageType === 'coverImageUrl')
                    .filter(obj => !fileNames.includes(getPartBeforeLastTwoPeriods(obj.name)));
                  if (!fileNames) {
                    setUploadImages([...removedCoverImageUrlInList, ...newBannerVector]);
                  } else {
                    setUploadImages([...removedCoverImageUrlInList]);
                  }
                }}
              />
            </Col>
            <Col className="col-12 col-sm-6">
              <ImageUploadField
                label="Banner Vector Image"
                isMultipleImage={false}
                onImagesSelected={images => {
                  const removedBannerVectorUrlInList = uploadImages.filter(val => val.imageType !== 'bannerVectorUrl');
                  setUploadImages([
                    ...removedBannerVectorUrlInList,
                    { image: images[0], imageType: 'bannerVectorUrl', name: images[0].name + uuidv4() + '.jpg', isUpdate: false },
                  ]);
                }}
                onChange={fileNames => {
                  const removedBannerVectorUrlInList = uploadImages.filter(val => val.imageType !== 'bannerVectorUrl');
                  const newBannerVector = uploadImages
                    .filter(val => val.imageType === 'bannerVectorUrl')
                    .filter(obj => !fileNames.includes(getPartBeforeLastTwoPeriods(obj.name)));
                  if (!fileNames) {
                    setUploadImages([...removedBannerVectorUrlInList, ...newBannerVector]);
                  } else {
                    setUploadImages([...removedBannerVectorUrlInList]);
                  }
                }}
                iconTitle="Add Vector"
                toolTipText="By adding a banner vector image, it will be displayed on the landing page. If no vector image is added, a cover image will be displayed instead"
                imageUploaderType="secondary"
                existingFileUrls={course.bannerVectorUrl && convertURL(course.bannerVectorUrl)}
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <AppInput
                id="previewVideo"
                name="previewVideo"
                label="Use video for program preview"
                placeholder="Preview Video Link"
                register={register('previewVideo')}
                isToggle={true}
                onChange={toggleState => {
                  setValue('previewVideo', toggleState ? getValues('previewVideo') : '');
                }}
                isClicked={course && course.previewVideo ? true : false}
                errors={errors}
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <Controller
                name="prerequisites"
                control={control}
                defaultValue={course ? course.prerequisites : ''}
                render={({ field, fieldState }) => (
                  <>
                    <TinyMceEditor
                      label="Prerequisites"
                      onEditorChange={content => field.onChange(content)}
                      content={field.value}
                      errorMessage={fieldState.error?.message}
                    />
                  </>
                )}
              />
            </Col>
          </Row>

          <Row className="py-3">
            <Col>
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  <p className={`${styles.tinyMceLabel}`}> Need to prepare section </p>
                </Col>
                <Col>
                  <AppToggle initialState={needToPrepareToggleState} onToggle={handleNeedToPrepareToggle} />
                </Col>
              </Row>
              <Row>
                <Col>
                  {needToPrepareToggleState && (
                    <Controller
                      name="needToPrepareSection"
                      control={control}
                      defaultValue={course ? course.needToPrepareSection : ''}
                      render={({ field, fieldState }) => (
                        <>
                          <TinyMceEditor
                            onEditorChange={content => field.onChange(content)}
                            content={field.value}
                            errorMessage={fieldState.error?.message}
                          />
                        </>
                      )}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="py-3">
            <Col>
              {/* TODO: tooltip text should be defined  */}
              <ImageUploadField
                label="In Collaboration With Logos"
                isMultipleImage={true}
                onImagesSelected={images => {
                  const removedCollaborationLogosInList = uploadImages.filter(val => val.imageType !== 'collaborationLogos');
                  const fileArray = Array.from(images);
                  const addCollaborationLogos = fileArray.map((image, index) => ({
                    image: images[index],
                    imageType: 'collaborationLogos' as const,
                    name: images[index]?.name + uuidv4() + '.jpg',
                    isUpdate: false,
                  }));

                  setUploadImages(ps => {
                    return [...ps, ...removedCollaborationLogosInList, ...addCollaborationLogos];
                  });
                }}
                onChange={fileNames => {
                  const removedCollaborationLogosInList = uploadImages.filter(val => val.imageType !== 'collaborationLogos');
                  const newCollaborationLogos = uploadImages
                    .filter(val => val.imageType === 'collaborationLogos')
                    .filter(obj => !fileNames.includes(getPartBeforeLastTwoPeriods(obj.name)));
                  setUploadImages([...removedCollaborationLogosInList, ...newCollaborationLogos]);
                }}
                iconTitle="Add Logo"
                toolTipText="Include the logos of partner organizations to be showcased on the course info page"
                existingFileUrls={
                  course.collaborationLogos &&
                  course?.collaborationLogos.map((logo: ICollaborationLogos) => {
                    const fileName = getPartBeforeLastTwoPeriods(logo.name);
                    return { url: logo.url, file: fileName };
                  })
                }
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <Controller
                name="classSchedule"
                control={control}
                defaultValue={course ? course.classSchedule : ''}
                rules={{
                  required: 'Class schedule is required',
                }}
                render={({ field, fieldState }) => (
                  <>
                    <TinyMceEditor
                      onEditorChange={content => field.onChange(content)}
                      content={field.value}
                      label="Class Schedule"
                      required
                      errorMessage={fieldState.error?.message}
                    />
                  </>
                )}
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <MultipleRowAdditionField
                addImageEnabled={false}
                buttonText="Add Point"
                label="Course Outline"
                rowInputsData={courseOutlineRowInputsData}
                dataList={courseOutlineList}
                setDataList={setCourseOutlineList}
                isRequired={true}
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <MultipleRowAdditionField
                addImageEnabled
                buttonText="Add an Expert"
                label="Experts Panel"
                iconTitle="Add image"
                rowInputsData={expertsPanelRowInputsData}
                dataList={expertsPanelList}
                setDataList={setExpertsPanelList}
                isRequired={true}
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <MultipleRowAdditionField
                addImageEnabled={false}
                buttonText="Add a FAQ"
                label="FAQs"
                rowInputsData={faqsRowInputsData}
                dataList={faqsList}
                setDataList={setFaqsList}
              />
            </Col>
          </Row>

          <Row className="py-2">
            <Col>
              <ExpandableField isRequired={true} label="Pricing">
                <>
                  <Row className="mt-3">
                    <Col>
                      <RadioButton
                        buttonOptions={[EPricingOption.PAID, EPricingOption.FREE]}
                        onChange={event => setPricingOption(event.target.value)}
                        selectedOption={pricingOption}
                      />
                    </Col>
                  </Row>
                  {pricingOption === EPricingOption.PAID && (
                    <>
                      <Row className="align-items-center mt-3">
                        <Col xs="auto" className={`${styles.tinyMceLabel} pe-0`}>
                          <p className={`${styles.tinyMceLabel}`}>Accept coupons?</p>
                        </Col>
                        <Col>
                          <AppToggle initialState={isCouponApplied} onToggle={newState => setIsCouponApplied(newState)} />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          <AppInput
                            id="price"
                            type="number"
                            name="price"
                            label="Base Price (LKR)"
                            placeholder="Base Price"
                            isRequired={true}
                            register={register('price', {
                              required: 'Price is required',
                            })}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              </ExpandableField>
            </Col>
          </Row>

          <Row className="align-items-center py-3">
            <Col className="pe-0 col-12 col-sm-4 col-md-3">
              <AppButton
                text={isNew ? 'Save and Publish' : 'Update and Publish'}
                type="submit"
                loading={!loadingDraft && (loading || isUploadingImages)}
                onClick={() => {
                  setIsSaveAsDraftClicked(false);
                  handleSubmit(onSubmit);
                }}
              />
            </Col>
            <Col className="pe-0 col-6 col-sm-4 col-md-3 mt-3 mt-sm-0">
              <AppButton
                text={isNew ? 'Save as draft' : 'Update as draft'}
                btnSize="sm"
                btnType="bordered"
                type="submit"
                onClick={() => {
                  setIsSaveAsDraftClicked(true);
                  handleSubmit(onSubmit);
                }}
                loading={loadingDraft && (loading || isUploadingImages)}
              />
            </Col>
            <Col className={`col-6 col-sm-auto mt-3 mt-sm-0`}>
              <AppButton
                text="Cancel"
                btnSize="sm"
                btnType="onlyText"
                type="button"
                className={styles.buttonCancel}
                onClick={() => {
                  navigate('/admin/courses');
                }}
              />
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default CourseAddView;
