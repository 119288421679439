// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ggZLqx9JlBKLGCexyTbw {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: var(--card-color);
  font-size: 14px;
  font-weight: 500;
}

.qUZLwQeOlo6b_DeLC_fQ {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--accent-color);
}

.o0cM4FXN9jjJh8a27ioh {
  color: var(--text-secondary-color);
  font-weight: 500;
}

.SOiiEx5KIHPJEpEOzMYJ {
  margin-top: 2px;
}

@media (max-width: 991px) {
  .ggZLqx9JlBKLGCexyTbw,
  .qUZLwQeOlo6b_DeLC_fQ {
    min-width: 32px;
    min-height: 32px;
    border-radius: 8px;
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .ggZLqx9JlBKLGCexyTbw,
  .qUZLwQeOlo6b_DeLC_fQ {
    min-width: 40px;
    min-height: 40px;
    border-radius: 10px;
    font-size: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/pagination/pagination.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mCAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,qCAAA;AACF;;AAEA;EACE,kCAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE;;IAEE,eAAA;IACA,gBAAA;IACA,kBAAA;IACA,eAAA;EACF;AACF;AAEA;EACE;;IAEE,eAAA;IACA,gBAAA;IACA,mBAAA;IACA,eAAA;EAAF;AACF","sourcesContent":[".page {\n  width: 24px;\n  height: 24px;\n  border-radius: 6px;\n  background-color: var(--card-color);\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.activePage {\n  width: 24px;\n  height: 24px;\n  border-radius: 6px;\n  font-size: 14px;\n  font-weight: 500;\n  background-color: var(--accent-color);\n}\n\n.pageText {\n  color: var(--text-secondary-color);\n  font-weight: 500;\n}\n\n.paginationButton {\n  margin-top: 2px;\n}\n\n@media (max-width: 991px) {\n  .page,\n  .activePage {\n    min-width: 32px;\n    min-height: 32px;\n    border-radius: 8px;\n    font-size: 18px;\n  }\n}\n\n@media (max-width: 768px) {\n  .page,\n  .activePage {\n    min-width: 40px;\n    min-height: 40px;\n    border-radius: 10px;\n    font-size: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `ggZLqx9JlBKLGCexyTbw`,
	"activePage": `qUZLwQeOlo6b_DeLC_fQ`,
	"pageText": `o0cM4FXN9jjJh8a27ioh`,
	"paginationButton": `SOiiEx5KIHPJEpEOzMYJ`
};
export default ___CSS_LOADER_EXPORT___;
