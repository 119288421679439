import { ECourseType } from 'app/shared/model/enumerations/course-type.model';

interface IUnit {
  unitId?: number;
  unitName?: string;
  completed?: boolean;
}

interface ILesson {
  lessonId?: number;
  lessonName?: string;
  lessonDescription?: string;
  containsUnits?: boolean;
  completed?: boolean;
  units?: IUnit[];
}

export interface ICourseLessonsUnitsManager {
  courseId?: number;
  enrollmentId?: number;
  courseName?: string;
  courseType?: ECourseType;
  courseTypeInText?: string;
  specialization?: string;
  coverImageUrl?: string;
  userCompletedPercentage?: number;
  lessons?: ILesson[];
}

export const defaultValue: Readonly<ICourseLessonsUnitsManager> = null;
