// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I6883FzDLunx223MRrzb {
  z-index: 1000 !important;
  position: absolute;
  right: 50%;
  transform: translateX(40%);
}
@media screen and (min-width: 992px) {
  .I6883FzDLunx223MRrzb {
    transform: translateX(0);
    right: 0;
  }
}

.BFzrBbrg455g6m8xj9cc {
  transform: translateX(0) !important;
  right: 0 !important;
}

._7C9qbCEyNEjoK0RyENO {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-date-picker/app-date-picker.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,kBAAA;EACA,UAAA;EACA,0BAAA;AACF;AACE;EANF;IAOI,wBAAA;IACA,QAAA;EAEF;AACF;;AACA;EACE,mCAAA;EACA,mBAAA;AAEF;;AACA;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;AAEF","sourcesContent":[".datePicker {\n  z-index: 1000 !important;\n  position: absolute;\n  right: 50%;\n  transform: translateX(40%);\n\n  @media screen and (min-width: 992px) {\n    transform: translateX(0);\n    right: 0;\n  }\n}\n\n.isInput {\n  transform: translateX(0) !important;\n  right: 0 !important;\n}\n\n.label {\n  color: var(--text-secondary-color);\n  font-size: 14px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datePicker": `I6883FzDLunx223MRrzb`,
	"isInput": `BFzrBbrg455g6m8xj9cc`,
	"label": `_7C9qbCEyNEjoK0RyENO`
};
export default ___CSS_LOADER_EXPORT___;
