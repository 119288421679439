import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IUser } from 'app/shared/model/user.model';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';

const initialState = {
  loading: false,
  courseEnrollmentError: false,
  courseEnrollmentStatus: 0,
  errorMessage: null,
  totalItems: 0,
  enrolledUsers: [] as ReadonlyArray<IUser>,
  enrolledUsersCluster: [] as ReadonlyArray<IUser>,
  toEnrollUsers: [] as ReadonlyArray<IUser>,
};

const apiUrl = 'api/admin/user-roster';
const apiUrlAllUsers = 'api/admin/user-roster-all-users';

export const getCourseEnrolledUsers = createAsyncThunk('userRoster/fetch_course_enrolled_users', async (data: { courseId: number }) => {
  const requestUrl = `${apiUrl}?courseId=${data.courseId}`;
  return axios.get<IUser[]>(requestUrl);
});

export const getAllCourseEnrolledUsers = createAsyncThunk(
  'userRoster/fetch_all_course_enrolled_users',
  async (data: { courseId: number }) => {
    const requestUrl = `${apiUrlAllUsers}?courseId=${data.courseId}`;
    return axios.get<IUser[]>(requestUrl);
  }
);

export const getCourseEnrolledUsersAsClusters = createAsyncThunk(
  'userRoster/fetch_course_enrolled_users_clusters',
  async (data: { courseId: number; searchText?: string; page: number; size: number; sort?: string }) => {
    const requestUrl = `${apiUrl}?courseId=${data.courseId}&size=${data.size}&page=${data.page}${
      data.searchText && data.searchText ? `&searchText=${data.searchText}` : ''
    }${data.sort ? `&sort=${data.sort}` : ''}`;
    return axios.get<IUser[]>(requestUrl);
  }
);

export const toEnrollUsersByEmails = createAsyncThunk(
  'userRoster/to_enroll_users_by_emails',
  async (data: { emails: string[]; courseId: number }) => {
    const result = axios.post('/api/admin/to-be-enrolled-users', data);

    return result;
  },
  { serializeError: serializeAxiosError }
);

export const courseEnrollment = createAsyncThunk(
  'userRoster/course_enrollment',
  async (data: { emails: string[]; courseId: number }) => {
    const result = axios.post('/api/admin/course-enrollments', data);

    return result;
  },
  { serializeError: serializeAxiosError }
);

export type UserRosterState = Readonly<typeof initialState>;

export const UserRosterSlice = createSlice({
  name: 'userRoster',
  initialState: initialState as UserRosterState,
  reducers: {
    resetUserRoster() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(toEnrollUsersByEmails.fulfilled, (state, action) => {
        state.loading = false;
        state.toEnrollUsers = action.payload.data;
      })
      .addCase(courseEnrollment.fulfilled, (state, action) => {
        state.loading = false;
        state.courseEnrollmentError = false;
        state.courseEnrollmentStatus = action.payload.status;
      })
      .addCase(courseEnrollment.rejected, state => {
        state.loading = false;
        state.courseEnrollmentError = true;
      })
      .addCase(getCourseEnrolledUsersAsClusters.fulfilled, (state, action) => {
        state.loading = false;
        state.enrolledUsersCluster = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(isFulfilled(getCourseEnrolledUsers, getAllCourseEnrolledUsers), (state, action) => {
        state.loading = false;
        state.enrolledUsers = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addMatcher(
        isPending(
          getCourseEnrolledUsers,
          getCourseEnrolledUsersAsClusters,
          toEnrollUsersByEmails,
          courseEnrollment,
          getAllCourseEnrolledUsers
        ),
        state => {
          state.errorMessage = null;
          state.loading = true;
          state.courseEnrollmentError = false;
        }
      )
      .addMatcher(
        isRejected(
          getCourseEnrolledUsers,
          toEnrollUsersByEmails,
          courseEnrollment,
          getCourseEnrolledUsersAsClusters,
          getAllCourseEnrolledUsers
        ),
        (state, action) => {
          state.loading = false;
          state.errorMessage = action.error.message;
        }
      );
  },
});

export const { resetUserRoster } = UserRosterSlice.actions;

export default UserRosterSlice.reducer;
