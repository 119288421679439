import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './icon-card.module.scss';

export type TIconCardVariant = 'default' | 'bordered';
interface IProps {
  icon: ReactNode;
  onClick?: () => void;
  iconCardVariant?: TIconCardVariant;
}

const IconCard = (props: IProps) => {
  const { icon, iconCardVariant = 'default', onClick } = props;

  const getStyleClass = () => {
    switch (iconCardVariant) {
      case 'bordered':
        return styles.previewIcon;
      default:
        return '';
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div
            className={`${styles.iconCard} ${
              onClick && 'cursor-pointer'
            } d-flex align-items-center justify-content-center ${getStyleClass()}`}
            onClick={() => onClick && onClick()}
          >
            {icon}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default IconCard;
