// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wyIpM6_Y4MzyR6V68Oc6 {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0px 0px 8px 0px rgba(24, 25, 36, 0.0509803922);
  border-color: transparent;
}
.wyIpM6_Y4MzyR6V68Oc6:hover {
  transform: scale(1.04);
  transition: transform 300ms ease-in;
}
.wyIpM6_Y4MzyR6V68Oc6:not(:hover) {
  transform: scale(1);
  transition: transform 300ms ease-out;
}

.A42V65vLISNC5qgr7Y8X {
  background-color: var(--card-color);
}

.dsuB0t6IuVg4mel6S3ai {
  background-color: var(--accent-color);
}

.aVC_wtOHdBuUrHi7GXuo {
  height: 160px;
  border-radius: 12px 12px 0px 0px;
}

.lnMR6OL3AVeMENilHEry {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary-color);
}

.Y6JxWAaNpLfgKODUpIMQ {
  font-size: 16px;
  font-weight: 700;
  height: 3em;
}

.y2BlXvyOUNf2eM1zZNmG {
  color: var(--text-primary-color);
}

.NiOc9w9nedDk9Ydygx2g {
  color: #ffffff;
}

.b2_ydKpZ58u3svPGhYkL {
  font-size: 10px;
  line-height: 12px;
}

.Sc1okX9VD2ps_eMAiEIX {
  color: var(--text-secondary-color);
}

.S1yMl6yqGsrQKChZDrx8 {
  color: #ffffff;
}

.vXjkiX9xgkGrMTw42sfS {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.JtXKKPER6TlyHhxxfB6A {
  color: var(--text-secondary-color);
}

.Jb5ksU0INzPrWYUNtIts {
  color: var(--success-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/program-catalog/course-grid-view/course-card/course-card.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,0DAAA;EACA,yBAAA;AACF;AACE;EACE,sBAAA;EACA,mCAAA;AACJ;AAEE;EACE,mBAAA;EACA,oCAAA;AAAJ;;AAIA;EACE,mCAAA;AADF;;AAIA;EACE,qCAAA;AADF;;AAIA;EACE,aAAA;EACA,gCAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,kCAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AADF;;AAIA;EACE,gCAAA;AADF;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,eAAA;EACA,iBAAA;AADF;;AAIA;EACE,kCAAA;AADF;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AADF;;AAIA;EACE,kCAAA;AADF;;AAIA;EACE,2BAAA;AADF","sourcesContent":[".courseCard {\n  width: 100%;\n  height: 100%;\n  border-radius: 12px;\n  box-shadow: 0px 0px 8px 0px #1819240d;\n  border-color: transparent;\n\n  &:hover {\n    transform: scale(1.04);\n    transition: transform 300ms ease-in;\n  }\n\n  &:not(:hover) {\n    transform: scale(1);\n    transition: transform 300ms ease-out;\n  }\n}\n\n.primaryCourseCard {\n  background-color: var(--card-color);\n}\n\n.enrolledCourseCard {\n  background-color: var(--accent-color);\n}\n\n.cardImg {\n  height: 160px;\n  border-radius: 12px 12px 0px 0px;\n}\n\n.cardText {\n  font-size: 12px;\n  font-weight: 400;\n  color: var(--text-secondary-color);\n}\n\n.cardTitle {\n  font-size: 16px;\n  font-weight: 700;\n  height: 3em;\n}\n\n.cardTitleFont {\n  color: var(--text-primary-color);\n}\n\n.cardTitleFontEnrolled {\n  color: #ffffff;\n}\n\n.cardRatings {\n  font-size: 10px;\n  line-height: 12px;\n}\n\n.cardRatingsFont {\n  color: var(--text-secondary-color);\n}\n\n.cardRatingsFontEnrolled {\n  color: #ffffff;\n}\n\n.cardPrice {\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 19px;\n}\n\n.cardPriceFont {\n  color: var(--text-secondary-color);\n}\n\n.cardPriceFontEnrolled {\n  color: var(--success-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseCard": `wyIpM6_Y4MzyR6V68Oc6`,
	"primaryCourseCard": `A42V65vLISNC5qgr7Y8X`,
	"enrolledCourseCard": `dsuB0t6IuVg4mel6S3ai`,
	"cardImg": `aVC_wtOHdBuUrHi7GXuo`,
	"cardText": `lnMR6OL3AVeMENilHEry`,
	"cardTitle": `Y6JxWAaNpLfgKODUpIMQ`,
	"cardTitleFont": `y2BlXvyOUNf2eM1zZNmG`,
	"cardTitleFontEnrolled": `NiOc9w9nedDk9Ydygx2g`,
	"cardRatings": `b2_ydKpZ58u3svPGhYkL`,
	"cardRatingsFont": `Sc1okX9VD2ps_eMAiEIX`,
	"cardRatingsFontEnrolled": `S1yMl6yqGsrQKChZDrx8`,
	"cardPrice": `vXjkiX9xgkGrMTw42sfS`,
	"cardPriceFont": `JtXKKPER6TlyHhxxfB6A`,
	"cardPriceFontEnrolled": `Jb5ksU0INzPrWYUNtIts`
};
export default ___CSS_LOADER_EXPORT___;
