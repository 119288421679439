import { useAppSelector } from 'app/config/store';
import AppPrimaryMobileNavBar from 'app/shared/components/nav-bar/app-primary-mobile-nav-bar/app-primary-mobile-nav-bar';
import AppPrimaryNavBar from 'app/shared/components/nav-bar/app-primary-nav-bar/app-primary-nav-bar';
import ScrollToTop from 'app/shared/components/scroll-to-top/ScrollToTop';
import React, { useState } from 'react';
import { Col, Container, Navbar, Row } from 'react-bootstrap';
import { NavLink, Outlet } from 'react-router-dom';
import OffCanvas from '../components/offCanvas/OffCanvas';
import styles from '../header/header.module.scss';
import MenuToggle from '../header/menu-toggle';

const MainLayout = () => {
  const authState = useAppSelector(state => state.authentication);

  const [open, setOpen] = useState(false);

  const closeHandler = () => setOpen(false);
  const openHandler = () => setOpen(true);

  return (
    <Container fluid className={`main-layout px-0 overflow-hidden`}>
      <nav className="main-layout__nav d-none d-md-flex">
        <AppPrimaryNavBar
          imagePath={authState.account.imageUrl}
          userFirstName={authState.account.firstName}
          userLastName={authState.account.lastName}
        />
      </nav>
      <section className="main-layout__body">
        <ScrollToTop />

        <div className={`d-flex d-md-none ${styles['navbar-container']}`}>
          <Navbar className="w-100">
            <Container fluid className="w-100 p-0 justify-content-center">
              <Row className="justify-content-between w-100">
                <Col>
                  <NavLink to={'/'}>
                    <img src={'../../../../content/images/skillsurf_logo.webp'} alt="skill_surf logo" height={40} />
                  </NavLink>
                </Col>
                <Col className="col-auto">
                  <MenuToggle onClick={openHandler} />
                </Col>
              </Row>
            </Container>
          </Navbar>
        </div>

        <div className="main-layout__content">
          <Outlet />
        </div>
      </section>

      <header>
        <OffCanvas open={open} closeHandler={closeHandler} placement="end">
          <AppPrimaryMobileNavBar closeHandler={closeHandler} isInMyCourses />
        </OffCanvas>
      </header>
    </Container>
  );
};

export default MainLayout;
