// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JLdpW37GaK58C_JBrT6f {
  border-radius: 12px;
  background: var(--card-color);
}
.JLdpW37GaK58C_JBrT6f .kMFR5dw_qZwVlWyqe3ci {
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: 700;
}
.JLdpW37GaK58C_JBrT6f ._eREqKsZkjx0cMr95Ouu {
  color: var(--text-secondary-color);
  font-size: 12px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-mini-card/mini-card.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,6BAAA;AACF;AACE;EACE,gCAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".miniCardView {\n  border-radius: 12px;\n  background: var(--card-color);\n\n  .headingText {\n    color: var(--text-primary-color);\n    font-size: 16px;\n    font-weight: 700;\n  }\n\n  .bodyText {\n    color: var(--text-secondary-color);\n    font-size: 12px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"miniCardView": `JLdpW37GaK58C_JBrT6f`,
	"headingText": `kMFR5dw_qZwVlWyqe3ci`,
	"bodyText": `_eREqKsZkjx0cMr95Ouu`
};
export default ___CSS_LOADER_EXPORT___;
