// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdnplqk1VhRJxCy8SOyo {
  border-radius: 8px;
  background: var(--card-color);
  color: var(--text-secondary-color);
  font-size: 12px;
  transition: 0.2s;
}
.rdnplqk1VhRJxCy8SOyo:hover {
  transform: scale(1.05);
  background: var(--card-color);
  color: var(--card-tertiary-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-pill/app-pill.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,6BAAA;EACA,kCAAA;EACA,eAAA;EACA,gBAAA;AACF;AACE;EACE,sBAAA;EACA,6BAAA;EACA,iCAAA;AACJ","sourcesContent":[".appPill {\n  border-radius: 8px;\n  background: var(--card-color);\n  color: var(--text-secondary-color);\n  font-size: 12px;\n  transition: 0.2s;\n\n  &:hover {\n    transform: scale(1.05);\n    background: var(--card-color);\n    color: var(--card-tertiary-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appPill": `rdnplqk1VhRJxCy8SOyo`
};
export default ___CSS_LOADER_EXPORT___;
