import { noLeadingWhiteSpaceRegex } from 'app/shared/util/regex';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import AppButton from '../app-button/app-button';
import styles from './app-search.module.scss';

export interface ISearchFilters {
  searchField: string;
  searchValue: string;
  important: boolean;
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  ascending: boolean;
}
interface IProps {
  onChange: (searchText: string) => void;
  onSearch: () => void;
  placeholder: string;
  value: string;
  className?: string;
}

const AppSearch = (props: IProps) => {
  const { onChange, onSearch, placeholder, value, className } = props;

  return (
    <div className={`${styles.searchWrapper} ${className}`}>
      <Row className={'align-items-center'}>
        <Col>
          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const inputValue = e.target.value;
              if (noLeadingWhiteSpaceRegex.test(inputValue)) {
                onChange(inputValue);
              }
            }}
            className={`${className} ${styles.searchInput}`}
          />
        </Col>
        <Col className={'col-auto'}>
          <AppButton
            text="Search"
            icon={BsSearch}
            iconOnly
            btnSize="md"
            className={`${styles.searchBtn}`}
            onClick={() => {
              onSearch();
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AppSearch;
