// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sK323FxL2S4nNFAH4mbn {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-primary-color);
}

.E5IfC30vtJtqNCRGG3Tw {
  color: var(--accent-color);
}

.IQlOwbAqGQH4rYhIYzUt {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--text-secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/components/course-add/CourseAdd.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gCAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kCAAA;AACF","sourcesContent":[".heading {\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 24px;\n  color: var(--text-primary-color);\n}\n\n.buttonCancel {\n  color: var(--accent-color);\n}\n\n.tinyMceLabel {\n  font-size: 14px;\n  font-weight: 400;\n  margin-bottom: 0;\n  color: var(--text-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `sK323FxL2S4nNFAH4mbn`,
	"buttonCancel": `E5IfC30vtJtqNCRGG3Tw`,
	"tinyMceLabel": `IQlOwbAqGQH4rYhIYzUt`
};
export default ___CSS_LOADER_EXPORT___;
