import 'app/config/dayjs';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar-heatmap/dist/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'swiper/swiper.scss';
import './app.scss';

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Card } from 'reactstrap';

import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getSession } from 'app/shared/reducers/authentication';
// import Header from 'app/shared/layout/header/header';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import Footer from 'app/shared/layout/footer/footer';
import { log } from 'react-jhipster';
import setupAxiosInterceptors from './config/axios-interceptor';
import { ETheme } from './shared/model/enumerations/theme.model';
import { changeTheme } from './shared/reducers/theme';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state: IRootState) => ({ theme: state.theme.theme }));

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());

    const appTheme = localStorage.getItem('theme');
    dispatch(changeTheme(appTheme || ETheme.LIGHT));
    document.documentElement.setAttribute('data-theme', appTheme);

    setupAxiosInterceptors(() => {
      log('Unauthenticated!');
    });
  }, []);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const paddingTop = '60px';
  return (
    <BrowserRouter basename={baseHref}>
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        className="toastify-container"
        toastClassName="toastify-toast"
        hideProgressBar
        theme={theme === ETheme.DARK ? ETheme.DARK : ETheme.LIGHT}
      />
      <div className="app-container d-none" style={{ paddingTop }}>
        <ErrorBoundary>
          {/* <Header
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            currentLocale={currentLocale}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled}
          /> */}
        </ErrorBoundary>
        <div className="container-fluid view-container" id="app-view-container">
          <Card className="jh-card">
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </Card>
          <Footer />
        </div>
      </div>

      <AppRoutes />
    </BrowserRouter>
  );
};

export default App;
