// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paAY_TR8S6RMXKO6ZrgR {
  padding-left: 120px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
}

.VrGfHJouaWMyEiQN9T3o {
  margin-top: 80px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/nav-bar/app-primary-mobile-nav-bar/app-primary-mobile-nav-bar.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".navBarItem {\n  padding-left: 120px;\n  color: #ffffff;\n  font-weight: 400;\n  font-size: 14px;\n}\n\n.navBar {\n  margin-top: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBarItem": `paAY_TR8S6RMXKO6ZrgR`,
	"navBar": `VrGfHJouaWMyEiQN9T3o`
};
export default ___CSS_LOADER_EXPORT___;
