// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ps25qpnm1rhhrMT2tKpK {
  width: 40px !important;
  height: 22px;
}

.fRSqhqQmCNhyarTRjutg {
  background-color: var(--accent-color) !important;
  border-color: var(--accent-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-toggle/app-toggle.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,YAAA;AACF;;AAEA;EACE,gDAAA;EACA,4CAAA;AACF","sourcesContent":[".formSwitch {\n  width: 40px !important;\n  height: 22px;\n}\n\n.checkedFormSwitch {\n  background-color: var(--accent-color) !important;\n  border-color: var(--accent-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSwitch": `Ps25qpnm1rhhrMT2tKpK`,
	"checkedFormSwitch": `fRSqhqQmCNhyarTRjutg`
};
export default ___CSS_LOADER_EXPORT___;
