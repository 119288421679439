import styles from './header.module.scss';

import { IRootState, useAppSelector } from 'app/config/store';
import AppPrimaryMobileNavBar from 'app/shared/components/nav-bar/app-primary-mobile-nav-bar/app-primary-mobile-nav-bar';
import { IListItem } from 'app/shared/components/profiel-views/list-views/nav-profile-list-item';
import NavProfile from 'app/shared/components/profiel-views/nav-profile';
import ThemeToggle from 'app/shared/components/theme-toggle/theme-toggle';
import useIsAdmin from 'app/shared/hooks/useIsAdmin';
import { ETheme } from 'app/shared/model/enumerations/theme.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import OffCanvas from '../components/offCanvas/OffCanvas';
import MenuToggle from './menu-toggle';

const navLinks: {
  path: string;
  text: string;
}[] = [
  {
    path: 'https://alevel.skillsurf.lk/',
    text: 'Home',
  },
  {
    path: '/catalog',
    text: 'Program Catalog',
  },
];

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const authState = useAppSelector(state => state.authentication);
  const { theme } = useAppSelector((state: IRootState) => ({ theme: state.theme.theme }));
  const [active, setActive] = React.useState<string>(location.pathname);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const itemArray: IListItem[] = [
    {
      itemName: 'My Courses',
      id: '1',
      path: isAdmin ? '/admin/courses' : '/student/courses',
    },
    {
      itemName: 'LMS Home',
      id: '2',
      path: '/',
    },
    {
      itemName: 'Notices',
      id: '3',
      path: '/',
    },
    {
      itemName: 'Settings',
      id: '4',
      path: isAdmin ? '/admin/settings/coupons' : '',
    },
    {
      itemName: 'Log Out',
      id: '5',
      path: '/logout',
    },
  ];

  const handleItemClick = (path: string) => {
    navigate(path);
  };

  const closeHandler = () => setOpen(false);
  const openHandler = () => setOpen(true);

  return (
    <Row className={`justify-content-center align-items-center ${styles['navbar-container']}`}>
      <Col className="col-12">
        <Navbar collapseOnSelect expand="lg">
          <Container>
            <NavLink to={'https://alevel.skillsurf.lk/'}>
              <img
                src={
                  theme === ETheme.DARK
                    ? 'content/images/ALAthwalaLogo(Horizontal)_Dark.webp'
                    : 'content/images/ALAthwalaLogo(Horizontal)_Light.webp'
                }
                alt="Athwela- skill surf logo"
                height={50}
                width={150}
              />
            </NavLink>
            <MenuToggle onClick={openHandler} />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto gap-md-0"></Nav>
              <Nav className="d-lg-flex gap-md-0 gap-lg-4 d-none">
                {navLinks.map((link, index) => (
                  <NavLink
                    key={index}
                    to={link.path}
                    className={`text-decoration-none d-flex align-items-center fs-6 font-weight-600 ${
                      active === link.path ||
                      (active.includes('/course-preview') && link.path === '/catalog') ||
                      (active.includes('/payment') && link.path === '/catalog')
                        ? `${styles['nav-hover-selected']}`
                        : `${styles['nav-hover']}`
                    }`}
                  >
                    {link.text}
                  </NavLink>
                ))}
              </Nav>
              <div className="align-items-center px-4">
                <ThemeToggle />
              </div>

              {authState.isAuthenticated ? (
                <NavProfile
                  firstName={authState.account.firstName}
                  lastName={authState.account.lastName}
                  items={itemArray}
                  image={authState.account.imageUrl}
                  onClick={handleItemClick}
                />
              ) : (
                <div className={`${styles['app-button-container']}`}>
                  <AppButton text="LOGIN" btnSize="sm" onClick={() => navigate('/login')} />
                </div>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Col>
      <header>
        <OffCanvas open={open} closeHandler={closeHandler} placement="end">
          <AppPrimaryMobileNavBar closeHandler={closeHandler} />
        </OffCanvas>
      </header>
    </Row>
  );
};

export default Header;
