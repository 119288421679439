import { ECouponDiscountTypeStrings } from 'app/shared/model/enumerations/coupon-mode';
import { IPayment } from 'app/shared/model/payment.model';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const loadImageToDataURL = async (imageUrl: string) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error loading image:', error);
    return null;
  }
};

export const generatePaymentReceiptPDF = async (data: IPayment) => {
  const imageUrl = '../../../content/images/footer-logo.png';
  const imageDataUrl = await loadImageToDataURL(imageUrl);

  const documentDefinition = {
    content: [
      {
        image: imageDataUrl,
        width: 130,
        alignment: 'left',
        margin: [0, 0, 0, 4],
      },
      {
        text: 'www.skillsurf.lk',
        link: 'www.skillsurf.lk',
        margin: [0, 0, 0, 4],
        color: '#1f9df2',
        fontSize: '13',
      },
      {
        text: `${moment(data?.paidOn).format('YYYY-MM-DD HH:mm:ss')}`,
        margin: [0, -70, 0, 4],
        alignment: 'right',
      },
      {
        text: 'Payment No',
        margin: [0, 0, 0, 4],
        alignment: 'right',
      },
      {
        text: `${data?.paymentId ? data?.paymentId : ''}`,
        margin: [0, 0, 0, 4],
        alignment: 'right',
      },
      {
        text: 'Processed via ',
        margin: [430, 0, 0, 0],
        fontSize: '8',
      },
      {
        text: 'PayHere',
        margin: [483, -9, 0, 20],
        fontSize: '8',
        color: '#1f9df2',
      },
      {
        text: `Dear ${data?.userFirstname},`,
        fontSize: 18,
        bold: true,
        alignment: 'left',
        margin: [30, 20, 0, 4],
        color: '#767676',
      },
      {
        text: `Thanks for your payment of LKR ${data?.subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} to learnova.`,
        margin: [30, 0, 0, -10],
        color: '#767676',
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 30,
            y: 30,
            w: 460,
            h: 0.5,
            color: '#CECECE',
          },
        ],
      },
      {
        text: `Billing Details`,
        margin: [30, 23, 0, 6],
        bold: true,
      },
      {
        text: `${data?.userFirstname + ' ' + data?.userLastname}`,
        margin: [30, 0, 0, 6],
        color: '#767676',
      },
      {
        text: `${data?.email ? data?.email : ''}`,
        link: `mailto:${data?.email}`,
        margin: [30, 0, 0, 6],
        color: '#767676',
      },
      {
        text: `${data?.contactNumber ? data?.contactNumber : ''}`,
        margin: [30, 0, 0, 25],
        color: '#767676',
      },
      {
        text: `${data?.address || ''}\n${data?.city ? `${data.city},` : ''} ${data?.country || ''}`,
        margin: [30, 0, 0, 15],
        color: '#767676',
      },
      {
        text: `Coupon `,
        margin: [30, 0, 0, 10],
        bold: true,
      },
      {
        text: `${data?.couponCode ? data?.couponCode : ''} ${
          data?.discountType
            ? data?.discountType === ECouponDiscountTypeStrings.AMOUNT
              ? data?.discount && '(LKR ' + data?.discount + ' Discount)'
              : data?.discount && '(' + data?.discount + '% Discount)'
            : ''
        }`,
        margin: [80, -23, 0, 10],
        color: '#767676',
      },
      {
        text: `Order Number`,
        margin: [300, -167, 0, 6],
        bold: true,
      },
      {
        text: `${data?.orderId ? data?.orderId : ''}`,
        margin: [300, 0, 0, 15],
        color: '#767676',
      },
      {
        text: `Paid On`,
        margin: [300, 0, 0, 6],
        bold: true,
      },
      {
        text: `${data?.paidOn ? moment(data?.paidOn).format('YYYY-MM-DD HH:mm:ss') : ''}`,
        margin: [300, 0, 0, 15],
        color: '#767676',
      },
      {
        text: `Payment Method`,
        margin: [300, 0, 0, 6],
        bold: true,
      },
      {
        text: `${data?.paymentMethod ? data?.paymentMethod : ''}`,
        margin: [300, 0, 0, 45],
        color: '#767676',
      },
      {
        table: {
          widths: ['*', 'auto', 'auto', 'auto'],
          body: [
            [
              {
                text: 'Paid For',
                fillColor: '#F1F1F1',
                margin: [5, 5, 5, 5],
                bold: true,
              },
              {
                text: 'Price',
                fillColor: '#F1F1F1',
                margin: [5, 5, 5, 5],
                bold: true,
              },
              { text: 'Discount', bold: true, fillColor: '#F1F1F1', margin: [5, 5, 5, 5] },
              {
                text: 'Total',
                fillColor: '#F1F1F1',
                margin: [5, 5, 5, 5],
                bold: true,
              },
            ],
            [
              { text: `${data?.item || ''}`, margin: [5, 5, 5, 5], color: '#767676', fontSize: '11' },
              {
                text: `${data?.amount ? 'LKR ' + data.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}`,
                color: '#767676',
                fontSize: '11',

                margin: [5, 5, 5, 5],
              },
              {
                text: `${
                  data?.discountType
                    ? data?.discountType === ECouponDiscountTypeStrings.AMOUNT
                      ? data?.discount && 'LKR ' + data?.discount
                      : data?.discount && data?.discount + '%'
                    : ''
                }`,
                margin: [5, 5, 5, 5],
                color: '#767676',
                fontSize: '11',
              },
              {
                text: `${data?.subTotal ? 'LKR ' + data.subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}`,

                margin: [5, 5, 5, 5],
                color: '#767676',
                fontSize: '11',
              },
            ],
          ],
          headerRows: 1,
          fillColor: '#F1F1F1',
          margin: [30, 0, 0, 0],
        },
        layout: {
          hLineWidth(i: number, node: any): number {
            return 1;
          },
          vLineWidth(i: number, node: any): number {
            return 1;
          },
          hLineColor(i: number, node: any): string {
            return '#FFFFFF';
          },
          vLineColor(i: number, node: any): string {
            return '#FFFFFF';
          },
          paddingLeft(i: number, node: any): number {
            return 30;
          },
          paddingRight(i: number, node: any): number {
            return 30;
          },
          paddingTop(i: number, node: any): number {
            return 10;
          },
          paddingBottom(i: number, node: any): number {
            return 10;
          },
        },
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 30,
            y: 5,
            w: 460,
            h: 0.5,
            color: '#CECECE',
          },
        ],
      },
      {
        text: 'Payment',
        margin: [350, 20, 0, 10],
        bold: true,
      },
      {
        text: `${data?.subTotal ? 'LKR ' + data.subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}`,
        margin: [420, -24, 0, 20],
        bold: true,
      },
      {
        text: 'Charge will appear on your statement as skill surf',
        margin: [30, 0, 0, -15],
        color: '#767676',
        fontSize: '11',
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 30,
            y: 30,
            w: 460,
            h: 0.5,
            color: '#CECECE',
          },
        ],
      },
      {
        text: 'For inquiries related to your purchase, you may contact:',
        margin: [30, 15, 0, 6],
        bold: true,
      },
      {
        text: 'Skill Surf',
        margin: [30, 0, 0, 4],
        color: '#767676',
      },
      {
        text: 'www.skillsurf.lk',
        link: 'www.skillsurf.lk',
        margin: [30, 0, 0, 4],
        color: '#1f9df2',
      },
      {
        text: '50, Skill Surf, Colombo',
        margin: [30, 0, 0, 4],
        color: '#767676',
      },
      {
        text: 'suport@scrapbook.com',
        link: `mailto:suport@scrapbook.com`,
        margin: [30, 0, 0, 4],
        color: '#767676',
      },
      {
        text: '1234764534',
        margin: [30, 0, 0, 4],
        color: '#767676',
      },
    ],
  };

  pdfMake.createPdf(documentDefinition).download(`Skill Surf payment receipt #${data?.orderId || ''}`);
};
