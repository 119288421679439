// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ILR44afkucuPUWwAb96w {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: var(--card-color);
  box-shadow: 0px 0px 8px 0px rgba(24, 25, 36, 0.0509803922);
  border-color: transparent;
}

.AdaDOTYtLm5Boj6fIeV3 {
  height: 160px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 12px 0px 0px 12px;
}

.KaYAAKujGhKvEby8uUoN {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary-color);
}

.FLyr4FG2CslLnvRfNasr {
  font-size: 16px;
  font-weight: 700;
  color: var(--text-primary-color);
}

span {
  color: var(--text-secondary-color);
  font-size: 12px;
}

.nAf6pQa9Z3G1zcDFhznW {
  color: var(--success-color);
  font-size: 12px;
}

.t9Oa_QuYQxIE4v2er8YQ {
  width: 20px;
  height: 26px;
  border-radius: 6px;
  background-color: var(--card-secondary-color);
  padding: 3px;
}

.Rqy1m3pMWFz6HJHJ_MXy {
  display: inline-block;
  border-left: 1px solid var(--line-color);
}

.CxnbFOBxmywu2p06mMa8 {
  min-width: 110px;
  width: auto;
}

@media (max-width: 992px) {
  .AdaDOTYtLm5Boj6fIeV3 {
    height: 210px;
  }
}
@media (max-width: 767px) {
  .AdaDOTYtLm5Boj6fIeV3 {
    border-radius: 12px 12px 0px 0px;
    height: 185px;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/courses/course-card/course-card.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,mCAAA;EACA,0DAAA;EACA,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,oBAAA;KAAA,iBAAA;EACA,gCAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,kCAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,gCAAA;AACF;;AAEA;EACE,kCAAA;EACA,eAAA;AACF;;AAEA;EACE,2BAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,6CAAA;EACA,YAAA;AACF;;AAEA;EACE,qBAAA;EACA,wCAAA;AACF;;AAEA;EACE,gBAAA;EACA,WAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF;AAEA;EACE;IACE,gCAAA;IACA,aAAA;EAAF;AACF","sourcesContent":[".courseCard {\n  width: 100%;\n  height: 100%;\n  border-radius: 12px;\n  background-color: var(--card-color);\n  box-shadow: 0px 0px 8px 0px #1819240d;\n  border-color: transparent;\n}\n\n.cardImg {\n  height: 160px;\n  object-fit: cover;\n  border-radius: 12px 0px 0px 12px;\n}\n\n.cardText {\n  font-size: 12px;\n  font-weight: 400;\n  color: var(--text-secondary-color);\n}\n\n.cardTitle {\n  font-size: 16px;\n  font-weight: 700;\n  color: var(--text-primary-color);\n}\n\nspan {\n  color: var(--text-secondary-color);\n  font-size: 12px;\n}\n\n.completeText {\n  color: var(--success-color);\n  font-size: 12px;\n}\n\n.viewMoreIcon {\n  width: 20px;\n  height: 26px;\n  border-radius: 6px;\n  background-color: var(--card-secondary-color);\n  padding: 3px;\n}\n\n.separator {\n  display: inline-block;\n  border-left: 1px solid var(--line-color);\n}\n\n.customDropdownList {\n  min-width: 110px;\n  width: auto;\n}\n\n@media (max-width: 992px) {\n  .cardImg {\n    height: 210px;\n  }\n}\n\n@media (max-width: 767px) {\n  .cardImg {\n    border-radius: 12px 12px 0px 0px;\n    height: 185px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseCard": `ILR44afkucuPUWwAb96w`,
	"cardImg": `AdaDOTYtLm5Boj6fIeV3`,
	"cardText": `KaYAAKujGhKvEby8uUoN`,
	"cardTitle": `FLyr4FG2CslLnvRfNasr`,
	"completeText": `nAf6pQa9Z3G1zcDFhznW`,
	"viewMoreIcon": `t9Oa_QuYQxIE4v2er8YQ`,
	"separator": `Rqy1m3pMWFz6HJHJ_MXy`,
	"customDropdownList": `CxnbFOBxmywu2p06mMa8`
};
export default ___CSS_LOADER_EXPORT___;
