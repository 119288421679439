// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u9bENIcS6lrgCeJwRU6X {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding-left: 25px;
  font-weight: 400;
  font-size: 16px;
  color: var(--text-secondary-color);
}
.u9bENIcS6lrgCeJwRU6X .B_SOwVLmDR8sFExtNzTL {
  margin-top: 1px;
}
.u9bENIcS6lrgCeJwRU6X input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.u9bENIcS6lrgCeJwRU6X .kdFRVUWlZ1YrBVyOHQDn {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background: var(--background-color);
  border: 1px solid var(--text-secondary-color);
  border-radius: 6px;
}
.u9bENIcS6lrgCeJwRU6X .kdFRVUWlZ1YrBVyOHQDn:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid var(--text-primary-color);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.u9bENIcS6lrgCeJwRU6X .u9bENIcS6lrgCeJwRU6X input:checked ~ .kdFRVUWlZ1YrBVyOHQDn {
  background-color: transparent;
}
.u9bENIcS6lrgCeJwRU6X input:checked ~ .kdFRVUWlZ1YrBVyOHQDn:after {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-checkbox/app-checkbox.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,sBAAA;EAEA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,kCAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,kBAAA;EACA,UAAA;EACA,eAAA;EACA,SAAA;EACA,QAAA;AAAJ;AAGE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,mCAAA;EACA,6CAAA;EACA,kBAAA;AADJ;AAGI;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,uCAAA;EACA,yBAAA;EAGA,wBAAA;AADN;AAKE;EACE,6BAAA;AAHJ;AAME;EACE,cAAA;AAJJ","sourcesContent":[".selectionBoxContainer {\n  display: flex;\n  align-items: center;\n  position: relative;\n  cursor: pointer;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  padding-left: 25px;\n  font-weight: 400;\n  font-size: 16px;\n  color: var(--text-secondary-color);\n\n  .label {\n    margin-top: 1px;\n  }\n\n  & input {\n    position: absolute;\n    opacity: 0;\n    cursor: pointer;\n    height: 0;\n    width: 0;\n  }\n\n  .checkmark {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 22px;\n    width: 22px;\n    background: var(--background-color);\n    border: 1px solid var(--text-secondary-color);\n    border-radius: 6px;\n\n    &:after {\n      content: '';\n      position: absolute;\n      display: none;\n      left: 7px;\n      top: 4px;\n      width: 6px;\n      height: 10px;\n      border: solid var(--text-primary-color);\n      border-width: 0 3px 3px 0;\n      -webkit-transform: rotate(45deg);\n      -ms-transform: rotate(45deg);\n      transform: rotate(45deg);\n    }\n  }\n\n  .selectionBoxContainer input:checked ~ .checkmark {\n    background-color: transparent;\n  }\n\n  input:checked ~ .checkmark:after {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectionBoxContainer": `u9bENIcS6lrgCeJwRU6X`,
	"label": `B_SOwVLmDR8sFExtNzTL`,
	"checkmark": `kdFRVUWlZ1YrBVyOHQDn`
};
export default ___CSS_LOADER_EXPORT___;
