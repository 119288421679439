import { IMaterial } from 'app/shared/util/dnd-utils';
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsFileEarmarkText, BsPlusLg, BsXLg } from 'react-icons/bs';
import AppTooltip from '../app-tooltip/AppTooltip';
import styles from './app-material-uploader.module.scss';

export type UploadMaterialType = 'primary' | 'secondary';

export interface IUploadMaterialProps {
  isMultipleMaterial: boolean;
  onMaterialsSelected: (files: FileList) => void;
  materials?: IMaterial[];
  iconTitle?: string;
  uploadMaterialType?: UploadMaterialType;
  loading?: boolean;
  onRemoveMaterial?: (index: number) => void;
}

const AppMaterialUploader: FC<IUploadMaterialProps> = props => {
  const { uploadMaterialType = 'primary', loading = false } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [materialPreviews, setMaterialPreviews] = useState<IMaterial[]>(props.materials || []);

  const getTextStyleClass = () => {
    switch (uploadMaterialType) {
      case 'primary':
        return styles['customButtonText-primary-color'];
      case 'secondary':
        return styles['customButtonText-secondary-color'];
      default:
        return styles['customButtonText-primary-color'];
    }
  };

  const getIconStyleClass = () => {
    switch (uploadMaterialType) {
      case 'primary':
        return styles['addMaterialBackground-primary-color'];
      case 'secondary':
        return styles['addMaterialBackground-secondary-color'];
      default:
        return styles['addMaterialBackground-primary-color'];
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      if (!props.isMultipleMaterial) {
        setMaterialPreviews([]);
      }
      props.onMaterialsSelected(files);
      const newMaterialPreviews: IMaterial[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onloadend = () => {
          const materialPreview = reader.result as string;
          newMaterialPreviews.push({ fileName: file.name, url: materialPreview });
          if (newMaterialPreviews.length === files.length) {
            setMaterialPreviews(prevPreviews => [...prevPreviews, ...newMaterialPreviews]);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const onRemoveMaterial = (index: number) => {
    props.onRemoveMaterial && props.onRemoveMaterial(index);

    setMaterialPreviews(prevPreviews => {
      const newPreviews = [...prevPreviews];
      newPreviews.splice(index, 1);
      return newPreviews;
    });

    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const materialAddButton = () => {
    return (
      <>
        <Row className="align-items-center h-100">
          <Col className="align-items-center col-auto pe-0">
            <div className={`${styles.addMaterialBackground} ${getIconStyleClass()} cursor-pointer`}>
              <BsPlusLg size={18} color="var(--card-color)" onClick={onClick} />
            </div>
          </Col>
          <Col>
            {props.iconTitle && (
              <span className={`${styles.customButtonText} ${getTextStyleClass()}`} onClick={onClick}>
                {props.iconTitle && props.iconTitle}
              </span>
            )}
          </Col>
        </Row>
      </>
    );
  };

  if (loading) {
    return (
      <Row>
        <Col>
          <Spinner animation="border" className={styles.spinner} />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col>
        <div>
          <input
            type="file"
            accept=".doc,.docx,.pdf,.txt"
            className="d-none"
            ref={fileInputRef}
            multiple={props.isMultipleMaterial}
            onChange={handleFileChange}
          />
        </div>
        {materialPreviews.length > 0 ? (
          <Row>
            {materialPreviews.map((preview, index) => (
              <Col key={index} className="col-auto">
                <div className={`${styles.materialPreviewCard}`}>
                  <div className={`${styles.materialContainer} d-flex align-items-center justify-content-center`}>
                    <a href={preview.url} target="_blank" rel="noopener noreferrer">
                      <BsFileEarmarkText color="var(--text-primary-color)" size={36} />
                    </a>
                    <div className={`${styles.materialRemoveIcon} cursor-pointer`} onClick={() => onRemoveMaterial(index)}>
                      <BsXLg color="#F05A5A" size={10} />
                    </div>
                  </div>

                  <AppTooltip tooltipId={preview.fileName + index} content={preview.fileName}>
                    <div className={`pt-1 ${styles.materialText} text-truncate`}>{preview.fileName}</div>
                  </AppTooltip>
                </div>
              </Col>
            ))}
            <Col>{props.isMultipleMaterial && materialAddButton()}</Col>
          </Row>
        ) : (
          materialAddButton()
        )}
      </Col>
    </Row>
  );
};

export default AppMaterialUploader;
