// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P8w0nAO_21HIgNAA0YBa {
  border-radius: 15px;
  background: var(--card-secondary-color);
}
.P8w0nAO_21HIgNAA0YBa .kTGP_DMscGrLjFVZdE2L {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/app-dnd-elements/app-dnd-card/app-dnd-card.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,uCAAA;AACF;AACE;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".dndCardContainer {\n  border-radius: 15px;\n  background: var(--card-secondary-color);\n\n  .labelStyle {\n    color: var(--text-secondary-color);\n    font-size: 14px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dndCardContainer": `P8w0nAO_21HIgNAA0YBa`,
	"labelStyle": `kTGP_DMscGrLjFVZdE2L`
};
export default ___CSS_LOADER_EXPORT___;
