import { useAppDispatch } from 'app/config/store';
import { deleteCourse, getAllEntitiesAdmin } from 'app/entities/course/course.reducer';
import { deleteEntity } from 'app/modules/admin/course-chapter-manager/course-chapter-manager.reducer';
import { deleteLesson, deleteUnit } from 'app/modules/admin/course-lessons-units-manager/course-lessons-units-manager.reducer';
import ModelLayout from 'app/shared/layout/layouts/ModelLayout';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { IDeleteModelData } from '../../../model/DeleteModel.model';
import AppButton from '../../app-button/app-button';
import styles from './DeleteModel.module.scss';

interface IProps {
  data: IDeleteModelData;
  closeHandler: () => void;
}

const DeleteModel = (props: IProps) => {
  const { data, closeHandler } = props;
  const dispatch = useAppDispatch();

  const onSubmit = () => {
    if (data.modelType === 'LESSON') {
      dispatch(deleteLesson({ courseId: data.courseId, id: data.id }));
    } else if (data.modelType === 'UNIT') {
      dispatch(deleteUnit({ courseId: data.courseId, id: data.id }));
    } else if (data.modelType === 'COURSE') {
      dispatch(deleteCourse(data.courseId))
        .unwrap()
        .then(() => {
          dispatch(
            getAllEntitiesAdmin({
              size: 4,
              fieldOfStudy: data?.filterData.fieldOfStudy,
              difficultyLevel: data?.filterData.difficultyLevel,
              searchText: data?.filterData.searchText,
              page: data?.filterData.page,
            })
          ).unwrap();
        })
        .catch(() => {});
    } else {
      dispatch(deleteEntity({ courseId: data.courseId, lessonId: data.lessonId, unitId: data.unitId, id: data.id }));
    }

    closeHandler();
  };

  return (
    <ModelLayout closeHandler={closeHandler} isOpen={data?.show} modelTitle={`Delete ${data?.title}?`}>
      <Row>
        <Col>
          <Row>
            <Col className={styles.deleteModelText}>{data?.warningMessage}</Col>
          </Row>

          <Row className="mt-4">
            <Col className="col-12 col-sm-4 mb-2 mb-sm-0">
              <AppButton text="Delete" btnType="delete" onClick={onSubmit} />
            </Col>
            <Col className="col-12 col-sm-4">
              <AppButton text="Cancel" btnType="bordered" onClick={closeHandler} />
            </Col>
          </Row>
        </Col>
      </Row>
    </ModelLayout>
  );
};

export default DeleteModel;
