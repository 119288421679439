// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BQ9t0Q0p9rg9wwrlKyxn, .BQ9t0Q0p9rg9wwrlKyxn:visited, .BQ9t0Q0p9rg9wwrlKyxn:hover, .BQ9t0Q0p9rg9wwrlKyxn:active, .BQ9t0Q0p9rg9wwrlKyxn:focus {
  color: var(--accent-color);
  text-decoration: none;
  outline: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-external-resource-presenter/app-external-resource-presenter.module.scss"],"names":[],"mappings":"AACE;EAKE,0BAAA;EACA,qBAAA;EACA,aAAA;EACA,6BAAA;EACA,gBAAA;EACA,eAAA;AAJJ","sourcesContent":[".externalResourcePresenter {\n  &__link,\n  &__link:visited,\n  &__link:hover,\n  &__link:active,\n  &__link:focus {\n    color: var(--accent-color);\n    text-decoration: none;\n    outline: none;\n    background-color: transparent;\n    font-weight: 500;\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"externalResourcePresenter__link": `BQ9t0Q0p9rg9wwrlKyxn`
};
export default ___CSS_LOADER_EXPORT___;
