import { useAppDispatch, useAppSelector } from 'app/config/store';
import { savePassword } from 'app/modules/account/password/password.reducer';
import { uploadFile } from 'app/services/file-upload-service';
import { updateMobile, updateProfile, updateProfileImage } from 'app/services/profile-service';
import useIsAdmin from 'app/shared/hooks/useIsAdmin';
import { IProfileUpdate } from 'app/shared/model/dto/profile-update.model';
import { EUploadFileType } from 'app/shared/model/enumerations/upload-file-type';
import { IOption } from 'app/shared/model/option.model';
import { IUserProfile } from 'app/shared/model/user-profile.model';
import { getAccount } from 'app/shared/reducers/authentication';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppInput from 'app/shared/ui-elements/app-input/app-input';
import AppSelect from 'app/shared/ui-elements/app-select/app-select';
import TextArea from 'app/shared/ui-elements/text-area-component/TextArea';
import { getDropdownSelectedValue } from 'app/shared/util/app-elements-utils';
import { bioRegex, mobileRegex, noSpecialCharsNoNumbersNoSpacesRegex, passwordRegex } from 'app/shared/util/regex';
import { showErrorToast, showSuccessToast } from 'app/shared/util/toast';
import { splitPhoneNumber } from 'app/shared/util/user-contact-utils';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsCheckCircleFill, BsEye, BsEyeSlash, BsPencilSquare } from 'react-icons/bs';
import { toast } from 'react-toastify';
import styles from './MyProfile.module.scss';

type TChangePasswordFormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

type TChangePhoneNumberFormValues = {
  phoneNumber: string;
  countryCode: string;
  email: string;
};

const MyProfile = () => {
  const dispatch = useAppDispatch();

  const authState = useAppSelector(state => state.authentication);

  const imageRef = useRef(null);
  const detailsContainerRef = useRef(null);

  const [editState, setEditState] = useState(false);
  const [emailEditState, setEmailEditState] = useState(false);
  const [mobileEditState, setMobileEditState] = useState(false);
  const [passwordEditState, setPasswordEditState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileChangeLoading, setMobileEditLoading] = useState(false);
  const [PasswordChangeLoading, setPasswordEditLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState('');
  const isAdmin = useIsAdmin();

  const { successMessage, updateSuccess } = useAppSelector(state => ({
    successMessage: state.password.successMessage,
    updateSuccess: state.password.updateSuccess,
  }));

  const defaultFormValues: IUserProfile = {
    firstName: authState.account.firstName,
    lastName: authState.account.lastName,
    bio: authState.account.bio,
  };

  const defaultValue: TChangePhoneNumberFormValues = {
    phoneNumber: authState.account.mobileNumber,
    countryCode: authState.account.mobileNumber,
    email: authState.account.email,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset: resetProfile,
    setValue: setValueProfile,
  } = useForm<IProfileUpdate>();

  const {
    register: registerUser,
    handleSubmit: handleUser,
    reset: resetUser,
    control,
    formState: { errors: errorsUser },
    setValue,
    watch: watchUser,
  } = useForm<TChangePhoneNumberFormValues>();

  const {
    register: registerPassword,
    handleSubmit: handlePassword,
    watch,
    formState: { errors: errorsPassword },
    reset,
  } = useForm<TChangePasswordFormValues>({});

  useEffect(() => {
    if (imageRef.current && detailsContainerRef.current) {
      const imageHeight = imageRef.current.clientHeight;
      detailsContainerRef.current.style.height = `${imageHeight}px`;
    }

    if (row1Ref.current && row2Ref.current) {
      const row1Height = row1Ref.current.clientHeight;
      row2Ref.current.style.marginTop = `${row1Height}px`;
    }
  }, []);

  const password = useRef({});
  password.current = watch('newPassword', '');
  const existingPassword = useRef({});
  existingPassword.current = watch('currentPassword', '');
  const selectedCountryCode = watchUser('countryCode');

  const saveProfile = values => {
    setLoading(true);
    updateProfile(values)
      .then(() => {
        setLoading(false);
        dispatch(getAccount());
        setEditState(false);
        toast.success('Profile update successful.', {
          toastId: 'profileUpdateToast',
        });
      })
      .catch(() => {
        setLoading(false);
        setEditState(false);
        toast.error('Profile update failed. Please try again', {
          toastId: 'profileUpdateToast',
        });
      });
  };

  const saveUser = values => {
    const { phoneNumber } = values;
    const fullPhoneNumber = `${countryCode} ${phoneNumber}`;
    const sanitizedPhoneNumber = fullPhoneNumber.replace(/\s/g, '');
    setMobileEditLoading(true);
    updateMobile({ phoneNumber: sanitizedPhoneNumber })
      .then(() => {
        setMobileEditLoading(false);
        dispatch(getAccount());
        setMobileEditState(false);
        showSuccessToast('Mobile number update successful.', 'mobileUpdateToast');
      })
      .catch(() => {
        setMobileEditLoading(false);
        setMobileEditState(false);
        showErrorToast('Mobile number update failed. Please try again', 'mobileUpdateToast');
      });
  };

  const changePassword = values => {
    setPasswordEditLoading(true);
    dispatch(savePassword(values))
      .then(() => {
        setPasswordEditLoading(false);
        setPasswordEditState(false);
        successMessage && showSuccessToast('Password update successful.', 'passwordUpdateSuccess');
        reset();
      })
      .catch(() => {
        setPasswordEditLoading(false);
        setPasswordEditState(false);
        reset();
      });
  };

  useEffect(() => {
    if (updateSuccess && passwordEditState) {
      showSuccessToast('Password update successful.', 'updateSuccess');
    }
  }, [updateSuccess]);

  const ProfileDetailRow = ({ label, value }) => (
    <Row className="mt-4">
      <Col className="col-auto">
        <Row>
          <Col>
            <p className={`${styles.label} mb-0`}>{label}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={`${styles.value} mb-0`}>{value}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const setEdit = () => {
    setEditState(!editState);
    resetProfile();
    setValueProfile('firstName', defaultFormValues.firstName);
    setValueProfile('lastName', defaultFormValues.lastName);
    setValueProfile('bio', defaultFormValues.bio || '');
  };

  const setEmailEdit = () => {
    setEmailEditState(!emailEditState);
  };

  const setMobileNoEdit = () => {
    setMobileEditState(!mobileEditState);
    resetUser();
    if (defaultValue.phoneNumber) {
      const phoneNumberInfo = splitPhoneNumber(defaultValue.phoneNumber);
      setValue('phoneNumber', phoneNumberInfo?.phoneNumber);
      const countryCodeValue = getDropdownSelectedValue(phoneNumberInfo?.code, countryCodes);
      setValue('countryCode' as any, countryCodeValue, { shouldDirty: true, shouldValidate: true });
    }
  };

  const setPasswordEdit = () => {
    setPasswordEditState(!passwordEditState);
    reset();
  };

  const row1Ref = useRef(null);
  const row2Ref = useRef(null);

  useEffect(() => {
    if (row1Ref.current && row2Ref.current) {
      const row1Height = row1Ref.current.clientHeight;
      row2Ref.current.style.marginTop = `${row1Height}px`;
    }
  }, []);

  const handleImageUpdate = () => {
    imageRef.current.click();
  };

  const handleImageChange = e => {
    const [file] = e.target.files;
    if (['image/jpg', 'image/jpeg', 'image/png', 'image/tiff'].includes(file.type)) {
      uploadFile(file, EUploadFileType.IMAGES, file.name)
        .then((response: IFileUploadDTO) => {
          showSuccessToast('Profile image updated successfully.', 'imageUpdateSuccess');
          return updateProfileImage({ imageUrl: response.storagePath });
        })
        .then(() => dispatch(getAccount()))
        .catch(() => {
          showErrorToast('An error occurred during file upload. Please try again later.', 'imageUpdateError');
        });
    } else {
      showErrorToast('Incorrect file format. Please upload a JPG/JPEG, PNG, or TIFF image.', 'imageIncorrectError');
    }
  };

  const handleCountryCodeChange = value => {
    setCountryCode(value);
  };

  const countryCodes: IOption[] = [{ value: '+94', label: '+94' }];

  useEffect(() => {
    handleCountryCodeChange((selectedCountryCode as any)?.value);
  }, [selectedCountryCode]);

  return (
    <Row className={`${styles.myProfile} mb-5`}>
      <Col className={`${styles.sectionOne} col-12 col-lg-6 px-1 px-lg-5 pe-lg-0`}>
        <Row ref={row1Ref}>
          <Col className="col-auto d-flex flex-column align-items-center">
            <div className="d-flex flex-column align-items-center">
              <img
                className={`${styles.profileImg}`}
                src={authState.account.imageUrl || '../././././content/images/default-profile.png'}
                alt="profile"
                width={100}
                height={100}
                ref={imageRef}
              />
              <input type="file" accept="image/*" onChange={handleImageChange} ref={imageRef} style={{ display: 'none' }} />
              <div
                className={`${styles.editProfileIcon} d-flex justify-content-center align-items-center cursor-pointer`}
                onClick={handleImageUpdate}
              >
                <BsPencilSquare size={15} color="white" />
              </div>
            </div>
          </Col>
          <Col className="col-auto px-0">
            <div ref={detailsContainerRef} className="mt-3 justify-content-center">
              <Row>
                <Col>
                  <p className={`${styles.userName} mb-1`}>
                    {authState.account.firstName} {authState.account.lastName}
                  </p>
                </Col>
              </Row>
              {!isAdmin && (
                <Row>
                  <Col>
                    <p className={`${styles.userId} mb-1`}> {authState.account.index} </p>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <p className={`${styles.userRole} mb-0`}> {authState.account.role} </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={`${styles.detailsSection} px-4`}>
              {!editState ? (
                <>
                  <ProfileDetailRow label="First Name" value={authState.account.firstName} />

                  <ProfileDetailRow label="Last Name" value={authState.account.lastName} />

                  <ProfileDetailRow label="Bio" value={authState.account.bio} />

                  <Row>
                    <Col className="col-auto ps-0">
                      <AppButton onClick={setEdit} className={`${styles.editBtn} my-4 `} btnType="onlyText" text="Edit Profile" />
                    </Col>
                  </Row>
                </>
              ) : (
                <Form onSubmit={handleSubmit(saveProfile)}>
                  <Row className="mt-4">
                    <Col>
                      <AppInput
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        placeholder="First Name"
                        register={register('firstName', {
                          required: 'First Name is required',
                          minLength: {
                            value: 2,
                            message: 'This field must contain at least 2 characters',
                          },
                          maxLength: {
                            value: 15,
                            message: 'You have exceeded the maximum number of 15 characters in this field',
                          },
                          pattern: {
                            value: noSpecialCharsNoNumbersNoSpacesRegex,
                            message:
                              'Entered value should contain only letters (uppercase/ lowercase), hyphens, and apostrophes. Spaces, special characters and numbers are not allowed.',
                          },
                        })}
                        errors={errors}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <AppInput
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        placeholder="Last Name"
                        register={register('lastName', {
                          required: 'Last Name is required',
                          minLength: {
                            value: 2,
                            message: 'This field must contain at least 2 characters',
                          },
                          maxLength: {
                            value: 15,
                            message: 'You have exceeded the maximum number of 15 characters in this field',
                          },
                          pattern: {
                            value: noSpecialCharsNoNumbersNoSpacesRegex,
                            message:
                              'Entered value should contain only letters (uppercase/ lowercase), hyphens, and apostrophes. Spaces, special characters and numbers are not allowed.',
                          },
                        })}
                        errors={errors}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <TextArea
                        id="bio"
                        label="Bio"
                        name="bio"
                        placeholder="Bio"
                        register={register('bio', {
                          maxLength: {
                            value: 300,
                            message: 'You have exceeded the maximum number of 300 characters in this field',
                          },
                          pattern: {
                            value: bioRegex,
                            message:
                              'This field should have only numeric and special characters combined with text, some special characters are not allowed',
                          },
                        })}
                        errors={errors}
                      />
                    </Col>
                  </Row>

                  <Row className="my-3">
                    <Col className="col-4">
                      <AppButton text="Save" btnType="filled" btnSize="sm" type="submit" loading={loading} />
                    </Col>
                    <Col className="col-4">
                      <AppButton text="Cancel" btnType="bordered" btnSize="sm" onClick={setEdit} type="button" />
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </Col>
        </Row>
      </Col>
      <Col className={`${styles.sectionTwo} col-12 col-lg-5 px-1 px-lg-5 ps-lg-3 pe-lg-0 mt-3 mt-lg-0`}>
        <Row ref={row2Ref} className={`${styles.rowOne}`}>
          <Col>
            <div className={`${styles.subSection} p-3`}>
              <Row>
                {emailEditState ? (
                  <Col className="pe-3">
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <Form onSubmit={() => {}}>
                      <Row>
                        <Col>
                          <AppInput
                            id="email"
                            name="email"
                            label="Email"
                            placeholder="Email"
                            register={registerUser('email')}
                            errors={errorsUser}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col className="col-4">
                          <AppButton text="Save" btnType="filled" btnSize="sm" type="submit" />
                        </Col>
                        <Col className="col-4">
                          <AppButton text="Cancel" btnType="bordered" btnSize="sm" onClick={setEmailEdit} type="button" />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                ) : (
                  <>
                    <Col className="col-10">
                      <Row>
                        <Col>
                          <p className={`${styles.fontStyle} ${styles.labelColor} mb-1`}> Email </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={`${styles.fontStyle} ${styles.valueColor} mb-0`}>
                            {authState.account.email}
                            <span className="ps-3">
                              <BsCheckCircleFill size={15} color="var(--accent-color)" />
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-2 d-flex justify-content-center align-items-center">
                      <BsPencilSquare
                        color="var(--text-secondary-color)"
                        className={`${styles.editIcon} ${styles.editIconNotAllow}`}
                        // onClick={setEmailEdit}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Col>
        </Row>

        <Row className={`mt-3`}>
          <Col>
            <div className={`${styles.subSection} p-3`}>
              <Row>
                {mobileEditState ? (
                  <Col className="pe-3">
                    <Form onSubmit={handleUser(saveUser)}>
                      <Row>
                        <Col xs={5} sm={3} lg={6} xxl={4}>
                          <p style={{ color: 'var(--text-secondary-color)' }} className="mb-0">
                            Mobile Number
                          </p>
                          <AppSelect
                            id="countryCode"
                            name="countryCode"
                            options={countryCodes}
                            register={registerUser('countryCode', {
                              required: 'Please select the country code',
                            })}
                            errors={errorsUser}
                            control={control}
                          />
                        </Col>
                        <Col xs={7} sm={9} lg={6} xxl={8} className="ps-0 mt-4">
                          <AppInput
                            id="phoneNumber"
                            name="phoneNumber"
                            type="number"
                            placeholder="Mobile Number"
                            register={registerUser('phoneNumber', {
                              required: 'Mobile number is required',
                              pattern: {
                                value: mobileRegex,
                                message: 'Please enter a value containing exactly 9 digits',
                              },
                            })}
                            errors={errorsUser}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col className="col-4">
                          <AppButton text="Save" btnType="filled" btnSize="sm" loading={mobileChangeLoading} />
                        </Col>
                        <Col className="col-4">
                          <AppButton text="Cancel" btnType="bordered" btnSize="sm" onClick={setMobileNoEdit} />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                ) : (
                  <>
                    <Col className="col-10">
                      <Row>
                        <Col>
                          <p className={`${styles.fontStyle} ${styles.labelColor} mb-1`}> Mobile Number </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={`${styles.fontStyle} ${styles.valueColor} mb-0`}>
                            {authState.account.mobileNumber ? authState.account.mobileNumber : '-'}
                            {authState.account.mobileNumber ? (
                              <span className="ps-3">
                                <BsCheckCircleFill size={15} color="var(--accent-color)" />
                              </span>
                            ) : (
                              <span className="ps-3">
                                <BsCheckCircleFill size={15} color="var(--icon-color)" />
                              </span>
                            )}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-2 d-flex justify-content-center align-items-center">
                      <BsPencilSquare
                        color="var(--text-secondary-color)"
                        className={`${styles.editIcon} cursor-pointer`}
                        onClick={setMobileNoEdit}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Col>
        </Row>

        <Row className={`mt-3 mb-5`}>
          <Col>
            <div className={`${styles.subSection} p-3`}>
              <Row>
                {passwordEditState ? (
                  <Col className="pe-3">
                    <Row>
                      <Col>
                        <p className={`${styles.fontStyle} ${styles.title} mb-1`}> Change Password </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-4">
                        <p className={`${styles.fontStyle} ${styles.labelColor} mb-0`}> Create a new strong password </p>
                      </Col>
                    </Row>
                    <Form onSubmit={handlePassword(changePassword)}>
                      <Row>
                        <Col className="mb-4">
                          <AppInput
                            id="currentPassword"
                            name="currentPassword"
                            label="Old Password"
                            placeholder={'Old Password'}
                            type={showOldPassword ? 'text' : 'password'}
                            icon={!showOldPassword ? BsEyeSlash : BsEye}
                            onIconClick={() => setShowOldPassword(!showOldPassword)}
                            register={registerPassword('currentPassword', {
                              required: 'Password is required',
                              minLength: {
                                value: 8,
                                message:
                                  'Your password must be at least 8 characters long, contain at least one number, special character, and have a mix of uppercase and lowercase letters.',
                              },
                              pattern: {
                                value: passwordRegex,
                                message:
                                  'Your password must be at least 8 characters long, contain at least one number, special character, and have a mix of uppercase and lowercase letters.',
                              },
                            })}
                            errors={errorsPassword}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-4">
                          <AppInput
                            id="newPassword"
                            name="newPassword"
                            label="New Password"
                            placeholder={'New Password'}
                            type={showPassword ? 'text' : 'password'}
                            icon={!showPassword ? BsEyeSlash : BsEye}
                            onIconClick={() => setShowPassword(!showPassword)}
                            register={registerPassword('newPassword', {
                              required: 'Password is required',
                              minLength: {
                                value: 8,
                                message:
                                  'Your password must be at least 8 characters long, contain at least one number, special character, and have a mix of uppercase and lowercase letters.',
                              },
                              pattern: {
                                value: passwordRegex,
                                message:
                                  'Your password must be at least 8 characters long, contain at least one number, special character, and have a mix of uppercase and lowercase letters.',
                              },
                              validate: value =>
                                value !== existingPassword.current || 'Password match the old password. Please enter a new password',
                            })}
                            errors={errorsPassword}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-4">
                          <AppInput
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirm Password"
                            placeholder={'Confirm password'}
                            type={showConfirmPassword ? 'text' : 'password'}
                            icon={!showConfirmPassword ? BsEyeSlash : BsEye}
                            onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            register={registerPassword('confirmPassword', {
                              required: 'Confirm password is required',
                              validate: value => value === password.current || 'The passwords do not match',
                            })}
                            errors={errorsPassword}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col className="col-6 col-md-5">
                          <AppButton text="Confirm" btnType="filled" btnSize="sm" loading={PasswordChangeLoading} />
                        </Col>
                        <Col className="col-6 col-md-5">
                          <AppButton text="Cancel" btnType="bordered" btnSize="sm" onClick={setPasswordEdit} />
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                ) : (
                  <>
                    <Col className="col-10">
                      <Row>
                        <Col>
                          <p className={`${styles.fontStyle} ${styles.labelColor} mb-1`}> Password </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className={`${styles.fontStyle} ${styles.valueColor} mb-0`}> ******* </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-2 d-flex justify-content-center align-items-center">
                      <BsPencilSquare
                        color="var(--text-secondary-color)"
                        className={`${styles.editIcon} cursor-pointer`}
                        onClick={setPasswordEdit}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MyProfile;
