export interface IChapter {
  id?: number;
  courseId?: number;
  lessonId?: number;
  unitId?: number | null;
  chapterName?: string;
  chapterDescription?: string | null;
  chapterSequenceNumber?: number | null;
}

export const defaultValue: Readonly<IChapter> = {};
