import useIsAdmin from 'app/shared/hooks/useIsAdmin';
import { ECourseType } from 'app/shared/model/enumerations/course-type.model';
import { EUserCourseStatus } from 'app/shared/model/enumerations/user-course-status.model';
import AppBadgeWithLabel from 'app/shared/ui-elements/app-badge-with-label/AppBadgeWithLabel';
import React, { useEffect, useState } from 'react';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import styles from './course-enrollment-summary-card.module.scss';

interface IProps {
  data: {
    courseId?: number;
    courseName?: string;
    courseType?: ECourseType;
    courseTypeInText?: string;
    specialization?: string;
    coverImageUrl?: string;
    userCompletedPercentage?: number;
    userCourseStatus?: string;
    [key: string]: any;
  };
  className?: string;
}

const CourseEnrollmentSummaryCard = ({ data, className }: IProps) => {
  const [type, setType] = useState<string>('');
  useEffect(() => {
    const courseType = ECourseType[data.courseType as keyof typeof ECourseType];
    setType(
      courseType &&
        courseType
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
    );
  }, [data.courseType]);

  const isAdmin = useIsAdmin();

  return (
    <Card className={`${styles.courseCard} ${className} cursor-pointer`}>
      <Row>
        <Col md={5} lg={3}>
          <Card.Img className={`${styles.cardImg}`} variant="top" src={data.coverImageUrl} />
        </Col>
        <Col className="d-flex justify-content-center align-items-center">
          <Card.Body className="m-3 ms-lg-0">
            <Row>
              <Col>
                <Row className="d-flex align-items-center">
                  {/* <Col className={`col-auto ${styles.cardBadge}`}>
                    <AppBadge label={type} variant="secondary" />
                  </Col> */}
                  <Col>
                    <AppBadgeWithLabel label={'Subject'} badgeLabel={data.specialization} />
                  </Col>
                </Row>
                <Card.Title className={`mt-1 ${styles.cardTitle}`}>{data.courseName}</Card.Title>

                {data.userCourseStatus && data.userCourseStatus !== EUserCourseStatus.COURSE_NOT_STARTED ? (
                  <Row className={`align-items-center ${isAdmin && 'd-none'}`}>
                    <Col xs={8} sm={8} lg={9}>
                      <ProgressBar className={`${styles.progressBar}`} now={data.userCompletedPercentage} />
                    </Col>
                    <Col>
                      <span className={`${styles.progressCount}`}>
                        {data.userCompletedPercentage ? data.userCompletedPercentage.toFixed(0) : 0}% Completed
                      </span>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default CourseEnrollmentSummaryCard;
