import { IRootState, useAppSelector } from 'app/config/store';
import { ETheme } from 'app/shared/model/enumerations/theme.model';
import React, { FC } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { OffcanvasPlacement } from 'react-bootstrap/esm/Offcanvas';
import './offCanvas.scss';

interface IProps {
  open: boolean;
  closeHandler: () => void;
  placement?: OffcanvasPlacement;
  children: JSX.Element;
}

const OffCanvas: FC<IProps> = props => {
  const customStyle = {
    backgroundColor: props.placement === 'end' ? 'rgba(0, 146, 208, 0.9)' : 'var(--background-color)',
  };

  const { theme } = useAppSelector((state: IRootState) => ({ theme: state.theme.theme }));

  return (
    <React.Fragment>
      <Offcanvas show={props.open} onHide={props.closeHandler} placement={props.placement || 'start'}>
        <Offcanvas.Header
          closeButton
          closeVariant={props.placement === 'end' ? 'white' : theme === ETheme.DARK ? 'white' : 'black'}
          style={customStyle}
          className="offCanvasHeader"
        />
        <Offcanvas.Body style={customStyle}>{props.children}</Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default OffCanvas;
