import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AppSearch from '../../../shared/ui-elements/app-search/app-search';
import styles from './catalog-header.module.scss';

const CatalogHeader = ({ onSearch }) => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <div className={`${styles.catalogHeaderContainer}`}>
      <Container>
        <Row className={`${styles.catalogHeader} justify-content-between align-items-center py-3 py-md-5`}>
          <Col className="col-12 col-md-6 text-center text-md-start mt-5 mt-md-0 mb-2 mb-md-0">
            <h1>Program Catalog</h1>
            <p>Find a program matches your interest</p>
          </Col>
          <Col className="col-12 col-md-6 col-lg-5 mb-5 mb-md-0">
            <AppSearch
              onSearch={() => {
                onSearch(searchInput);
              }}
              onChange={input => setSearchInput(input)}
              value={searchInput}
              placeholder={'Search'}
              className={styles.catalogSearch}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CatalogHeader;
