import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './app-mini-pop-up.module.scss';

export interface IMiniPopUpItem {
  text: string;
  onClick?: () => void;
}

interface IProps {
  items: IMiniPopUpItem[];
  className?: string;
}

const AppMiniPopUp: FC<IProps> = props => {
  return (
    <Row>
      <Col>
        <div className={`${styles.miniPopUp} ${props.className}`}>
          {props.items
            .filter((item: IMiniPopUpItem) => item.text.length > 0)
            .map((item: IMiniPopUpItem, index, array) => {
              const isLastItem = index === array.length - 1;
              return (
                <Row key={index}>
                  <Col>
                    <div
                      className={`${styles.miniPopUpText} ${!isLastItem && styles.miniPopUpBorderBottom} cursor-pointer`}
                      onClick={item.onClick}
                    >
                      {item.text}
                    </div>
                  </Col>
                </Row>
              );
            })}
        </div>
      </Col>
    </Row>
  );
};

export default AppMiniPopUp;
