import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { ETheme } from 'app/shared/model/enumerations/theme.model';
import { changeTheme } from 'app/shared/reducers/theme';
import React from 'react';
import { BsMoon, BsSun } from 'react-icons/bs';
import styles from './theme-toggle.module.scss';

interface IProps {
  iconColor?: boolean;
}

const ThemeToggle = (props: IProps) => {
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state: IRootState) => ({ theme: state.theme.theme }));

  const handleThemeChange = () => {
    const newTheme: ETheme = theme === ETheme.LIGHT ? ETheme.DARK : ETheme.LIGHT;
    dispatch(changeTheme(newTheme));
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <div>
      <button onClick={handleThemeChange} className={`${props.iconColor ? styles.iconColor : styles.toggle} d-flex align-items-center`}>
        {theme === ETheme.DARK ? <BsSun size={16} /> : <BsMoon size={16} />}
      </button>
    </div>
  );
};

export default ThemeToggle;
