import AppTooltip from 'app/shared/ui-elements/app-tooltip/AppTooltip';
import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsQuestionCircle } from 'react-icons/bs';
import ImageUploader, { IImageUploaderProps } from '../image-uploader/image-uploader';
import styles from './image-uploader-field.module.scss';

interface IProps extends IImageUploaderProps {
  label: string;
  toolTipText?: string;
  isRequired?: boolean;
  existingFileUrls?: { url: string; file: File }[];
}

const ImageUploadField: FC<IProps> = props => {
  return (
    <>
      <Row>
        <Col className="col-auto pe-1">
          <span className={`${styles.labelText}`}>{props.label}</span>
          {props.isRequired && <span className={`${styles.required} ms-1`}>*</span>}
        </Col>
        {props.toolTipText && (
          <Col className="col-auto ps-1">
            <AppTooltip tooltipId={props.label} content={props.toolTipText}>
              <BsQuestionCircle data-tooltip-id={props.label} size={12} />
            </AppTooltip>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="mt-1">
          <div className={`${styles.imageUploaderCustomCard} d-flex align-items-center px-3 py-2`}>
            <ImageUploader {...props} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ImageUploadField;
