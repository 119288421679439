// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.owK27PlorT1f1t64asw5 {
  transition: transform 200ms ease-in;
}

.fMF06bb4e9_LggZxe5tl {
  transform: rotate(0deg);
}

.jKM7gdAULiK3tPNpEngh {
  transform: rotate(180deg);
}

.AjFhQUvdw3C_Hd5egoHt {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-primary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-sort-icon/app-sort-icon.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,2CAAA;AACF","sourcesContent":[".upArrow {\n  transition: transform 200ms ease-in;\n}\n\n.rotate0 {\n  transform: rotate(0deg);\n}\n\n.rotate180 {\n  transform: rotate(180deg);\n}\n\n.txt1 {\n  font-size: 14px;\n  font-weight: 400;\n  color: var(--text-primary-color) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upArrow": `owK27PlorT1f1t64asw5`,
	"rotate0": `fMF06bb4e9_LggZxe5tl`,
	"rotate180": `jKM7gdAULiK3tPNpEngh`,
	"txt1": `AjFhQUvdw3C_Hd5egoHt`
};
export default ___CSS_LOADER_EXPORT___;
