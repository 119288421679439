import React from 'react';
import { Card, Col, Placeholder, Row } from 'react-bootstrap';
import styles from '../../../../modules/program-catalog/course-grid-view/course-card/course-card.module.scss';
import stylesLoader from '../../../../modules/program-catalog/course-grid-view/course-loader-card/course-loader-card.module.scss';
import StylesProgramcard from './card-loader.module.scss';

const CardLoader = () => {
  return (
    <Card className={`${styles.courseCard} ${stylesLoader.courseCardLoader}`}>
      <Row>
        <Col md={5} lg={3}>
          <Card.Img variant="top" className={`${StylesProgramcard.cardImg} ${stylesLoader.cardLoaderImg}`} />
        </Col>
        <Col>
          <Card.Body className="ms-1 ms-lg-0">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Placeholder as={Card.Text} animation="glow" className={`mt-3 mb-3`}>
                      <Placeholder xs={3} md={5} className={`${stylesLoader.cardBorderRadius}`} />
                    </Placeholder>
                  </Col>
                  <Col>
                    <Placeholder as={Card.Text} animation="glow" className={`mt-3 mb-3`}>
                      <Placeholder xs={3} md={5} className={`${stylesLoader.cardBorderRadius}`} />
                    </Placeholder>
                  </Col>
                </Row>
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={12} className={`${stylesLoader.cardBorderRadius}`} />
                </Placeholder>
                <Row className="mt-2 mt-xl-0">
                  <Col sm={6}>
                    <Placeholder as={Card.Title} animation="glow">
                      <Placeholder xs={12} className={`${stylesLoader.cardBorderRadius}`} />
                    </Placeholder>{' '}
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col xs={8} sm={8} lg={9}>
                    <Placeholder as={Card.Title} animation="glow">
                      <Placeholder xs={12} className={`${stylesLoader.cardBorderRadius}`} />
                    </Placeholder>
                  </Col>
                  <Col>
                    <Placeholder as={Card.Title} animation="glow">
                      <Placeholder xs={12} className={`${stylesLoader.cardBorderRadius}`} />
                    </Placeholder>
                  </Col>
                </Row>
              </Col>
              <Col lg={3} className="mt-3 mt-lg-0 align-self-center">
                <Row>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={12} className={`${stylesLoader.cardBorderRadius}`} />
                  </Placeholder>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default CardLoader;
