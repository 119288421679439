import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './IconWithText.module.scss';

import { IconType } from 'react-icons';

interface IProps {
  text: string;
  icon: IconType;
  variant?: 'primary' | 'secondary' | 'colored';
  onClick?: () => void;
  iconSize?: number;
}

const IconWithText = (props: IProps) => {
  const { icon: IconComponent, text, variant = 'primary', iconSize } = props;
  return (
    <div onClick={() => props.onClick && props.onClick()}>
      <Row className={`align-items-center flex-nowrap ${props.onClick && 'cursor-pointer'}`}>
        <Col className="col-auto pe-2 d-flex">
          <IconComponent
            size={iconSize || 14}
            className={`${variant === 'secondary' ? styles.secondary : variant === 'colored' ? styles.coloredIcon : styles.icon}`}
          />
        </Col>
        <Col className="px-0 d-flex">
          <span className={`${styles.iconWithTextFont} ${styles[variant]}`}>{text}</span>
        </Col>
      </Row>
    </div>
  );
};

export default IconWithText;
