import React from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import './range-slider.scss';

interface IProps {
  priceRangeMinValue: number;
  priceRangeMaxValue: number;
  onInput: any;
  value: any;
}

const PriceRangeSlider = (props: IProps) => {
  const formatPrice = price => {
    return price.toLocaleString('en-LK', {
      style: 'currency',
      currency: 'LKR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  return (
    <>
      <p className={`priceRange mt-3`}>{formatPrice(props.priceRangeMinValue) + ' - ' + formatPrice(props.priceRangeMaxValue).slice(4)} </p>
      <RangeSlider value={props.value} onInput={props.onInput} min={0} max={100000} step={500} />
    </>
  );
};

export default PriceRangeSlider;
