import { IResourceLinkUtilized } from 'app/shared/util/dnd-utils';
import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IRowInputsData } from '../../multiple-rows-addition-field/multiple-rows-addition-field';
import MultipleRowAdditionFieldSecond from '../../multiple-rows-addition-field/multiple-rows-addition-field-second';
import AppDNDCard from '../app-dnd-card/app-dnd-card';

interface IProps {
  label: string;
  addImageEnabled: boolean;
  buttonText: string;
  rowInputsData: IRowInputsData;
  resourceLinks: IResourceLinkUtilized[];
  onClickRemoveIcon?: () => void;
  chapterItemSequenceNumber: string;
  referenceNameChangeHandler: (chapterItemSequenceNumber: string, index: number, referenceName: string) => void;
  urlChangeHandler: (chapterItemSequenceNumber: string, index: number, url: string) => void;
  referenceLinksAddNewRowHandler: (chapterItemSequenceNumber: string) => void;
  referenceLinksRemoveRowHandler: (chapterItemSequenceNumber: string, index: number) => void;
}

const ExternalResourceHandlerSecond: FC<IProps> = props => {
  return (
    <>
      <Row>
        <Col>
          <AppDNDCard label={props.label} onClickRemoveIcon={props.onClickRemoveIcon}>
            <Row>
              <Col>
                <MultipleRowAdditionFieldSecond
                  buttonText={props.buttonText}
                  addImageEnabled={props.addImageEnabled}
                  rowInputsData={props.rowInputsData}
                  resourceLinks={props.resourceLinks}
                  chapterItemSequenceNumber={props.chapterItemSequenceNumber}
                  referenceNameChangeHandler={props.referenceNameChangeHandler}
                  urlChangeHandler={props.urlChangeHandler}
                  referenceLinksAddNewRowHandler={props.referenceLinksAddNewRowHandler}
                  referenceLinksRemoveRowHandler={props.referenceLinksRemoveRowHandler}
                />
              </Col>
            </Row>
          </AppDNDCard>
        </Col>
      </Row>
    </>
  );
};

export default ExternalResourceHandlerSecond;
