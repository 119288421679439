import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import AppDNDMiniCard from 'app/shared/components/app-dnd-elements/app-dnd-mini-card/app-dnd-mini-card';
import CourseEnrollmentSummaryCard from 'app/shared/components/course-enrollment-summary-card/course-enrollment-summary-card';
import MainLoader from 'app/shared/components/main-loader/main-loader';
import NoticeView from 'app/shared/components/notice-views/notice-view';
import { EUserCourseStatus } from 'app/shared/model/enumerations/user-course-status.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import IconCard from 'app/shared/ui-elements/icon-card/icon-card';
import React, { Fragment, useEffect } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaCircleCheck } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ICourseLessonsUnitsPresenter } from './course-lessons-units-presenter.model';
import styles from './course-lessons-units-presenter.module.scss';
import { getLessonsAndUnitsOfCourse } from './course-lessons-units-presenter.reducer';

const CourseLessonsUnitsPresenter = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { state, loading } = useAppSelector((rootState: IRootState) => ({
    state: rootState.courseLessonsUnitsPresenter.entity as ICourseLessonsUnitsPresenter,
    loading: rootState.courseLessonsUnitsPresenter.loading,
  }));

  useEffect(() => {
    dispatch(getLessonsAndUnitsOfCourse(courseId));
  }, []);

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <CourseEnrollmentSummaryCard data={state} />
          </Col>
        </Row>

        {state.userCourseStatus === EUserCourseStatus.COURSE_NOT_STARTED ? (
          <Row className="my-5">
            <Col>
              <NoticeView
                title={'The course has not started yet.'}
                description={
                  "But don't worry—once it kicks off, you'll gain access to all the enriching content. Stay tuned for an engaging learning experience!"
                }
              />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col className={`mt-3 ${styles.presenterTitle}`}>Course Content</Col>
            </Row>
            <Row>
              <Col className="my-4">
                {state.lessons &&
                  state.lessons.map((outerItem, index) => (
                    <Row key={index}>
                      <Col className="my-1">
                        <Fragment key={uuidv4()}>
                          <Card className={`${styles.accordionContainer}`}>
                            <Accordion>
                              <Accordion.Header>
                                <Card.Body>
                                  <Row className="align-items-center">
                                    <Col className={styles.header}>{outerItem.lessonName}</Col>
                                    <Col className="col-auto">
                                      <IconCard
                                        icon={
                                          <FaCircleCheck
                                            className={`me-2 ${!outerItem.completed && 'd-none'}`}
                                            color="var(--success-color)"
                                            size={23}
                                          />
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Accordion.Header>

                              <Accordion.Body>
                                <Row>
                                  <Col>
                                    {outerItem.lessonDescription && (
                                      <Row>
                                        <Col className={`${styles.descriptionText} mb-3`}>{outerItem.lessonDescription}</Col>
                                      </Row>
                                    )}

                                    {outerItem.containsUnits ? (
                                      <>
                                        {outerItem && outerItem.units.length > 0 && outerItem.units[0].unitId !== null ? (
                                          outerItem.units.map((innerItem, innerIndex) => (
                                            <Row key={innerIndex}>
                                              <Col className="my-1">
                                                <AppDNDMiniCard
                                                  text={innerItem.unitName}
                                                  buttonText="View Unit"
                                                  onClickButton={() => {
                                                    navigate(
                                                      `/student/courses/${state.courseId}/course-materials/enroll/${state.enrollmentId}/lesson/${outerItem.lessonId}/unit/${innerItem.unitId}`
                                                    );
                                                  }}
                                                  lastIcon={
                                                    <FaCircleCheck
                                                      className={`me-2 ${!outerItem.units[innerIndex].completed && 'd-none'}`}
                                                      color="var(--success-color)"
                                                      size={23}
                                                    />
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                          ))
                                        ) : (
                                          <Row className="align-items-center">
                                            <Col className="col-auto pe-0">
                                              <div className="d-flex align-items-center">
                                                <BsExclamationCircle color="#FFC444" size={14} />
                                                <div className={`${styles.descriptionText} ms-2`}>No units added yet.</div>
                                              </div>
                                            </Col>
                                          </Row>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <Row className="justify-content-start">
                                          <Col className="col-6 col-md-3">
                                            <AppButton
                                              text="View Lesson"
                                              btnType="gray"
                                              onClick={() => {
                                                !outerItem.containsUnits &&
                                                  navigate(
                                                    `/student/courses/${courseId}/course-materials/enroll/${state.enrollmentId}/lesson/${outerItem.lessonId}`
                                                  );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </Accordion.Body>
                            </Accordion>
                          </Card>
                        </Fragment>
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </>
        )}
        <MainLoader show={loading} />
      </Col>
    </Row>
  );
};

export default CourseLessonsUnitsPresenter;
