import React from 'react';
import { Col, Row } from 'react-bootstrap';

import styles from './AppBadge.module.scss';

interface IProps {
  label: string;
  variant?: 'primary' | 'secondary';
}

const AppBadge = (props: IProps) => {
  const { variant = 'primary', label } = props;

  return (
    <Row>
      <Col>
        <div className={`${styles.appBadge} ${styles[variant]} ${variant === 'secondary' ? 'px-1' : 'px-2'} text-center`}>{label}</div>
      </Col>
    </Row>
  );
};

export default AppBadge;
