import { createSlice } from '@reduxjs/toolkit';
import { ETheme } from '../model/enumerations/theme.model';

export const initialState = {
  theme: localStorage.getItem('theme') || ETheme.LIGHT,
};

export type ThemeState = Readonly<typeof initialState>;

export const ThemeSlice = createSlice({
  name: 'theme',
  initialState: initialState as ThemeState,
  reducers: {
    changeTheme(state, action) {
      return {
        ...initialState,
        theme: action.payload as ETheme,
      };
    },
  },
});

export const { changeTheme } = ThemeSlice.actions;

export default ThemeSlice.reducer;
