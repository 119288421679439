// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background-color: #f5f5f5;
  --card-color: #ffffff;
  --accent-color: #0092d0;
  --text-primary-color: #3f3e4a;
  --text-secondary-color: #767676;
  --icon-color: #969fa1;
  --card-secondary-color: #f9f9f9;
  --line-color: #f1f1f1;
  --error-color: #dd5c73;
  --error-secondary-color: #ff4c6c;
  --error-icon-color: #ef4f4f;
  --success-color: #20d167;
  --success-icon-color: #1bd164;
  --card-tertiary-color: #054c70;
  --text-tertiary-color: #8d8d8d;
  --text-light-color: #69768b;
}

[data-theme=dark] {
  --background-color: #181924;
  --card-color: #262837;
  --accent-color: #0092d0;
  --text-primary-color: #fdffff;
  --text-secondary-color: #908f99;
  --icon-color: #969fa1;
  --card-secondary-color: #222331;
  --line-color: #1e202d;
  --error-color: #dd5c73;
  --error-secondary-color: #ff4c6c;
  --error-icon-color: #ef4f4f;
  --success-color: #20d167;
  --success-icon-color: #1bd164;
  --card-tertiary-color: #054c70;
  --text-tertiary-color: #8d8d8d;
  --text-light-color: #69768b;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/content/sass/abstracts/_variables.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,qBAAA;EACA,uBAAA;EACA,6BAAA;EACA,+BAAA;EACA,qBAAA;EACA,+BAAA;EACA,qBAAA;EACA,sBAAA;EACA,gCAAA;EACA,2BAAA;EACA,wBAAA;EACA,6BAAA;EACA,8BAAA;EACA,8BAAA;EACA,2BAAA;AACF;;AAEA;EACE,2BAAA;EACA,qBAAA;EACA,uBAAA;EACA,6BAAA;EACA,+BAAA;EACA,qBAAA;EACA,+BAAA;EACA,qBAAA;EACA,sBAAA;EACA,gCAAA;EACA,2BAAA;EACA,wBAAA;EACA,6BAAA;EACA,8BAAA;EACA,8BAAA;EACA,2BAAA;AACF","sourcesContent":[":root {\n  --background-color: #f5f5f5;\n  --card-color: #ffffff;\n  --accent-color: #0092d0;\n  --text-primary-color: #3f3e4a;\n  --text-secondary-color: #767676;\n  --icon-color: #969fa1;\n  --card-secondary-color: #f9f9f9;\n  --line-color: #f1f1f1;\n  --error-color: #dd5c73;\n  --error-secondary-color: #ff4c6c;\n  --error-icon-color: #ef4f4f;\n  --success-color: #20d167;\n  --success-icon-color: #1bd164;\n  --card-tertiary-color: #054c70;\n  --text-tertiary-color: #8d8d8d;\n  --text-light-color: #69768b;\n}\n\n[data-theme='dark'] {\n  --background-color: #181924;\n  --card-color: #262837;\n  --accent-color: #0092d0;\n  --text-primary-color: #fdffff;\n  --text-secondary-color: #908f99;\n  --icon-color: #969fa1;\n  --card-secondary-color: #222331;\n  --line-color: #1e202d;\n  --error-color: #dd5c73;\n  --error-secondary-color: #ff4c6c;\n  --error-icon-color: #ef4f4f;\n  --success-color: #20d167;\n  --success-icon-color: #1bd164;\n  --card-tertiary-color: #054c70;\n  --text-tertiary-color: #8d8d8d;\n  --text-light-color: #69768b;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
