// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rScM3jEwJg0vwM4ACgSB {
  background-color: var(--background-color);
  height: 75px;
  position: fixed;
  width: 100%;
  margin: 0;
  z-index: 10;
}
.rScM3jEwJg0vwM4ACgSB .ZvFPGgh2VL6V71Gb86jQ {
  width: 80px;
}
.rScM3jEwJg0vwM4ACgSB .i6lfuNPqSHHuC4sHNhHa {
  border: none;
  background-color: #0092d0;
  height: 40px;
  width: 40px;
  border-radius: 12px;
}
.rScM3jEwJg0vwM4ACgSB .i6lfuNPqSHHuC4sHNhHa:hover {
  cursor: pointer;
}
.rScM3jEwJg0vwM4ACgSB .mlV4iOeTrs4u0nLNLhzM {
  color: var(--text-secondary-color);
  transition: all 0.5s;
}
.rScM3jEwJg0vwM4ACgSB .PHBkSKA6YRlQVsb3Xhy7 {
  color: var(--accent-color);
  transition: all 0.5s;
}
.rScM3jEwJg0vwM4ACgSB .mlV4iOeTrs4u0nLNLhzM:hover {
  color: var(--accent-color);
  transition: all 0.5s;
}

@media only screen and (min-width: 992px) {
  .i6lfuNPqSHHuC4sHNhHa {
    display: none !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/header/header.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,YAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAIE;EACE,kCAAA;EACA,oBAAA;AAFJ;AAKE;EACE,0BAAA;EACA,oBAAA;AAHJ;AAME;EACE,0BAAA;EACA,oBAAA;AAJJ;;AAQA;EACE;IACE,wBAAA;EALF;AACF","sourcesContent":[".navbar-container {\n  background-color: var(--background-color);\n  height: 75px;\n  position: fixed;\n  width: 100%;\n  margin: 0;\n  z-index: 10;\n\n  .app-button-container {\n    width: 80px;\n  }\n\n  .navbar-toggler {\n    border: none;\n    background-color: #0092d0;\n    height: 40px;\n    width: 40px;\n    border-radius: 12px;\n  }\n\n  .navbar-toggler:hover {\n    cursor: pointer;\n  }\n\n  .nav-hover {\n    color: var(--text-secondary-color);\n    transition: all 0.5s;\n  }\n\n  .nav-hover-selected {\n    color: var(--accent-color);\n    transition: all 0.5s;\n  }\n\n  .nav-hover:hover {\n    color: var(--accent-color);\n    transition: all 0.5s;\n  }\n}\n\n@media only screen and (min-width: 992px) {\n  .navbar-toggler {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar-container": `rScM3jEwJg0vwM4ACgSB`,
	"app-button-container": `ZvFPGgh2VL6V71Gb86jQ`,
	"navbar-toggler": `i6lfuNPqSHHuC4sHNhHa`,
	"nav-hover": `mlV4iOeTrs4u0nLNLhzM`,
	"nav-hover-selected": `PHBkSKA6YRlQVsb3Xhy7`
};
export default ___CSS_LOADER_EXPORT___;
