// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offcanvas {
  --bs-offcanvas-bg: transparent;
  width: 350px !important;
}

.offCanvasHeader {
  padding-bottom: 0 !important;
}

.btn-close {
  margin-left: auto;
  margin-top: 20px !important;
  margin-right: 20px !important;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/components/offCanvas/offCanvas.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,uBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,iBAAA;EACA,2BAAA;EACA,6BAAA;EACA,UAAA;AACF","sourcesContent":[".offcanvas {\n  --bs-offcanvas-bg: transparent;\n  width: 350px !important;\n}\n\n.offCanvasHeader {\n  padding-bottom: 0 !important;\n}\n\n.btn-close {\n  margin-left: auto;\n  margin-top: 20px !important;\n  margin-right: 20px !important;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
