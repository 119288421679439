// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NHjzx5ZXDJOEEEd6x3g8 {
  border-radius: 12px;
  background: var(--card-color);
}
.NHjzx5ZXDJOEEEd6x3g8 .QMv8YktpTdjy9BJmR5wq {
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/app-dnd-elements/app-dnd-mini-card/app-dnd-mini-card.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,6BAAA;AACF;AACE;EACE,gCAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".miniCardView {\n  border-radius: 12px;\n  background: var(--card-color);\n\n  .headingText {\n    color: var(--text-primary-color);\n    font-size: 16px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"miniCardView": `NHjzx5ZXDJOEEEd6x3g8`,
	"headingText": `QMv8YktpTdjy9BJmR5wq`
};
export default ___CSS_LOADER_EXPORT___;
