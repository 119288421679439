import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ICourseChapterPresenter } from 'app/modules/student/course-chapter-presenter/course-chapter-presenter.model';
import {
  CourseChapterPresenterEntityState,
  navigateToSpecificChapter,
} from 'app/modules/student/course-chapter-presenter/course-chapter-presenter.reducer';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsCardText, BsCheckLg, BsPlay, BsQuestionSquare } from 'react-icons/bs';
import styles from './AppChapterContentSecondaryNavBarItem.module.scss';

interface IProps {
  title: string;
  isCompleted: boolean;
  courseType: 'video' | 'text' | 'quiz';
  index: number;
}

const AppChapterContentSecondaryNavBarItem = (props: IProps) => {
  const dispatch = useAppDispatch();
  const courseChapterPresenter = useAppSelector(
    state => state.courseChapterPresenter
  ) as CourseChapterPresenterEntityState<ICourseChapterPresenter>;

  const getIcon = () => {
    const size = 24;
    const color = courseChapterPresenter.currentChapterIndex === props.index ? '#ffffff' : 'var(--icon-color)';
    switch (props.courseType) {
      case 'video':
        return <BsPlay size={size} color={color} />;
      case 'text':
        return <BsCardText size={size} color={color} />;
      case 'quiz':
        return <BsQuestionSquare size={size} color={color} />;
      default:
        return <BsPlay size={size} color={color} />;
    }
  };

  return (
    <Row className="cursor-pointer">
      <Col
        className={`${styles.menuItem} ${courseChapterPresenter.currentChapterIndex === props.index ? styles.activeItem : ''} px-2 py-3`}
        onClick={() => {
          dispatch(navigateToSpecificChapter(props.index));
        }}
      >
        <Row className="align-items-center">
          <Col className="col-2">{getIcon()}</Col>
          <Col className={`col-8 ps-0`}>{props.title}</Col>
          {props.isCompleted && (
            <Col className="col-2">
              <BsCheckLg color="#20D167" size={16} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default AppChapterContentSecondaryNavBarItem;
