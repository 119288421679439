// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OTD2FPrgHZQwtGmjZH02 {
  color: var(--text-primary-color, #3f3e4a);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.yTLmk64zM3H9bq91gxqq {
  color: var(--text-primary-color, #3f3e4a);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.sQBqIkJd15WWBZkmZrgC {
  color: var(--text-secondary-color, #767676);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/admin/admin-payments/admin-payment.module.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,yCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,2CAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AACF","sourcesContent":[".txt1 {\n  color: var(--text-primary-color, #3f3e4a);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n}\n\n.txt2 {\n  color: var(--text-primary-color, #3f3e4a);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n}\n\n.txt3 {\n  color: var(--text-secondary-color, #767676);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt1": `OTD2FPrgHZQwtGmjZH02`,
	"txt2": `yTLmk64zM3H9bq91gxqq`,
	"txt3": `sQBqIkJd15WWBZkmZrgC`
};
export default ___CSS_LOADER_EXPORT___;
