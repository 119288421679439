import { reset } from 'app/modules/student/course-chapter-presenter/course-chapter-presenter.reducer';
import { ECHAPTER } from 'app/shared/model/enumerations/chapters-types';
import { truncate } from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BsFillCaretLeftFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NoticeView from '../../notice-views/notice-view';
import styles from './AppChapterContentSecondaryNavBar.module.scss';
import AppChapterContentSecondaryNavBarItem from './AppChapterContentSecondaryNavBarItem';

export interface IChaptersNavList {
  itemName: string;
  chapterId: number;
  isCompleted?: boolean;
  contents?: Array<Object>;
}

interface IProps {
  title: string;
  subTitle?: string;
  itemList: IChaptersNavList[];
}

const AppChapterContentSecondaryNavBar = (props: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const checkVideoContent = item => {
    const hasVideo = item.contents.some(i => i.type === ECHAPTER.VIDEO);
    if (hasVideo) return 'video';
    else return 'text';
  };

  const onClickBackButton = () => {
    navigate(-1);
    dispatch(reset());
  };

  return (
    <>
      <Container>
        <Row>
          <Col className={`${styles.courseHeader}`}>
            <Row className="align-items-center">
              <Col className="col-2">
                <BsFillCaretLeftFill onClick={onClickBackButton} className="cursor-pointer" size={24} color={'var(--accent-color)'} />
              </Col>
              <Col className="col-10">
                <Row>
                  <Col className={`${styles.courseTitle} ps-0`}>{truncate(props.title, { length: 50 })}</Col>
                </Row>
                {props.subTitle && (
                  <Row className="mt-1">
                    <Col className={`${styles.courseSubTitle} ps-0`}>{props.subTitle}</Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={`${styles.itemsContainer}`}>
            {props.itemList.length > 0 ? (
              <Col>
                {props.itemList.map((item: IChaptersNavList, index: number) => {
                  return (
                    <AppChapterContentSecondaryNavBarItem
                      key={index}
                      isCompleted={item.isCompleted}
                      title={item.itemName}
                      courseType={checkVideoContent(item)}
                      index={index}
                    />
                  );
                })}
              </Col>
            ) : (
              <Row>
                <Col className="mt-3">
                  <NoticeView title="No Chapters" description="The instructor has yet to create chapters" />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AppChapterContentSecondaryNavBar;
