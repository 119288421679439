// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ORJtAmwRnbo_gpWJ_TrO {
  background-color: var(--card-color);
  border: 1px solid var(--line-color);
  width: 100%;
  padding: 0 6px;
  color: var(--text-primary-color);
}
.ORJtAmwRnbo_gpWJ_TrO.CNj_xEEbrLwFfHa3PeGh {
  border: 1px solid var(--error-color);
}

.sUN8aT027ezxs5rygJuZ {
  color: var(--text-secondary-color);
  margin-bottom: 3px;
  font-size: 14px;
}

.CaZtgphJxNFT0z5CMJj7 {
  color: var(--error-secondary-color);
}

.XqUroqjuJUkPIbXSGU3u {
  font-size: 12px;
  color: var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-select/app-select.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;EACA,mCAAA;EACA,WAAA;EACA,cAAA;EACA,gCAAA;AACF;AACE;EACE,oCAAA;AACJ;;AAGA;EACE,kCAAA;EACA,kBAAA;EACA,eAAA;AAAF;;AAGA;EACE,mCAAA;AAAF;;AAGA;EACE,eAAA;EACA,yBAAA;AAAF","sourcesContent":[".appSelectInput {\n  background-color: var(--card-color);\n  border: 1px solid var(--line-color);\n  width: 100%;\n  padding: 0 6px;\n  color: var(--text-primary-color);\n\n  &.hasDanger {\n    border: 1px solid var(--error-color);\n  }\n}\n\n.label {\n  color: var(--text-secondary-color);\n  margin-bottom: 3px;\n  font-size: 14px;\n}\n\n.required {\n  color: var(--error-secondary-color);\n}\n\n.errorMessage {\n  font-size: 12px;\n  color: var(--error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appSelectInput": `ORJtAmwRnbo_gpWJ_TrO`,
	"hasDanger": `CNj_xEEbrLwFfHa3PeGh`,
	"label": `sUN8aT027ezxs5rygJuZ`,
	"required": `CaZtgphJxNFT0z5CMJj7`,
	"errorMessage": `XqUroqjuJUkPIbXSGU3u`
};
export default ___CSS_LOADER_EXPORT___;
