// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eoopcTX7xJtwRcKXwmVv {
  background-color: var(--card-color);
  border-radius: 12px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/app-dnd-elements/app-panel-card/app-panel-card.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;EACA,mBAAA;AACF","sourcesContent":[".panel {\n  background-color: var(--card-color);\n  border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `eoopcTX7xJtwRcKXwmVv`
};
export default ___CSS_LOADER_EXPORT___;
