import { IMobileUpdate } from 'app/shared/model/dto/mobileNo-update.model';
import { IProfileUpdate } from 'app/shared/model/dto/profile-update.model';
import { IUser } from 'app/shared/model/user.model';
import axios from 'axios';

export const updateProfile = (entity: IProfileUpdate) => {
  return axios.put('api/update-current-user', entity);
};

export const updateMobile = (entity: IMobileUpdate) => {
  return axios.put('api/update-current-user-phone-no', entity);
};

export const updateProfileImage = (entity: IUser) => {
  return axios.put('api/account/update-profile-image', entity);
};
