import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getAllEntitiesStudent } from 'app/entities/course-enrollment/course-enrollment.reducer';
import CardLoader from 'app/shared/components/courses/card-loader/card-loader';
import CourseCard from 'app/shared/components/courses/course-card/couse-card';
import CourseFilter from 'app/shared/components/courses/course-filter/course-filter';
import Pagination from 'app/shared/components/pagination/pagination';
import { ECourseDifficultyLevel } from 'app/shared/model/enumerations/course-difficulty-level.model';
import { EFieldOfStudy } from 'app/shared/model/enumerations/field-of-study-type.model';
import AppButton from 'app/shared/ui-elements/app-button/app-button';
import AppTab from 'app/shared/ui-elements/app-tabs/app-tab';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { log } from 'react-jhipster';
import { useNavigate } from 'react-router-dom';

const StudentCourses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedTabId, setSelectedTabId] = useState('inProgress');
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedField, setSelectedField] = useState('');
  const [selectedSkillLevel, setSelectedSkillLevel] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  const { courseEnrollmentEntities, totalItems, loading } = useAppSelector((state: IRootState) => ({
    courseEnrollmentEntities: state.courseEnrollment.entities,
    totalItems: state.courseEnrollment.totalItems,
    loading: state.courseEnrollment.loading,
  }));

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setItemsPerPage(4);
      } else if (window.innerWidth >= 768) {
        setItemsPerPage(4);
      } else {
        setItemsPerPage(2);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (searchInput.trim()) {
      const timerId = setTimeout(() => {
        setDebouncedQuery(searchInput);
      }, 400);

      return () => {
        clearTimeout(timerId);
      };
    } else {
      setDebouncedQuery('');
    }
  }, [searchInput]);

  const getOrdinalValue = (enumObject: any, enumValue: string): number => {
    const keys = Object.keys(enumObject);
    const index = keys.findIndex(key => enumObject[key] === enumValue);
    log('index', index);
    return index;
  };

  const paramsList = useMemo(() => {
    let userCourseStatus = [];

    if (selectedTabId === 'inProgress') {
      userCourseStatus = [1];
    } else if (selectedTabId === 'notStarted') {
      userCourseStatus = [0];
    } else if (selectedTabId === 'completed') {
      userCourseStatus = [2];
    }

    return {
      ...(selectedSkillLevel && {
        difficultyLevel: getOrdinalValue(ECourseDifficultyLevel, selectedSkillLevel),
      }),
      ...(debouncedQuery && { searchText: debouncedQuery }),
      ...(selectedField && {
        fieldOfStudy: getOrdinalValue(EFieldOfStudy, selectedField),
      }),
      ...(selectedTabId && {
        userCourseStatus,
      }),
      page: currentPage - 1,
    };
  }, [selectedSkillLevel, debouncedQuery, selectedField, selectedTabId, currentPage]);

  useEffect(() => {
    dispatch(
      getAllEntitiesStudent({
        difficultyLevel: paramsList.difficultyLevel,
        searchText: paramsList.searchText,
        fieldOfStudy: paramsList.fieldOfStudy,
        userCourseStatus: paramsList.userCourseStatus,
        page: paramsList.page,
        size: itemsPerPage,
      })
    );
  }, [dispatch, paramsList, selectedTabId]);

  useEffect(() => {
    const maxPage = Math.ceil(totalItems / itemsPerPage);
    const newPage = maxPage < currentPage ? 1 : currentPage;
    setCurrentPage(newPage);
  }, [totalItems, currentPage]);

  const handlePageChange = currentPageNumber => {
    setCurrentPage(currentPageNumber);
  };

  const handleFilterChange = (field, skillLevel) => {
    setSelectedField(field);
    setSelectedSkillLevel(skillLevel);
  };

  const handleSearch = searchValue => {
    setSearchInput(searchValue);
  };

  return (
    <>
      <CourseFilter title="My Courses" onFilterChange={handleFilterChange} onSearch={handleSearch} />
      <Container className="mt-4 mt-md-5 mb-5">
        <Row className="justify-content-between mt-5 mb-5">
          <Col className="col-12 col-md-6 col-lg-5">
            <AppTab
              tabs={[
                {
                  tabName: 'In Progress',
                  id: 'inProgress',
                  path: 'inProgress',
                },
                {
                  tabName: 'Not Started',
                  id: 'notStarted',
                  path: 'notStarted',
                },
                {
                  tabName: 'Completed',
                  id: 'completed',
                  path: 'completed',
                },
              ]}
              onChange={id => {
                setCurrentPage(1);
                setSelectedTabId(id);
              }}
              selectedTabId={selectedTabId}
            />
          </Col>
          {selectedTabId === 'inProgress' && (
            <Col sm={12} md={4} lg={3} xl={2} className="text-md-right mt-4 mt-md-0">
              <AppButton
                text={'Program Catalog'}
                btnSize="md"
                onClick={() => {
                  navigate('/catalog');
                }}
              />
            </Col>
          )}
        </Row>

        <Row className="mt-3">
          {loading &&
            Array.from({ length: itemsPerPage }).map((_, index) => (
              <Col sm={12} key={index} className="mb-4">
                <CardLoader />
              </Col>
            ))}
        </Row>

        {!loading && (
          <>
            <Row className={`mt-5 mb-0 mb-md-2 mb-lg-5`}>
              {courseEnrollmentEntities.map(item => (
                <Col sm={12} key={item.id} className="mb-4">
                  <CourseCard
                    course={item}
                    status={item}
                    onClick={() => {
                      navigate(`/student/courses/${item.id}/course-materials`);
                    }}
                  />
                </Col>
              ))}
            </Row>
            <div className="mb-3">
              <Pagination length={totalItems} itemsPerPage={itemsPerPage} currentPage={currentPage} updateCurrentPage={handlePageChange} />
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default StudentCourses;
