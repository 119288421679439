// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VNFqQluuOfCTmQtxpUAh {
  height: 100%;
  border-radius: 12px 0px 0px 12px;
}
@media (max-width: 768px) {
  .VNFqQluuOfCTmQtxpUAh {
    height: 280px;
    border-radius: 12px 12px 0px 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/courses/card-loader/card-loader.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gCAAA;AACF;AACE;EAJF;IAKI,aAAA;IACA,gCAAA;EAEF;AACF","sourcesContent":[".cardImg {\n  height: 100%;\n  border-radius: 12px 0px 0px 12px;\n\n  @media (max-width: 768px) {\n    height: 280px;\n    border-radius: 12px 12px 0px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardImg": `VNFqQluuOfCTmQtxpUAh`
};
export default ___CSS_LOADER_EXPORT___;
