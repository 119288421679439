import { IRootState, useAppSelector } from 'app/config/store';
import { ETheme } from 'app/shared/model/enumerations/theme.model';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const AccountSideImageSection = () => {
  const { theme } = useAppSelector((state: IRootState) => ({ theme: state.theme.theme }));

  return (
    <>
      <Row className="justify-content-center d-lg-none">
        <Col className="col-6 mb-4">
          <img
            className="img-fluid"
            src={
              theme === ETheme.DARK
                ? '../../../../content/images/SKF_ALAthwalaLogo_Dark@2x.png'
                : '../../../../content/images/SKF_ALAthwalaLogo_Light@2x.png'
            }
            alt="skill surf logo"
          />
        </Col>
      </Row>
      <Row className="d-none d-lg-flex justify-content-center">
        <Col className="col-11">
          <img
            className="img-fluid"
            src={theme === ETheme.DARK ? '../../../../content/images/account_dark.webp' : '../../../../content/images/account_light.webp'}
            alt="Athwela Screen"
          />
        </Col>
      </Row>
    </>
  );
};

export default AccountSideImageSection;
