// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VDm8JMansMkLnrW0a76T {
  border-color: transparent;
  background-color: transparent;
  color: var(--icon-color);
}

.OI2Oq4uLjwk6C798Qkdm {
  border-color: transparent;
  background-color: transparent;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/theme-toggle/theme-toggle.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,6BAAA;EACA,wBAAA;AACF;;AAEA;EACE,yBAAA;EACA,6BAAA;EACA,cAAA;AACF","sourcesContent":[".toggle {\n  border-color: transparent;\n  background-color: transparent;\n  color: var(--icon-color);\n}\n\n.iconColor {\n  border-color: transparent;\n  background-color: transparent;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `VDm8JMansMkLnrW0a76T`,
	"iconColor": `OI2Oq4uLjwk6C798Qkdm`
};
export default ___CSS_LOADER_EXPORT___;
