// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nPghCS16lZbumes9qvOf {
  border: none;
  border-radius: 14px;
}
.nPghCS16lZbumes9qvOf .eFcsX8go4c2Mh0yvOwZA {
  color: var(--text-primary-color);
  font-size: 16px;
  font-weight: 400;
}
.nPghCS16lZbumes9qvOf .W36zVFuisKrXCXfCum5f {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-weight: 400;
}

.TKUKyGvmk_Isix8h5Ybc {
  color: var(--text-primary-color);
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/student/course-lessons-units-presenter/course-lessons-units-presenter.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mBAAA;AACF;AACE;EACE,gCAAA;EACA,eAAA;EACA,gBAAA;AACJ;AAEE;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;AAAJ;;AAIA;EACE,gCAAA;EACA,gBAAA;AADF","sourcesContent":[".accordionContainer {\n  border: none;\n  border-radius: 14px;\n\n  .header {\n    color: var(--text-primary-color);\n    font-size: 16px;\n    font-weight: 400;\n  }\n\n  .descriptionText {\n    color: var(--text-secondary-color);\n    font-size: 14px;\n    font-weight: 400;\n  }\n}\n\n.presenterTitle {\n  color: var(--text-primary-color);\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionContainer": `nPghCS16lZbumes9qvOf`,
	"header": `eFcsX8go4c2Mh0yvOwZA`,
	"descriptionText": `W36zVFuisKrXCXfCum5f`,
	"presenterTitle": `TKUKyGvmk_Isix8h5Ybc`
};
export default ___CSS_LOADER_EXPORT___;
