import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TinyMceEditorContent from '../../tinymce-content-editor/TinyMceEditorContent';
import AppDNDCard from '../app-dnd-card/app-dnd-card';

interface IProps {
  label: string;
  content: string;
  index?: string;
  height?: number;
  onEditorChange: (content: string) => void;
  onClickRemoveIcon?: () => void;
}

const AppDNDTinyMCEEditor = (props: IProps) => {
  const { label, content, index, height, onEditorChange, onClickRemoveIcon } = props;

  return (
    <>
      <Row>
        <Col>
          <AppDNDCard label={label} onClickRemoveIcon={onClickRemoveIcon}>
            <Row>
              <Col>
                <TinyMceEditorContent content={content} height={height} index={index} onEditorChange={onEditorChange} />
              </Col>
            </Row>
          </AppDNDCard>
        </Col>
      </Row>
    </>
  );
};

export default AppDNDTinyMCEEditor;
