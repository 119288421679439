// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GnvqPdaz0MZA6yYy4GQG .mlHpuOlgyQJluOnl_Ndm {
  color: var(--text-primary-color);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.GnvqPdaz0MZA6yYy4GQG .rRvobGEHyYKBWWkaiAvy {
  color: var(--accent-color);
  font-size: 30px;
}
.GnvqPdaz0MZA6yYy4GQG .ndwVCDEmV66u4OzuXRew {
  color: var(--text-primary-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.GnvqPdaz0MZA6yYy4GQG .Bx9Yl8FMAkyJbc_dpzCJ {
  color: var(--accent-color);
  font-size: 14px;
}
.GnvqPdaz0MZA6yYy4GQG .Bi42eI6TdBR7HtCRuwF6 {
  margin-bottom: 60px;
  margin-top: 60px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/pages/account/email-confirmation/email-confirmation.module.scss"],"names":[],"mappings":"AACE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAEI;EACE,0BAAA;EACA,eAAA;AAAN;AAIE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AAII;EACE,0BAAA;EACA,eAAA;AAFN;AAME;EACE,mBAAA;EACA,gBAAA;AAJJ","sourcesContent":[".formSection {\n  .mainText {\n    color: var(--text-primary-color);\n    font-size: 30px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n\n    &_verifyText {\n      color: var(--accent-color);\n      font-size: 30px;\n    }\n  }\n\n  .subText {\n    color: var(--text-primary-color);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n\n    &_emailText {\n      color: var(--accent-color);\n      font-size: 14px;\n    }\n  }\n\n  .customGap {\n    margin-bottom: 60px;\n    margin-top: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formSection": `GnvqPdaz0MZA6yYy4GQG`,
	"mainText": `mlHpuOlgyQJluOnl_Ndm`,
	"mainText_verifyText": `rRvobGEHyYKBWWkaiAvy`,
	"subText": `ndwVCDEmV66u4OzuXRew`,
	"subText_emailText": `Bx9Yl8FMAkyJbc_dpzCJ`,
	"customGap": `Bi42eI6TdBR7HtCRuwF6`
};
export default ___CSS_LOADER_EXPORT___;
