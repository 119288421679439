// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NUoeQcz4bSfLhtxtaXa_ {
  color: var(--text-secondary-color);
  font-size: 16px;
}

input[type=radio]:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: -4px;
  position: relative;
  background-color: #ffffff;
  content: "";
  display: inline-block;
  border: 1px solid #969fa1;
}

input[type=radio]:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: -4px;
  position: relative;
  background-color: #0092d0;
  content: "";
  display: inline-block;
  border: 1px solid #969fa1;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/radio-button/radio-button.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,qBAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,qBAAA;EACA,yBAAA;AACF","sourcesContent":[".label {\n  color: var(--text-secondary-color);\n  font-size: 16px;\n}\n\ninput[type='radio']:after {\n  width: 16px;\n  height: 16px;\n  border-radius: 15px;\n  top: -4px;\n  position: relative;\n  background-color: #ffffff;\n  content: '';\n  display: inline-block;\n  border: 1px solid #969fa1;\n}\n\ninput[type='radio']:checked:after {\n  width: 16px;\n  height: 16px;\n  border-radius: 15px;\n  top: -4px;\n  position: relative;\n  background-color: #0092d0;\n  content: '';\n  display: inline-block;\n  border: 1px solid #969fa1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `NUoeQcz4bSfLhtxtaXa_`
};
export default ___CSS_LOADER_EXPORT___;
