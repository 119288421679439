import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';
import styles from './expandable-field.module.scss';

interface IProps {
  label: string;
  isRequired?: boolean;
  children: JSX.Element;
}

const ExpandableField = (props: IProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const onClickExpandIcon = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Row>
      <Col>
        <div className={`${styles.customCard} p-3`}>
          {props.label && (
            <Row className="align-items-center">
              <Col className={`${styles.labelStyle}`}>
                {props.label} {props.isRequired && <span className={`${styles.asteriskStyles}`}> * </span>}
              </Col>

              <Col className="d-flex justify-content-end">
                {isExpanded ? (
                  <BsChevronDown className="cursor-pointer" size={20} onClick={onClickExpandIcon} />
                ) : (
                  <BsChevronRight className="cursor-pointer" size={20} onClick={onClickExpandIcon} />
                )}
              </Col>
            </Row>
          )}
          <Collapse in={isExpanded}>
            <div>{props.children}</div>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
};

export default ExpandableField;
