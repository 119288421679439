// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B3OTaFluDhfkrl4TUP8Y {
  --bs-progress-height: 6px;
  --bs-progress-bg: var(--card-secondary-color);
  --bs-progress-border-radius: 50px;
  --bs-progress-bar-bg: var(--success-color);
}

.upiCcQTWvf14bZFQgONs {
  color: var(--text-primary-color);
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/ui-elements/app-progress-bar/app-progress-bar.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,6CAAA;EACA,iCAAA;EACA,0CAAA;AACF;;AAEA;EACE,gCAAA;EACA,eAAA;AACF","sourcesContent":[".progressBar {\n  --bs-progress-height: 6px;\n  --bs-progress-bg: var(--card-secondary-color);\n  --bs-progress-border-radius: 50px;\n  --bs-progress-bar-bg: var(--success-color);\n}\n\n.progressCount {\n  color: var(--text-primary-color);\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": `B3OTaFluDhfkrl4TUP8Y`,
	"progressCount": `upiCcQTWvf14bZFQgONs`
};
export default ___CSS_LOADER_EXPORT___;
