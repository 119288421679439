import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { ICoupon } from 'app/shared/model/coupon.model';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';

const initialState = {
  loading: false,
  actionStatus: false,
  errorMessage: null,
  totalItems: 0,
  couponsList: [] as ReadonlyArray<ICoupon>,
  couponEntity: null as ICoupon,
  couponUsageList: [],
};

const apiUrlCoupons = 'api/admin/coupons';

export const getCouponByCode = createAsyncThunk('couponDashBoard/fetch_coupon_by_code', async (data: { coupon: string }) => {
  const requestUrl = `${apiUrlCoupons}?couponCode=${data.coupon}`;
  return axios.get<ICoupon[]>(requestUrl);
});

export const getCouponEntity = createAsyncThunk('couponViewer/view_coupon_details', async (data: { coupon: string }) => {
  const requestUrl = `${apiUrlCoupons}/${data.coupon}`;
  return axios.get<ICoupon>(requestUrl);
});

export const searchCoupons = createAsyncThunk(
  'couponManager/search_coupon_by_name',
  async (data: { searchText?: string; page: number; size: number; sort?: string }) => {
    const requestUrl = `${apiUrlCoupons}?search=${data.searchText}&page=${data.page}&size=${data.size}${
      data.sort ? `&sort=${data.sort}` : ''
    }&searchBy=couponCode`;
    return axios.get<ICoupon[]>(requestUrl);
  }
);

export const getAllCoupons = createAsyncThunk('couponDashBoard/fetch_all_coupons', async (data: { page: number; size: number }) => {
  const requestUrl = `${apiUrlCoupons}?page=${data.page}&size=${data.size}`;
  return axios.get<ICoupon[]>(requestUrl);
});

export const getCouponUsage = createAsyncThunk(
  'couponDashBoard/view_coupon_usage',
  async (data: { id: string; page?: number; size?: number; sort?: string }) => {
    const requestUrl = `${apiUrlCoupons}/${data.id}/usage-view?${data.sort ? `page=${data.page}&size=${data.size}&sort=${data.sort}` : ''}`;
    return axios.get<ICoupon[]>(requestUrl);
  }
);

export const createCoupon = createAsyncThunk(
  'couponsManager/to_create_coupon',
  async (data: ICoupon) => {
    const result = axios.post(apiUrlCoupons, data);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateCoupon = createAsyncThunk(
  'couponsManager/to_update_coupon',
  async (data: { id: string; coupon: ICoupon }) => {
    const result = axios.put(`${apiUrlCoupons}/${data.id}`, data.coupon);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateCouponState = createAsyncThunk(
  'couponsManager/to_update_coupon_state',
  async (data: { id: string; isActive: boolean }) => {
    const result = axios.patch(`${apiUrlCoupons}/state-change/${data.id}?isActive=${data.isActive}`);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteCoupon = createAsyncThunk(
  'couponViewer/delete_coupon_details',
  async (data: { id: string }) => {
    const requestUrl = `${apiUrlCoupons}/${data.id}`;
    return axios.delete<ICoupon>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export type TCouponState = Readonly<typeof initialState>;

export const couponsSlice = createSlice({
  name: 'coupons',
  initialState: initialState as TCouponState,
  reducers: {
    resetCoupons() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.couponsList = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(getCouponByCode.fulfilled, (state, action) => {
        state.loading = false;
        state.couponsList = action.payload.data;
      })
      .addCase(getCouponEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.couponEntity = action.payload.data;
      })
      .addCase(getCouponUsage.fulfilled, (state, action) => {
        state.loading = false;
        state.couponUsageList = action.payload.data;
      })
      .addCase(searchCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.couponsList = action.payload.data;
        state.totalItems = parseInt(action.payload.headers['x-total-count'], 10);
      })
      .addCase(createCoupon.fulfilled, (state, action) => {
        state.loading = false;
        state.actionStatus = true;
        state.couponEntity = action.payload.data;
      })
      .addCase(updateCoupon.fulfilled, (state, action) => {
        state.loading = false;
        state.actionStatus = true;
        state.couponEntity = action.payload.data;
      })
      .addCase(updateCouponState.fulfilled, (state, action) => {
        state.loading = false;
        state.couponEntity = action.payload.data;
        state.actionStatus = true;
        state.errorMessage = null;
      })
      .addCase(deleteCoupon.fulfilled, (state, action) => {
        state.loading = false;
        state.couponEntity = action.payload.data;
        state.actionStatus = true;
      })
      .addMatcher(
        isPending(
          getCouponByCode,
          getAllCoupons,
          getCouponUsage,
          getCouponEntity,
          searchCoupons,
          createCoupon,
          updateCoupon,
          updateCouponState,
          deleteCoupon
        ),
        state => {
          state.errorMessage = null;
          state.actionStatus = false;
          state.loading = true;
        }
      )
      .addMatcher(
        isRejected(
          getCouponByCode,
          getAllCoupons,
          getCouponUsage,
          getCouponEntity,
          searchCoupons,
          createCoupon,
          updateCoupon,
          updateCouponState,
          deleteCoupon
        ),
        (state, action) => {
          state.loading = false;
          state.actionStatus = false;
          state.errorMessage = action.error.message;
        }
      );
  },
});

export const { resetCoupons } = couponsSlice.actions;
export default couponsSlice.reducer;
