// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L9bqRK_2oGrjSePw7j4D .dDspb5X7_SfP1iKLjAut {
  background: transparent;
  border: 2px dashed var(--icon-color);
  border-radius: 12px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.L9bqRK_2oGrjSePw7j4D .QoqcbnKBbkrzzCkjCAaT {
  color: var(--text-primary-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.L9bqRK_2oGrjSePw7j4D .WBvSZV7VNO0NiFFgG9ac {
  color: var(--text-secondary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/components/notice-views/notice-view.module.scss"],"names":[],"mappings":"AACE;EACE,uBAAA;EACA,oCAAA;EACA,mBAAA;EACA,kBAAA;EACA,qBAAA;AAAJ;AAGE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAIE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".noticeViewBox {\n  .noticeViewBoxBorder {\n    background: transparent;\n    border: 2px dashed var(--icon-color);\n    border-radius: 12px;\n    padding-top: 100px;\n    padding-bottom: 100px;\n  }\n\n  .noticeViewBoxTitle {\n    color: var(--text-primary-color);\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n\n  .noticeViewBoxDescription {\n    color: var(--text-secondary-color);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noticeViewBox": `L9bqRK_2oGrjSePw7j4D`,
	"noticeViewBoxBorder": `dDspb5X7_SfP1iKLjAut`,
	"noticeViewBoxTitle": `QoqcbnKBbkrzzCkjCAaT`,
	"noticeViewBoxDescription": `WBvSZV7VNO0NiFFgG9ac`
};
export default ___CSS_LOADER_EXPORT___;
