// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vXYGRhNmt5Oxa5q64Sbk {
  color: var(--text-secondary-color);
  font-size: 14px;
  font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/payment-footer/payment-footer.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;AACF","sourcesContent":[".txt3 {\n  color: var(--text-secondary-color);\n  font-size: 14px;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"txt3": `vXYGRhNmt5Oxa5q64Sbk`
};
export default ___CSS_LOADER_EXPORT___;
