import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './app-panel-card.module.scss';

interface IProps {
  children: JSX.Element;
}

const AppPanelCard = (props: IProps) => {
  return (
    <>
      <Row>
        <Col>
          <div className={`${styles.panel} p-3 mb-3`}>{props.children}</div>
        </Col>
      </Row>
    </>
  );
};

export default AppPanelCard;
