import ModelLayout from 'app/shared/layout/layouts/ModelLayout';
import { ECouponDiscountTypeStrings } from 'app/shared/model/enumerations/coupon-mode';
import { EPaymentStatus } from 'app/shared/model/enumerations/payment-status.model';
import { IPayment } from 'app/shared/model/payment.model';
import { displayDateTime } from 'app/shared/util/date-utils';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './order-information-modal.module.scss';

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  OrderInformationData: IPayment;
}

const OrderInformationModal = (props: IProps) => {
  const renderLabelValue = (label: string, value: any) => {
    return (
      <Row key={label}>
        <Col xs={'auto'} lg={3} className={`${styles.txt2}`}>
          {label}
        </Col>
        <Col className={`${styles.txt3} col-auto`}>{value}</Col>
      </Row>
    );
  };

  return (
    <ModelLayout isOpen={props.isOpen} closeHandler={props.closeHandler} modelTitle="Order Information">
      <>
        <Row>
          <Col className={styles.txt1}>Payment Information</Col>
        </Row>
        {renderLabelValue('Payment ID', props.OrderInformationData?.paymentId)}
        {renderLabelValue('Amount', props.OrderInformationData?.subTotal && 'LKR ' + props.OrderInformationData?.subTotal.toLocaleString())}
        {renderLabelValue(
          'Status',
          props.OrderInformationData?.paymentStatus === EPaymentStatus.PENDING ? (
            <span className={`${styles.pending}`}>Pending Payhere</span>
          ) : props.OrderInformationData?.paymentStatus === EPaymentStatus.SUCCESS ? (
            <span className={`${styles.success}`}>Received Payment</span>
          ) : props.OrderInformationData?.paymentStatus === EPaymentStatus.FAILED ? (
            <span className={`${styles.txt3}`}>Payment Failed</span>
          ) : props.OrderInformationData?.paymentStatus === EPaymentStatus.CANCELED ? (
            <span className={`${styles.canceled}`}>Payment Canceled</span>
          ) : props.OrderInformationData?.paymentStatus === EPaymentStatus.CHARGEBACK ? (
            <span className={`${styles.txt3}`}>Change Back</span>
          ) : (
            <></>
          )
        )}
        {renderLabelValue('Purchased on', displayDateTime(props.OrderInformationData?.paidOn))}
        {renderLabelValue(
          'Payment Method',
          props.OrderInformationData?.paymentMethod &&
            props.OrderInformationData?.paymentMethod.charAt(0) +
              props.OrderInformationData?.paymentMethod.slice(1).toLowerCase() +
              ' | ' +
              `${props.OrderInformationData?.paymentMethodInformation.slice(
                0,
                4
              )} ${props.OrderInformationData?.paymentMethodInformation.slice(
                4,
                8
              )} ${props.OrderInformationData?.paymentMethodInformation.slice(
                8,
                12
              )} ${props.OrderInformationData?.paymentMethodInformation.slice(12)}`
        )}
        <Row className="mt-4">
          <Col className={styles.txt1}>Course Information and Discounts</Col>
        </Row>
        {renderLabelValue('Course', props.OrderInformationData?.courseName)}
        {renderLabelValue(
          'Course Price',
          props.OrderInformationData?.coursePrice && 'LKR ' + props.OrderInformationData?.coursePrice.toLocaleString()
        )}
        {renderLabelValue(
          'Discount',
          props.OrderInformationData?.discount && props.OrderInformationData?.discount
            ? props.OrderInformationData?.discountType === ECouponDiscountTypeStrings.AMOUNT
              ? `LKR ${(props.OrderInformationData.discount as number).toLocaleString()}`
              : `${props.OrderInformationData?.discount}%`
            : null
        )}
        {renderLabelValue('Coupon Codes', props.OrderInformationData?.couponCode)}
        <Row className="mt-4">
          <Col className={styles.txt1}>User Information</Col>
        </Row>
        {renderLabelValue('Account ID', props.OrderInformationData?.userAccountId)}
        {renderLabelValue('Name', props.OrderInformationData?.billingFirstname + ' ' + props.OrderInformationData?.billingLastname)}
        {renderLabelValue('Email', props.OrderInformationData?.email)}
        {renderLabelValue(
          'Contact No',
          props.OrderInformationData?.contactNumber.startsWith('+94')
            ? props.OrderInformationData?.contactNumber.replace('+94', '0')
            : props.OrderInformationData?.contactNumber
        )}
      </>
    </ModelLayout>
  );
};

export default OrderInformationModal;
